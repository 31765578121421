import { DEFAULT_FALLBACK_CHAIN, SupportedChainId } from './chains'

export enum FeatureState {
  Hidden = -3,
  Unsupported = -2,
  BridgeSupported = -1,
  ComingSoon = 0,
  PartlySupported = 1,
  Supported = 2,
}

export enum Features {
  TRADE = 'Trade',
  BRIDGE = 'Bridge',
  SUPERFARMS = 'Superfarms',
  PERPETUALS = 'Perpetuals',
  LEADERBOARD = 'Leaderboard',
  MOCKTOKEN = 'Mocktoken',
}

type Feats = {
  [key in Features]: {
    [key in SupportedChainId]?: FeatureState
  }
}

export const DappFeatures: Feats = {
  [Features.TRADE]: {
    [SupportedChainId.OPTIMISM_GOERLI]: FeatureState.Hidden,
    [SupportedChainId.OPTIMISM]: FeatureState.Hidden,
    [SupportedChainId.MAINNET]: FeatureState.Supported,
  },
  [Features.BRIDGE]: {
    [SupportedChainId.OPTIMISM_GOERLI]: FeatureState.Supported,
    [SupportedChainId.OPTIMISM]: FeatureState.ComingSoon,
    [SupportedChainId.MAINNET]: FeatureState.Supported,
  },
  [Features.SUPERFARMS]: {
    [SupportedChainId.OPTIMISM_GOERLI]: FeatureState.Hidden,
    [SupportedChainId.OPTIMISM]: FeatureState.Hidden,
    [SupportedChainId.MAINNET]: FeatureState.Supported,
  },
  [Features.PERPETUALS]: {
    [SupportedChainId.NULL_CHAIN]: FeatureState.Unsupported,
    [SupportedChainId.OPTIMISM_GOERLI]: FeatureState.Supported,
    [SupportedChainId.OPTIMISM]: FeatureState.Supported,
    [SupportedChainId.MAINNET]: FeatureState.ComingSoon,
  },
  [Features.LEADERBOARD]: {
    [SupportedChainId.OPTIMISM_GOERLI]: FeatureState.Supported,
    [SupportedChainId.OPTIMISM]: FeatureState.Supported,
    [SupportedChainId.MAINNET]: FeatureState.Unsupported,
  },
  [Features.MOCKTOKEN]: {
    [SupportedChainId.OPTIMISM_GOERLI]: FeatureState.Supported,
    [SupportedChainId.MAINNET]: FeatureState.Unsupported,
    [SupportedChainId.OPTIMISM]: FeatureState.Unsupported,
  },
}

export function checkSupport(
  feature: Features,
  chainId: number = DEFAULT_FALLBACK_CHAIN,
  support: FeatureState | number = 1
): boolean {
  if (!SupportedChainId[chainId]) return false
  if (!DappFeatures[feature]) return false
  const featureState = DappFeatures[feature][chainId as SupportedChainId]
  if (!featureState) return false
  return featureState >= support
}

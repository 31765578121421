import { TransactionResponse } from '@ethersproject/abstract-provider'
import { BytesLike } from '@ethersproject/bytes'
import { formatBytes32String, parseBytes32String } from '@ethersproject/strings'
import Coin from 'assets/images/coin_ref.png'
import TwoCoins from 'assets/images/coins_ref.png'
import { ButtonError } from 'components/Button'
import { Dots } from 'components/swap/styleds'
import { ZERO_ADDRESS } from 'constants/misc'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import useCopyClipboard from 'hooks/useCopyClipboard'
import ms from 'ms.macro'
import { useEffect, useMemo, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { generate } from 'referral-codes'
import { useGetReferrerLeaderboardQuery } from 'state/referral/generated'
import { OrderDirection, ReferrerStat_OrderBy } from 'state/referral/generated'
import { useReferralCodes, useReferralStats, useRefReaderContract, useRefStorageContract } from 'state/referral/hooks'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

const ColumnWrapper = styled.div`
  width: 100%;
  margin-top: 2.5rem;
  display: flex;
  gap: 2rem;
  justify-content: center;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: flex;
    flex-direction: column-reverse;
    justify-content: start;
  `}
`

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const LeaderboardContainer = styled(CardContainer)`
  width: 100%;
  max-width: 700px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    max-width: 100%;
  `}
  background-color: ${({ theme }) => theme.bg0};
`
const ReferralWrapper = styled(CardContainer)`
  position: sticky;
  width: 100%;
  max-width: 450px;
  height: fit-content;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    position: static;
    max-width: 100%;
  `}
`

const ReferralContainer = styled.div`
  background-color: ${({ theme }) => theme.bg0};
`

const CoinImgResponsive = styled.img`
  position: absolute;
  top: 2.5rem;
  right: -2.5rem;
  user-select: none;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display:none;
  `}
`

const PageButton = styled.button<{ disabled?: boolean; right?: boolean; onClick?: any }>`
  background: none;
  border: none;
  width: 50%;
  font-size: 14px;
  text-align: ${({ right }) => (right ? 'right' : 'left')};
  cursor: ${({ theme, disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  color: ${({ theme, disabled }) => (disabled ? theme.text3 : theme.text1)};
`

const TwoCoinsImgResponsive = styled.img`
  position: absolute;
  right: -3.25rem;
  bottom: -3rem;
  user-select: none;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display:none;
  `}
`
const CardContent = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 0.75rem;
  `}
`

const LeaderboardContent = styled(CardContent)`
  gap: 1.5rem;
`

const CodeContainer = styled.div`
  padding: 1rem;
  display: flex;
  background-color: ${({ theme }) => theme.bg2};
  justify-content: space-between;
`

const StatsContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1px 1fr;
  border: 1px solid;
  border-color: ${({ theme }) => theme.bg2};
  > div {
    padding: 1rem;
  }
`

const SwitchContainer = styled.div`
  padding: 0.75rem;
  display: flex;
  width: fit-content;
  background-color: ${({ theme }) => theme.bg2};
`

const SwitchButton = styled.button<{ active: boolean }>`
  padding: 0.75rem;
  background-color: ${({ theme, active }) => (active ? theme.primary1 : 'transparent')};
  border: none;
  color: ${({ theme, active }) => (active ? theme.white : theme.text1)};
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme, active }) => (active ? theme.primary1 : theme.bg1)};
  }
`

const TableContent = styled.div`
  display: flex;
  flex-direction: column;
`
const gridColumns = '0.5fr 1fr 1fr 2fr'
const gridColumnsSmall = '0.5fr 1fr 1fr 1fr'
const RefTableHeader = styled.div`
  display: grid;
  grid-template-columns: ${gridColumns};
  background-color: ${({ theme }) => theme.bg2};
  padding: 0 0.5rem;
  font-size: 12px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0.75rem;
    grid-template-columns: ${gridColumnsSmall};
  `}
`

const RefListItem = styled.div`
  display: grid;
  grid-template-columns: ${gridColumns};
  padding: 0 0.5rem;
  font-size: 12px;
  border-bottom: 1px solid ${({ theme }) => theme.bg2};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0.75rem;
    grid-template-columns: ${gridColumnsSmall};
  `}
`

const RefListColumn = styled.div`
  padding: 0.75rem 0;
`

const RefHeadColumn = styled.div`
  padding: 0.75rem 0;
`

const Divider = styled.span`
  height: 100%;
  background-color: ${({ theme }) => theme.bg2};
  width: 1px;
`

const ReferralButton = styled(ButtonError)`
  width: unset;
`

const TextColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`

const PAGE_SIZE = 50
export default function ReferAndCompete(props: RouteComponentProps) {
  const { account } = useActiveWeb3React()
  const [isCopied, setCopied] = useCopyClipboard()
  const [isRefLeaderboard, setRefLeaderboard] = useState(true)

  const refStorageContract = useRefStorageContract()
  const refReaderContract = useRefReaderContract()

  const [code, setCode] = useState('')
  const [loaded, setLoaded] = useState(false)

  const [leaderboard, setLeaderboard] = useState([])
  const [leaderboardLoaded, setLeaderboardLoaded] = useState(false)
  const [page, setPage] = useState(0)
  const [canNextPage, setCanNextPage] = useState(false)

  const [generating, setGenerating] = useState(false)
  const referralCode: any = useReferralCodes()
  const referrerStats: any = useReferralStats(formatBytes32String(code))
  const referrerLeaderboardRaw = useGetReferrerLeaderboardQuery(
    {
      first: PAGE_SIZE + 1,
      skip: page * PAGE_SIZE,
      orderBy: ReferrerStat_OrderBy.TradedReferralsCountCumulative,
      orderDirection: OrderDirection.Desc,
    },
    {
      pollingInterval: ms`5 minutes`,
    }
  )

  useEffect(() => {
    if (!referrerLeaderboardRaw) return
    if (referrerLeaderboardRaw.status !== 'fulfilled') {
      return
    }
    const raw_response = referrerLeaderboardRaw.data.referrerStats
    setLeaderboardLoaded(true)
    setCanNextPage(raw_response.length === 51)
    if (!raw_response.length) return
    setLeaderboard(raw_response)
  }, [referrerLeaderboardRaw])

  useEffect(() => {
    if (!account || !referralCode) return
    if (referralCode.status !== 'fulfilled') {
      return
    }
    setLoaded(true)
    const raw_code = referralCode.data.referralCodes[0]
    if (!raw_code) return
    if (raw_code.code) {
      setGenerating(false)
    }
    setCode(raw_code.code ? parseBytes32String(raw_code.code) : '')
  }, [account, referralCode])

  const polishedStats: [
    boolean,
    { tradedReferralsCountCumulative: number; registeredReferralsCountCumulative: number }
  ] = useMemo(() => {
    if (!account || !code || !referrerStats)
      return [false, { tradedReferralsCountCumulative: 0, registeredReferralsCountCumulative: 0 }]
    if (referrerStats.status !== 'fulfilled') {
      return [false, { tradedReferralsCountCumulative: 0, registeredReferralsCountCumulative: 0 }]
    }
    const raw = referrerStats.data.referrerStats[0]
    if (!raw) return [false, { tradedReferralsCountCumulative: 0, registeredReferralsCountCumulative: 0 }]

    const { tradedReferralsCountCumulative, registeredReferralsCountCumulative } = raw

    return [true, { tradedReferralsCountCumulative, registeredReferralsCountCumulative }]
  }, [account, code, referrerStats])

  const handleRegister = async () => {
    if (!account || !refReaderContract || !refStorageContract) {
      return
    }
    const newCodes = generate({
      length: 8,
      count: 10,
      charset: '0123456789abcdefghijklmnopqrstuvwxyz',
    })
    let true_code = ''
    setGenerating(true)
    await refReaderContract
      .getCodeOwners(
        refStorageContract.address,
        newCodes.map((code) => formatBytes32String(code))
      )
      .then((response: any) => {
        true_code = newCodes[response.findIndex((el: any) => el === ZERO_ADDRESS)]
      })

    if (!true_code) return
    refStorageContract
      .registerCode(formatBytes32String(true_code))
      .then((response: TransactionResponse) => {
        // console.log(true_code, formatBytes32String(true_code))
      })
      .catch((e: any) => {
        console.log(e)
        setGenerating(false)
      })
  }

  const handleCopy = () => {
    setCopied(window.location.host + '/#/?ref=' + code)
  }
  return (
    <ColumnWrapper>
      <LeaderboardContainer>
        <LeaderboardContent>
          <ThemedText.MediumHeader fontWeight={700}>Leaderboard</ThemedText.MediumHeader>
          <SwitchContainer>
            <SwitchButton active={isRefLeaderboard} onClick={() => setRefLeaderboard(true)}>
              Referral Leaderboard
            </SwitchButton>
            <SwitchButton active={!isRefLeaderboard} onClick={() => setRefLeaderboard(false)}>
              Trading Leaderboard
            </SwitchButton>
          </SwitchContainer>
          <TableContent>
            {false ? (
              <>
                <RefTableHeader>
                  <RefHeadColumn>#</RefHeadColumn>
                  <RefHeadColumn>Referral</RefHeadColumn>
                  <RefHeadColumn>Ref. Count</RefHeadColumn>
                  <RefHeadColumn>Volume</RefHeadColumn>
                </RefTableHeader>
                <div style={{ minHeight: '50vh' }}>
                  {leaderboard.map((item: Record<string, unknown>, i: number) => {
                    if (i === 50) return null
                    return (
                      <RefListItem key={'leaderboard-item-' + (item.referralCode as string)}>
                        <RefListColumn>{page * PAGE_SIZE + i + 1}</RefListColumn>
                        <RefListColumn>{parseBytes32String(item.referralCode as BytesLike)}</RefListColumn>
                        <RefListColumn>{item.registeredReferralsCountCumulative as number}</RefListColumn>
                        <RefListColumn>${item.volumeCumulative as number}</RefListColumn>
                      </RefListItem>
                    )
                  })}
                </div>
                <div style={{ display: 'flex', gap: '1rem', justifyContent: 'center' }}>
                  <PageButton disabled={page === 0} right onClick={() => setPage((prev) => prev - 1)}>
                    Previous page
                  </PageButton>
                  <PageButton disabled={!canNextPage} onClick={() => setPage((prev) => prev + 1)}>
                    Next page
                  </PageButton>
                </div>
              </>
            ) : (
              <div style={{ minHeight: '50vh', marginBottom: '59px' }}>Coming soon</div>
            )}
          </TableContent>
        </LeaderboardContent>
      </LeaderboardContainer>
      <ReferralWrapper>
        <CoinImgResponsive src={Coin} draggable={false} />
        <TwoCoinsImgResponsive src={TwoCoins} draggable={false} />
        <ReferralContainer>
          <CardContent>
            <ThemedText.MediumHeader fontWeight={700}>Refer your friends</ThemedText.MediumHeader>
            <ThemedText.Small fontWeight={300}>
              Refer and get access to lower fees and special rewards!
            </ThemedText.Small>
            <CodeContainer>
              <TextColumn>
                <ThemedText.Main fontWeight={300}>Your Referral Code</ThemedText.Main>
                <ThemedText.LargeHeader fontWeight={400}>
                  {loaded ? (
                    generating ? (
                      <>
                        Generating code
                        <Dots />
                      </>
                    ) : (
                      code || '_ _ _ _ _ _ _ _'
                    )
                  ) : account ? (
                    <>
                      Loading
                      <Dots />
                    </>
                  ) : (
                    '_ _ _ _ _ _ _ _'
                  )}
                </ThemedText.LargeHeader>
              </TextColumn>
              <ReferralButton disabled={true} onClick={() => (loaded && !code ? handleRegister() : handleCopy())}>
                {loaded && !code ? 'Generate' : isCopied ? 'Copied!' : 'Copy'}
              </ReferralButton>
            </CodeContainer>
            <StatsContainer>
              <TextColumn>
                <ThemedText.Main fontWeight={300}>People referred</ThemedText.Main>
                <ThemedText.LargeHeader fontWeight={400}>
                  {polishedStats[1].registeredReferralsCountCumulative}
                </ThemedText.LargeHeader>
              </TextColumn>
              <Divider />
              <TextColumn>
                <ThemedText.Main fontWeight={300}>Fee discount</ThemedText.Main>
                <ThemedText.LargeHeader fontWeight={400}>0%</ThemedText.LargeHeader>
              </TextColumn>
            </StatsContainer>
          </CardContent>
        </ReferralContainer>
      </ReferralWrapper>
    </ColumnWrapper>
  )
}

import { Trans } from '@lingui/macro'
import { CHAIN_INFO } from 'constants/chainInfo'
import { DEFAULT_FALLBACK_CHAIN } from 'constants/chains'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { AlertOctagon, AlertTriangle, ThumbsUp } from 'react-feather'
import styled from 'styled-components/macro'
import { ExternalLink, MEDIA_WIDTHS } from 'theme'

const BodyRow = styled.div`
  color: ${({ theme }) => theme.black};
  font-size: 12px;
`

const DangerIcon = styled(AlertTriangle)`
  color: ${({ theme }) => theme.black};
`

const CautionIcon = styled(AlertOctagon)`
  color: ${({ theme }) => theme.black};
`

const OkayIcon = styled(ThumbsUp)`
  color: ${({ theme }) => theme.black};
`

const Link = styled(ExternalLink)`
  color: ${({ theme }) => theme.black};
  text-decoration: underline;
`
const TitleRow = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 8px;
`
const TitleText = styled.div`
  color: black;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin: 0px 12px;
`
const Wrapper = styled.div<{ level: number }>`
  background-color: ${({ theme, level }) => (level > 1 ? theme.red2 : level > 0 ? theme.yellow3 : theme.green1)};
  border-radius: 0;
  bottom: 60px;
  display: none;
  max-width: 348px;
  padding: 16px 20px;
  position: fixed;
  right: 16px;
  cursor: pointer;
  @media screen and (min-width: ${MEDIA_WIDTHS.upToMedium}px) {
    display: block;
  }
`

export function VersionWarning({ version, level }: { version: string; level: number }) {
  const { chainId } = useActiveWeb3React()
  const info = CHAIN_INFO[chainId ?? DEFAULT_FALLBACK_CHAIN]
  const label = info?.label

  return (
    <Wrapper onClick={() => window.location.reload()} level={level}>
      <TitleRow>
        {level > 1 ? <DangerIcon /> : level > 0 ? <CautionIcon /> : <OkayIcon />}
        <TitleText>
          <Trans>New version {version} released!</Trans>
        </TitleText>
      </TitleRow>
      <BodyRow>
        <Trans>Click this popup to reload the page and update the app</Trans>
      </BodyRow>
    </Wrapper>
  )
}

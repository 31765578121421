import { ChevronDown24 } from '@carbon/icons-react'
import Column from 'components/Column'
import CurrencyLogo from 'components/CurrencyLogo'
import { PriceContext } from 'components/PriceProvider'
import Row from 'components/Row'
import { Product } from 'constants/perps'
import { useContext } from 'react'
import styled, { useTheme } from 'styled-components/macro'
import { ThemedText } from 'theme'

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.bg0};
`

const HeaderGrid = styled.div`
  display: flex;
  width: 100%;
  grid-gap: 0;
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    align-items: start;
  `}
`

const Separator = styled.div`
  height: 3rem;
  width: 1px;
  background-color: ${({ theme }) => theme.bg2};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `}
`

const SelectContractButton = styled.button`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.text1};
  padding: 0.75rem;
  background: ${({ theme }) => theme.bg0};
  border: none;
  cursor: pointer;
  .price-small {
    display: none;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    height: 50%;
    width: 100%;
    justify-content: start;
    > svg:last-child {
      margin-left: auto;
    }
    .price-small {
      display: block;
      margin-left: 0.5rem;
    }
  `}
`

const SettingsButton = styled.button`
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.text1};
  padding: 0.75rem;
  background: ${({ theme }) => theme.bg0};
  border: none;
  cursor: pointer;
`

const HeaderColumn = styled(Column)`
  height: 100%;
  padding: 0.75rem;
  justify-content: center;
  gap: 0.2rem;
`

const ResponsiveRow = styled(Row)<{ cardWidth: number }>`
  gap: 1.25rem;
  * {
    white-space: nowrap;
  }
  ${({ cardWidth }) => {
    switch (true) {
      case cardWidth < 2:
        return `
          margin-top: auto;
          width:100%;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          .pricechange-box, .volume-box {
            display:none!important;
          }
          > div {
            padding: 0rem 0.75rem 0.75rem 0.75rem;
            gap:0rem;
          }
        `
      case cardWidth < 9:
        return `
          .volume-box, high-box, low-box {
              display: none!important;
            } 
        `
      case cardWidth < 10:
        return `
          .pricechange-box {
            display: none!important;
          } 
        `
      case cardWidth < 11:
        return `
          gap: 0.75rem!important;
        `
      default:
        return ``
    }
  }}

  @media only screen and (max-width: 1080px) {
    gap: 0.25rem;
  }
`

export default function ContractHeader({
  product,
  width,
  onChooseModal,
}: {
  product: Product
  width: number
  onChooseModal: () => void
}) {
  const { text3, red2, green1 } = useTheme()

  const PriceCtx = useContext(PriceContext) as any
  const productPrice = PriceCtx[product.id]

  return (
    <Wrapper>
      <HeaderGrid>
        <SelectContractButton className="drag-header" onClick={onChooseModal}>
          <CurrencyLogo currency={product.token} style={{ marginRight: '0.75rem' }} />
          <ThemedText.LargeHeader fontWeight={400} style={{ marginRight: '0.25rem' }}>
            {product.label}
          </ThemedText.LargeHeader>
          <ThemedText.MediumHeader
            className="price-small"
            fontSize={18}
            fontWeight={300}
            color={productPrice ? (productPrice.diffSide ? green1 : red2) : 'white'}
          >
            {productPrice?.diffSide ? '+' : '-'}
            {productPrice ? productPrice.diff_24h : 0}%
          </ThemedText.MediumHeader>
          <ChevronDown24 />
        </SelectContractButton>
        <Separator />
        <ResponsiveRow cardWidth={width}>
          <HeaderColumn className="price-box">
            <ThemedText.MediumHeader fontSize={18} fontWeight={300}>
              {productPrice ? productPrice.price : 0}
            </ThemedText.MediumHeader>
            <ThemedText.Small color={text3}>Price</ThemedText.Small>
          </HeaderColumn>
          <HeaderColumn className="pricechange-box">
            <ThemedText.MediumHeader
              fontSize={18}
              fontWeight={300}
              color={productPrice ? (productPrice.diffSide ? green1 : red2) : 'white'}
            >
              {productPrice?.diffSide ? '+' : '-'}
              {productPrice ? productPrice.diff_24h : 0}%
            </ThemedText.MediumHeader>
            <ThemedText.Small color={text3}>24h Change</ThemedText.Small>
          </HeaderColumn>
          <HeaderColumn className="high-box">
            <ThemedText.MediumHeader fontSize={18} fontWeight={300}>
              {productPrice ? productPrice.high_24h : 0}
            </ThemedText.MediumHeader>
            <ThemedText.Small color={text3}>24h High</ThemedText.Small>
          </HeaderColumn>
          <HeaderColumn className="low-box">
            <ThemedText.MediumHeader fontSize={18} fontWeight={300}>
              {productPrice ? productPrice.low_24h : 0}
            </ThemedText.MediumHeader>
            <ThemedText.Small color={text3}>24h Low</ThemedText.Small>
          </HeaderColumn>
        </ResponsiveRow>
      </HeaderGrid>
    </Wrapper>
  )
}

/* Settings butto, unused
{false && (
  <SettingsButton style={{ marginLeft: 'auto' }}>
    <Settings24 />
  </SettingsButton>
)} 
*/

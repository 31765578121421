import Column from 'components/Column'
import Row, { RowBetween } from 'components/Row'
import { transparentize } from 'polished'
import styled, { useTheme } from 'styled-components/macro'
import { ThemedText } from 'theme'

const TradesWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0;
  background: ${({ theme }) => theme.bg0};
`

const TradeGrid = styled.div`
  display: grid;
  grid-gap: 0em;
  grid-template-columns: 1.25fr 1.25fr 2fr;
  &:nth-of-type(even) {
    background-color: ${({ theme }) => theme.bg1};
  }
`
const Background = styled.div<{ width: number; tradeDirection?: boolean }>`
  position: absolute;
  z-index: 0;
  width: ${({ width }) => width.toFixed(2)}%;
  background-color: ${({ theme, tradeDirection, width }) =>
    transparentize(0.92, tradeDirection ? theme.green1 : theme.red2)};
  height: 100%;
  top: 0;
  left: 0;
`

function OrderBookItem({
  price,
  quantity,
  total,
  percent,
  tradeDirection,
}: {
  price: number
  quantity: number
  total: number
  percent: number
  tradeDirection: boolean
}) {
  const { green1, red2 } = useTheme()

  return (
    <TradeGrid>
      <Row
        alignItems="center"
        style={{ color: tradeDirection ? green1 : red2, cursor: 'pointer', padding: '0.5rem 0.75rem' }}
      >
        <ThemedText.Small>{price.toFixed(2)}</ThemedText.Small>
      </Row>
      <Row justify="end" style={{ padding: '0.5rem 0.75rem' }}>
        <ThemedText.Small>{quantity.toFixed(2)}</ThemedText.Small>
      </Row>
      <Row justify="end" style={{ position: 'relative', padding: '0.5rem 0.75rem' }}>
        <Background width={percent} tradeDirection={tradeDirection} />
        <ThemedText.Small style={{ position: 'relative', zIndex: 900 }}>{total.toFixed(2)}</ThemedText.Small>
      </Row>
    </TradeGrid>
  )
}

const dummyList = [
  { price: 1902.05, quantity: 115.21, total: 115.21 },
  { price: 1902.0, quantity: 2.88, total: 118.09 },
  { price: 1901.95, quantity: 6.49, total: 124.58 },
  { price: 1901.9, quantity: 116.86, total: 241.44 },
  { price: 1901.85, quantity: 48.23, total: 289.67 },
  { price: 1901.8, quantity: 29.26, total: 318.93 },
]

const dummyBetaList = [
  { price: 1902.4, quantity: 30.54, total: 434.67 },
  { price: 1902.35, quantity: 14.52, total: 404.13 },
  { price: 1902.3, quantity: 51.32, total: 389.61 },
  { price: 1902.25, quantity: 22.21, total: 338.29 },
  { price: 1902.2, quantity: 18.46, total: 316.08 },
  { price: 1902.15, quantity: 18.61, total: 297.62 },
].reverse()

export default function OrderBook({ height }: { height: number }) {
  let totalTally = 0
  let tally = 0
  let totalBetaTally = 0
  let betaTally = 0
  const totalList = dummyList.map((b) => {
    totalTally += b.quantity
    return { ...b, total: totalTally }
  })

  const totalBetaList = dummyBetaList.map((b) => {
    totalBetaTally += b.quantity
    return { ...b, total: totalBetaTally }
  })
  const mappedList = totalList.map((b) => {
    tally += b.quantity
    return { ...b, percent: (tally / totalList[dummyList.length - 1].total) * 100 }
  })

  const mappedBetaList = totalBetaList.map((b) => {
    betaTally += b.quantity
    return { ...b, percent: (betaTally / totalBetaList[dummyList.length - 1].total) * 100 }
  })

  return (
    <TradesWrapper style={{ overflow: 'hidden' }}>
      <RowBetween className="drag-header" style={{ padding: '0.5rem 0.75rem' }}>
        <Row style={{ display: 'flex', alignItems: 'center' }}>
          <ThemedText.Small fontWeight={600}>Order Book</ThemedText.Small>
        </Row>
      </RowBetween>
      <Column>
        <TradeGrid>
          <Row alignItems="center" style={{ padding: '0.5rem 0.75rem' }}>
            <ThemedText.Small>Price</ThemedText.Small>
          </Row>
          <Row justify="end" style={{ padding: '0.5rem 0.75rem', textAlign: 'right' }}>
            <ThemedText.Small>Quantity</ThemedText.Small>
          </Row>
          <Row justify="end" style={{ padding: '0.5rem 0.75rem' }}>
            <ThemedText.Small>Total</ThemedText.Small>
          </Row>
        </TradeGrid>
        {mappedList.map((item, i) => (
          <OrderBookItem
            key={`marketitem-${i}`}
            price={item.price}
            tradeDirection={true}
            quantity={item.quantity}
            total={item.total}
            percent={item.percent}
          />
        ))}
        <div style={{ height: '2.25rem' }}></div>
        {mappedBetaList.reverse().map((item, i) => (
          <OrderBookItem
            key={`marketitem-${i}`}
            price={item.price}
            tradeDirection={false}
            quantity={item.quantity}
            total={item.total}
            percent={item.percent}
          />
        ))}
      </Column>
    </TradesWrapper>
  )
}

import Row from 'components/Row'

import { SORT_FIELD } from './common'
import { ClickableText, OrderHeaderGrid } from './styled'

export default function OrderHeader({
  breakpoint,
  width,
  sortDirection,
  onSetSortDirection,
  sortedColumn,
  onSetSortedColumn,
}: {
  breakpoint: string
  width: number
  sortDirection: boolean
  onSetSortDirection: React.Dispatch<React.SetStateAction<boolean>>
  sortedColumn: string
  onSetSortedColumn: React.Dispatch<React.SetStateAction<string>>
}) {
  return (
    <>
      <OrderHeaderGrid width={width} breakpoint={breakpoint}>
        <Row alignItems="center" justifyContent="flexStart">
          <ClickableText color="text" area="contract" fontWeight="500">
            Contract
            <div style={{ minWidth: '0.5rem' }}>
              {sortedColumn === SORT_FIELD.CONTRACT ? (!sortDirection ? '↑' : '↓') : ''}
            </div>
          </ClickableText>
        </Row>
        <Row alignItems="center">
          <ClickableText area="value">
            Value
            <div style={{ minWidth: '0.5rem' }}>
              {sortedColumn === SORT_FIELD.VALUE ? (!sortDirection ? '↑' : '↓') : ''}
            </div>
          </ClickableText>
        </Row>
        <Row alignItems="center">
          <ClickableText area="value">
            Margin
            <div style={{ minWidth: '0.5rem' }}>
              {sortedColumn === SORT_FIELD.MARGIN ? (!sortDirection ? '↑' : '↓') : ''}
            </div>
          </ClickableText>
        </Row>
        <Row alignItems="center">
          <ClickableText area="liq">
            Order Type
            <div style={{ minWidth: '0.5rem' }}>
              {sortedColumn === SORT_FIELD.ORDER_TYPE ? (!sortDirection ? '↑' : '↓') : ''}
            </div>
          </ClickableText>
        </Row>
      </OrderHeaderGrid>
    </>
  )
}

import { CHAIN_INFO } from 'constants/chainInfo'
import { DEFAULT_FALLBACK_CHAIN, SupportedChainId } from 'constants/chains'
import { DappFeatures, Features } from 'constants/dapp'
import { useMemo } from 'react'
import { useTheme } from 'styled-components/macro'
import { ThemedText } from 'theme'

export default function ChainWarning({
  chainId = DEFAULT_FALLBACK_CHAIN,
  feature,
}: {
  chainId?: number
  feature: Features
}) {
  const info = CHAIN_INFO[chainId]
  const { bg2 } = useTheme()

  const message = useMemo(() => {
    const support = DappFeatures[feature][chainId as SupportedChainId]
    switch (true) {
      case chainId === 9001 && feature === Features.PERPETUALS:
        return (
          <ThemedText.Black>
            Perpetual Trading is not yet supported on {info.label}, you will soon be able to use our Bridge powered by
            Multichain to transfer your CRN over to Optimism and start earning with staking.
          </ThemedText.Black>
        )
      case support === 0:
        return (
          <ThemedText.Black>
            {feature} feature is not yet supported on {info.label}, but it might be soon!
            <br />
            Please switch chain to use the {feature} Interface.
          </ThemedText.Black>
        )
      case !support || support < 0:
        return (
          <ThemedText.Black>
            {feature} feature is not supported on {info.label}, please switch chain to use the {feature} Interface.
          </ThemedText.Black>
        )
      default:
        return (
          <ThemedText.Black>
            This chain is not supported, please switch chain to use the Cronus Interface.
          </ThemedText.Black>
        )
    }
  }, [chainId])

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        padding: '2rem 2rem 100px 2rem',
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: bg2,
        zIndex: 2000,
      }}
    >
      <div style={{ maxWidth: '480px', textAlign: 'center' }}>{message}</div>
    </div>
  )
}

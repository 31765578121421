import { Product } from 'constants/perps'

export type CardProps = {
  product: Product
  isLong: boolean
  pnl: number
  pnlPercent: number
  leverage: number
  entryPrice: number
  markPrice: number
}

export const SORT_FIELD = {
  CONTRACT: 'contract',
  SIZE: 'size',
  VALUE: 'value',
  ENTRY_PRICE: 'entry_price',
  MARK_PRICE: 'mark_price',
  LIQ_PRICE: 'liq_price',
  MARGIN: 'margin',
  DATE: 'date',
  PNL: 'pnl',
  ACTIONS: 'actions',
  ORDER_TYPE: 'order_type',
  COLLATERAL: 'collateral',
  TIME: 'time',
}

export const TABS = {
  POSITIONS: 'positions',
  CLOSED: 'closedPositions',
  OPEN_ORDERS: 'openOrders',
  ORDER_HISTORY: 'orderHistory',
}

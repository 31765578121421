import CoverScreen from 'components/CoverScreen'
import Row from 'components/Row'
import React, { useEffect } from 'react'
import { useDarkModeManager } from 'state/user/hooks'
import styled, { useTheme } from 'styled-components/macro'
import { ThemedText } from 'theme'

const ChartWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.bg0};
`

declare const TradingView: any
type Widget = HTMLDivElement & { _ready: any }

export default function CandleChart({
  productId,
  breakpoint,
  width,
  height,
}: {
  productId: string
  breakpoint?: string
  width: number
  height: number
}) {
  const ref: { current: Widget | null } = React.createRef()
  const [darkMode] = useDarkModeManager()
  const { bg0 } = useTheme()
  const isSm = breakpoint === 'sm'

  useEffect(() => {
    if (typeof TradingView !== 'undefined') {
      ref.current = new TradingView.widget({
        theme: darkMode ? 'dark' : 'light',
        loading_screen: {
          backgroundColor: 'transparent',
          foregroundColor: 'transparent',
        },
        width: '100%',
        height: '100%',
        symbol: `COINBASE:${productId.toUpperCase()}USD`,
        interval: '30',
        timeframe: '1D',
        style: '1',
        locale: 'en',
        toolbar_bg: 'rgba(0, 0, 0, 0.0)',
        hide_top_toolbar: isSm,
        hide_side_toolbar: isSm,
        withdateranges: !isSm,
        save_image: true,
        enable_publishing: false,
        container_id: 'tradingview-candle-chart',
        autosize: true,
        overrides: {
          'paneProperties.backgroundColor': 'rgba(0, 0, 0, 0.0)',
          'paneProperties.backgroundType': 'solid',
        },
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId, TradingView, darkMode, breakpoint])

  return (
    <ChartWrapper>
      <CoverScreen bg={bg0} />
      {!isSm && (
        <Row className="drag-header">
          <ThemedText.Small fontWeight={600} style={{ padding: '0.5rem 0.75rem' }}>
            Advanced Chart
          </ThemedText.Small>
        </Row>
      )}
      <div style={{ height: 'fill-available', width: '100%' }} id="tradingview-candle-chart"></div>
    </ChartWrapper>
  )
}

import { ArrowDown16, ArrowUp16 } from '@carbon/icons-react'
import Column from 'components/Column'
import Row, { RowBetween } from 'components/Row'
import { Collateral } from 'constants/perps'
import styled, { useTheme } from 'styled-components/macro'
import { ThemedText } from 'theme'

const TradesWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0;
  background: ${({ theme }) => theme.bg0};
`

const TradeGrid = styled.div`
  display: grid;
  padding: 0.5rem 0.75rem;
  grid-gap: 0.5em;
  grid-template-columns: 1.5fr 1fr 1fr;
  &:nth-of-type(even) {
    background-color: ${({ theme }) => theme.bg1};
  }
`

function MarketTradeItem({
  value,
  tradeDirection,
  sign = false,
  size,
  time,
}: {
  value: number
  tradeDirection?: boolean
  sign?: boolean
  size: number
  time: Date
}) {
  const { green1, red2 } = useTheme()
  const hours = time.getHours() < 10 ? '0' + time.getHours() : time.getHours()
  const minutes = time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes()
  const seconds = time.getSeconds() < 10 ? '0' + time.getSeconds() : time.getSeconds()

  return (
    <TradeGrid>
      <Row alignItems="center" style={{ color: tradeDirection ? green1 : red2, cursor: 'pointer' }}>
        <ThemedText.Small>
          {value.toFixed(2)}
          {sign ? (
            tradeDirection ? (
              <ArrowUp16 height={11} style={{ marginBottom: '-1px' }} />
            ) : (
              <ArrowDown16 height={11} style={{ marginBottom: '-1px' }} />
            )
          ) : (
            ''
          )}
        </ThemedText.Small>
      </Row>
      <Row>
        <ThemedText.Small>{size.toFixed(2)}</ThemedText.Small>
      </Row>
      <Row justify="end">
        <ThemedText.Small>{hours + ':' + minutes + ':' + seconds}</ThemedText.Small>
      </Row>
    </TradeGrid>
  )
}

const dummyList = [
  { price: 1895.15, size: 0.01, time: 1660588373000, tradeDirection: true },
  { price: 1895.1, size: 0.1, time: 1660588373000, tradeDirection: false },
  { price: 1895.15, size: 1.07, time: 1660588373000, tradeDirection: false },
  { price: 1895.15, size: 0.01, time: 1660588373000, tradeDirection: false },
  { price: 1895.2, size: 0.01, time: 1660588373000, tradeDirection: false },
  { price: 1895.2, size: 0.01, time: 1660588373000, tradeDirection: false },
  { price: 1895.3, size: 0.01, time: 1660588373000, tradeDirection: false },
  { price: 1895.3, size: 0.01, time: 1660588373000, tradeDirection: false },
  { price: 1895.35, size: 0.01, time: 1660588373000, tradeDirection: false },
  { price: 1895.35, size: 0.01, time: 1660588373000, tradeDirection: false },
  { price: 1895.4, size: 0.01, time: 1660588370000, tradeDirection: true },
  { price: 1895.4, size: 0.07, time: 1660588369000, tradeDirection: true },
  { price: 1895.35, size: 0.09, time: 1660588368000, tradeDirection: false },
  { price: 1895.35, size: 0.03, time: 1660588368000, tradeDirection: false },
  { price: 1895.4, size: 0.69, time: 1660588368000, tradeDirection: true },
  { price: 1895.4, size: 0.1, time: 1660588368000, tradeDirection: true },
  { price: 1895.4, size: 0.01, time: 1660588367000, tradeDirection: true },
  { price: 1895.35, size: 0.1, time: 1660588366000, tradeDirection: false },
]

export default function MarketTrades({ collateral, height }: { collateral?: Collateral; height: number }) {
  const mappedList = dummyList.map((item, i) => {
    return i !== dummyList.length - 1 && item.price !== dummyList[i + 1].price
      ? {
          ...item,
          sign: true,
        }
      : { ...item, sign: false }
  })
  return (
    <TradesWrapper style={{ overflow: 'hidden' }}>
      <RowBetween className="drag-header" style={{ padding: '0.5rem 0.75rem' }}>
        <Row style={{ display: 'flex', alignItems: 'center' }}>
          <ThemedText.Small fontWeight={600}>Latest Trades</ThemedText.Small>
        </Row>
      </RowBetween>
      <Column>
        {mappedList.map((item, i) => (
          <MarketTradeItem
            key={`marketitem-${i}`}
            value={item.price}
            tradeDirection={item.tradeDirection}
            sign={item.sign}
            size={item.size}
            time={new Date(item.time)}
          />
        ))}
      </Column>
    </TradesWrapper>
  )
}

import { TransactionResponse } from '@ethersproject/abstract-provider'
import { Trans } from '@lingui/macro'
import TOKEN_FAUCET_ABI from 'abis/TokenFaucet.json'
import USDC_IMG from 'assets/images/usdc_logo.png'
import { ButtonPrimary } from 'components/Button'
import { BlueCard } from 'components/Card'
import { AutoColumn } from 'components/Column'
import { TOKEN_FAUCET } from 'constants/addresses'
import { SupportedChainId } from 'constants/chains'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useContext } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useWalletModalToggle } from 'state/application/hooks'
import { ThemeContext } from 'styled-components/macro'
import styled from 'styled-components/macro'
import { getContract } from 'utils'

import Row from '../../components/Row'
import { ThemedText } from '../../theme'
import AppBody from '../AppBody'
import { Wrapper } from '../Pool/styleds'

const StyledRow = styled(Row)`
  padding: 0 0 1rem 0;
  width: 100%;
  color: ${({ theme }) => theme.text2};
`

export default function ClaimTestnet({ history }: RouteComponentProps) {
  const { account, chainId, library } = useActiveWeb3React()
  const theme = useContext(ThemeContext)
  const toggleWalletModal = useWalletModalToggle()

  const contract =
    library && chainId && chainId === SupportedChainId.OPTIMISM_GOERLI
      ? getContract(TOKEN_FAUCET[chainId], TOKEN_FAUCET_ABI, library, account ?? undefined)
      : undefined

  const handleClaim = async () => {
    if (!account || !contract) return
    contract.claimTokens(account).then((res: TransactionResponse) => {
      // console.log(res)
    })
  }

  return (
    <>
      <AppBody>
        <Wrapper style={{ gap: '1rem', display: 'flex', flexDirection: 'column' }}>
          <ThemedText.Black fontWeight={500} fontSize={20} textAlign="center" style={{ marginRight: '8px' }}>
            <Trans>Claim your testnet tokens</Trans>
          </ThemedText.Black>
          <ThemedText.Main textAlign="center">
            Claim your testnet USDC and start using the Cronus Perp Interface
          </ThemedText.Main>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center', padding: '3rem 0' }}>
            <img src={USDC_IMG} alt="USDC" width={100} height={100} />
          </div>
        </Wrapper>
        {account ? (
          <ButtonPrimary onClick={() => handleClaim()}>Claim</ButtonPrimary>
        ) : (
          <ButtonPrimary onClick={() => toggleWalletModal()}>Connect wallet</ButtonPrimary>
        )}
      </AppBody>
      <AutoColumn gap="md" style={{ marginTop: '20px' }}>
        <BlueCard>
          <AutoColumn gap="10px">
            <ThemedText.Link fontWeight={400} color={'primaryText1'}>
              <Trans>
                Searching for a Optimism Goerli ETH faucet?{' '}
                <a
                  style={{ textDecoration: 'none', fontWeight: 'bold' }}
                  rel="noreferrer"
                  target="_blank"
                  href="https://faucet.paradigm.xyz/"
                >
                  Try here
                </a>
              </Trans>
            </ThemedText.Link>
          </AutoColumn>
        </BlueCard>
      </AutoColumn>
    </>
  )
}

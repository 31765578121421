import '@dotlottie/player-component'

import loaderLottie from 'assets/videos/loader.lottie'
import { PriceContext } from 'components/PriceProvider'
import { ProductContext } from 'components/ProductProvider'
import { useContext, useEffect, useRef, useState } from 'react'
import { useAppSelector } from 'state/hooks'
import { useTheme } from 'styled-components/macro'

export default function CoverScreen({
  bg,
  timeout = 4000,
  loading,
}: {
  bg?: string
  timeout?: number
  loading?: boolean
}) {
  const lottieRef = useRef(null)
  const [time, setTime] = useState(false)
  const legalDisclaimer = useAppSelector((state) => state.user.legalDisclaimer)
  const { bg2 } = useTheme()

  const PriceCtx = useContext(PriceContext) as any
  const ProductCtx = useContext(ProductContext) as any

  useEffect(() => {
    const _timeout = setTimeout(() => {
      setTime(true)
    }, timeout)

    return () => clearTimeout(_timeout)
  }, [timeout])

  const isLoading =
    !time || !lottieRef.current || !legalDisclaimer || !Object.keys(PriceCtx).length || !ProductCtx[0] || loading

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: bg || bg2,
        zIndex: 2000,
        visibility: isLoading ? 'visible' : 'hidden',
      }}
    >
      <div style={{ width: '10%' }}>
        <dotlottie-player ref={lottieRef} autoplay loop mode="normal" src={loaderLottie} />
      </div>
    </div>
  )
}

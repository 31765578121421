/**
 * List of all the networks supported by the Cronus Interface
 */
export enum SupportedChainId {
  MAINNET = 9001,
  OPTIMISM = 10,
  ARBITRUM = 999999999999,
  EVMOS_TESTNET = 9000,
  OPTIMISM_GOERLI = 420,
  ETHEREUM = 999999999998,
  RINKEBY = 4,
  NULL_CHAIN = 0,
}

export const CHAIN_IDS_TO_NAMES = {
  [SupportedChainId.MAINNET]: 'evmos',
  [SupportedChainId.OPTIMISM]: 'optimism',
  [SupportedChainId.ARBITRUM]: 'arbitrum_one',
  [SupportedChainId.EVMOS_TESTNET]: 'evmos_testnet',
  [SupportedChainId.OPTIMISM_GOERLI]: 'optimism_goerli',
  [SupportedChainId.RINKEBY]: 'rinkeby',
  [SupportedChainId.ETHEREUM]: 'ethereum',
  [SupportedChainId.NULL_CHAIN]: 'arbitrum_rinkeby',
}
// eslint-disable-next-line no-restricted-globals
export const DEFAULT_FALLBACK_CHAIN = SupportedChainId.OPTIMISM

export const DEFAULT_INPUT_CURRENCY = {
  [SupportedChainId.MAINNET]: 'EVMOS',
  [SupportedChainId.OPTIMISM]: 'ETH',
  [SupportedChainId.ARBITRUM]: 'ETH',
  [SupportedChainId.EVMOS_TESTNET]: 'EVMOS',
  [SupportedChainId.OPTIMISM_GOERLI]: 'ETH',
  [SupportedChainId.RINKEBY]: 'ETH',
  [SupportedChainId.ETHEREUM]: 'ETH',
  [SupportedChainId.NULL_CHAIN]: 'ETH',
}

/**
 * Array of all the supported chain IDs
 */
export const ALL_SUPPORTED_CHAIN_IDS: SupportedChainId[] = Object.values(SupportedChainId).filter(
  (id) => typeof id === 'number'
) as SupportedChainId[]

export const SUPPORTED_GAS_ESTIMATE_CHAIN_IDS = [SupportedChainId.NULL_CHAIN]

/**
 * All the chain IDs that are running the Ethereum protocol.
 */
export const L1_CHAIN_IDS = [
  SupportedChainId.MAINNET,
  SupportedChainId.EVMOS_TESTNET,
  SupportedChainId.RINKEBY,
] as const

export const PERP_CHAIN_IDS = [
  SupportedChainId.OPTIMISM,
  SupportedChainId.ARBITRUM,
  SupportedChainId.OPTIMISM_GOERLI,
] as const

export type SupportedL1ChainId = typeof L1_CHAIN_IDS[number]

/**
 * Controls some L2 specific behavior, e.g. slippage tolerance, special UI behavior.
 * The expectation is that all of these networks have immediate transaction confirmation.
 */
export const L2_CHAIN_IDS = [
  SupportedChainId.OPTIMISM,
  SupportedChainId.OPTIMISM_GOERLI,
  SupportedChainId.ARBITRUM,
  SupportedChainId.NULL_CHAIN,
] as const

export type SupportedL2ChainId = typeof L2_CHAIN_IDS[number]

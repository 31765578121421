import { Trans } from '@lingui/macro'
import { LedgerContext } from 'components/LedgerProvider'
import { Case, Switch } from 'components/Switch'
import { Collateral, Product } from 'constants/perps'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useContext, useMemo, useRef, useState } from 'react'
import { Order, Position } from 'state/perpetuals/hooks'
import { ThemedText } from 'theme'

import { CardProps, SORT_FIELD, TABS } from './common'
import OrderHeader from './OrderHeader'
import OrderItem from './OrderItem'
import PositionHeader from './PositionHeader'
import { PositionItem } from './PositionItem'
import { ClickableTab, HeaderRow, ItemContainer, PositionsWrapper } from './styled'

function HeaderTabs({ tab, active, onSetTab }: { tab: string; active: boolean; onSetTab: (newTab: string) => void }) {
  const header = useRef<HTMLDivElement>()
  return (
    <HeaderRow ref={header} className="drag-header">
      <ClickableTab
        selected={tab === TABS.POSITIONS}
        onClick={() => {
          active && onSetTab(TABS.POSITIONS)
        }}
        draggable="false"
        onDragStart={(e) => e.stopPropagation()}
      >
        <ThemedText.Small fontWeight="inherit">Your positions</ThemedText.Small>
      </ClickableTab>
      <ClickableTab
        selected={tab === TABS.OPEN_ORDERS}
        onClick={(e) => {
          active && onSetTab(TABS.OPEN_ORDERS)
        }}
        draggable="false"
        style={{
          cursor: active ? 'pointer' : 'default',
        }}
      >
        <ThemedText.Small fontWeight="inherit">Open Orders</ThemedText.Small>
      </ClickableTab>
    </HeaderRow>
  )
}

export default function PositionsWidget({
  breakpoint,
  width,
  onCancelOrder,
  onUserSettleOrder,
  onClosePosition,
  onCancelClose,
  onProductChange,
  onOpenCardModal,
}: {
  breakpoint: string
  width: number
  onCancelOrder: OrderFunction
  onClosePosition: OrderFunction
  onCancelClose: OrderFunction
  onUserSettleOrder: (margin: number, product: Product, collateral: Collateral, isLong: boolean, price: number) => void
  onProductChange: (productId: string) => void
  onOpenCardModal: (props: CardProps) => void
}) {
  const { account } = useActiveWeb3React()

  const [tab, setTab] = useState(TABS.POSITIONS)
  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)

  const [sortDirection, setSortDirection] = useState(true)
  const [sortedColumn, setSortedColumn] = useState(SORT_FIELD.DATE)

  const DataContext = useContext(LedgerContext) as Record<string, unknown>
  const orders = (DataContext.orders || []) as Order[]

  type PricedPosition = Position & { markPrice: number; liquidationPrice: number; pnl: number; pnlPercent: number }
  const positions = useMemo(() => {
    if (!('positions' in DataContext)) {
      return []
    }
    let sortedPositions = DataContext.positions as PricedPosition[]

    if (sortedColumn === SORT_FIELD.CONTRACT) {
      sortedPositions = sortedPositions.sort((a, b) => {
        return a.product.label.localeCompare(b.product.label)
      })
    }

    if (sortedColumn === SORT_FIELD.SIZE) {
      sortedPositions = sortedPositions.sort((a, b) => {
        return a.size - b.size
      })
    }

    if (sortedColumn === SORT_FIELD.VALUE) {
      sortedPositions = sortedPositions.sort((a, b) => {
        return a.value - b.value
      })
    }

    if (sortedColumn === SORT_FIELD.ENTRY_PRICE) {
      sortedPositions = sortedPositions.sort((a, b) => {
        return a.price - b.price
      })
    }

    if (sortedColumn === SORT_FIELD.MARK_PRICE) {
      sortedPositions = sortedPositions.sort((a, b) => {
        return a.markPrice - b.markPrice
      })
    }

    if (sortedColumn === SORT_FIELD.LIQ_PRICE) {
      sortedPositions = sortedPositions.sort((a, b) => {
        return a.liquidationPrice - b.liquidationPrice
      })
    }

    if (sortedColumn === SORT_FIELD.PNL) {
      sortedPositions = sortedPositions.sort((a, b) => {
        return a.pnl - b.pnl
      })
    }

    if (sortedColumn === SORT_FIELD.MARGIN) {
      sortedPositions = sortedPositions.sort((a, b) => {
        return a.margin - b.margin
      })
    }

    if (!sortDirection) {
      return sortedPositions.reverse()
    }
    return sortedPositions
  }, [DataContext, sortDirection, sortedColumn]) as PricedPosition[]

  const handleSetTab = (newTab: string) => {
    setPage(1)
    setSortedColumn(SORT_FIELD.DATE)
    setSortDirection(true)
    setTab(newTab)
  }

  const memoisedPositionTab = useMemo(() => {
    return (
      <>
        <PositionHeader
          breakpoint={breakpoint}
          sortDirection={sortDirection}
          onSetSortDirection={setSortDirection}
          sortedColumn={sortedColumn}
          onSetSortedColumn={setSortedColumn}
          active={!!account}
        />
        {account ? (
          positions && positions.length ? (
            <ItemContainer>
              {positions.map((entry, i) => {
                return (
                  <PositionItem
                    key={entry.key + '-position-list-item'}
                    position={entry}
                    onProductChange={onProductChange}
                    onClosePosition={onClosePosition}
                    onCancelClose={onCancelClose}
                    onSetTab={handleSetTab}
                    onOpenCardModal={onOpenCardModal}
                    onUserSettleOrder={onUserSettleOrder}
                    last={i === positions.length - 1}
                  />
                )
              })}
            </ItemContainer>
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
                flex: '1 1 auto',
                justifyContent: 'center',
                marginBottom: '2rem',
                alignItems: 'center',
                width: '100%',
                height: 'fill-available',
              }}
            >
              <Trans>Open a position to see it live</Trans>
            </div>
          )
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
              flex: '1 1 auto',
              justifyContent: 'center',
              marginBottom: '2rem',
              alignItems: 'center',
              width: '100%',
              height: 'fill-available',
            }}
          >
            <Trans>Connect your wallet to see your positions</Trans>
          </div>
        )}
      </>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, positions, sortDirection, sortedColumn])

  return (
    <PositionsWrapper id="positions">
      <HeaderTabs tab={tab} active={!!account} onSetTab={handleSetTab} />
      <Switch test={tab} defaultComponent={memoisedPositionTab}>
        <Case value={TABS.POSITIONS}>{memoisedPositionTab}</Case>
        <Case value={TABS.OPEN_ORDERS}>
          <OrderHeader
            breakpoint={breakpoint}
            width={width}
            sortDirection={sortDirection}
            onSetSortDirection={setSortDirection}
            sortedColumn={sortedColumn}
            onSetSortedColumn={setSortedColumn}
          />
          <ItemContainer>
            {orders &&
              orders.map((entry) => {
                if (!entry.isClose) {
                  return (
                    <OrderItem
                      key={entry.key + '-order-list-item'}
                      breakpoint={breakpoint}
                      width={width}
                      order={entry}
                      onProductChange={onProductChange}
                      onCancelOrder={onCancelOrder}
                      onUserSettleOrder={onUserSettleOrder}
                    />
                  )
                }
                return undefined
              })}
          </ItemContainer>
        </Case>
      </Switch>
    </PositionsWrapper>
  )
}

import { ReactNode } from 'react'
import { Optional } from 'typescript-optional'
const { ofNullable } = Optional

export const Case = ({ value, children }: { value: string; children: ReactNode }) => {
  return <>{children}</>
}

export const Switch = ({
  test,
  defaultComponent,
  children,
}: {
  test: string
  defaultComponent: JSX.Element
  children: ReactNode
}) => {
  return ofNullable(children)
    .map((child: ReactNode) => {
      return ofNullable((child as JSX.Element[]).find((child) => child.props['value'] === test)).orElse(
        defaultComponent
      )
    })
    .orElseThrow(() => new Error('Children are required for a switch component'))
}

import { Contract } from '@ethersproject/contracts'
import { keccak256 as solidityKeccak256 } from '@ethersproject/solidity'
import { formatUnits } from '@ethersproject/units'
import POOL_ABI from 'abis/perpPool.json'
import POS_ROUTER_ABI from 'abis/perpPosRouter.json'
import REWARDS_ABI from 'abis/perpRewards.json'
import ROUTER_ABI from 'abis/perpRouter.json'
import VAULT_ABI from 'abis/perpVault.json'
import { PERP_POS_ROUTER_ADDRESS, PERP_ROUTER_ADDRESS, PERP_VAULT_ADDRESS } from 'constants/addresses'
import { PERP_CHAIN_IDS, SupportedChainId } from 'constants/chains'
import { ZERO_ADDRESS } from 'constants/misc'
import { PERP_POOLS, PerpPool } from 'constants/perps'
import { Collateral, collaterals, PERP_PRODUCTS, Product } from 'constants/perps'
import { USDC } from 'constants/tokens'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useContract } from 'hooks/useContract'
import { useSingleCallResult, useSingleContractMultipleData } from 'lib/hooks/multicall'
import useBlockNumber from 'lib/hooks/useBlockNumber'
import useCurrencyBalance from 'lib/hooks/useCurrencyBalance'
import getms from 'ms'
import { useEffect, useMemo, useState } from 'react'
import { getContract, objMap } from 'utils'

import { useGetOrdersQuery, useGetPoolInfoQuery, useGetPositionsQuery } from './enhanced'

function getAPY(
  inceptionDate: number,
  cumulativeFees: number,
  poolShare: number,
  cumulativePnl: number,
  tvl: number
): number {
  const timeSinceInception = Date.now() - inceptionDate
  const timeInAYear = 365 * 24 * 3600 * 1000
  const timeScaler = timeInAYear / timeSinceInception
  const apy = (timeScaler * 100 * ((cumulativeFees * poolShare) / 100 - 1 * cumulativePnl)) / tvl
  return +apy.toFixed(2)
}

export function useTradingContract(): Contract | null {
  const { account, library, chainId } = useActiveWeb3React()

  const safeChainId = useMemo(() => {
    return chainId && PERP_CHAIN_IDS.indexOf(chainId) >= 0 ? chainId : SupportedChainId.OPTIMISM
  }, [chainId])

  return useMemo(() => {
    if (!library) return null
    try {
      return getContract(PERP_VAULT_ADDRESS[safeChainId], VAULT_ABI, library, account ?? undefined) as Contract | null
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [library, safeChainId, account])
}

export function usePerpPosRouterContract(customChainId?: number): Contract | null {
  const { account, library, chainId } = useActiveWeb3React()

  const safeChainId = useMemo(() => {
    return chainId && PERP_CHAIN_IDS.indexOf(chainId) >= 0 ? chainId : SupportedChainId.OPTIMISM
  }, [chainId])

  return useMemo(() => {
    if (!library) return null
    try {
      return getContract(
        PERP_POS_ROUTER_ADDRESS[customChainId || safeChainId],
        POS_ROUTER_ABI,
        library,
        account ?? undefined
      ) as Contract | null
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [library, safeChainId, account])
}

export function usePerpRouterContract(customChainId?: number): Contract | null {
  const { account, library, chainId } = useActiveWeb3React()

  const safeChainId = useMemo(() => {
    return chainId && PERP_CHAIN_IDS.indexOf(chainId) >= 0 ? chainId : SupportedChainId.OPTIMISM
  }, [chainId])

  return useMemo(() => {
    if (!library) return null
    try {
      return getContract(
        PERP_ROUTER_ADDRESS[customChainId || safeChainId],
        ROUTER_ABI,
        library,
        account ?? undefined
      ) as Contract | null
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [library, safeChainId, account])
}

export function usePoolContract(poolAddress: string | undefined, withSignerIfPossible?: boolean): Contract | null {
  try {
    return useContract(poolAddress, POOL_ABI, withSignerIfPossible) as Contract | null
  } catch (error) {
    console.error('Failed to get contract', error)
    return null
  }
}

export function useRewardContract(rewardAddress: string | undefined): Contract | null {
  const { account, library } = useActiveWeb3React()

  return useMemo(() => {
    if (!library || !rewardAddress) return null
    try {
      return getContract(rewardAddress, REWARDS_ABI, library, account ?? undefined) as Contract | null
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [library, account])
}

export function useMultipleRewardContracts(poolAddresses: { [pool: string]: string } | undefined): {
  [pool: string]: Contract | null
} {
  const { account, library } = useActiveWeb3React()
  return useMemo(() => {
    if (!library || !poolAddresses) return {}
    return Object.fromEntries(
      Object.entries(poolAddresses).map((address) => {
        try {
          return [address[0], getContract(address[1], REWARDS_ABI, library, account ?? undefined) as Contract | null]
        } catch (error) {
          console.error('Failed to get contracts', error)

          return [address[0], null]
        }
      })
    ) as { [pool: string]: Contract | null }
  }, [library, poolAddresses, account])
}

export type ProductResult = {
  fee: number
  interest: number
  liquidationThreshold: number
  maxLeverage: number
}

const emptyProduct: ProductResult = {
  fee: 0,
  interest: 0,
  liquidationThreshold: 0,
  maxLeverage: 50,
}

export function useProduct(id: string): [boolean, ProductResult] {
  const contract = useTradingContract()

  const product = useSingleCallResult(contract, 'getProduct', [id])

  return useMemo(() => {
    if (product.error || !product.result || !contract || product.loading) {
      return [false, emptyProduct]
    }

    const { fee, interest, liquidationThreshold, maxLeverage } = product.result[0]

    return [
      true,
      {
        fee: fee / 10 ** 6,
        interest: interest * 1,
        liquidationThreshold: 1 - liquidationThreshold / 10000 || 0.05,
        maxLeverage: maxLeverage / 10 ** 8 || 1,
      },
    ]
  }, [contract, product])
}

export function useProducts(ids: string[]): [boolean, { [k: string]: ProductResult }] {
  const contract = useTradingContract()
  const products = useSingleContractMultipleData(
    contract,
    'getProduct',
    ids.map((id) => [id ? id : ''])
  )
  const idmap = ids.map((id) => [id, emptyProduct]) as [string, ProductResult][]
  return useMemo(() => {
    const result = Object.fromEntries(idmap)
    if (
      products.some((product) => product.error) ||
      products.some((product) => !product.result) ||
      !contract ||
      products.some((product) => product.loading)
    ) {
      return [false, result]
    }
    const productresults = ids.map((id, index) => {
      if (products[index].result) {
        const { fee, interest, liquidationThreshold, maxLeverage } = products[index].result?.[0]
        return [
          id,
          {
            fee: fee / 10 ** 6,
            interest: interest * 1,
            liquidationThreshold: 1 - liquidationThreshold / 10000 || 0.05,
            maxLeverage: maxLeverage / 10 ** 8 || 1,
          },
        ]
      }
      return [id, emptyProduct]
    })
    return [true, Object.fromEntries(productresults)]
  }, [contract, idmap, ids, products])
}

export type Order = {
  key: string
  collateral: Collateral
  isClose: boolean
  isLong: boolean
  product: Product
  margin: number
  value: number
  user: string
  timestamp: number
}

const emptyOrder: Order = {
  key: '',
  collateral: collaterals[0],
  isClose: false,
  isLong: true,
  product: PERP_PRODUCTS[SupportedChainId.OPTIMISM].eth,
  margin: 0,
  value: 0,
  user: '',
  timestamp: 0,
}

export function useOldOrders(): [boolean, { [k: string]: Order }] {
  const [result, setResult] = useState<{ [k: string]: Order }>({ '': emptyOrder })
  const [loaded, setLoaded] = useState(false)

  const { account, library, chainId } = useActiveWeb3React()

  const safeChainId = useMemo(() => {
    return chainId && PERP_CHAIN_IDS.indexOf(chainId) >= 0 ? chainId : SupportedChainId.OPTIMISM
  }, [chainId])

  const contract = useTradingContract()
  const decimals = useSingleCallResult(contract, 'UNIT_DECIMALS')
  const filter = contract?.filters.NewOrder(null, account)

  useEffect(
    () => {
      let ignore = false

      async function fetchDetails() {
        if (!filter || !contract || !library || !account) {
          setResult({ '': emptyOrder })
          setLoaded(false)
          return
        }

        const events = await contract.queryFilter(filter, 10)

        if (!events) {
          setResult({ '': emptyOrder })
          setLoaded(false)
          return
        }

        const details: { [k: string]: Order } = {}
        for (const ev of events) {
          if (ev.args) {
            const [key, user, productId, currency, isLong, margin, size, isClose] = ev.args // margin and size position uncertain, check once different
            const collateral = collaterals.filter((collateral) => {
              return currency === ZERO_ADDRESS
                ? collateral.symbol === 'ETH' && safeChainId === collateral.chainId
                : collateral.token.wrapped.address.toLowerCase() === currency.toLowerCase() &&
                    safeChainId === collateral.chainId
            })[0]

            const product = Object.values(PERP_PRODUCTS[safeChainId]).filter((product) => {
              return product.bytes32Id === productId
            })[0]
            const timestamp = (await library.getBlock(ev.blockNumber)).timestamp * 1000
            details[ev.args.key] = {
              collateral,
              isClose,
              isLong,
              key,
              margin: +margin / 10 ** 8,
              product,
              value: +size / 10 ** 8,
              user,
              timestamp,
            }
          }
        }

        const keys = events.map((e) => {
          if (e.args) return e.args.key
        })

        const unique_keys: any[] | undefined = []
        for (const k of keys) {
          if (unique_keys.includes(k)) continue
          unique_keys.push(k)
        }

        const orders = await contract.getOrders(unique_keys)
        const fullDetails: { [k: string]: Order } = {}
        unique_keys.map((key, i) => {
          const [isClose, size, value] = orders[i]
          if (!+size) {
            return undefined
          }
          fullDetails[key] = {
            ...details[key],
            isClose,
          }
          return undefined
        })
        if (!ignore || !Object.keys(result)[0]) {
          setResult(fullDetails)
          setLoaded(true)
        }
        // console.log(unique_keys)
        // console.log(orders)
      }
      if (account) {
        fetchDetails()
      }

      return () => {
        ignore = true
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [decimals]
  )

  return [loaded, result]
}

export function useGraphOrders(interval: string): [boolean, Order[]] {
  const { account, chainId } = useActiveWeb3React()
  const safeChainId = useMemo(() => {
    return chainId && PERP_CHAIN_IDS.indexOf(chainId) >= 0 ? chainId : SupportedChainId.OPTIMISM
  }, [chainId])

  const subgraph = useOrdersFromSubgraph(account, interval)

  const allOrders = useMemo(() => {
    return subgraph.data && subgraph.data.orders ? subgraph.data.orders : []
  }, [subgraph.data])

  const keys = useMemo(() => {
    return allOrders.map((e: any) => {
      if (e) return e.id
    })
  }, [allOrders])

  const unique = useMemo(() => {
    const unique_keys: any[] | undefined = []
    for (const k of keys) {
      if (unique_keys.includes(k)) continue
      unique_keys.push(k)
    }
    return unique_keys
  }, [keys])

  const [activeOrdersLoaded, activeOrders] = useActiveOrders(unique)
  return useMemo(() => {
    if (!account) {
      return [false, []]
    }
    const fullDetails: Order[] = []

    for (const key of Object.keys(activeOrders)) {
      const { user, productId, currency, isLong, margin, size, isClose, createdAtTimestamp } = allOrders.find(
        (order: any) => order.id === key
      )

      const collateral = collaterals.filter((collateral) => {
        return currency === ZERO_ADDRESS
          ? collateral.symbol === 'ETH' && safeChainId === collateral.chainId
          : collateral.token.wrapped.address.toLowerCase() === currency.toLowerCase() &&
              safeChainId === collateral.chainId
      })[0]

      const product = Object.values(PERP_PRODUCTS[safeChainId]).filter((product) => {
        return product.bytes32Id === productId
      })[0]

      fullDetails.push({
        key,
        collateral,
        isClose,
        isLong,
        product,
        margin: +margin / 10 ** 8,
        value: +size / 10 ** 8,
        user,
        timestamp: createdAtTimestamp * 1000,
      })
    }
    return [true, fullDetails]
  }, [account, activeOrders, allOrders, safeChainId])
}

function useActiveOrders(uniqueKeys: string[]): [boolean, { [k: string]: { isClose: boolean } }] {
  const contract = useTradingContract()
  const activeOrders = useSingleCallResult(contract, 'getOrders', [uniqueKeys])

  return useMemo(() => {
    if (!uniqueKeys.length || !contract || activeOrders.error || !activeOrders.result) {
      return [false, {}]
    }

    const details: { [k: string]: { isClose: boolean } } = {}
    uniqueKeys.map((key, i) => {
      if (!activeOrders.result) {
        return undefined
      }
      if (!activeOrders.result[0][i]) {
        return undefined
      }
      const [isClose, size] = activeOrders.result[0][i]
      if (!+size) {
        return undefined
      }
      details[key] = {
        isClose,
      }
      return undefined
    })

    return [true, details]
  }, [uniqueKeys, contract, activeOrders])
}

export type Position = {
  key: string
  collateral: Collateral
  fee: number
  price: number
  isLong: boolean
  product: Product
  margin: number
  value: number
  size: number
  timestamp: number
  leverage: number
  isUpdating: boolean
  isClosing: boolean
}

export function useOrders(hash: string): [boolean, Order[]] {
  const { account, chainId } = useActiveWeb3React()
  const [mockProducts, setMockproducts] = useState<
    {
      key: string
      user: string
      collateral: Collateral
      product: Product
      isLong: boolean
    }[]
  >([])
  const [keys, setKeys] = useState<string[]>([])

  const [data, setData] = useState<any[]>([])
  const safeChainId = useMemo(() => {
    return chainId && PERP_CHAIN_IDS.indexOf(chainId) >= 0 ? chainId : SupportedChainId.OPTIMISM
  }, [chainId])
  const contract = useTradingContract()
  const latestBlock = useBlockNumber()

  useEffect(() => {
    if (!account) {
      return
    }
    const mockProducts = Object.values(PERP_PRODUCTS[safeChainId])
      .map((product: Product) => {
        return product.collaterals.map((collateral) => {
          const { isNative } = collateral.token
          const tokenAddress = isNative ? ZERO_ADDRESS : collateral.token.address
          const long = solidityKeccak256(
            ['address', 'bytes32', 'address', 'bool'],
            [account, product.bytes32Id, tokenAddress, true]
          )
          const short = solidityKeccak256(
            ['address', 'bytes32', 'address', 'bool'],
            [account, product.bytes32Id, tokenAddress, false]
          )
          return [
            { key: long, user: account, collateral, product, isLong: true },
            { key: short, user: account, collateral, product, isLong: false },
          ]
        })
      })
      .flat(2)
    setMockproducts(mockProducts)
    const new_keys = mockProducts.map((product) => product.key)
    setKeys(new_keys)
  }, [safeChainId, account])

  useEffect(() => {
    let ready = true
    async function getOrdersFromContract() {
      if (ready && contract) {
        await contract
          .getOrders(keys)
          .then((res: any[][]) => {
            setData(res)
            ready = true
          })
          .catch(() => {
            ready = true
          })
      }
    }

    getOrdersFromContract()
    return () => {
      ready = false
    }
  }, [contract, latestBlock, hash, keys])

  return useMemo<[boolean, Order[]]>(() => {
    if (!account || !data.length) {
      return [false, []]
    }
    const callResult: Order[] = keys
      .map((key: any, i) => {
        if (!data || !data[i] || !+data[i][1]) {
          return undefined
        } else {
          const [isClose, size, margin] = data[i]
          return {
            ...mockProducts.find((prod) => prod.key === key),
            value: +size / 10 ** 8,
            isClose,
            margin: +margin / 10 ** 8,
            leverage: +size / 10 ** 8 / (margin / 10 ** 8),
            timestamp: 0,
          }
        }
      })
      .filter((product) => product) as Order[]
    return [true, callResult]
  }, [account, data])
}

export function usePositions(hash: string): [boolean, Position[]] {
  const { account, chainId } = useActiveWeb3React()
  const [mockProducts, setMockproducts] = useState<
    {
      key: string
      user: string
      collateral: Collateral
      product: Product
      isLong: boolean
    }[]
  >([])
  const [keys, setKeys] = useState<string[]>([])

  const [data, setData] = useState<any[]>([])
  const safeChainId = useMemo(() => {
    return chainId && PERP_CHAIN_IDS.indexOf(chainId) >= 0 ? chainId : SupportedChainId.OPTIMISM
  }, [chainId])
  const contract = useTradingContract()
  const latestBlock = useBlockNumber()

  useEffect(() => {
    if (!account) {
      return
    }
    const mockProducts = Object.values(PERP_PRODUCTS[safeChainId])
      .map((product: Product) => {
        return product.collaterals.map((collateral) => {
          const { isNative } = collateral.token
          const tokenAddress = isNative ? ZERO_ADDRESS : collateral.token.address
          const long = solidityKeccak256(
            ['address', 'bytes32', 'address', 'bool'],
            [account, product.bytes32Id, tokenAddress, true]
          )
          const short = solidityKeccak256(
            ['address', 'bytes32', 'address', 'bool'],
            [account, product.bytes32Id, tokenAddress, false]
          )
          return [
            { key: long, user: account, collateral, product, isLong: true },
            { key: short, user: account, collateral, product, isLong: false },
          ]
        })
      })
      .flat(2)
    setMockproducts(mockProducts)
    const new_keys = mockProducts.map((product) => product.key)
    setKeys(new_keys)
  }, [safeChainId, account])

  useEffect(() => {
    let ready = true
    async function getPositionsFromContract() {
      if (ready && contract) {
        await contract
          .getPositions(keys)
          .then((res: any[][]) => {
            setData(res)
            ready = true
          })
          .catch(() => {
            ready = true
          })
      }
    }

    getPositionsFromContract()
    return () => {
      ready = false
    }
  }, [contract, latestBlock, hash, keys])

  return useMemo<[boolean, Position[]]>(() => {
    if (!account || !data.length) {
      return [false, []]
    }
    const callResult: Position[] = keys
      .map((key: any, i) => {
        if (!data || !data[i] || !+data[i][0]) {
          return undefined
        } else {
          const [size, margin, timestamp, price] = data[i]
          return {
            ...mockProducts.find((prod) => prod.key === key),
            value: +size / 10 ** 8,
            price: +price / 10 ** 8,
            margin: +margin / 10 ** 8,
            leverage: +size / 10 ** 8 / (margin / 10 ** 8),
            size: +size / 10 ** 8 / (price / 10 ** 8),
            timestamp: timestamp * 1000,
            isClosing: false,
            isUpdating: false,
          }
        }
      })
      .filter((product) => product) as Position[]
    return [true, callResult]
  }, [account, data])
}

export function useGetLastDeposit(poolAddress: string): [boolean, number] {
  const [result, setResult] = useState<number>(0)
  const [loaded, setLoaded] = useState(false)

  const { account, library, chainId } = useActiveWeb3React()

  const safeChainId = useMemo(() => {
    return chainId && PERP_CHAIN_IDS.indexOf(chainId) >= 0 ? chainId : SupportedChainId.OPTIMISM
  }, [chainId])

  const contract = usePoolContract(poolAddress)

  const filter = contract?.filters.Deposit(account)

  useEffect(
    () => {
      async function fetchDeposit() {
        if (!filter || !contract || !library || !account) {
          setResult(0)
          setLoaded(false)
          return
        }

        const events = await contract.queryFilter(filter, 1)

        if (!events || !events.length) {
          setResult(0)
          setLoaded(false)
          return
        }
        const timestamp = (await library.getBlock(events[0].blockNumber)).timestamp * 1000
        setResult(timestamp)
        setLoaded(true)
      }

      const fetchDepositTimeOut = setTimeout(fetchDeposit, 7500)
      if (!result || !Object.keys(result)[0]) {
        fetchDeposit()
      }

      return () => clearTimeout(fetchDepositTimeOut)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contract, filter, library, safeChainId]
  )

  return [loaded, result]
}

type PoolDetails = {
  tvl: number
  userBalance: number
  claimableReward: number
  poolShare: number
  withdrawFee: number
  utilization: number
  openInterest: number
  utilizationMultiplier: number
  cooldown: number
  cumulativeFees: number
  cumulativePnl: number
  apy: number
}

export type DetailedPerpPool = PerpPool & PoolDetails

const emptyPool: DetailedPerpPool = {
  id: '',
  chainId: SupportedChainId.OPTIMISM,
  symbol: 'TOKEN',
  token: USDC[SupportedChainId.OPTIMISM],
  poolAddress: '',
  poolRewardsAddress: '',
  poolRewardsInception: 0,
  tvl: 0,
  userBalance: 0,
  claimableReward: 0,
  poolShare: 0,
  withdrawFee: 0,
  utilization: 0,
  openInterest: 0,
  utilizationMultiplier: 0,
  isExchange: false,
  cooldown: 0,
  cumulativeFees: 0,
  cumulativePnl: 0,
  apy: 0,
}

export function useSinglePerpPool(pool: PerpPool): [boolean, DetailedPerpPool] {
  const { account } = useActiveWeb3React()

  const { poolAddress, poolRewardsAddress, poolRewardsAddresses, chainId, token, isExchange, poolRewardsInception } =
    pool

  const { isNative } = token
  const routerContract = usePerpRouterContract(chainId)
  const poolContract = usePoolContract(poolAddress)
  const poolRewardContract = useRewardContract(poolRewardsAddress)
  const poolRewardsContracts = useMultipleRewardContracts(
    objMap(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      poolRewardsAddresses || PERP_POOLS[chainId].crn.poolRewardsAddresses!,
      (k, a: { address: string; inception: number }) => a.address
    ) as { [pool: string]: string }
  )

  const poolBalance = useCurrencyBalance(poolAddress, token)
  const userBalance = useSingleCallResult(poolContract, 'getCurrencyBalance', [account ?? undefined])
  const poolShareQuery = useSingleCallResult(routerContract, 'getPoolShare', [
    !isNative ? token?.address : ZERO_ADDRESS,
  ])
  const updateReward = useSingleCallResult(poolRewardContract, 'updateRewards', [account ?? undefined])
  const claimableReward = useSingleCallResult(!isExchange ? poolRewardContract : null, 'getClaimableReward', [
    account ?? undefined,
  ])
  // const claimableExchangeReward = useSingleCallResult(poolRewardsContracts[pool.id], 'getClaimableReward')

  const openInterest = useSingleCallResult(poolContract, 'openInterest')
  const withdrawFee = useSingleCallResult(poolContract, 'withdrawFee')
  const utilizationMultiplier = useSingleCallResult(poolContract, 'utilizationMultiplier')
  const minDepositTime = useSingleCallResult(poolContract, 'minDepositTime')
  const graphData = usePoolFromSubgraph(token.isNative ? ZERO_ADDRESS : token.address)

  return useMemo(() => {
    let result = emptyPool
    if (!pool) {
      return [false, result]
    }

    result = { ...result, ...pool }

    if (
      !poolContract ||
      !poolBalance ||
      !utilizationMultiplier.result ||
      utilizationMultiplier.error ||
      !openInterest.result ||
      openInterest.error ||
      !withdrawFee.result ||
      withdrawFee.error ||
      !minDepositTime.result ||
      minDepositTime.error
    ) {
      return [false, result]
    }
    const tvl = +poolBalance.toExact()
    const parsedOpenInterest = +formatUnits(openInterest.result[0], 18)
    const parsedUtilizationMultiplier = +formatUnits(utilizationMultiplier.result[0], 2)
    const { cumulativeFees, cumulativePnl } = { cumulativeFees: 0, cumulativePnl: 0 } // move after subgraph check
    const apy = getAPY(poolRewardsInception, +formatUnits(cumulativeFees, 6), 100, +formatUnits(cumulativePnl, 6), tvl) // move after subgraph check
    result = {
      ...result,
      tvl,
      withdrawFee: +formatUnits(withdrawFee.result[0], 2),
      openInterest: parsedOpenInterest,
      utilization: tvl ? (parsedOpenInterest * parsedUtilizationMultiplier) / tvl : 0,
      utilizationMultiplier: parsedUtilizationMultiplier,
      cooldown: +minDepositTime.result[0], // in seconds
      cumulativeFees: +formatUnits(cumulativeFees, 6),
      cumulativePnl: +formatUnits(cumulativePnl, 6),
      apy,
    }
    if (
      !account ||
      !userBalance.result ||
      userBalance.error ||
      !claimableReward.result ||
      claimableReward.error ||
      // !claimableExchangeReward.result ||
      // claimableExchangeReward.error ||
      !poolShareQuery.result ||
      poolShareQuery.error
    ) {
      return [true, result]
    }
    const poolShare = +formatUnits(poolShareQuery.result[0], 2)
    result = {
      ...result,
      userBalance: +formatUnits(userBalance.result[0], 18),
      claimableReward: +formatUnits(claimableReward.result[0], 18),
      poolShare,
      apy,
    }
    return [true, result]
  }, [
    pool,
    poolContract,
    poolBalance,
    utilizationMultiplier,
    openInterest,
    withdrawFee,
    minDepositTime,
    poolRewardsInception,
    account,
    userBalance,
    claimableReward,
    poolShareQuery,
  ])
}

function usePoolFromSubgraph(address: string, customChainId?: number) {
  const { chainId } = useActiveWeb3React()

  const queryChainId = customChainId ?? chainId

  return useGetPoolInfoQuery(
    { tokenAddress: address.toLowerCase(), chainId: queryChainId ?? undefined },
    {
      pollingInterval: getms(`30s`),
    }
  )
}

function useOrdersFromSubgraph(account: string | null | undefined, interval: string, customChainId?: number) {
  const { chainId } = useActiveWeb3React()

  const queryChainId = customChainId ?? chainId
  const int = getms(interval)

  return useGetOrdersQuery(
    { account: account ? account.toLowerCase() : undefined, chainId: queryChainId ?? undefined },
    {
      pollingInterval: int,
    }
  )
}

function usePositionsFromSubgraph(account: string | null | undefined, interval: string, customChainId?: number) {
  const { chainId } = useActiveWeb3React()

  const queryChainId = customChainId ?? chainId
  const int = getms(interval)
  return useGetPositionsQuery(
    { account: account ? account.toLowerCase() : undefined, first: 30, chainId: queryChainId ?? undefined },
    {
      pollingInterval: int,
    }
  )
}

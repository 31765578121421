import { createReducer } from '@reduxjs/toolkit'
import { Product } from 'constants/perps'

import { setProducts } from './actions'

export interface PerpetualsState {
  activeProducts: Omit<Product, 'token' | 'collaterals'>[]
}

export const initialState: PerpetualsState = {
  activeProducts: [],
}

export default createReducer(initialState, (builder) =>
  builder.addCase(setProducts, (state, action) => {
    state.activeProducts = action.payload.products
  })
)

import Column from 'components/Column'
import Row, { RowBetween } from 'components/Row'
import { Product } from 'constants/perps'
import { ProductResult } from 'state/perpetuals/hooks'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0;
  background: ${({ theme }) => theme.bg0};
`

export default function InfoWidget({
  productInfo,
  productDetails,
}: {
  productInfo: Product
  productDetails: ProductResult | undefined
}) {
  return (
    <Wrapper>
      <RowBetween className="drag-header" style={{ padding: '0.5rem 0.75rem' }}>
        <Row style={{ display: 'flex', alignItems: 'center' }}>
          <ThemedText.Small fontWeight={600}>Info</ThemedText.Small>
        </Row>
      </RowBetween>
      <Column style={{ padding: '0.25rem 0.75rem 0.75rem 0.75rem', justifyContent: 'space-between', flex: '1 0 auto' }}>
        <RowBetween style={{ gap: '0.75rem' }}>
          <ThemedText.Small fontSize={14} fontWeight={600}>
            Contract
          </ThemedText.Small>
          <ThemedText.Small fontSize={14} fontWeight={300}>
            {productInfo.label}
          </ThemedText.Small>
        </RowBetween>
        <RowBetween style={{ gap: '0.75rem' }}>
          <ThemedText.Small fontSize={14} fontWeight={600}>
            Order fee
          </ThemedText.Small>
          <ThemedText.Small fontSize={14} fontWeight={300}>
            {(productDetails?.fee || 0) * 10 ** 2}%
          </ThemedText.Small>
        </RowBetween>
        <RowBetween style={{ gap: '0.75rem' }}>
          <ThemedText.Small fontSize={14} fontWeight={600}>
            Liquidation Treshold
          </ThemedText.Small>
          <ThemedText.Small fontSize={14} fontWeight={300}>
            {productDetails ? 100 - productDetails.liquidationThreshold * 100 : 0}%
          </ThemedText.Small>
        </RowBetween>
        <RowBetween style={{ gap: '0.75rem' }}>
          <ThemedText.Small fontSize={14} fontWeight={600}>
            Maximum Leverage
          </ThemedText.Small>
          <ThemedText.Small fontSize={14} fontWeight={300}>
            {productDetails ? productDetails.maxLeverage.toFixed(2) : (5).toFixed(2)}x
          </ThemedText.Small>
        </RowBetween>
      </Column>
    </Wrapper>
  )
}

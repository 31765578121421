import { BaseQueryFn } from '@reduxjs/toolkit/query'
import { createApi } from '@reduxjs/toolkit/query/react'
import { SupportedChainId } from 'constants/chains'
import { DocumentNode } from 'graphql'
import { ClientError, gql, GraphQLClient } from 'graphql-request'
import { AppState } from 'state'

// List of supported subgraphs. Note that the app currently only support one active subgraph at a time
const CHAIN_SUBGRAPH_URL: Record<number, string> = {
  [SupportedChainId.OPTIMISM]: 'https://api.thegraph.com/subgraphs/name/barenfels/crn-trading',
  [SupportedChainId.OPTIMISM_GOERLI]: 'https://api.thegraph.com/subgraphs/name/barenfels/perp-stuff',
}

export const api = createApi({
  reducerPath: 'perpApi',
  baseQuery: graphqlRequestBaseQuery(),
  endpoints: (builder) => ({
    getPoolInfo: builder.query({
      query: ({ tokenAddress, chainId }) => ({
        document: gql`
          query getPoolInfo($tokenAddress: ID, $chainId: Int) {
            datas(where: { id: $tokenAddress }) {
              id
              cumulativeFees
              cumulativePnl
              cumulativeVolume
              cumulativeMargin
              openInterest
              openInterestLong
              openInterestShort
              positionCount
              tradeCount
            }
          }
        `,
        variables: {
          tokenAddress,
          chainId,
        },
      }),
    }),
    getUserHistory: builder.query({
      query: ({ first, skip, account, chainId }) => ({
        document: gql`
          query getUserHistory($first: Int, $skip: Int, $account: Bytes, $chainId: Int) {
            trades(orderBy: timestamp, orderDirection: desc, first: $first, skip: $skip, where: { user: $account }) {
              id
              txHash
              positionKey
              productId
              currency
              margin
              leverage
              size
              entryPrice
              closePrice
              isLong
              pnl
              fee
              timestamp
              blockNumber
              wasLiquidated
              isFullClose
            }
          }
        `,
        variables: {
          first,
          skip,
          account,
          chainId,
        },
      }),
    }),
    getPositions: builder.query({
      query: ({ first, account, chainId }) => ({
        document: gql`
          query getPositions($first: Int, $account: Bytes, $chainId: Int) {
            positions(orderBy: createdAtTimestamp, orderDirection: desc, first: $first, where: { user: $account }) {
              id
              productId
              currency
              margin
              fee
              size
              leverage
              price
              isLong
              createdAtTimestamp
            }
          }
        `,
        variables: {
          first,
          account,
          chainId,
        },
      }),
    }),
    getOrders: builder.query({
      query: ({ first, account, chainId }) => ({
        document: gql`
          query getOrders($first: Int, $account: Bytes, $chainId: Int) {
            orders(orderBy: createdAtTimestamp, orderDirection: desc, first: $first, where: { user: $account }) {
              id
              productId
              currency
              margin
              size
              isClose
              isLong
              createdAtTimestamp
            }
          }
        `,
        variables: {
          first,
          account,
          chainId,
        },
      }),
    }),
    getVolume: builder.query({
      query: ({ chainId }) => ({
        document: gql`
          query getVolume($chainId: Int) {
            datas(first: 2) {
              id
              cumulativeVolume
            }
          }
        `,
        variables: {
          chainId,
        },
      }),
    }),
  }),
})

// Graphql query client wrapper that builds a dynamic url based on chain id
function graphqlRequestBaseQuery(): BaseQueryFn<
  { document: string | DocumentNode; variables?: any },
  unknown,
  Pick<ClientError, 'name' | 'message' | 'stack'>,
  Partial<Pick<ClientError, 'request' | 'response'>>
> {
  return async ({ document, variables }, { getState }) => {
    try {
      const queryVariables = variables
      const queryChainId = queryVariables.chainId ?? (getState() as AppState).application.chainId
      delete queryVariables.chainId

      const subgraphUrl = queryChainId ? CHAIN_SUBGRAPH_URL[queryChainId] : undefined

      if (!subgraphUrl) {
        return {
          error: {
            name: 'UnsupportedChainId',
            message: `Subgraph queries against ChainId ${queryChainId} are not supported.`,
            stack: '',
          },
        }
      }

      return { data: await new GraphQLClient(subgraphUrl).request(document, queryVariables), meta: {} }
    } catch (error) {
      if (error instanceof ClientError) {
        const { name, message, stack, request, response } = error
        return { error: { name, message, stack }, meta: { request, response } }
      }
      throw error
    }
  }
}

import { Analytics16, Bullhorn16, Collaborate16, Connect16, Currency16 } from '@carbon/icons-react'
import { Trans } from '@lingui/macro'
import CurrencyLogo from 'components/CurrencyLogo'
// import useScrollPosition from '@react-hook/window-scroll'
import { CHAIN_INFO } from 'constants/chainInfo'
import { DEFAULT_FALLBACK_CHAIN, PERP_CHAIN_IDS, SupportedChainId } from 'constants/chains'
import { checkSupport, Features } from 'constants/dapp'
import { PERP_POOLS } from 'constants/perps'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import useTheme from 'hooks/useTheme'
import { darken } from 'polished'
import { useMemo } from 'react'
import { ArrowRight, FileText, GitHub, MessageCircle, Moon, Sun, Trello, Twitter } from 'react-feather'
import { NavLink } from 'react-router-dom'
import { Text } from 'rebass'
import { ApplicationModal } from 'state/application/reducer'
import { useAppSelector } from 'state/hooks'
import { useDarkModeManager } from 'state/user/hooks'
import { useNativeCurrencyBalances } from 'state/wallet/hooks'
import styled from 'styled-components/macro'

import { ReactComponent as LogoSmall } from '../../assets/svg/logo-small.svg'
import { ExternalLink } from '../../theme'
import ClaimModal from '../claim/ClaimModal'
import Menu, { FlyoutAlignment } from '../Menu'
import Row from '../Row'
import Web3Status from '../Web3Status'
import MenuModal from './MenuModal'
import NetworkSelector from './NetworkSelector'

const HeaderFrame = styled.div<{ showBackground: boolean }>`
  background-image: ${({ theme }) => `linear-gradient(to bottom, transparent 50%, ${theme.bg2} 50% )}}`};
  background-position: ${({ showBackground }) => (showBackground ? '0 -100%' : '0 0')};
  background-size: 100% 200%;
  transition: background-position 0.1s, box-shadow 0.1s;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.bg3};
`
const PoolMenu = styled(Menu)``
const HeaderInner = styled.div`
  display: flex;
  grid-template-columns: 120px 1fr;
  align-items: center;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  width: 100%;
  top: 0;
  position: relative;
  padding: 0;
  z-index: 21;
  position: relative;
  margin: 0 auto;
  padding: 0;

  ${({ theme }) => theme.mediaWidth.upToMedium`
  height: 4.5rem;
  grid-template-columns: 1fr 1fr;

`};

  ${({ theme }) => theme.mediaWidth.upToSmall`
  grid-template-columns: 36px 1fr;
`};
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    align-items: center;
  `};
`
const HeaderElementAtLarge = styled(HeaderElement)`
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: none;
  `};
`

const HeaderLinks = styled(Row)`
  justify-self: center;
  background-color: ${({ theme }) => theme.bg2};
  width: fit-content;
  padding: 0;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
  overflow: initial;
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToLarge`
    justify-self: start;  
    `};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    border-radius: 0.25rem;
    height: 3rem;
    justify-self: center;
  `};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: row;
    justify-content: space-between;
    justify-self: center;
    z-index: 99;
    position: fixed;
    bottom: 0; right: 50%;
    transform: translate(50%,-50%);
    width: 480px;
    display: flex;
    background-color: transparent;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100vw;
  `}
`

const Separator = styled.div`
  height: 6.25rem;
  width: 1px;
  background-color: ${({ theme }) => theme.bg3};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `}
  ${({ theme }) => theme.mediaWidth.upToMedium`
    height: 4.5rem;
  `}
`

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, active }) => (!active ? theme.bg2 : theme.bg2)};
  border-radius: 16px;
  white-space: nowrap;
  width: 100%;
  height: 40px;

  :focus {
    border: 1px solid blue;
  }
`

const BalanceContainer = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToLarge`
    display: none;
  `};
`

const BalanceText = styled(Text)`
  color: ${({ theme }) => theme.text2};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
  margin-right: 1rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-self: start;
    margin-right: 0;
  `};
  :hover {
    cursor: pointer;
  }
`

const CronusIconSmall = styled.div`
  display: flex;
  margin: 0 2.5rem;
  position: relative;
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToLarge`
  height: 40px;
`};
  ${({ theme }) => theme.mediaWidth.upToMedium`
  margin: 0 1rem;
`}
`

const activeClassName = 'ACTIVE'

const StyledNavLink = styled(NavLink).attrs({
  activeClassName,
})<{ visibility?: string[] }>`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  height: 100%;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text2};
  font-size: 1rem;
  font-weight: 500;
  padding: 0 1rem;
  word-break: break-word;
  overflow: hidden;
  white-space: nowrap;
  &.${activeClassName} {
    justify-content: center;
    color: ${({ theme }) => theme.text1};
  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.text1)};
  }
  ${({ theme, visibility }) => theme.mediaWidth.upToLarge`
    display: ${visibility ? (!visibility.length || visibility.indexOf('lg') !== -1 ? 'flex' : 'none') : 'flex'};
  `}
  ${({ theme, visibility }) => theme.mediaWidth.upToMedium`
     display: ${visibility ? (!visibility.length || visibility.indexOf('md') !== -1 ? 'flex' : 'none') : 'flex'};
    font-size: 16px;
    justify-content: start;
    &:active,   &.${activeClassName} {
      justify-content: start;
    }
  `}

  ${({ theme, visibility }) => theme.mediaWidth.upToSmall`
  display: ${visibility ? (!visibility.length || visibility.indexOf('sm') !== -1 ? 'flex' : 'none') : 'flex'};
`}
  ${({ theme, visibility }) => theme.mediaWidth.upToExtraSmall`
  display: ${visibility ? (!visibility.length || visibility.indexOf('xs') !== -1 ? 'flex' : 'none') : 'flex'};
`}
`

const StyledLink = styled.a.attrs({
  activeClassName,
})<{ visibility?: string[] }>`
  ${({ theme }) => theme.flexRowNoWrap}
  display: ${({ visibility }) =>
    visibility ? (!visibility.length || visibility.indexOf('xl') !== -1 ? 'flex' : 'none') : 'flex'};
  align-items: center;
  height: 100%;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text2};
  font-size: 1rem;
  font-weight: 500;
  padding: 0 1rem;
  word-break: break-word;
  overflow: hidden;
  white-space: nowrap;
  &.${activeClassName} {
    justify-content: center;
    color: ${({ theme }) => theme.text1};
  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.text1)};
  }

  ${({ theme, visibility }) => theme.mediaWidth.upToLarge`
  display: ${visibility ? (!visibility.length || visibility.indexOf('lg') !== -1 ? 'flex' : 'none') : 'flex'};

`}
  ${({ theme, visibility }) => theme.mediaWidth.upToMedium`
  display: ${visibility ? (!visibility.length || visibility.indexOf('md') !== -1 ? 'flex' : 'none') : 'flex'};
  font-size: 16px;
  justify-content: start;
  &:active,   &.${activeClassName} {
    justify-content: start;
  }
`}
  ${({ theme, visibility }) => theme.mediaWidth.upToSmall`
  display: ${visibility ? (!visibility.length || visibility.indexOf('sm') !== -1 ? 'flex' : 'none') : 'flex'};
`}
  ${({ theme, visibility }) => theme.mediaWidth.upToExtraSmall`
  display: ${visibility ? (!visibility.length || visibility.indexOf('xs') !== -1 ? 'flex' : 'none') : 'flex'};
`}
`

const HeaderElementBelowLarge = styled(HeaderElement)`
  display: none;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: flex;
    margin: 0 1rem;
    width: 100%;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.bg2};
    border: 1px solid ${({ theme }) => theme.bg3};
    box-shadow: 0px 6px 10px rgb(0 0 0 / 2%);
    .hidebuttonmobile {
      display: none!important;
    }
  `};
`

const ToggleMenuItem = styled.button`
  background-color: transparent;
  margin: 0;
  padding: 0;
  border: none;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 0.5rem;
  justify-content: space-between;
  font-size: 1rem;
  font-weight: 500;
  color: ${({ theme }) => theme.text2};
  :hover {
    color: ${({ theme }) => theme.text1};
    cursor: pointer;
    text-decoration: none;
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: row-reverse;
    justify-content: start;
  `}
`

const MenuItem = styled(ExternalLink)<{ visibility?: string[] }>`
  display: ${({ visibility }) =>
    visibility ? (!visibility.length || visibility.indexOf('xl') !== -1 ? 'flex' : 'none') : 'flex'};
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 0.5rem;
  justify-content: space-between;
  color: ${({ theme }) => theme.text2};
  :hover {
    color: ${({ theme }) => theme.text1};
    cursor: pointer;
    text-decoration: none;
  }

  ${({ theme, visibility }) => theme.mediaWidth.upToLarge`
  display: ${visibility ? (!visibility.length || visibility.indexOf('lg') !== -1 ? 'flex' : 'none') : 'flex'};
  `}

  ${({ theme, visibility }) => theme.mediaWidth.upToMedium`
  display: ${visibility ? (!visibility.length || visibility.indexOf('md') !== -1 ? 'flex' : 'none') : 'flex'};
    flex-direction: row-reverse;
    justify-content: start;
  `}

  ${({ theme, visibility }) => theme.mediaWidth.upToSmall`
  display: ${visibility ? (!visibility.length || visibility.indexOf('sm') !== -1 ? 'flex' : 'none') : 'flex'};
`}
  ${({ theme, visibility }) => theme.mediaWidth.upToExtraSmall`
  display: ${visibility ? (!visibility.length || visibility.indexOf('xs') !== -1 ? 'flex' : 'none') : 'flex'};
`}
`

const InternalMenuItem = styled(NavLink)<{ reverseMobile?: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 0.5rem;
  justify-content: space-between;
  color: ${({ theme }) => theme.text2};
  text-decoration: none;
  :hover {
    color: ${({ theme }) => theme.text1};
    cursor: pointer;
    text-decoration: none;
  }
  ${({ theme, reverseMobile }) => theme.mediaWidth.upToMedium`
    flex-direction: ${reverseMobile ? 'row-reverse' : 'row'};
    justify-content: start;
  `}
`
const MarginIcon = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-right: 0.75rem
  `}
`

const LinkIcon = styled.div<{ reverse?: boolean }>`
  margin-right: ${({ reverse }) => (reverse ? '0' : '0.75rem')};
  margin-left: ${({ reverse }) => (reverse ? '0.75rem' : '0')};
  display: flex;
  align-items: center;
`

const Break = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.bg3};
`

function NavItems({ chainId }: { chainId: number | undefined }) {
  const isUsCitizen = useAppSelector((state) => state.user.isUsCitizen)
  const { account } = useActiveWeb3React()
  return (
    <StyledNavItems>
      <StyledNavLink id={`swap-nav-link`} to={'/trade'}>
        <LinkIcon>
          <Analytics16 />
        </LinkIcon>
        <Trans>Trade</Trans>
      </StyledNavLink>
      <PoolMenu
        title="Earn"
        disabled={!checkSupport(Features.PERPETUALS, chainId)}
        appModal={ApplicationModal.PERP_POOLS}
        className={'hidebuttonmobile'}
        buttonIcon={
          <LinkIcon>
            <Collaborate16 />
          </LinkIcon>
        }
        flyoutAlignment={FlyoutAlignment.LEFT}
      >
        <PerpPoolMenuItems />
      </PoolMenu>
      <StyledNavLink id={`refer-nav-link`} to={'/referrals-and-leaderboards'} visibility={['lg']}>
        <LinkIcon>
          <Bullhorn16 />
        </LinkIcon>
        <Trans>Referrals & Leaderboards</Trans>
      </StyledNavLink>
      <StyledLink
        id={`refer-nav-link`}
        href={'https://app.multichain.org/#/router?bridgetoken=CRN&toChainId=10&network=9001'}
        target="_blank"
      >
        <LinkIcon>
          <Connect16 />
        </LinkIcon>
        <Trans>Bridge</Trans>
      </StyledLink>
      {/*       
      <StyledNavLink
        id={`tetra-nav-link`}
        to={'/tetrapool/0'}
        isActive={(match, { pathname }) => Boolean(match) || pathname.startsWith('/tetrapool')}
      >
        <LinkIcon>
          <DataSet16 />
        </LinkIcon>
        <Trans>Tetraswap</Trans>
      </StyledNavLink> 
      */}
    </StyledNavItems>
  )
}

const StyledNavItems = styled.div`
  display: flex;
  height: 100%;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding-top: 12px;
    flex-direction: column;
    row-gap: 12px;
    .hidebuttonmobile {
      display: none!important;
    }
  `}
`

const StyledNavItemsAtLarge = styled.div`
  height: 100%;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: none;
  `}
`
function MenuItems() {
  const [darkMode, toggleDarkMode] = useDarkModeManager()
  const { chainId } = useActiveWeb3React()
  return (
    <>
      {checkSupport(Features.MOCKTOKEN, chainId) && (
        <InternalMenuItem reverseMobile to="/claim">
          <div>
            <Trans>Claim Tokens</Trans>
          </div>
          <MarginIcon>
            <Currency16 style={{ opacity: 0.6 }} />
          </MarginIcon>
        </InternalMenuItem>
      )}
      <MenuItem href="https://app.cronusfinancexyz.com" target="_blank">
        <span>
          <Trans>Exchange (Evmos)</Trans>
        </span>
        <MarginIcon>
          <ArrowRight opacity={0.6} size={16} />
        </MarginIcon>
      </MenuItem>
      <MenuItem href="https://medium.com/@cronusfinance" target="_blank">
        <span>
          <Trans>Medium</Trans>
        </span>
        <MarginIcon>
          <Trello opacity={0.6} size={16} />
        </MarginIcon>
      </MenuItem>
      <MenuItem href="https://twitter.com/cronusfinance" target="_blank">
        <span>
          <Trans>Twitter</Trans>
        </span>
        <MarginIcon>
          <Twitter opacity={0.6} size={16} />
        </MarginIcon>
      </MenuItem>
      <MenuItem href="https://discord.gg/zs6kAZzPdF" target="_blank">
        <span>
          <Trans>Discord</Trans>
        </span>
        <MarginIcon>
          <MessageCircle opacity={0.6} size={16} />
        </MarginIcon>
      </MenuItem>
      <MenuItem href="https://github.com/cronus-finance" target="_blank">
        <span>
          <Trans>Github</Trans>
        </span>
        <MarginIcon>
          <GitHub opacity={0.6} size={16} />
        </MarginIcon>
      </MenuItem>
      {false && (
        <MenuItem href="https://docs.cronusfinancexyz.com" target="_blank">
          <span>
            <Trans>Docs</Trans>
          </span>
          <MarginIcon>
            <FileText opacity={0.6} size={16} />
          </MarginIcon>
        </MenuItem>
      )}
      <ToggleMenuItem onClick={() => toggleDarkMode()}>
        <span>{darkMode ? <Trans>Light Theme</Trans> : <Trans>Dark Theme</Trans>}</span>
        <MarginIcon>{darkMode ? <Sun opacity={0.6} size={16} /> : <Moon opacity={0.6} size={16} />}</MarginIcon>
      </ToggleMenuItem>
    </>
  )
}

function PerpPoolMenuItems() {
  const { chainId } = useActiveWeb3React()

  const safeChainId = useMemo(() => {
    return chainId && PERP_CHAIN_IDS.indexOf(chainId) >= 0 ? chainId : SupportedChainId.OPTIMISM
  }, [chainId])

  const pools = useMemo(() => {
    return Object.values(PERP_POOLS[safeChainId]).map((pool) => {
      if (pool.isExchange) return null
      const msg = pool.isExchange ? (
        <Trans>Stake {pool.symbol} and earn</Trans>
      ) : (
        <Trans>Provide {pool.symbol} liquidity</Trans>
      )
      return (
        <InternalMenuItem
          key={`pool-link-${pool.id}`}
          to={`/perps/pool/${pool.id}`}
          style={{ justifyContent: 'start' }}
        >
          <MarginIcon style={{ marginRight: '0.75rem' }}>
            <CurrencyLogo size={'18px'} currency={pool.token} />
          </MarginIcon>
          <div style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>{msg}</div>
        </InternalMenuItem>
      )
    })
  }, [safeChainId])
  return <>{!!pools && pools}</>
}

export default function Header() {
  const { account, chainId } = useActiveWeb3React()

  const userEthBalance = useNativeCurrencyBalances(account ? [account] : [])?.[account ?? '']
  const [darkMode] = useDarkModeManager()
  const { white } = useTheme()

  const {
    // infoLink,
    nativeCurrency: { symbol: nativeCurrencySymbol },
  } = CHAIN_INFO[chainId ? chainId : DEFAULT_FALLBACK_CHAIN]
  return (
    <HeaderFrame showBackground={true}>
      <HeaderInner>
        <ClaimModal />
        <Title href=".">
          <CronusIconSmall>
            <LogoSmall fill={darkMode ? white : '#B50028'} height="40px" title="logo" />
          </CronusIconSmall>
          <Separator />
        </Title>
        <HeaderLinks>
          <HeaderElementBelowLarge>
            <MenuModal>
              <NavItems chainId={chainId || 0} />
              <Break />
              <PerpPoolMenuItems />
              <Break />
              <MenuItems />
            </MenuModal>
          </HeaderElementBelowLarge>
          <StyledNavItemsAtLarge>
            <NavItems chainId={chainId || 0} />
          </StyledNavItemsAtLarge>
        </HeaderLinks>
        <HeaderControls style={{ marginLeft: 'auto' }}>
          <HeaderElementAtLarge>
            <Menu title="More" appModal={ApplicationModal.MENU}>
              <MenuItems />
            </Menu>
          </HeaderElementAtLarge>
          <Separator />
          <HeaderElement>
            <NetworkSelector />
          </HeaderElement>
          <HeaderElement>
            <AccountElement active={!!account}>
              <Separator />
              {false && (
                <BalanceContainer>
                  {account ? (
                    <>
                      <BalanceText style={{ flexShrink: 0 }} pl="1rem" pr="1rem" fontWeight={500}>
                        <Trans>
                          {userEthBalance?.toSignificant(3) ?? 0} {nativeCurrencySymbol}
                        </Trans>
                      </BalanceText>
                      <Separator />
                    </>
                  ) : null}
                </BalanceContainer>
              )}
              <Web3Status />
            </AccountElement>
          </HeaderElement>
        </HeaderControls>
      </HeaderInner>
    </HeaderFrame>
  )
}

import debounce from 'lodash/debounce'
import { useEffect, useState } from 'react'
import { MEDIA_WIDTHS } from 'theme'

function useWindowWidth(delay = 700): [number, string] {
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth)
    const debouncedHandleResize = debounce(handleResize, delay)
    window.addEventListener('resize', debouncedHandleResize)
    return () => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  }, [delay])

  const breakpoints = {
    lg: MEDIA_WIDTHS.upToLarge - 23,
    md: 960 - 23,
    sm: 0,
  }
  const breakpoint = width >= breakpoints.lg ? 'lg' : width > breakpoints.md ? 'md' : 'sm'
  return [width, breakpoint]
}

export default useWindowWidth

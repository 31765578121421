// import { keccak256 as solidityKeccak256 } from '@ethersproject/solidity'
import { PriceContext } from 'components/PriceProvider'
import { ProductContext } from 'components/ProductProvider'
import { Product } from 'constants/perps'
import usePreviousConditional from 'hooks/usePreviousConditional'
import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react'
import { useAppDispatch } from 'state/hooks'
import { setProducts } from 'state/perpetuals/actions'
import { Order, Position, useOrders, usePositions } from 'state/perpetuals/hooks'
// import simpleBeautifyNumber from 'utils/simpleBeautifyNumber'

export enum POSITIONS_STATUS {
  UNINITIALIZED = 'uninitialized',
  LOADING = 'loading',
  READY = 'ready',
  ERROR = 'error',
  STALE = 'stale',
}

type PositionElement = {
  time: string
  price: string
  open_24h: string
  low_24h: string
  volume_24h: string
  diff_24h: string
  diffSide: boolean
  sideFromLast: boolean
}

type OrderElement = {
  time: string
  price: string
  open_24h: string
  low_24h: string
  volume_24h: string
  diff_24h: string
  diffSide: boolean
  sideFromLast: boolean
}

export const LedgerContext = createContext({})

function areEqual(array1: any[], array2: any[]): boolean {
  if (array1.length === array2.length) {
    return array1.every((element) => {
      if (array2.includes(element)) {
        return true
      }

      return false
    })
  }

  return false
}

export type EnhancedPosition = Position & {
  markPrice: number
  liquidationPrice: number
  pnl: number
  pnlPercent: number
}

export default function LedgerProvider({
  product,
  hash,
  children,
}: {
  product: Product
  hash: string
  children: ReactNode
}) {
  const [orderCtx, setOrderCtx] = useState<Order[]>([])
  const [positionCtx, setPositionCtx] = useState<EnhancedPosition[]>([])

  const [loading, setLoading] = useState(true)

  const PriceCtx = useContext(PriceContext) as any
  const ProductCtx = useContext(ProductContext) as any

  const dispatch = useAppDispatch()

  const [ordersLoaded, ordersRaw] = useOrders(hash)
  const orders = usePreviousConditional(ordersRaw, ordersLoaded) || ordersRaw

  const [positionsLoaded, positionsRaw] = usePositions(hash)
  const positions: Position[] = usePreviousConditional(positionsRaw, positionsLoaded) || positionsRaw

  const positionProducts = useMemo(() => {
    const products: Product[] = []
    positionCtx.forEach((position) => {
      const isRegistered = products.find((product) => {
        return product.bytes32Id === position.product.bytes32Id && product.chainId === position.product.chainId
      })
      if (!!isRegistered) return
      const newProd: any = { ...position.product }
      delete newProd.token
      delete newProd.collaterals
      products.push(newProd)
    })

    return products
  }, [positionCtx])

  useEffect(() => {
    dispatch(setProducts({ products: positionProducts }))

    return () => {
      dispatch(setProducts({ products: [] }))
    }
  }, [positionProducts])

  useEffect(() => {
    if (!areEqual(orders, orderCtx)) setOrderCtx(orders)
  }, [orders, positions])

  useEffect(() => {
    const pricedPositions = positions.map((position) => {
      const markPrice = PriceCtx[position.product.id] ? +PriceCtx[position.product.id].price.split(',').join('') : 0
      const { isLong, size, margin, price: entryPrice, leverage } = position
      const pnl = markPrice ? (isLong ? size * markPrice - size * entryPrice : size * entryPrice - size * markPrice) : 0
      const pnlPercent = markPrice ? (pnl / margin) * 100 : 0
      const liquidationThreshold = ProductCtx[1][position.product.bytes32Id]
        ? ProductCtx[1][position.product.bytes32Id].liquidationThreshold
        : 0
      const fee = ProductCtx[1][position.product.bytes32Id] ? ProductCtx[1][position.product.bytes32Id].fee : 0
      const product = ProductCtx[1][position.product.bytes32Id] ? ProductCtx[1][position.product.bytes32Id] : undefined
      const multiplier = 1 - (1 - liquidationThreshold) / leverage

      const liquidationPriceLong = entryPrice * multiplier
      const liquidationPriceShort = entryPrice / multiplier
      return {
        ...position,
        product: product ? { ...position.product, ...product } : position.product,
        markPrice,
        liquidationPrice: +(isLong ? liquidationPriceLong : liquidationPriceShort).toFixed(2),
        pnlPercent,
        pnl,
        fee,
      } as EnhancedPosition
    })
    if (!areEqual(positions, positionCtx) || orders.length) {
      if (!orders.length) {
        setPositionCtx(pricedPositions)
      }
      const positionsWithUpdates: EnhancedPosition[] = pricedPositions.map((pos) => {
        const outstandingOrder = orders.find((order) => order.key === pos.key)
        return { ...pos, isUpdating: !!outstandingOrder, isClosing: !!outstandingOrder?.isClose }
      })
      setPositionCtx(positionsWithUpdates)
    }
  }, [positions, orders])

  useEffect(() => {
    if (loading === !(ordersLoaded && positionsLoaded)) setLoading(!(ordersLoaded && positionsLoaded))
  }, [ordersLoaded, positionsLoaded])

  return (
    <LedgerContext.Provider value={{ orders: orderCtx, positions: positionCtx, loading }}>
      {children}
    </LedgerContext.Provider>
  )
}

import { LogoDiscord32 } from '@carbon/icons-react'
import WidgetBot from '@widgetbot/react-embed'
import Row from 'components/Row'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0;
  background: ${({ theme }) => theme.bg0};
`

const WrapperLink = styled.a`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0;
  background: ${({ theme }) => theme.bg0};
  color: unset;
  text-decoration: none;
`

const InvisibleHeader = styled.div`
  top: 0;
  left: 50px;
  position: absolute;
  height: 47px;
  width: 230px;
  z-index: 2000;
`

export default function DiscordWidget({ breakpoint }: { breakpoint?: string }) {
  const isSm = breakpoint === 'sm'
  return isSm ? (
    <WrapperLink
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        padding: '1rem',
        gap: '0.5rem',
      }}
      target="_blank"
      href="https://discord.gg/zs6kAZzPdF"
    >
      <LogoDiscord32 width={78} height={78} />
      <ThemedText.LargeHeader fontWeight={300} fontSize={20}>
        Chat with the community on Discord
      </ThemedText.LargeHeader>
    </WrapperLink>
  ) : (
    <Wrapper>
      {true && (
        <Row className="drag-header" style={{ padding: '0.5rem 0.75rem' }}>
          <ThemedText.Small>Chat</ThemedText.Small>
        </Row>
      )}
      <WidgetBot
        server="945697579310645360"
        channel="1067515342127104112"
        style={{ flex: '1 0 auto', borderRadius: '0' }}
      />
    </Wrapper>
  )
}

import { Settings20 as ManageIcon } from '@carbon/icons-react'
import { Trans } from '@lingui/macro'
import Card, { DarkGreyCard } from 'components/Card'
import CurrencyLogo from 'components/CurrencyLogo'
import { MouseoverTooltip } from 'components/Tooltip'
import { PerpPool } from 'constants/perps'
import { TransactionResult } from 'firebase/database'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import usePreviousConditional from 'hooks/usePreviousConditional'
import JSBI from 'jsbi'
import useCurrencyBalance from 'lib/hooks/useCurrencyBalance'
import AppBody from 'pages/AppBody'
import { useCallback, useMemo, useState } from 'react'
import { useGetLastDeposit, useRewardContract, useSinglePerpPool } from 'state/perpetuals/hooks'
import styled from 'styled-components/macro'
import { CountUp } from 'use-count-up'
import beautify from 'utils/simpleBeautifyNumber'

import { ButtonGray, ButtonPrimary, ButtonYellow } from '../../components/Button'
import { AutoColumn } from '../../components/Column'
// import ClaimRewardPerpModal from '../../components/earn/ClaimRewardPerpModal'
import ManagePerpModal from '../../components/earn/ManagePerpModal'
import { CardSection, DataCard } from '../../components/earn/styled'
import { RowBetween } from '../../components/Row'
import usePrevious from '../../hooks/usePrevious'
import { useWalletModalToggle } from '../../state/application/hooks'
import { ThemedText } from '../../theme'

const StyledCardSection = styled(CardSection)<{ disabled?: boolean }>`
  padding: 1rem;
  z-index: 1;
  border-bottom: 1px solid ${({ theme }) => theme.bg3};
  opacity: ${({ disabled }) => disabled && '0.4'};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    align-items: center;
    text-align: center;
  `}
`

const PositionInfo = styled(AutoColumn)<{ dim: any }>`
  position: relative;
  width: 100%;
  opacity: ${({ dim }) => (dim ? 0.6 : 1)};
`

const BottomSection = styled(AutoColumn)`
  border-radius: 12px;
  width: 100%;
  position: relative;
`

const ResponsiveRowBetween = styled(RowBetween)`
  align-items: baseline;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    align-items: inherit;
  `};
`

const StyledBottomCard = styled(DataCard)`
  background: transparent;
`

const PoolData = styled(DarkGreyCard)`
  background: transparent;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 110px;
    text-align: center;
  `};
`

const PoolDataRow = styled(PoolData)<{ dim: any | undefined }>`
  color: ${({ dim, theme }) => (dim ? theme.text4 : theme.text1)};
  &:first-child:not(:last-child) {
    border-right: 1px solid #2c2f36;
  }
`

const PoolDataBorder = styled(DarkGreyCard)`
  border: 1px solid ${({ theme }) => theme.bg3};
  background-color: transparent;
  border-radius: 8px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    border: 0px solid transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 110px;
    text-align: center;
  `};
`

const Wrapper = styled(Card)`
  border-radius: 10px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 12px;
  width: 100%;
  padding: 0;
`

const DataRow = styled(RowBetween)`
  justify-content: center;
  gap: 0;

  ${({ theme }) => theme.mediaWidth.upToSmall`
     gap: 0;
   `};
`

export default function ManagePerpPool({ pool }: { pool: PerpPool }) {
  const { account } = useActiveWeb3React()
  const [poolInfoLoaded, poolInfoRaw] = useSinglePerpPool(pool)

  const poolInfo = usePreviousConditional(poolInfoRaw, poolInfoLoaded) || poolInfoRaw
  const rewardContract = useRewardContract(poolInfo.poolRewardsAddress)
  const [lastDepositLoaded, lastDeposit] = useGetLastDeposit(pool.poolAddress)
  const [lastDepositTransaction, setLastDepositTransaction] = useState(null)

  const canWithdraw = useMemo(() => {
    if (!lastDepositLoaded || !poolInfoLoaded) return false
    if (lastDepositTransaction && lastDepositTransaction > lastDeposit) {
      return Date.now() > lastDepositTransaction + poolInfo.cooldown
    }
    return Date.now() > lastDeposit + poolInfo.cooldown
  }, [lastDeposit, lastDepositLoaded, lastDepositTransaction, poolInfo.cooldown, poolInfoLoaded])

  const previousClaimableReward = usePrevious(poolInfo.claimableReward)
  // get currencies and pair
  const totalStakedInUSDFriendly = beautify(poolInfo.tvl)
  const poolPercent = poolInfo.tvl === 0 ? 0 : (100 * poolInfo.userBalance) / poolInfo.tvl

  // detect existing unstaked LP position to show add button if none found
  const userLiquidityUnstaked = useCurrencyBalance(account ?? undefined, poolInfo.token)

  // toggle for staking modal and unstaking modal
  const [showManageModal, setShowManageModal] = useState(false)
  const [showClaimRewardModal, setShowClaimRewardModal] = useState(false)

  // fade cards if nothing staked or nothing earned yet
  const disableTop = !poolInfo.userBalance

  const toggleWalletModal = useWalletModalToggle()

  const handleManageClick = useCallback(() => {
    if (account) {
      setShowManageModal(true)
    } else {
      toggleWalletModal()
    }
  }, [account, toggleWalletModal])

  const handleClaimReward = useCallback(async () => {
    if (!rewardContract) return
    await rewardContract.collectReward().then((res: TransactionResult) => {
      // smt
    })
  }, [rewardContract])

  return (
    <AppBody maxWidth="640px">
      <AutoColumn gap="lg" justify="center">
        <AutoColumn gap="sm" style={{ width: '100%', padding: '1rem 1.25rem 0.5rem 1.25rem' }}>
          <RowBetween style={{ gap: '24px' }}>
            <ThemedText.Black fontWeight={500} fontSize={20} style={{ marginRight: '8px' }}>
              <Trans>{pool.symbol || 'TOKEN'}</Trans>
            </ThemedText.Black>

            <CurrencyLogo currency={pool.token} />
          </RowBetween>
          <ThemedText.SubHeader>This pool pays trader profits and receives their losses and fees.</ThemedText.SubHeader>
        </AutoColumn>
        <div style={{ display: 'grid', gridRowGap: '24px', padding: '0 1rem', width: '100%' }}>
          <DataRow style={{ gap: '24px' }}>
            <PoolData>
              <AutoColumn gap="sm">
                <ThemedText.SubHeader>
                  <Trans>Total staked</Trans>
                </ThemedText.SubHeader>
                <ThemedText.Body fontSize={24} fontWeight={500}>
                  {`${totalStakedInUSDFriendly} ${poolInfo.token?.symbol ?? 'TOKEN'}`}
                </ThemedText.Body>
              </AutoColumn>
            </PoolData>
            <PoolDataBorder>
              <MouseoverTooltip
                text={
                  'We calculate our APY based on past platform activity, check in a few days for a clearer estimate'
                }
              >
                <AutoColumn gap="sm">
                  <ThemedText.SubHeader>APY</ThemedText.SubHeader>
                  <ThemedText.Body fontSize={22} fontWeight={500}>
                    {/*poolInfo.apy*/}~%
                  </ThemedText.Body>
                </AutoColumn>
              </MouseoverTooltip>
            </PoolDataBorder>
          </DataRow>
          <ManagePerpModal
            isOpen={showManageModal}
            onDismiss={() => setShowManageModal(false)}
            poolInfo={poolInfo}
            userLiquidityUnstaked={userLiquidityUnstaked}
          />
          {/* <ClaimRewardPerpModal
            isOpen={showClaimRewardModal}
            onDismiss={() => setShowClaimRewardModal(false)}
            poolInfo={poolInfo}
          /> */}
          <PositionInfo gap="lg" justify="center" dim={false}>
            <BottomSection gap="lg" justify="center">
              <Wrapper disabled={disableTop}>
                <StyledCardSection style={{ position: 'relative' }}>
                  <AutoColumn gap="md" style={{ padding: '1.5rem 0' }}>
                    <ThemedText.Black fontWeight={600}>
                      <Trans>Your stake</Trans>
                    </ThemedText.Black>
                    <ResponsiveRowBetween>
                      <>
                        <AutoColumn gap="md">
                          <ThemedText.Black fontSize={36} fontWeight={600}>
                            {poolInfo.userBalance || '0'} {poolInfo.token?.symbol || 'TOKEN'}
                          </ThemedText.Black>
                        </AutoColumn>
                        {!!poolPercent && <ThemedText.Black>{poolPercent.toFixed(2)}% of pool</ThemedText.Black>}
                      </>
                    </ResponsiveRowBetween>
                  </AutoColumn>
                </StyledCardSection>
              </Wrapper>
              <StyledBottomCard>
                <RowBetween>
                  <PoolDataRow dim={poolInfo.userBalance === 0}>
                    <AutoColumn gap="sm">
                      <ThemedText.Black style={{ color: 'inherit' }}>
                        <Trans>Your unclaimed rewards</Trans>
                      </ThemedText.Black>
                      <ThemedText.LargeHeader fontSize={36} fontWeight={600}>
                        <CountUp
                          key={poolInfo.claimableReward.toString()}
                          isCounting={poolInfo.claimableReward > 0}
                          decimalPlaces={4}
                          start={previousClaimableReward}
                          end={poolInfo.claimableReward}
                          thousandsSeparator={','}
                          duration={1}
                        />
                      </ThemedText.LargeHeader>
                    </AutoColumn>
                  </PoolDataRow>
                </RowBetween>
              </StyledBottomCard>
            </BottomSection>
          </PositionInfo>
        </div>
        <DataRow>
          {account ? (
            userLiquidityUnstaked?.equalTo(JSBI.BigInt(0)) && poolInfo.userBalance ? (
              <ButtonGray $borderRadius="0" disabled={false} padding="1rem" onClick={handleManageClick}>
                <Trans>Withdraw</Trans>
              </ButtonGray>
            ) : !poolInfo.userBalance ? (
              <ButtonPrimary $borderRadius="0" disabled={false} padding="1rem" onClick={handleManageClick}>
                <Trans>Deposit</Trans>
              </ButtonPrimary>
            ) : (
              <ButtonGray $borderRadius="0" disabled={false} padding="1rem" onClick={handleManageClick}>
                <ManageIcon style={{ marginRight: '0.5rem' }} /> <Trans>Manage</Trans>
              </ButtonGray>
            )
          ) : (
            <ButtonPrimary $borderRadius="0" disabled={false} padding="1rem" onClick={handleManageClick}>
              <Trans>Connect Wallet</Trans>
            </ButtonPrimary>
          )}
          {account && (
            <ButtonYellow
              style={{ height: '100%' }}
              disabled={!poolInfo.claimableReward}
              padding="1rem"
              $borderRadius="0"
              onClick={() => handleClaimReward()}
            >
              <Trans>Claim</Trans>
            </ButtonYellow>
          )}
        </DataRow>
      </AutoColumn>
    </AppBody>
  )
}

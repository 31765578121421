import { api as generatedApi } from './generated'

// tag that should be applied to queries that need to be invalidated when the chain changes
export const CHAIN_TAG = 'Chain'

// enhanced api to provide/invalidate tags
export const api = generatedApi.enhanceEndpoints({
  addTagTypes: [CHAIN_TAG],
  endpoints: {
    getReferralCodes: {
      providesTags: [CHAIN_TAG],
    },
    getReferrerStats: {
      providesTags: [CHAIN_TAG],
    },
    getReferrerLeaderboard: {
      providesTags: [CHAIN_TAG],
    },
  },
})

export const {
  useGetReferralCodesQuery,
  useGetReferrerStatsQuery,
  useGetReferralQuery,
  useGetReferrerLeaderboardQuery,
} = api

import { Contract } from '@ethersproject/contracts'
import REFREADER_ABI from 'abis/referralReader.json'
import REFSTORAGE_ABI from 'abis/referralStorage.json'
import { REFREADER_ADDRESS, REFSTORAGE_ADDRESS } from 'constants/addresses'
import { PERP_CHAIN_IDS, SupportedChainId } from 'constants/chains'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useSingleCallResult } from 'lib/hooks/multicall'
import ms from 'ms.macro'
import { useMemo } from 'react'
import { getContract } from 'utils'

import { useGetReferralCodesQuery, useGetReferrerStatsQuery } from './enhanced'

export function useRefStorageContract(): Contract | null {
  const { account, library, chainId } = useActiveWeb3React()

  const safeChainId = useMemo(() => {
    return chainId && PERP_CHAIN_IDS.indexOf(chainId) >= 0 ? chainId : SupportedChainId.OPTIMISM
  }, [chainId])

  return useMemo(() => {
    if (!library) return null
    try {
      return getContract(
        REFSTORAGE_ADDRESS[safeChainId],
        REFSTORAGE_ABI,
        library,
        account ?? undefined
      ) as Contract | null
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [library, safeChainId, account])
}

export function useRefReaderContract(): Contract | null {
  const { account, library, chainId } = useActiveWeb3React()

  const safeChainId = useMemo(() => {
    return chainId && PERP_CHAIN_IDS.indexOf(chainId) >= 0 ? chainId : SupportedChainId.OPTIMISM
  }, [chainId])

  return useMemo(() => {
    if (!library) return null
    try {
      return getContract(
        REFREADER_ADDRESS[safeChainId],
        REFREADER_ABI,
        library,
        account ?? undefined
      ) as Contract | null
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [library, safeChainId, account])
}

export function useRefCodes() {
  const { account, chainId } = useActiveWeb3React()
  const contract = useRefStorageContract()

  const codes = useSingleCallResult(contract, 'traderReferralCodes', [account ?? undefined])

  useMemo(() => {
    return 0
  }, [codes])
}

export function useReferralCodes() {
  const { account } = useActiveWeb3React()
  return useGetReferralCodesQuery(
    { address: account?.toLowerCase() },
    {
      pollingInterval: ms`5s`,
    }
  )
}

export function useReferralStats(referralCode: string) {
  return useGetReferrerStatsQuery(
    { referralCode },
    {
      pollingInterval: ms`30s`,
    }
  )
}

function numberWithCommas(input: string) {
  return input.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export default function simpleBeautifyNumber(input: number | string, decimalPlaces = 2): string {
  const array = String(input).split('.')
  const sanitizedInt = numberWithCommas(array[0])
  if (array.length === 1) {
    return `${sanitizedInt}.00`
  }

  const sanitizedDecimals = array[1].length >= 2 ? array[1].substring(0, decimalPlaces) : array[1] + '0'

  return `${sanitizedInt}.${+sanitizedDecimals ? sanitizedDecimals : '00'}`
}

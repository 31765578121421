import Row from 'components/Row'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

export const PositionsWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
  background: ${({ theme }) => theme.bg0};
`

export const Divider = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.bg2};
`
export const HeaderRow = styled(Row)`
  background-color: ${({ theme }) => theme.bg1};
  justify-content: start;
`

export const SymbolRow = styled(Row)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `}
`

export const PNLRow = styled(Row)`
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: none;
  `}
`

export const LiqPriceRow = styled(Row)`
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: none;
  `}
`

export const DashGrid = styled.div<{
  templateColumns?: { sm?: string; md?: string; lg?: string }
  templateAreas?: { sm?: string; md?: string; lg?: string }
}>`
  display: grid;
  grid-gap: 1em;
  grid-template-columns: ${({ templateColumns }) => templateColumns?.sm || '100px 1fr 1fr'};
  grid-template-areas: ${({ templateAreas }) => templateAreas || '"name liq vol"'};
  padding: 0 0.5rem;

  > * {
    justify-content: flex-start;

    &:first-child {
      justify-content: flex-start;
      text-align: left;
      width: 100px;
    }
  }

  @media screen and (min-width: 680px) {
    display: grid;
    grid-gap: 1em;
    grid-template-areas: ${({ templateAreas }) => `'${templateAreas?.md}'` || 'name size value entry '};
    grid-template-columns: ${({ templateColumns }) => templateColumns?.md || '180px 1fr 1fr 1fr'};

    > * {
      justify-content: flex-start;
      width: 100%;

      &:first-child {
        justify-content: flex-start;
      }
    }
    > .hideonmedium {
      display: none;
    }
  }

  @media screen and (min-width: 1280px) {
    display: grid;
    grid-gap: 0.5em;
    grid-template-columns: ${({ templateColumns }) => templateColumns?.lg || '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1.5fr'};
    > .hideonmedium {
      display: flex;
    }
  }
`

export const PositionRow = styled(DashGrid)<{ isClosing?: boolean }>`
  height: fit-content;
  padding: 0.5rem 0.75rem;
  opacity: ${({ isClosing }) => (isClosing ? '0.55' : '1')};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 1.25rem 0.75rem 0.75rem 0.75rem;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    .button-cell {
      grid-area: 3 / 1 / 4 / 4;
    }
    .pnl-cell {
      grid-area: 2 / 1 / 3 / 2;
    }
  `}
`

export const PnlContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `}
`

export const PnlContainerSmall = styled.button<{ onClick: () => void }>`
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
  display: none;
  color: unset;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  `}
`

export const CellContent = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  padding-right: 0.5rem;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
  `}
`

export const ButtonContent = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  padding-right: 0.5rem;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-content: start;
  `}
`

export const TitleSmall = styled.div`
  display: none;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: block
  `}
`

export const ItemContainer = styled.div`
  scrollbar-gutter: stable;

  overflow-y: auto;
  /* ===== Scrollbar CSS ===== */
  /* Firefox */
  scrollbar-width: 16px;
  scrollbar-color: ${({ theme }) => theme.bg3} transparent;
  * {
    scrollbar-width: 16px;
    scrollbar-color: ${({ theme }) => theme.bg3} transparent;
  }

  /* Chrome, Edge, and Safari */
  &::-webkit-scrollbar,
  *::-webkit-scrollbar {
    width: 16px;
  }
  &::-webkit-scrollbar-track,
  *::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb,
  *::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.bg3};
    border-radius: 100px;
    border: 4px solid ${({ theme }) => theme.bg0};
    min-height: 40px;
  }

  ::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0);
  }
`

export const OrderContentGrid = styled.div<{ breakpoint: string; width: number }>`
  display: grid;
  grid-column-gap: 0.5em;
  grid-row-gap: 0.5em;
  padding: 0 0.5em;
  border-bottom: 1px solid ${({ theme }) => theme.bg2};
  grid-template-columns: 0.5fr 1fr 1fr 1fr;
  > * {
    justify-content: flex-start;

    &:first-child {
      justify-content: flex-start;
      text-align: left;
      width: 100px;
    }
  }

  ${({ breakpoint, width, theme }) =>
    breakpoint === 'lg'
      ? width > 12
        ? `
  grid-template-columns: 0.5fr 1fr 1fr 1fr 2.5fr;
  `
        : `
  grid-template-columns: 0.5fr 1fr 1fr 1fr;
  .order-type-row {
    justify-content: end;
  }
  .order-button-row {
    grid-column: span 4;
    padding-right: 0.5rem;
  }
      `
      : ''}

  ${({ breakpoint, width, theme }) =>
    breakpoint === 'md'
      ? width > 9
        ? `
  grid-template-columns: 0.5fr 1fr 1fr 1fr 2.5fr;
    `
        : `      
        .order-type-row {
          justify-content: end;
        }
        .order-button-row {
          grid-column: span 4;
          padding-right: 0.5rem;
        }
        `
      : ''}
`

export const PositionHeaderGrid = styled(DashGrid)`
  height: fit-content;
  padding: 0.5rem 0.75rem;
  padding-right: 26px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `}
`

export const OrderHeaderGrid = styled.div<{ breakpoint: string; width: number }>`
  display: none;
  height: fit-content;

  grid-column-gap: 0.5em;
  grid-row-gap: 0.5em;
  padding: 0.5rem 0.75rem;
  padding-right: 26px;
  grid-template-columns: 0.5fr 1fr 1fr 1fr;
  border-bottom: 1px solid ${({ theme }) => theme.bg2};
  > * {
    justify-content: flex-start;

    &:first-child {
      justify-content: flex-start;
      text-align: left;
      width: 100px;
    }
  }

  ${({ breakpoint, width, theme }) =>
    breakpoint === 'lg'
      ? width > 12
        ? `
        display:grid;
  grid-template-columns: 0.5fr 1fr 1fr 1fr 2.5fr;
  `
        : `
        display:grid;
  grid-template-columns: 0.5fr 1fr 1fr 1fr;
  div:last-child {
    justify-content: end;
  }
      `
      : ''}

  ${({ breakpoint, width, theme }) =>
    breakpoint === 'md'
      ? width > 9
        ? `
        display:grid;
  grid-template-columns: 0.5fr 1fr 1fr 1fr 2.5fr;`
        : `
        display:grid;
        div:last-child {
          justify-content: end;
        }`
      : ''}
`

export const ClickableText = styled(ThemedText.Small)<{ area?: string }>`
  display: flex;
  text-align: end;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
  user-select: none;
  color: ${({ theme }) => theme.text1} !important;
  @media screen and (max-width: 640px) {
    font-size: 0.85rem;
  }
`

export const ClickableTab = styled(Row)<{ selected?: boolean }>`
  background-color: ${({ selected, theme }) => (selected ? theme.bg0 : 'transparent')};
  font-weight: ${({ selected }) => (selected ? '600' : '400')}!important;
  display: flex;
  align-items: center;
  width: auto;
  padding: 0.75rem;
  cursor: pointer;
`

export const CloseButton = styled.button`
  background: ${({ theme }) => theme.bg0};
  color: ${({ theme }) => theme.text1};
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  border: 1px solid ${({ theme }) => theme.bg3};
  cursor: pointer;
  &:hover,
  &:focus {
    background: ${({ theme }) => theme.bg1};
  }
`

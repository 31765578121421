import { parseUnits } from '@ethersproject/units'
import Column from 'components/Column'
import Row from 'components/Row'
import { Collateral, Product } from 'constants/perps'
import { Order } from 'state/perpetuals/hooks'
import { useTheme } from 'styled-components/macro'
import { ThemedText } from 'theme'

import { CloseButton, OrderContentGrid } from './styled'

export default function OrderItem({
  breakpoint,
  width,
  order,
  onCancelOrder,
  onUserSettleOrder,
  onProductChange,
}: {
  breakpoint: string
  width: number
  order: Order
  onCancelOrder: OrderFunction
  onUserSettleOrder: (margin: number, product: Product, collateral: Collateral, isLong: boolean, price: number) => void
  onProductChange: (productId: string) => void
}) {
  const {
    product,
    value,
    margin,
    isLong,
    collateral,
    // timestamp,
    isClose,
  } = order

  const leverage = value / margin
  // const date = new Date(timestamp) // @TODO: once graphql integration is live
  const orderType = isClose ? 'Close Order' : 'Last traded price'

  const { green1, red2, white } = useTheme()

  return (
    <>
      <OrderContentGrid
        width={width}
        breakpoint={breakpoint}
        style={{
          height: 'fit-content',
          padding: '0.5rem 0.75rem',
        }}
      >
        <Column style={{ paddingRight: '0.5rem', alignItems: 'start', gap: '0.2rem' }}>
          <ThemedText.Small
            color="text"
            fontWeight="600"
            style={{ cursor: 'pointer' }}
            onClick={() => onProductChange(product.id)}
          >
            {product.label}
          </ThemedText.Small>
          <Row>
            <span
              style={{
                marginRight: '0.25rem',
                padding: '0.125rem 0.25rem',
                backgroundColor: isLong ? green1 : red2,
                borderRadius: '0.125rem',
              }}
            >
              <ThemedText.Small color={white} fontSize={9}>
                {isLong ? 'Long' : 'Short'}
              </ThemedText.Small>
            </span>
            <ThemedText.Small fontSize={10}>{leverage.toFixed(2)}x</ThemedText.Small>
          </Row>
        </Column>
        <Row alignItems="start" style={{ paddingRight: '0.5rem' }}>
          <ThemedText.Small>
            {value} {collateral.symbol || collateral.token.symbol || collateral.token.wrapped.symbol}
          </ThemedText.Small>
        </Row>
        <Row alignItems="start" style={{ paddingRight: '0.5rem' }}>
          <ThemedText.Small>
            {margin} {collateral.symbol || collateral.token.symbol || collateral.token.wrapped.symbol}
          </ThemedText.Small>
        </Row>
        <Row className="order-type-row" alignItems="start" style={{ paddingRight: '0.5rem' }}>
          <ThemedText.Small>{orderType}</ThemedText.Small>
        </Row>
        <Row className="order-button-row" style={{ gap: '0.5rem', justifyContent: 'end' }}>
          {false && (
            <CloseButton
              onClick={() =>
                onUserSettleOrder(
                  margin,
                  product,
                  collateral,
                  isLong,
                  +parseUnits(product.id === 'eth' ? '1200' : '16000', 8)
                )
              }
            >
              <ThemedText.Small>Force Execute</ThemedText.Small>
            </CloseButton>
          )}
          <CloseButton onClick={() => onCancelOrder(margin, 0, 0, product, collateral, isLong)}>
            <ThemedText.Small>Cancel</ThemedText.Small>
          </CloseButton>
        </Row>
      </OrderContentGrid>
    </>
  )
}

import { Collateral } from 'constants/perps'
import { createContext, ReactNode, useState } from 'react'

export enum CANDLE_STATUS {
  UNINITIALIZED = 'uninitialized',
  LOADING = 'loading',
  READY = 'ready',
  ERROR = 'error',
  STALE = 'stale',
}

export enum RESOLUTIONS {
  '1M' = 60,
  '5M' = 300,
  '15M' = 900,
  '1H' = 3600,
  '6H' = 21600,
  '1D' = 86400,
}

const lookbacks = {
  60: 300 * 60 * 1000,
  300: 24 * 60 * 60 * 1000,
  900: 48 * 60 * 60 * 1000,
  3600: 12 * 24 * 60 * 60 * 1000,
  21600: 6 * 12 * 24 * 60 * 60 * 1000,
  86400: 24 * 12 * 24 * 60 * 60 * 1000,
}

export const Candles = createContext({})

export default function CandleContext({
  productId,
  collateral,
  resolution,
  start,
  end,
  visibleLogicalRange,
  onStatusChange,
  children,
}: {
  productId: string
  collateral: Collateral
  resolution: RESOLUTIONS
  start: any
  end: any
  visibleLogicalRange: any
  onStatusChange: (status: CANDLE_STATUS) => void
  children: ReactNode
}) {
  const [ctx, setCtx] = useState({})

  return <Candles.Provider value={ctx}>{children}</Candles.Provider>
}

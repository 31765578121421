import {
  Analytics16,
  CalculatorCheck16,
  ChartCandlestick16,
  ChartMarimekko16,
  Close24,
  Collaborate16,
  DataVis_216,
  Information16,
  LogoDiscord16,
  LogoTwitter16,
  Store16,
  Table16,
  Template16,
  UserData16,
} from '@carbon/icons-react'
import Column from 'components/Column'
import Polling from 'components/Header/SimplePolling'
import Row from 'components/Row'
import { generatedTemplates, GridCard } from 'constants/cards'
import { ReactNode, useState } from 'react'
import { Layouts } from 'react-grid-layout'
import { useStoredLayoutManager } from 'state/user/hooks'
import { initialState as userInitialState } from 'state/user/reducer'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 5000;
  width: 100%;
`

const Footer = styled.footer`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0;
  background: ${({ theme }) => theme.bg2};
  border-top: 1px solid ${({ theme }) => theme.bg3};
  height: 2.5rem;
`

const LayoutButton = styled.button<{ open?: boolean }>`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  height: 100%;
  border: none;
  padding: 0 1.5rem;
  color: ${({ theme }) => theme.text1};
  background-color: ${({ theme }) => theme.bg2};
  cursor: ${({ open }) => (open ? 'default' : 'pointer')};
  &:hover,
  &:active {
    background-color: ${({ theme, open }) => (open ? theme.bg2 : theme.bg1)};
  }
  > * {
    flex-wrap: nowrap;
    flex: 1 0 auto;
  }
`

const Drawer = styled.div<{ open?: boolean }>`
  background-color: ${({ theme }) => theme.bg2};
  height: ${({ open }) => (open ? ' 15rem' : '0')};
  overflow-y: scroll;
  transition: 250ms ease-in-out;

  /* ===== Scrollbar CSS ===== */
  /* Firefox */
  scrollbar-width: 16px;
  scrollbar-color: ${({ theme }) => theme.bg4} transparent;
  * {
    scrollbar-width: 16px;
    scrollbar-color: ${({ theme }) => theme.bg4} transparent;
  }

  /* Chrome, Edge, and Safari */
  &::-webkit-scrollbar,
  *::-webkit-scrollbar {
    width: 16px;
  }
  &::-webkit-scrollbar-track,
  *::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb,
  *::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.bg4};
    border-radius: 100px;
    border: 4px solid ${({ theme }) => theme.bg2};
    min-height: 40px;
  }
`

const DrawerContent = styled.div`
  padding: 1rem 1.5rem;
  display: flex;
  gap: 1.5rem;
`

const TemplateColumn = styled(Column)`
  gap: 0.75rem;
  border-right: 1px solid ${({ theme }) => theme.bg3};
  padding-right: 1.5rem;
`

const StyledToolboxButton = styled.button`
  display: flex;
  align-items: center;
  padding: 1rem;
  gap: 0.75rem;
  border: 1px solid ${({ theme }) => theme.bg3};
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.bg1};
  color: ${({ theme }) => theme.text1};
  cursor: pointer;

  &:hover,
  &:active {
    background-color: ${({ theme }) => theme.bg0};
  }
`

function getIcon(identifier: string) {
  switch (identifier) {
    case 'simpleChart':
      return <Analytics16 />
    case 'standard':
      return <Template16 />
    case 'advanced':
      return <ChartMarimekko16 />
    case 'social':
      return <Collaborate16 />
    case 'discord':
      return <LogoDiscord16 />
    case 'twitterFeed':
      return <LogoTwitter16 />
    case 'tachart':
      return <ChartCandlestick16 />
    case 'marketTrades':
      return <Table16 />
    case 'orderbook':
      return <DataVis_216 />
    case 'manager':
      return <CalculatorCheck16 />
    case 'positions':
      return <UserData16 />
    case 'info':
      return <Information16 />
    case 'adv':
      return <Store16 />
    default:
      return <></>
  }
}

function CardToolboxButton({
  identifier,
  name,
  description,
  icon,
  onClick,
}: {
  identifier: string
  name: string
  description?: string
  icon?: ReactNode
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}) {
  const computedIcon = getIcon(identifier)
  return (
    <StyledToolboxButton onClick={onClick}>
      {icon || computedIcon}
      <ThemedText.Main>{name}</ThemedText.Main>
    </StyledToolboxButton>
  )
}
export default function FixedTradeFooter({
  drawer,
  onSetDrawer,
  availableCards,
  onAddCard,
  currentLayouts,
}: {
  drawer: boolean
  onSetDrawer: React.Dispatch<React.SetStateAction<boolean>>
  availableCards: { [k: string]: GridCard }
  onAddCard: (name: string) => void
  currentLayouts: Layouts
  currentBreakpoint: string
}) {
  const [storedLayouts, updateStoredLayouts] = useStoredLayoutManager()
  const [alert, setAlert] = useState('')

  const handleReset = () => {
    updateStoredLayouts(userInitialState.layouts)
  }

  const handleTemplate = (identifier: string) => {
    updateStoredLayouts(generatedTemplates[identifier])
  }

  const handleAlert = () => {
    setAlert(JSON.stringify(currentLayouts, null, 4))
  }
  return (
    <Wrapper>
      <Footer>
        <LayoutButton open={drawer} onClick={() => onSetDrawer(true)}>
          <Template16 style={{ marginRight: '0.5rem' }} />
          <ThemedText.Main>Edit Layout</ThemedText.Main>
        </LayoutButton>
        {drawer && (
          <>
            {false && (
              <>
                <LayoutButton onClick={handleReset}>
                  <ThemedText.Main>Reset</ThemedText.Main>
                </LayoutButton>
                <LayoutButton onClick={handleAlert}>
                  <ThemedText.Main>Read Config</ThemedText.Main>
                </LayoutButton>
              </>
            )}
            {alert && (
              <LayoutButton onClick={() => setAlert('')}>
                <ThemedText.Main>Close Config</ThemedText.Main>
              </LayoutButton>
            )}
          </>
        )}
        {drawer ? (
          <Close24
            style={{ margin: '0 1.5rem 0 auto', height: '100%', cursor: 'pointer' }}
            onClick={() => onSetDrawer(false)}
          />
        ) : (
          <Polling />
        )}
      </Footer>
      <Drawer open={drawer}>
        <DrawerContent>
          <TemplateColumn>
            <ThemedText.Small>Templates</ThemedText.Small>
            <CardToolboxButton identifier={'standard'} name={'Standard'} onClick={() => handleTemplate('standard')} />
          </TemplateColumn>
          <div>
            <ThemedText.Small style={{ marginBottom: '0.75rem' }}>Cards</ThemedText.Small>

            {alert && (
              <div>
                <pre>{alert}</pre>
              </div>
            )}
            <Row style={{ gap: '1rem' }}>
              {Object.values(availableCards).map((item) => {
                if (item.hidden) return null
                return (
                  <CardToolboxButton
                    key={`card-${item.identifier}`}
                    identifier={item.identifier}
                    name={item.name}
                    onClick={() => onAddCard(item.identifier)}
                  />
                )
              })}
            </Row>
          </div>
        </DrawerContent>
      </Drawer>
    </Wrapper>
  )
}

import { api } from './slice';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigDecimal: any;
  BigInt: any;
  Bytes: any;
};






export type BlockChangedFilter = {
  number_gte: Scalars['Int'];
};

export type Block_Height = {
  hash?: Maybe<Scalars['Bytes']>;
  number?: Maybe<Scalars['Int']>;
  number_gte?: Maybe<Scalars['Int']>;
};


export type Distribution = {
  __typename?: 'Distribution';
  id: Scalars['ID'];
  receiver: Scalars['String'];
  amount: Scalars['BigInt'];
  token: Scalars['String'];
  typeId: Scalars['BigInt'];
  transactionHash: Scalars['String'];
  blockNumber: Scalars['BigInt'];
  timestamp: Scalars['BigInt'];
};

export type Distribution_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  receiver?: Maybe<Scalars['String']>;
  receiver_not?: Maybe<Scalars['String']>;
  receiver_gt?: Maybe<Scalars['String']>;
  receiver_lt?: Maybe<Scalars['String']>;
  receiver_gte?: Maybe<Scalars['String']>;
  receiver_lte?: Maybe<Scalars['String']>;
  receiver_in?: Maybe<Array<Scalars['String']>>;
  receiver_not_in?: Maybe<Array<Scalars['String']>>;
  receiver_contains?: Maybe<Scalars['String']>;
  receiver_contains_nocase?: Maybe<Scalars['String']>;
  receiver_not_contains?: Maybe<Scalars['String']>;
  receiver_not_contains_nocase?: Maybe<Scalars['String']>;
  receiver_starts_with?: Maybe<Scalars['String']>;
  receiver_starts_with_nocase?: Maybe<Scalars['String']>;
  receiver_not_starts_with?: Maybe<Scalars['String']>;
  receiver_not_starts_with_nocase?: Maybe<Scalars['String']>;
  receiver_ends_with?: Maybe<Scalars['String']>;
  receiver_ends_with_nocase?: Maybe<Scalars['String']>;
  receiver_not_ends_with?: Maybe<Scalars['String']>;
  receiver_not_ends_with_nocase?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['BigInt']>;
  amount_not?: Maybe<Scalars['BigInt']>;
  amount_gt?: Maybe<Scalars['BigInt']>;
  amount_lt?: Maybe<Scalars['BigInt']>;
  amount_gte?: Maybe<Scalars['BigInt']>;
  amount_lte?: Maybe<Scalars['BigInt']>;
  amount_in?: Maybe<Array<Scalars['BigInt']>>;
  amount_not_in?: Maybe<Array<Scalars['BigInt']>>;
  token?: Maybe<Scalars['String']>;
  token_not?: Maybe<Scalars['String']>;
  token_gt?: Maybe<Scalars['String']>;
  token_lt?: Maybe<Scalars['String']>;
  token_gte?: Maybe<Scalars['String']>;
  token_lte?: Maybe<Scalars['String']>;
  token_in?: Maybe<Array<Scalars['String']>>;
  token_not_in?: Maybe<Array<Scalars['String']>>;
  token_contains?: Maybe<Scalars['String']>;
  token_contains_nocase?: Maybe<Scalars['String']>;
  token_not_contains?: Maybe<Scalars['String']>;
  token_not_contains_nocase?: Maybe<Scalars['String']>;
  token_starts_with?: Maybe<Scalars['String']>;
  token_starts_with_nocase?: Maybe<Scalars['String']>;
  token_not_starts_with?: Maybe<Scalars['String']>;
  token_not_starts_with_nocase?: Maybe<Scalars['String']>;
  token_ends_with?: Maybe<Scalars['String']>;
  token_ends_with_nocase?: Maybe<Scalars['String']>;
  token_not_ends_with?: Maybe<Scalars['String']>;
  token_not_ends_with_nocase?: Maybe<Scalars['String']>;
  typeId?: Maybe<Scalars['BigInt']>;
  typeId_not?: Maybe<Scalars['BigInt']>;
  typeId_gt?: Maybe<Scalars['BigInt']>;
  typeId_lt?: Maybe<Scalars['BigInt']>;
  typeId_gte?: Maybe<Scalars['BigInt']>;
  typeId_lte?: Maybe<Scalars['BigInt']>;
  typeId_in?: Maybe<Array<Scalars['BigInt']>>;
  typeId_not_in?: Maybe<Array<Scalars['BigInt']>>;
  transactionHash?: Maybe<Scalars['String']>;
  transactionHash_not?: Maybe<Scalars['String']>;
  transactionHash_gt?: Maybe<Scalars['String']>;
  transactionHash_lt?: Maybe<Scalars['String']>;
  transactionHash_gte?: Maybe<Scalars['String']>;
  transactionHash_lte?: Maybe<Scalars['String']>;
  transactionHash_in?: Maybe<Array<Scalars['String']>>;
  transactionHash_not_in?: Maybe<Array<Scalars['String']>>;
  transactionHash_contains?: Maybe<Scalars['String']>;
  transactionHash_contains_nocase?: Maybe<Scalars['String']>;
  transactionHash_not_contains?: Maybe<Scalars['String']>;
  transactionHash_not_contains_nocase?: Maybe<Scalars['String']>;
  transactionHash_starts_with?: Maybe<Scalars['String']>;
  transactionHash_starts_with_nocase?: Maybe<Scalars['String']>;
  transactionHash_not_starts_with?: Maybe<Scalars['String']>;
  transactionHash_not_starts_with_nocase?: Maybe<Scalars['String']>;
  transactionHash_ends_with?: Maybe<Scalars['String']>;
  transactionHash_ends_with_nocase?: Maybe<Scalars['String']>;
  transactionHash_not_ends_with?: Maybe<Scalars['String']>;
  transactionHash_not_ends_with_nocase?: Maybe<Scalars['String']>;
  blockNumber?: Maybe<Scalars['BigInt']>;
  blockNumber_not?: Maybe<Scalars['BigInt']>;
  blockNumber_gt?: Maybe<Scalars['BigInt']>;
  blockNumber_lt?: Maybe<Scalars['BigInt']>;
  blockNumber_gte?: Maybe<Scalars['BigInt']>;
  blockNumber_lte?: Maybe<Scalars['BigInt']>;
  blockNumber_in?: Maybe<Array<Scalars['BigInt']>>;
  blockNumber_not_in?: Maybe<Array<Scalars['BigInt']>>;
  timestamp?: Maybe<Scalars['BigInt']>;
  timestamp_not?: Maybe<Scalars['BigInt']>;
  timestamp_gt?: Maybe<Scalars['BigInt']>;
  timestamp_lt?: Maybe<Scalars['BigInt']>;
  timestamp_gte?: Maybe<Scalars['BigInt']>;
  timestamp_lte?: Maybe<Scalars['BigInt']>;
  timestamp_in?: Maybe<Array<Scalars['BigInt']>>;
  timestamp_not_in?: Maybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<Distribution_Filter>>>;
  or?: Maybe<Array<Maybe<Distribution_Filter>>>;
};

export enum Distribution_OrderBy {
  Id = 'id',
  Receiver = 'receiver',
  Amount = 'amount',
  Token = 'token',
  TypeId = 'typeId',
  TransactionHash = 'transactionHash',
  BlockNumber = 'blockNumber',
  Timestamp = 'timestamp'
}

export type ExecuteDecreaseOrder = {
  __typename?: 'ExecuteDecreaseOrder';
  id: Scalars['ID'];
  sizeDelta: Scalars['BigInt'];
  account: Scalars['String'];
  timestamp: Scalars['BigInt'];
};

export type ExecuteDecreaseOrder_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  sizeDelta?: Maybe<Scalars['BigInt']>;
  sizeDelta_not?: Maybe<Scalars['BigInt']>;
  sizeDelta_gt?: Maybe<Scalars['BigInt']>;
  sizeDelta_lt?: Maybe<Scalars['BigInt']>;
  sizeDelta_gte?: Maybe<Scalars['BigInt']>;
  sizeDelta_lte?: Maybe<Scalars['BigInt']>;
  sizeDelta_in?: Maybe<Array<Scalars['BigInt']>>;
  sizeDelta_not_in?: Maybe<Array<Scalars['BigInt']>>;
  account?: Maybe<Scalars['String']>;
  account_not?: Maybe<Scalars['String']>;
  account_gt?: Maybe<Scalars['String']>;
  account_lt?: Maybe<Scalars['String']>;
  account_gte?: Maybe<Scalars['String']>;
  account_lte?: Maybe<Scalars['String']>;
  account_in?: Maybe<Array<Scalars['String']>>;
  account_not_in?: Maybe<Array<Scalars['String']>>;
  account_contains?: Maybe<Scalars['String']>;
  account_contains_nocase?: Maybe<Scalars['String']>;
  account_not_contains?: Maybe<Scalars['String']>;
  account_not_contains_nocase?: Maybe<Scalars['String']>;
  account_starts_with?: Maybe<Scalars['String']>;
  account_starts_with_nocase?: Maybe<Scalars['String']>;
  account_not_starts_with?: Maybe<Scalars['String']>;
  account_not_starts_with_nocase?: Maybe<Scalars['String']>;
  account_ends_with?: Maybe<Scalars['String']>;
  account_ends_with_nocase?: Maybe<Scalars['String']>;
  account_not_ends_with?: Maybe<Scalars['String']>;
  account_not_ends_with_nocase?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['BigInt']>;
  timestamp_not?: Maybe<Scalars['BigInt']>;
  timestamp_gt?: Maybe<Scalars['BigInt']>;
  timestamp_lt?: Maybe<Scalars['BigInt']>;
  timestamp_gte?: Maybe<Scalars['BigInt']>;
  timestamp_lte?: Maybe<Scalars['BigInt']>;
  timestamp_in?: Maybe<Array<Scalars['BigInt']>>;
  timestamp_not_in?: Maybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<ExecuteDecreaseOrder_Filter>>>;
  or?: Maybe<Array<Maybe<ExecuteDecreaseOrder_Filter>>>;
};

export enum ExecuteDecreaseOrder_OrderBy {
  Id = 'id',
  SizeDelta = 'sizeDelta',
  Account = 'account',
  Timestamp = 'timestamp'
}

export type GlobalStat = {
  __typename?: 'GlobalStat';
  id: Scalars['ID'];
  volume: Scalars['BigInt'];
  volumeCumulative: Scalars['BigInt'];
  totalRebateUsd: Scalars['BigInt'];
  totalRebateUsdCumulative: Scalars['BigInt'];
  discountUsd: Scalars['BigInt'];
  discountUsdCumulative: Scalars['BigInt'];
  trades: Scalars['BigInt'];
  tradesCumulative: Scalars['BigInt'];
  referrersCount: Scalars['BigInt'];
  referrersCountCumulative: Scalars['BigInt'];
  referralCodesCount: Scalars['BigInt'];
  referralCodesCountCumulative: Scalars['BigInt'];
  referralsCount: Scalars['BigInt'];
  referralsCountCumulative: Scalars['BigInt'];
  timestamp: Scalars['BigInt'];
  period: Period;
};

export type GlobalStat_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  volume?: Maybe<Scalars['BigInt']>;
  volume_not?: Maybe<Scalars['BigInt']>;
  volume_gt?: Maybe<Scalars['BigInt']>;
  volume_lt?: Maybe<Scalars['BigInt']>;
  volume_gte?: Maybe<Scalars['BigInt']>;
  volume_lte?: Maybe<Scalars['BigInt']>;
  volume_in?: Maybe<Array<Scalars['BigInt']>>;
  volume_not_in?: Maybe<Array<Scalars['BigInt']>>;
  volumeCumulative?: Maybe<Scalars['BigInt']>;
  volumeCumulative_not?: Maybe<Scalars['BigInt']>;
  volumeCumulative_gt?: Maybe<Scalars['BigInt']>;
  volumeCumulative_lt?: Maybe<Scalars['BigInt']>;
  volumeCumulative_gte?: Maybe<Scalars['BigInt']>;
  volumeCumulative_lte?: Maybe<Scalars['BigInt']>;
  volumeCumulative_in?: Maybe<Array<Scalars['BigInt']>>;
  volumeCumulative_not_in?: Maybe<Array<Scalars['BigInt']>>;
  totalRebateUsd?: Maybe<Scalars['BigInt']>;
  totalRebateUsd_not?: Maybe<Scalars['BigInt']>;
  totalRebateUsd_gt?: Maybe<Scalars['BigInt']>;
  totalRebateUsd_lt?: Maybe<Scalars['BigInt']>;
  totalRebateUsd_gte?: Maybe<Scalars['BigInt']>;
  totalRebateUsd_lte?: Maybe<Scalars['BigInt']>;
  totalRebateUsd_in?: Maybe<Array<Scalars['BigInt']>>;
  totalRebateUsd_not_in?: Maybe<Array<Scalars['BigInt']>>;
  totalRebateUsdCumulative?: Maybe<Scalars['BigInt']>;
  totalRebateUsdCumulative_not?: Maybe<Scalars['BigInt']>;
  totalRebateUsdCumulative_gt?: Maybe<Scalars['BigInt']>;
  totalRebateUsdCumulative_lt?: Maybe<Scalars['BigInt']>;
  totalRebateUsdCumulative_gte?: Maybe<Scalars['BigInt']>;
  totalRebateUsdCumulative_lte?: Maybe<Scalars['BigInt']>;
  totalRebateUsdCumulative_in?: Maybe<Array<Scalars['BigInt']>>;
  totalRebateUsdCumulative_not_in?: Maybe<Array<Scalars['BigInt']>>;
  discountUsd?: Maybe<Scalars['BigInt']>;
  discountUsd_not?: Maybe<Scalars['BigInt']>;
  discountUsd_gt?: Maybe<Scalars['BigInt']>;
  discountUsd_lt?: Maybe<Scalars['BigInt']>;
  discountUsd_gte?: Maybe<Scalars['BigInt']>;
  discountUsd_lte?: Maybe<Scalars['BigInt']>;
  discountUsd_in?: Maybe<Array<Scalars['BigInt']>>;
  discountUsd_not_in?: Maybe<Array<Scalars['BigInt']>>;
  discountUsdCumulative?: Maybe<Scalars['BigInt']>;
  discountUsdCumulative_not?: Maybe<Scalars['BigInt']>;
  discountUsdCumulative_gt?: Maybe<Scalars['BigInt']>;
  discountUsdCumulative_lt?: Maybe<Scalars['BigInt']>;
  discountUsdCumulative_gte?: Maybe<Scalars['BigInt']>;
  discountUsdCumulative_lte?: Maybe<Scalars['BigInt']>;
  discountUsdCumulative_in?: Maybe<Array<Scalars['BigInt']>>;
  discountUsdCumulative_not_in?: Maybe<Array<Scalars['BigInt']>>;
  trades?: Maybe<Scalars['BigInt']>;
  trades_not?: Maybe<Scalars['BigInt']>;
  trades_gt?: Maybe<Scalars['BigInt']>;
  trades_lt?: Maybe<Scalars['BigInt']>;
  trades_gte?: Maybe<Scalars['BigInt']>;
  trades_lte?: Maybe<Scalars['BigInt']>;
  trades_in?: Maybe<Array<Scalars['BigInt']>>;
  trades_not_in?: Maybe<Array<Scalars['BigInt']>>;
  tradesCumulative?: Maybe<Scalars['BigInt']>;
  tradesCumulative_not?: Maybe<Scalars['BigInt']>;
  tradesCumulative_gt?: Maybe<Scalars['BigInt']>;
  tradesCumulative_lt?: Maybe<Scalars['BigInt']>;
  tradesCumulative_gte?: Maybe<Scalars['BigInt']>;
  tradesCumulative_lte?: Maybe<Scalars['BigInt']>;
  tradesCumulative_in?: Maybe<Array<Scalars['BigInt']>>;
  tradesCumulative_not_in?: Maybe<Array<Scalars['BigInt']>>;
  referrersCount?: Maybe<Scalars['BigInt']>;
  referrersCount_not?: Maybe<Scalars['BigInt']>;
  referrersCount_gt?: Maybe<Scalars['BigInt']>;
  referrersCount_lt?: Maybe<Scalars['BigInt']>;
  referrersCount_gte?: Maybe<Scalars['BigInt']>;
  referrersCount_lte?: Maybe<Scalars['BigInt']>;
  referrersCount_in?: Maybe<Array<Scalars['BigInt']>>;
  referrersCount_not_in?: Maybe<Array<Scalars['BigInt']>>;
  referrersCountCumulative?: Maybe<Scalars['BigInt']>;
  referrersCountCumulative_not?: Maybe<Scalars['BigInt']>;
  referrersCountCumulative_gt?: Maybe<Scalars['BigInt']>;
  referrersCountCumulative_lt?: Maybe<Scalars['BigInt']>;
  referrersCountCumulative_gte?: Maybe<Scalars['BigInt']>;
  referrersCountCumulative_lte?: Maybe<Scalars['BigInt']>;
  referrersCountCumulative_in?: Maybe<Array<Scalars['BigInt']>>;
  referrersCountCumulative_not_in?: Maybe<Array<Scalars['BigInt']>>;
  referralCodesCount?: Maybe<Scalars['BigInt']>;
  referralCodesCount_not?: Maybe<Scalars['BigInt']>;
  referralCodesCount_gt?: Maybe<Scalars['BigInt']>;
  referralCodesCount_lt?: Maybe<Scalars['BigInt']>;
  referralCodesCount_gte?: Maybe<Scalars['BigInt']>;
  referralCodesCount_lte?: Maybe<Scalars['BigInt']>;
  referralCodesCount_in?: Maybe<Array<Scalars['BigInt']>>;
  referralCodesCount_not_in?: Maybe<Array<Scalars['BigInt']>>;
  referralCodesCountCumulative?: Maybe<Scalars['BigInt']>;
  referralCodesCountCumulative_not?: Maybe<Scalars['BigInt']>;
  referralCodesCountCumulative_gt?: Maybe<Scalars['BigInt']>;
  referralCodesCountCumulative_lt?: Maybe<Scalars['BigInt']>;
  referralCodesCountCumulative_gte?: Maybe<Scalars['BigInt']>;
  referralCodesCountCumulative_lte?: Maybe<Scalars['BigInt']>;
  referralCodesCountCumulative_in?: Maybe<Array<Scalars['BigInt']>>;
  referralCodesCountCumulative_not_in?: Maybe<Array<Scalars['BigInt']>>;
  referralsCount?: Maybe<Scalars['BigInt']>;
  referralsCount_not?: Maybe<Scalars['BigInt']>;
  referralsCount_gt?: Maybe<Scalars['BigInt']>;
  referralsCount_lt?: Maybe<Scalars['BigInt']>;
  referralsCount_gte?: Maybe<Scalars['BigInt']>;
  referralsCount_lte?: Maybe<Scalars['BigInt']>;
  referralsCount_in?: Maybe<Array<Scalars['BigInt']>>;
  referralsCount_not_in?: Maybe<Array<Scalars['BigInt']>>;
  referralsCountCumulative?: Maybe<Scalars['BigInt']>;
  referralsCountCumulative_not?: Maybe<Scalars['BigInt']>;
  referralsCountCumulative_gt?: Maybe<Scalars['BigInt']>;
  referralsCountCumulative_lt?: Maybe<Scalars['BigInt']>;
  referralsCountCumulative_gte?: Maybe<Scalars['BigInt']>;
  referralsCountCumulative_lte?: Maybe<Scalars['BigInt']>;
  referralsCountCumulative_in?: Maybe<Array<Scalars['BigInt']>>;
  referralsCountCumulative_not_in?: Maybe<Array<Scalars['BigInt']>>;
  timestamp?: Maybe<Scalars['BigInt']>;
  timestamp_not?: Maybe<Scalars['BigInt']>;
  timestamp_gt?: Maybe<Scalars['BigInt']>;
  timestamp_lt?: Maybe<Scalars['BigInt']>;
  timestamp_gte?: Maybe<Scalars['BigInt']>;
  timestamp_lte?: Maybe<Scalars['BigInt']>;
  timestamp_in?: Maybe<Array<Scalars['BigInt']>>;
  timestamp_not_in?: Maybe<Array<Scalars['BigInt']>>;
  period?: Maybe<Period>;
  period_not?: Maybe<Period>;
  period_in?: Maybe<Array<Period>>;
  period_not_in?: Maybe<Array<Period>>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<GlobalStat_Filter>>>;
  or?: Maybe<Array<Maybe<GlobalStat_Filter>>>;
};

export enum GlobalStat_OrderBy {
  Id = 'id',
  Volume = 'volume',
  VolumeCumulative = 'volumeCumulative',
  TotalRebateUsd = 'totalRebateUsd',
  TotalRebateUsdCumulative = 'totalRebateUsdCumulative',
  DiscountUsd = 'discountUsd',
  DiscountUsdCumulative = 'discountUsdCumulative',
  Trades = 'trades',
  TradesCumulative = 'tradesCumulative',
  ReferrersCount = 'referrersCount',
  ReferrersCountCumulative = 'referrersCountCumulative',
  ReferralCodesCount = 'referralCodesCount',
  ReferralCodesCountCumulative = 'referralCodesCountCumulative',
  ReferralsCount = 'referralsCount',
  ReferralsCountCumulative = 'referralsCountCumulative',
  Timestamp = 'timestamp',
  Period = 'period'
}

/** Defines the order direction, either ascending or descending */
export enum OrderDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export enum Period {
  Hourly = 'hourly',
  Daily = 'daily',
  Weekly = 'weekly',
  Total = 'total'
}

export type PositionReferralAction = {
  __typename?: 'PositionReferralAction';
  id: Scalars['ID'];
  account: Scalars['String'];
  referralCode: Scalars['String'];
  referrer: Scalars['String'];
  isIncrease: Scalars['Boolean'];
  volume: Scalars['BigInt'];
  transactionHash: Scalars['String'];
  blockNumber: Scalars['Int'];
  logIndex: Scalars['Int'];
  timestamp: Scalars['BigInt'];
};

export type PositionReferralAction_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  account?: Maybe<Scalars['String']>;
  account_not?: Maybe<Scalars['String']>;
  account_gt?: Maybe<Scalars['String']>;
  account_lt?: Maybe<Scalars['String']>;
  account_gte?: Maybe<Scalars['String']>;
  account_lte?: Maybe<Scalars['String']>;
  account_in?: Maybe<Array<Scalars['String']>>;
  account_not_in?: Maybe<Array<Scalars['String']>>;
  account_contains?: Maybe<Scalars['String']>;
  account_contains_nocase?: Maybe<Scalars['String']>;
  account_not_contains?: Maybe<Scalars['String']>;
  account_not_contains_nocase?: Maybe<Scalars['String']>;
  account_starts_with?: Maybe<Scalars['String']>;
  account_starts_with_nocase?: Maybe<Scalars['String']>;
  account_not_starts_with?: Maybe<Scalars['String']>;
  account_not_starts_with_nocase?: Maybe<Scalars['String']>;
  account_ends_with?: Maybe<Scalars['String']>;
  account_ends_with_nocase?: Maybe<Scalars['String']>;
  account_not_ends_with?: Maybe<Scalars['String']>;
  account_not_ends_with_nocase?: Maybe<Scalars['String']>;
  referralCode?: Maybe<Scalars['String']>;
  referralCode_not?: Maybe<Scalars['String']>;
  referralCode_gt?: Maybe<Scalars['String']>;
  referralCode_lt?: Maybe<Scalars['String']>;
  referralCode_gte?: Maybe<Scalars['String']>;
  referralCode_lte?: Maybe<Scalars['String']>;
  referralCode_in?: Maybe<Array<Scalars['String']>>;
  referralCode_not_in?: Maybe<Array<Scalars['String']>>;
  referralCode_contains?: Maybe<Scalars['String']>;
  referralCode_contains_nocase?: Maybe<Scalars['String']>;
  referralCode_not_contains?: Maybe<Scalars['String']>;
  referralCode_not_contains_nocase?: Maybe<Scalars['String']>;
  referralCode_starts_with?: Maybe<Scalars['String']>;
  referralCode_starts_with_nocase?: Maybe<Scalars['String']>;
  referralCode_not_starts_with?: Maybe<Scalars['String']>;
  referralCode_not_starts_with_nocase?: Maybe<Scalars['String']>;
  referralCode_ends_with?: Maybe<Scalars['String']>;
  referralCode_ends_with_nocase?: Maybe<Scalars['String']>;
  referralCode_not_ends_with?: Maybe<Scalars['String']>;
  referralCode_not_ends_with_nocase?: Maybe<Scalars['String']>;
  referrer?: Maybe<Scalars['String']>;
  referrer_not?: Maybe<Scalars['String']>;
  referrer_gt?: Maybe<Scalars['String']>;
  referrer_lt?: Maybe<Scalars['String']>;
  referrer_gte?: Maybe<Scalars['String']>;
  referrer_lte?: Maybe<Scalars['String']>;
  referrer_in?: Maybe<Array<Scalars['String']>>;
  referrer_not_in?: Maybe<Array<Scalars['String']>>;
  referrer_contains?: Maybe<Scalars['String']>;
  referrer_contains_nocase?: Maybe<Scalars['String']>;
  referrer_not_contains?: Maybe<Scalars['String']>;
  referrer_not_contains_nocase?: Maybe<Scalars['String']>;
  referrer_starts_with?: Maybe<Scalars['String']>;
  referrer_starts_with_nocase?: Maybe<Scalars['String']>;
  referrer_not_starts_with?: Maybe<Scalars['String']>;
  referrer_not_starts_with_nocase?: Maybe<Scalars['String']>;
  referrer_ends_with?: Maybe<Scalars['String']>;
  referrer_ends_with_nocase?: Maybe<Scalars['String']>;
  referrer_not_ends_with?: Maybe<Scalars['String']>;
  referrer_not_ends_with_nocase?: Maybe<Scalars['String']>;
  isIncrease?: Maybe<Scalars['Boolean']>;
  isIncrease_not?: Maybe<Scalars['Boolean']>;
  isIncrease_in?: Maybe<Array<Scalars['Boolean']>>;
  isIncrease_not_in?: Maybe<Array<Scalars['Boolean']>>;
  volume?: Maybe<Scalars['BigInt']>;
  volume_not?: Maybe<Scalars['BigInt']>;
  volume_gt?: Maybe<Scalars['BigInt']>;
  volume_lt?: Maybe<Scalars['BigInt']>;
  volume_gte?: Maybe<Scalars['BigInt']>;
  volume_lte?: Maybe<Scalars['BigInt']>;
  volume_in?: Maybe<Array<Scalars['BigInt']>>;
  volume_not_in?: Maybe<Array<Scalars['BigInt']>>;
  transactionHash?: Maybe<Scalars['String']>;
  transactionHash_not?: Maybe<Scalars['String']>;
  transactionHash_gt?: Maybe<Scalars['String']>;
  transactionHash_lt?: Maybe<Scalars['String']>;
  transactionHash_gte?: Maybe<Scalars['String']>;
  transactionHash_lte?: Maybe<Scalars['String']>;
  transactionHash_in?: Maybe<Array<Scalars['String']>>;
  transactionHash_not_in?: Maybe<Array<Scalars['String']>>;
  transactionHash_contains?: Maybe<Scalars['String']>;
  transactionHash_contains_nocase?: Maybe<Scalars['String']>;
  transactionHash_not_contains?: Maybe<Scalars['String']>;
  transactionHash_not_contains_nocase?: Maybe<Scalars['String']>;
  transactionHash_starts_with?: Maybe<Scalars['String']>;
  transactionHash_starts_with_nocase?: Maybe<Scalars['String']>;
  transactionHash_not_starts_with?: Maybe<Scalars['String']>;
  transactionHash_not_starts_with_nocase?: Maybe<Scalars['String']>;
  transactionHash_ends_with?: Maybe<Scalars['String']>;
  transactionHash_ends_with_nocase?: Maybe<Scalars['String']>;
  transactionHash_not_ends_with?: Maybe<Scalars['String']>;
  transactionHash_not_ends_with_nocase?: Maybe<Scalars['String']>;
  blockNumber?: Maybe<Scalars['Int']>;
  blockNumber_not?: Maybe<Scalars['Int']>;
  blockNumber_gt?: Maybe<Scalars['Int']>;
  blockNumber_lt?: Maybe<Scalars['Int']>;
  blockNumber_gte?: Maybe<Scalars['Int']>;
  blockNumber_lte?: Maybe<Scalars['Int']>;
  blockNumber_in?: Maybe<Array<Scalars['Int']>>;
  blockNumber_not_in?: Maybe<Array<Scalars['Int']>>;
  logIndex?: Maybe<Scalars['Int']>;
  logIndex_not?: Maybe<Scalars['Int']>;
  logIndex_gt?: Maybe<Scalars['Int']>;
  logIndex_lt?: Maybe<Scalars['Int']>;
  logIndex_gte?: Maybe<Scalars['Int']>;
  logIndex_lte?: Maybe<Scalars['Int']>;
  logIndex_in?: Maybe<Array<Scalars['Int']>>;
  logIndex_not_in?: Maybe<Array<Scalars['Int']>>;
  timestamp?: Maybe<Scalars['BigInt']>;
  timestamp_not?: Maybe<Scalars['BigInt']>;
  timestamp_gt?: Maybe<Scalars['BigInt']>;
  timestamp_lt?: Maybe<Scalars['BigInt']>;
  timestamp_gte?: Maybe<Scalars['BigInt']>;
  timestamp_lte?: Maybe<Scalars['BigInt']>;
  timestamp_in?: Maybe<Array<Scalars['BigInt']>>;
  timestamp_not_in?: Maybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<PositionReferralAction_Filter>>>;
  or?: Maybe<Array<Maybe<PositionReferralAction_Filter>>>;
};

export enum PositionReferralAction_OrderBy {
  Id = 'id',
  Account = 'account',
  ReferralCode = 'referralCode',
  Referrer = 'referrer',
  IsIncrease = 'isIncrease',
  Volume = 'volume',
  TransactionHash = 'transactionHash',
  BlockNumber = 'blockNumber',
  LogIndex = 'logIndex',
  Timestamp = 'timestamp'
}

export type Query = {
  __typename?: 'Query';
  tier?: Maybe<Tier>;
  tiers: Array<Tier>;
  referrer?: Maybe<Referrer>;
  referrers: Array<Referrer>;
  distribution?: Maybe<Distribution>;
  distributions: Array<Distribution>;
  referralVolumeRecord?: Maybe<ReferralVolumeRecord>;
  referralVolumeRecords: Array<ReferralVolumeRecord>;
  referrerStat?: Maybe<ReferrerStat>;
  referrerStats: Array<ReferrerStat>;
  referralCode?: Maybe<ReferralCode>;
  referralCodes: Array<ReferralCode>;
  tradedReferral?: Maybe<TradedReferral>;
  tradedReferrals: Array<TradedReferral>;
  registeredReferral?: Maybe<RegisteredReferral>;
  registeredReferrals: Array<RegisteredReferral>;
  referralStat?: Maybe<ReferralStat>;
  referralStats: Array<ReferralStat>;
  globalStat?: Maybe<GlobalStat>;
  globalStats: Array<GlobalStat>;
  executeDecreaseOrder?: Maybe<ExecuteDecreaseOrder>;
  executeDecreaseOrders: Array<ExecuteDecreaseOrder>;
  positionReferralAction?: Maybe<PositionReferralAction>;
  positionReferralActions: Array<PositionReferralAction>;
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>;
};


export type QueryTierArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryTiersArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Tier_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Tier_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryReferrerArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryReferrersArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Referrer_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Referrer_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryDistributionArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryDistributionsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Distribution_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Distribution_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryReferralVolumeRecordArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryReferralVolumeRecordsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReferralVolumeRecord_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<ReferralVolumeRecord_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryReferrerStatArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryReferrerStatsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReferrerStat_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<ReferrerStat_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryReferralCodeArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryReferralCodesArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReferralCode_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<ReferralCode_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryTradedReferralArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryTradedReferralsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<TradedReferral_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<TradedReferral_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryRegisteredReferralArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryRegisteredReferralsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<RegisteredReferral_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<RegisteredReferral_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryReferralStatArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryReferralStatsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReferralStat_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<ReferralStat_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryGlobalStatArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryGlobalStatsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<GlobalStat_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<GlobalStat_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryExecuteDecreaseOrderArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryExecuteDecreaseOrdersArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ExecuteDecreaseOrder_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<ExecuteDecreaseOrder_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryPositionReferralActionArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryPositionReferralActionsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<PositionReferralAction_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<PositionReferralAction_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type Query_MetaArgs = {
  block?: Maybe<Block_Height>;
};

export type ReferralCode = {
  __typename?: 'ReferralCode';
  id: Scalars['ID'];
  code: Scalars['String'];
  owner: Scalars['String'];
  timestamp: Scalars['BigInt'];
};

export type ReferralCode_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  code?: Maybe<Scalars['String']>;
  code_not?: Maybe<Scalars['String']>;
  code_gt?: Maybe<Scalars['String']>;
  code_lt?: Maybe<Scalars['String']>;
  code_gte?: Maybe<Scalars['String']>;
  code_lte?: Maybe<Scalars['String']>;
  code_in?: Maybe<Array<Scalars['String']>>;
  code_not_in?: Maybe<Array<Scalars['String']>>;
  code_contains?: Maybe<Scalars['String']>;
  code_contains_nocase?: Maybe<Scalars['String']>;
  code_not_contains?: Maybe<Scalars['String']>;
  code_not_contains_nocase?: Maybe<Scalars['String']>;
  code_starts_with?: Maybe<Scalars['String']>;
  code_starts_with_nocase?: Maybe<Scalars['String']>;
  code_not_starts_with?: Maybe<Scalars['String']>;
  code_not_starts_with_nocase?: Maybe<Scalars['String']>;
  code_ends_with?: Maybe<Scalars['String']>;
  code_ends_with_nocase?: Maybe<Scalars['String']>;
  code_not_ends_with?: Maybe<Scalars['String']>;
  code_not_ends_with_nocase?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  owner_not?: Maybe<Scalars['String']>;
  owner_gt?: Maybe<Scalars['String']>;
  owner_lt?: Maybe<Scalars['String']>;
  owner_gte?: Maybe<Scalars['String']>;
  owner_lte?: Maybe<Scalars['String']>;
  owner_in?: Maybe<Array<Scalars['String']>>;
  owner_not_in?: Maybe<Array<Scalars['String']>>;
  owner_contains?: Maybe<Scalars['String']>;
  owner_contains_nocase?: Maybe<Scalars['String']>;
  owner_not_contains?: Maybe<Scalars['String']>;
  owner_not_contains_nocase?: Maybe<Scalars['String']>;
  owner_starts_with?: Maybe<Scalars['String']>;
  owner_starts_with_nocase?: Maybe<Scalars['String']>;
  owner_not_starts_with?: Maybe<Scalars['String']>;
  owner_not_starts_with_nocase?: Maybe<Scalars['String']>;
  owner_ends_with?: Maybe<Scalars['String']>;
  owner_ends_with_nocase?: Maybe<Scalars['String']>;
  owner_not_ends_with?: Maybe<Scalars['String']>;
  owner_not_ends_with_nocase?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['BigInt']>;
  timestamp_not?: Maybe<Scalars['BigInt']>;
  timestamp_gt?: Maybe<Scalars['BigInt']>;
  timestamp_lt?: Maybe<Scalars['BigInt']>;
  timestamp_gte?: Maybe<Scalars['BigInt']>;
  timestamp_lte?: Maybe<Scalars['BigInt']>;
  timestamp_in?: Maybe<Array<Scalars['BigInt']>>;
  timestamp_not_in?: Maybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<ReferralCode_Filter>>>;
  or?: Maybe<Array<Maybe<ReferralCode_Filter>>>;
};

export enum ReferralCode_OrderBy {
  Id = 'id',
  Code = 'code',
  Owner = 'owner',
  Timestamp = 'timestamp'
}

export type ReferralStat = {
  __typename?: 'ReferralStat';
  id: Scalars['ID'];
  referral: Scalars['String'];
  volume: Scalars['BigInt'];
  volumeCumulative: Scalars['BigInt'];
  discountUsd: Scalars['BigInt'];
  discountUsdCumulative: Scalars['BigInt'];
  timestamp: Scalars['BigInt'];
  period: Period;
};

export type ReferralStat_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  referral?: Maybe<Scalars['String']>;
  referral_not?: Maybe<Scalars['String']>;
  referral_gt?: Maybe<Scalars['String']>;
  referral_lt?: Maybe<Scalars['String']>;
  referral_gte?: Maybe<Scalars['String']>;
  referral_lte?: Maybe<Scalars['String']>;
  referral_in?: Maybe<Array<Scalars['String']>>;
  referral_not_in?: Maybe<Array<Scalars['String']>>;
  referral_contains?: Maybe<Scalars['String']>;
  referral_contains_nocase?: Maybe<Scalars['String']>;
  referral_not_contains?: Maybe<Scalars['String']>;
  referral_not_contains_nocase?: Maybe<Scalars['String']>;
  referral_starts_with?: Maybe<Scalars['String']>;
  referral_starts_with_nocase?: Maybe<Scalars['String']>;
  referral_not_starts_with?: Maybe<Scalars['String']>;
  referral_not_starts_with_nocase?: Maybe<Scalars['String']>;
  referral_ends_with?: Maybe<Scalars['String']>;
  referral_ends_with_nocase?: Maybe<Scalars['String']>;
  referral_not_ends_with?: Maybe<Scalars['String']>;
  referral_not_ends_with_nocase?: Maybe<Scalars['String']>;
  volume?: Maybe<Scalars['BigInt']>;
  volume_not?: Maybe<Scalars['BigInt']>;
  volume_gt?: Maybe<Scalars['BigInt']>;
  volume_lt?: Maybe<Scalars['BigInt']>;
  volume_gte?: Maybe<Scalars['BigInt']>;
  volume_lte?: Maybe<Scalars['BigInt']>;
  volume_in?: Maybe<Array<Scalars['BigInt']>>;
  volume_not_in?: Maybe<Array<Scalars['BigInt']>>;
  volumeCumulative?: Maybe<Scalars['BigInt']>;
  volumeCumulative_not?: Maybe<Scalars['BigInt']>;
  volumeCumulative_gt?: Maybe<Scalars['BigInt']>;
  volumeCumulative_lt?: Maybe<Scalars['BigInt']>;
  volumeCumulative_gte?: Maybe<Scalars['BigInt']>;
  volumeCumulative_lte?: Maybe<Scalars['BigInt']>;
  volumeCumulative_in?: Maybe<Array<Scalars['BigInt']>>;
  volumeCumulative_not_in?: Maybe<Array<Scalars['BigInt']>>;
  discountUsd?: Maybe<Scalars['BigInt']>;
  discountUsd_not?: Maybe<Scalars['BigInt']>;
  discountUsd_gt?: Maybe<Scalars['BigInt']>;
  discountUsd_lt?: Maybe<Scalars['BigInt']>;
  discountUsd_gte?: Maybe<Scalars['BigInt']>;
  discountUsd_lte?: Maybe<Scalars['BigInt']>;
  discountUsd_in?: Maybe<Array<Scalars['BigInt']>>;
  discountUsd_not_in?: Maybe<Array<Scalars['BigInt']>>;
  discountUsdCumulative?: Maybe<Scalars['BigInt']>;
  discountUsdCumulative_not?: Maybe<Scalars['BigInt']>;
  discountUsdCumulative_gt?: Maybe<Scalars['BigInt']>;
  discountUsdCumulative_lt?: Maybe<Scalars['BigInt']>;
  discountUsdCumulative_gte?: Maybe<Scalars['BigInt']>;
  discountUsdCumulative_lte?: Maybe<Scalars['BigInt']>;
  discountUsdCumulative_in?: Maybe<Array<Scalars['BigInt']>>;
  discountUsdCumulative_not_in?: Maybe<Array<Scalars['BigInt']>>;
  timestamp?: Maybe<Scalars['BigInt']>;
  timestamp_not?: Maybe<Scalars['BigInt']>;
  timestamp_gt?: Maybe<Scalars['BigInt']>;
  timestamp_lt?: Maybe<Scalars['BigInt']>;
  timestamp_gte?: Maybe<Scalars['BigInt']>;
  timestamp_lte?: Maybe<Scalars['BigInt']>;
  timestamp_in?: Maybe<Array<Scalars['BigInt']>>;
  timestamp_not_in?: Maybe<Array<Scalars['BigInt']>>;
  period?: Maybe<Period>;
  period_not?: Maybe<Period>;
  period_in?: Maybe<Array<Period>>;
  period_not_in?: Maybe<Array<Period>>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<ReferralStat_Filter>>>;
  or?: Maybe<Array<Maybe<ReferralStat_Filter>>>;
};

export enum ReferralStat_OrderBy {
  Id = 'id',
  Referral = 'referral',
  Volume = 'volume',
  VolumeCumulative = 'volumeCumulative',
  DiscountUsd = 'discountUsd',
  DiscountUsdCumulative = 'discountUsdCumulative',
  Timestamp = 'timestamp',
  Period = 'period'
}

export type ReferralVolumeRecord = {
  __typename?: 'ReferralVolumeRecord';
  id: Scalars['ID'];
  volume: Scalars['BigInt'];
  referral: Scalars['String'];
  referralCode: Scalars['String'];
  referrer: Scalars['String'];
  tierId: Scalars['BigInt'];
  totalRebate: Scalars['BigInt'];
  discountShare: Scalars['BigInt'];
  marginFee: Scalars['BigInt'];
  totalRebateUsd: Scalars['BigInt'];
  discountUsd: Scalars['BigInt'];
  transactionHash: Scalars['String'];
  blockNumber: Scalars['BigInt'];
  timestamp: Scalars['BigInt'];
};

export type ReferralVolumeRecord_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  volume?: Maybe<Scalars['BigInt']>;
  volume_not?: Maybe<Scalars['BigInt']>;
  volume_gt?: Maybe<Scalars['BigInt']>;
  volume_lt?: Maybe<Scalars['BigInt']>;
  volume_gte?: Maybe<Scalars['BigInt']>;
  volume_lte?: Maybe<Scalars['BigInt']>;
  volume_in?: Maybe<Array<Scalars['BigInt']>>;
  volume_not_in?: Maybe<Array<Scalars['BigInt']>>;
  referral?: Maybe<Scalars['String']>;
  referral_not?: Maybe<Scalars['String']>;
  referral_gt?: Maybe<Scalars['String']>;
  referral_lt?: Maybe<Scalars['String']>;
  referral_gte?: Maybe<Scalars['String']>;
  referral_lte?: Maybe<Scalars['String']>;
  referral_in?: Maybe<Array<Scalars['String']>>;
  referral_not_in?: Maybe<Array<Scalars['String']>>;
  referral_contains?: Maybe<Scalars['String']>;
  referral_contains_nocase?: Maybe<Scalars['String']>;
  referral_not_contains?: Maybe<Scalars['String']>;
  referral_not_contains_nocase?: Maybe<Scalars['String']>;
  referral_starts_with?: Maybe<Scalars['String']>;
  referral_starts_with_nocase?: Maybe<Scalars['String']>;
  referral_not_starts_with?: Maybe<Scalars['String']>;
  referral_not_starts_with_nocase?: Maybe<Scalars['String']>;
  referral_ends_with?: Maybe<Scalars['String']>;
  referral_ends_with_nocase?: Maybe<Scalars['String']>;
  referral_not_ends_with?: Maybe<Scalars['String']>;
  referral_not_ends_with_nocase?: Maybe<Scalars['String']>;
  referralCode?: Maybe<Scalars['String']>;
  referralCode_not?: Maybe<Scalars['String']>;
  referralCode_gt?: Maybe<Scalars['String']>;
  referralCode_lt?: Maybe<Scalars['String']>;
  referralCode_gte?: Maybe<Scalars['String']>;
  referralCode_lte?: Maybe<Scalars['String']>;
  referralCode_in?: Maybe<Array<Scalars['String']>>;
  referralCode_not_in?: Maybe<Array<Scalars['String']>>;
  referralCode_contains?: Maybe<Scalars['String']>;
  referralCode_contains_nocase?: Maybe<Scalars['String']>;
  referralCode_not_contains?: Maybe<Scalars['String']>;
  referralCode_not_contains_nocase?: Maybe<Scalars['String']>;
  referralCode_starts_with?: Maybe<Scalars['String']>;
  referralCode_starts_with_nocase?: Maybe<Scalars['String']>;
  referralCode_not_starts_with?: Maybe<Scalars['String']>;
  referralCode_not_starts_with_nocase?: Maybe<Scalars['String']>;
  referralCode_ends_with?: Maybe<Scalars['String']>;
  referralCode_ends_with_nocase?: Maybe<Scalars['String']>;
  referralCode_not_ends_with?: Maybe<Scalars['String']>;
  referralCode_not_ends_with_nocase?: Maybe<Scalars['String']>;
  referrer?: Maybe<Scalars['String']>;
  referrer_not?: Maybe<Scalars['String']>;
  referrer_gt?: Maybe<Scalars['String']>;
  referrer_lt?: Maybe<Scalars['String']>;
  referrer_gte?: Maybe<Scalars['String']>;
  referrer_lte?: Maybe<Scalars['String']>;
  referrer_in?: Maybe<Array<Scalars['String']>>;
  referrer_not_in?: Maybe<Array<Scalars['String']>>;
  referrer_contains?: Maybe<Scalars['String']>;
  referrer_contains_nocase?: Maybe<Scalars['String']>;
  referrer_not_contains?: Maybe<Scalars['String']>;
  referrer_not_contains_nocase?: Maybe<Scalars['String']>;
  referrer_starts_with?: Maybe<Scalars['String']>;
  referrer_starts_with_nocase?: Maybe<Scalars['String']>;
  referrer_not_starts_with?: Maybe<Scalars['String']>;
  referrer_not_starts_with_nocase?: Maybe<Scalars['String']>;
  referrer_ends_with?: Maybe<Scalars['String']>;
  referrer_ends_with_nocase?: Maybe<Scalars['String']>;
  referrer_not_ends_with?: Maybe<Scalars['String']>;
  referrer_not_ends_with_nocase?: Maybe<Scalars['String']>;
  tierId?: Maybe<Scalars['BigInt']>;
  tierId_not?: Maybe<Scalars['BigInt']>;
  tierId_gt?: Maybe<Scalars['BigInt']>;
  tierId_lt?: Maybe<Scalars['BigInt']>;
  tierId_gte?: Maybe<Scalars['BigInt']>;
  tierId_lte?: Maybe<Scalars['BigInt']>;
  tierId_in?: Maybe<Array<Scalars['BigInt']>>;
  tierId_not_in?: Maybe<Array<Scalars['BigInt']>>;
  totalRebate?: Maybe<Scalars['BigInt']>;
  totalRebate_not?: Maybe<Scalars['BigInt']>;
  totalRebate_gt?: Maybe<Scalars['BigInt']>;
  totalRebate_lt?: Maybe<Scalars['BigInt']>;
  totalRebate_gte?: Maybe<Scalars['BigInt']>;
  totalRebate_lte?: Maybe<Scalars['BigInt']>;
  totalRebate_in?: Maybe<Array<Scalars['BigInt']>>;
  totalRebate_not_in?: Maybe<Array<Scalars['BigInt']>>;
  discountShare?: Maybe<Scalars['BigInt']>;
  discountShare_not?: Maybe<Scalars['BigInt']>;
  discountShare_gt?: Maybe<Scalars['BigInt']>;
  discountShare_lt?: Maybe<Scalars['BigInt']>;
  discountShare_gte?: Maybe<Scalars['BigInt']>;
  discountShare_lte?: Maybe<Scalars['BigInt']>;
  discountShare_in?: Maybe<Array<Scalars['BigInt']>>;
  discountShare_not_in?: Maybe<Array<Scalars['BigInt']>>;
  marginFee?: Maybe<Scalars['BigInt']>;
  marginFee_not?: Maybe<Scalars['BigInt']>;
  marginFee_gt?: Maybe<Scalars['BigInt']>;
  marginFee_lt?: Maybe<Scalars['BigInt']>;
  marginFee_gte?: Maybe<Scalars['BigInt']>;
  marginFee_lte?: Maybe<Scalars['BigInt']>;
  marginFee_in?: Maybe<Array<Scalars['BigInt']>>;
  marginFee_not_in?: Maybe<Array<Scalars['BigInt']>>;
  totalRebateUsd?: Maybe<Scalars['BigInt']>;
  totalRebateUsd_not?: Maybe<Scalars['BigInt']>;
  totalRebateUsd_gt?: Maybe<Scalars['BigInt']>;
  totalRebateUsd_lt?: Maybe<Scalars['BigInt']>;
  totalRebateUsd_gte?: Maybe<Scalars['BigInt']>;
  totalRebateUsd_lte?: Maybe<Scalars['BigInt']>;
  totalRebateUsd_in?: Maybe<Array<Scalars['BigInt']>>;
  totalRebateUsd_not_in?: Maybe<Array<Scalars['BigInt']>>;
  discountUsd?: Maybe<Scalars['BigInt']>;
  discountUsd_not?: Maybe<Scalars['BigInt']>;
  discountUsd_gt?: Maybe<Scalars['BigInt']>;
  discountUsd_lt?: Maybe<Scalars['BigInt']>;
  discountUsd_gte?: Maybe<Scalars['BigInt']>;
  discountUsd_lte?: Maybe<Scalars['BigInt']>;
  discountUsd_in?: Maybe<Array<Scalars['BigInt']>>;
  discountUsd_not_in?: Maybe<Array<Scalars['BigInt']>>;
  transactionHash?: Maybe<Scalars['String']>;
  transactionHash_not?: Maybe<Scalars['String']>;
  transactionHash_gt?: Maybe<Scalars['String']>;
  transactionHash_lt?: Maybe<Scalars['String']>;
  transactionHash_gte?: Maybe<Scalars['String']>;
  transactionHash_lte?: Maybe<Scalars['String']>;
  transactionHash_in?: Maybe<Array<Scalars['String']>>;
  transactionHash_not_in?: Maybe<Array<Scalars['String']>>;
  transactionHash_contains?: Maybe<Scalars['String']>;
  transactionHash_contains_nocase?: Maybe<Scalars['String']>;
  transactionHash_not_contains?: Maybe<Scalars['String']>;
  transactionHash_not_contains_nocase?: Maybe<Scalars['String']>;
  transactionHash_starts_with?: Maybe<Scalars['String']>;
  transactionHash_starts_with_nocase?: Maybe<Scalars['String']>;
  transactionHash_not_starts_with?: Maybe<Scalars['String']>;
  transactionHash_not_starts_with_nocase?: Maybe<Scalars['String']>;
  transactionHash_ends_with?: Maybe<Scalars['String']>;
  transactionHash_ends_with_nocase?: Maybe<Scalars['String']>;
  transactionHash_not_ends_with?: Maybe<Scalars['String']>;
  transactionHash_not_ends_with_nocase?: Maybe<Scalars['String']>;
  blockNumber?: Maybe<Scalars['BigInt']>;
  blockNumber_not?: Maybe<Scalars['BigInt']>;
  blockNumber_gt?: Maybe<Scalars['BigInt']>;
  blockNumber_lt?: Maybe<Scalars['BigInt']>;
  blockNumber_gte?: Maybe<Scalars['BigInt']>;
  blockNumber_lte?: Maybe<Scalars['BigInt']>;
  blockNumber_in?: Maybe<Array<Scalars['BigInt']>>;
  blockNumber_not_in?: Maybe<Array<Scalars['BigInt']>>;
  timestamp?: Maybe<Scalars['BigInt']>;
  timestamp_not?: Maybe<Scalars['BigInt']>;
  timestamp_gt?: Maybe<Scalars['BigInt']>;
  timestamp_lt?: Maybe<Scalars['BigInt']>;
  timestamp_gte?: Maybe<Scalars['BigInt']>;
  timestamp_lte?: Maybe<Scalars['BigInt']>;
  timestamp_in?: Maybe<Array<Scalars['BigInt']>>;
  timestamp_not_in?: Maybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<ReferralVolumeRecord_Filter>>>;
  or?: Maybe<Array<Maybe<ReferralVolumeRecord_Filter>>>;
};

export enum ReferralVolumeRecord_OrderBy {
  Id = 'id',
  Volume = 'volume',
  Referral = 'referral',
  ReferralCode = 'referralCode',
  Referrer = 'referrer',
  TierId = 'tierId',
  TotalRebate = 'totalRebate',
  DiscountShare = 'discountShare',
  MarginFee = 'marginFee',
  TotalRebateUsd = 'totalRebateUsd',
  DiscountUsd = 'discountUsd',
  TransactionHash = 'transactionHash',
  BlockNumber = 'blockNumber',
  Timestamp = 'timestamp'
}

export type Referrer = {
  __typename?: 'Referrer';
  id: Scalars['ID'];
  discountShare: Scalars['BigInt'];
  tierId: Scalars['BigInt'];
};

export type ReferrerStat = {
  __typename?: 'ReferrerStat';
  id: Scalars['ID'];
  referrer: Scalars['String'];
  volume: Scalars['BigInt'];
  volumeCumulative: Scalars['BigInt'];
  referralCode: Scalars['String'];
  trades: Scalars['BigInt'];
  tradesCumulative: Scalars['BigInt'];
  tradedReferrals: Array<TradedReferral>;
  tradedReferralsCount: Scalars['BigInt'];
  tradedReferralsCountCumulative: Scalars['BigInt'];
  registeredReferrals: Array<RegisteredReferral>;
  registeredReferralsCount: Scalars['BigInt'];
  registeredReferralsCountCumulative: Scalars['BigInt'];
  totalRebateUsd: Scalars['BigInt'];
  totalRebateUsdCumulative: Scalars['BigInt'];
  discountUsd: Scalars['BigInt'];
  discountUsdCumulative: Scalars['BigInt'];
  timestamp: Scalars['BigInt'];
  period: Period;
};


export type ReferrerStatTradedReferralsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<TradedReferral_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<TradedReferral_Filter>;
};


export type ReferrerStatRegisteredReferralsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<RegisteredReferral_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<RegisteredReferral_Filter>;
};

export type ReferrerStat_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  referrer?: Maybe<Scalars['String']>;
  referrer_not?: Maybe<Scalars['String']>;
  referrer_gt?: Maybe<Scalars['String']>;
  referrer_lt?: Maybe<Scalars['String']>;
  referrer_gte?: Maybe<Scalars['String']>;
  referrer_lte?: Maybe<Scalars['String']>;
  referrer_in?: Maybe<Array<Scalars['String']>>;
  referrer_not_in?: Maybe<Array<Scalars['String']>>;
  referrer_contains?: Maybe<Scalars['String']>;
  referrer_contains_nocase?: Maybe<Scalars['String']>;
  referrer_not_contains?: Maybe<Scalars['String']>;
  referrer_not_contains_nocase?: Maybe<Scalars['String']>;
  referrer_starts_with?: Maybe<Scalars['String']>;
  referrer_starts_with_nocase?: Maybe<Scalars['String']>;
  referrer_not_starts_with?: Maybe<Scalars['String']>;
  referrer_not_starts_with_nocase?: Maybe<Scalars['String']>;
  referrer_ends_with?: Maybe<Scalars['String']>;
  referrer_ends_with_nocase?: Maybe<Scalars['String']>;
  referrer_not_ends_with?: Maybe<Scalars['String']>;
  referrer_not_ends_with_nocase?: Maybe<Scalars['String']>;
  volume?: Maybe<Scalars['BigInt']>;
  volume_not?: Maybe<Scalars['BigInt']>;
  volume_gt?: Maybe<Scalars['BigInt']>;
  volume_lt?: Maybe<Scalars['BigInt']>;
  volume_gte?: Maybe<Scalars['BigInt']>;
  volume_lte?: Maybe<Scalars['BigInt']>;
  volume_in?: Maybe<Array<Scalars['BigInt']>>;
  volume_not_in?: Maybe<Array<Scalars['BigInt']>>;
  volumeCumulative?: Maybe<Scalars['BigInt']>;
  volumeCumulative_not?: Maybe<Scalars['BigInt']>;
  volumeCumulative_gt?: Maybe<Scalars['BigInt']>;
  volumeCumulative_lt?: Maybe<Scalars['BigInt']>;
  volumeCumulative_gte?: Maybe<Scalars['BigInt']>;
  volumeCumulative_lte?: Maybe<Scalars['BigInt']>;
  volumeCumulative_in?: Maybe<Array<Scalars['BigInt']>>;
  volumeCumulative_not_in?: Maybe<Array<Scalars['BigInt']>>;
  referralCode?: Maybe<Scalars['String']>;
  referralCode_not?: Maybe<Scalars['String']>;
  referralCode_gt?: Maybe<Scalars['String']>;
  referralCode_lt?: Maybe<Scalars['String']>;
  referralCode_gte?: Maybe<Scalars['String']>;
  referralCode_lte?: Maybe<Scalars['String']>;
  referralCode_in?: Maybe<Array<Scalars['String']>>;
  referralCode_not_in?: Maybe<Array<Scalars['String']>>;
  referralCode_contains?: Maybe<Scalars['String']>;
  referralCode_contains_nocase?: Maybe<Scalars['String']>;
  referralCode_not_contains?: Maybe<Scalars['String']>;
  referralCode_not_contains_nocase?: Maybe<Scalars['String']>;
  referralCode_starts_with?: Maybe<Scalars['String']>;
  referralCode_starts_with_nocase?: Maybe<Scalars['String']>;
  referralCode_not_starts_with?: Maybe<Scalars['String']>;
  referralCode_not_starts_with_nocase?: Maybe<Scalars['String']>;
  referralCode_ends_with?: Maybe<Scalars['String']>;
  referralCode_ends_with_nocase?: Maybe<Scalars['String']>;
  referralCode_not_ends_with?: Maybe<Scalars['String']>;
  referralCode_not_ends_with_nocase?: Maybe<Scalars['String']>;
  trades?: Maybe<Scalars['BigInt']>;
  trades_not?: Maybe<Scalars['BigInt']>;
  trades_gt?: Maybe<Scalars['BigInt']>;
  trades_lt?: Maybe<Scalars['BigInt']>;
  trades_gte?: Maybe<Scalars['BigInt']>;
  trades_lte?: Maybe<Scalars['BigInt']>;
  trades_in?: Maybe<Array<Scalars['BigInt']>>;
  trades_not_in?: Maybe<Array<Scalars['BigInt']>>;
  tradesCumulative?: Maybe<Scalars['BigInt']>;
  tradesCumulative_not?: Maybe<Scalars['BigInt']>;
  tradesCumulative_gt?: Maybe<Scalars['BigInt']>;
  tradesCumulative_lt?: Maybe<Scalars['BigInt']>;
  tradesCumulative_gte?: Maybe<Scalars['BigInt']>;
  tradesCumulative_lte?: Maybe<Scalars['BigInt']>;
  tradesCumulative_in?: Maybe<Array<Scalars['BigInt']>>;
  tradesCumulative_not_in?: Maybe<Array<Scalars['BigInt']>>;
  tradedReferrals_?: Maybe<TradedReferral_Filter>;
  tradedReferralsCount?: Maybe<Scalars['BigInt']>;
  tradedReferralsCount_not?: Maybe<Scalars['BigInt']>;
  tradedReferralsCount_gt?: Maybe<Scalars['BigInt']>;
  tradedReferralsCount_lt?: Maybe<Scalars['BigInt']>;
  tradedReferralsCount_gte?: Maybe<Scalars['BigInt']>;
  tradedReferralsCount_lte?: Maybe<Scalars['BigInt']>;
  tradedReferralsCount_in?: Maybe<Array<Scalars['BigInt']>>;
  tradedReferralsCount_not_in?: Maybe<Array<Scalars['BigInt']>>;
  tradedReferralsCountCumulative?: Maybe<Scalars['BigInt']>;
  tradedReferralsCountCumulative_not?: Maybe<Scalars['BigInt']>;
  tradedReferralsCountCumulative_gt?: Maybe<Scalars['BigInt']>;
  tradedReferralsCountCumulative_lt?: Maybe<Scalars['BigInt']>;
  tradedReferralsCountCumulative_gte?: Maybe<Scalars['BigInt']>;
  tradedReferralsCountCumulative_lte?: Maybe<Scalars['BigInt']>;
  tradedReferralsCountCumulative_in?: Maybe<Array<Scalars['BigInt']>>;
  tradedReferralsCountCumulative_not_in?: Maybe<Array<Scalars['BigInt']>>;
  registeredReferrals_?: Maybe<RegisteredReferral_Filter>;
  registeredReferralsCount?: Maybe<Scalars['BigInt']>;
  registeredReferralsCount_not?: Maybe<Scalars['BigInt']>;
  registeredReferralsCount_gt?: Maybe<Scalars['BigInt']>;
  registeredReferralsCount_lt?: Maybe<Scalars['BigInt']>;
  registeredReferralsCount_gte?: Maybe<Scalars['BigInt']>;
  registeredReferralsCount_lte?: Maybe<Scalars['BigInt']>;
  registeredReferralsCount_in?: Maybe<Array<Scalars['BigInt']>>;
  registeredReferralsCount_not_in?: Maybe<Array<Scalars['BigInt']>>;
  registeredReferralsCountCumulative?: Maybe<Scalars['BigInt']>;
  registeredReferralsCountCumulative_not?: Maybe<Scalars['BigInt']>;
  registeredReferralsCountCumulative_gt?: Maybe<Scalars['BigInt']>;
  registeredReferralsCountCumulative_lt?: Maybe<Scalars['BigInt']>;
  registeredReferralsCountCumulative_gte?: Maybe<Scalars['BigInt']>;
  registeredReferralsCountCumulative_lte?: Maybe<Scalars['BigInt']>;
  registeredReferralsCountCumulative_in?: Maybe<Array<Scalars['BigInt']>>;
  registeredReferralsCountCumulative_not_in?: Maybe<Array<Scalars['BigInt']>>;
  totalRebateUsd?: Maybe<Scalars['BigInt']>;
  totalRebateUsd_not?: Maybe<Scalars['BigInt']>;
  totalRebateUsd_gt?: Maybe<Scalars['BigInt']>;
  totalRebateUsd_lt?: Maybe<Scalars['BigInt']>;
  totalRebateUsd_gte?: Maybe<Scalars['BigInt']>;
  totalRebateUsd_lte?: Maybe<Scalars['BigInt']>;
  totalRebateUsd_in?: Maybe<Array<Scalars['BigInt']>>;
  totalRebateUsd_not_in?: Maybe<Array<Scalars['BigInt']>>;
  totalRebateUsdCumulative?: Maybe<Scalars['BigInt']>;
  totalRebateUsdCumulative_not?: Maybe<Scalars['BigInt']>;
  totalRebateUsdCumulative_gt?: Maybe<Scalars['BigInt']>;
  totalRebateUsdCumulative_lt?: Maybe<Scalars['BigInt']>;
  totalRebateUsdCumulative_gte?: Maybe<Scalars['BigInt']>;
  totalRebateUsdCumulative_lte?: Maybe<Scalars['BigInt']>;
  totalRebateUsdCumulative_in?: Maybe<Array<Scalars['BigInt']>>;
  totalRebateUsdCumulative_not_in?: Maybe<Array<Scalars['BigInt']>>;
  discountUsd?: Maybe<Scalars['BigInt']>;
  discountUsd_not?: Maybe<Scalars['BigInt']>;
  discountUsd_gt?: Maybe<Scalars['BigInt']>;
  discountUsd_lt?: Maybe<Scalars['BigInt']>;
  discountUsd_gte?: Maybe<Scalars['BigInt']>;
  discountUsd_lte?: Maybe<Scalars['BigInt']>;
  discountUsd_in?: Maybe<Array<Scalars['BigInt']>>;
  discountUsd_not_in?: Maybe<Array<Scalars['BigInt']>>;
  discountUsdCumulative?: Maybe<Scalars['BigInt']>;
  discountUsdCumulative_not?: Maybe<Scalars['BigInt']>;
  discountUsdCumulative_gt?: Maybe<Scalars['BigInt']>;
  discountUsdCumulative_lt?: Maybe<Scalars['BigInt']>;
  discountUsdCumulative_gte?: Maybe<Scalars['BigInt']>;
  discountUsdCumulative_lte?: Maybe<Scalars['BigInt']>;
  discountUsdCumulative_in?: Maybe<Array<Scalars['BigInt']>>;
  discountUsdCumulative_not_in?: Maybe<Array<Scalars['BigInt']>>;
  timestamp?: Maybe<Scalars['BigInt']>;
  timestamp_not?: Maybe<Scalars['BigInt']>;
  timestamp_gt?: Maybe<Scalars['BigInt']>;
  timestamp_lt?: Maybe<Scalars['BigInt']>;
  timestamp_gte?: Maybe<Scalars['BigInt']>;
  timestamp_lte?: Maybe<Scalars['BigInt']>;
  timestamp_in?: Maybe<Array<Scalars['BigInt']>>;
  timestamp_not_in?: Maybe<Array<Scalars['BigInt']>>;
  period?: Maybe<Period>;
  period_not?: Maybe<Period>;
  period_in?: Maybe<Array<Period>>;
  period_not_in?: Maybe<Array<Period>>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<ReferrerStat_Filter>>>;
  or?: Maybe<Array<Maybe<ReferrerStat_Filter>>>;
};

export enum ReferrerStat_OrderBy {
  Id = 'id',
  Referrer = 'referrer',
  Volume = 'volume',
  VolumeCumulative = 'volumeCumulative',
  ReferralCode = 'referralCode',
  Trades = 'trades',
  TradesCumulative = 'tradesCumulative',
  TradedReferrals = 'tradedReferrals',
  TradedReferralsCount = 'tradedReferralsCount',
  TradedReferralsCountCumulative = 'tradedReferralsCountCumulative',
  RegisteredReferrals = 'registeredReferrals',
  RegisteredReferralsCount = 'registeredReferralsCount',
  RegisteredReferralsCountCumulative = 'registeredReferralsCountCumulative',
  TotalRebateUsd = 'totalRebateUsd',
  TotalRebateUsdCumulative = 'totalRebateUsdCumulative',
  DiscountUsd = 'discountUsd',
  DiscountUsdCumulative = 'discountUsdCumulative',
  Timestamp = 'timestamp',
  Period = 'period'
}

export type Referrer_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  discountShare?: Maybe<Scalars['BigInt']>;
  discountShare_not?: Maybe<Scalars['BigInt']>;
  discountShare_gt?: Maybe<Scalars['BigInt']>;
  discountShare_lt?: Maybe<Scalars['BigInt']>;
  discountShare_gte?: Maybe<Scalars['BigInt']>;
  discountShare_lte?: Maybe<Scalars['BigInt']>;
  discountShare_in?: Maybe<Array<Scalars['BigInt']>>;
  discountShare_not_in?: Maybe<Array<Scalars['BigInt']>>;
  tierId?: Maybe<Scalars['BigInt']>;
  tierId_not?: Maybe<Scalars['BigInt']>;
  tierId_gt?: Maybe<Scalars['BigInt']>;
  tierId_lt?: Maybe<Scalars['BigInt']>;
  tierId_gte?: Maybe<Scalars['BigInt']>;
  tierId_lte?: Maybe<Scalars['BigInt']>;
  tierId_in?: Maybe<Array<Scalars['BigInt']>>;
  tierId_not_in?: Maybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<Referrer_Filter>>>;
  or?: Maybe<Array<Maybe<Referrer_Filter>>>;
};

export enum Referrer_OrderBy {
  Id = 'id',
  DiscountShare = 'discountShare',
  TierId = 'tierId'
}

export type RegisteredReferral = {
  __typename?: 'RegisteredReferral';
  id: Scalars['ID'];
  referrerStat: ReferrerStat;
  referral: Scalars['String'];
};

export type RegisteredReferral_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  referrerStat?: Maybe<Scalars['String']>;
  referrerStat_not?: Maybe<Scalars['String']>;
  referrerStat_gt?: Maybe<Scalars['String']>;
  referrerStat_lt?: Maybe<Scalars['String']>;
  referrerStat_gte?: Maybe<Scalars['String']>;
  referrerStat_lte?: Maybe<Scalars['String']>;
  referrerStat_in?: Maybe<Array<Scalars['String']>>;
  referrerStat_not_in?: Maybe<Array<Scalars['String']>>;
  referrerStat_contains?: Maybe<Scalars['String']>;
  referrerStat_contains_nocase?: Maybe<Scalars['String']>;
  referrerStat_not_contains?: Maybe<Scalars['String']>;
  referrerStat_not_contains_nocase?: Maybe<Scalars['String']>;
  referrerStat_starts_with?: Maybe<Scalars['String']>;
  referrerStat_starts_with_nocase?: Maybe<Scalars['String']>;
  referrerStat_not_starts_with?: Maybe<Scalars['String']>;
  referrerStat_not_starts_with_nocase?: Maybe<Scalars['String']>;
  referrerStat_ends_with?: Maybe<Scalars['String']>;
  referrerStat_ends_with_nocase?: Maybe<Scalars['String']>;
  referrerStat_not_ends_with?: Maybe<Scalars['String']>;
  referrerStat_not_ends_with_nocase?: Maybe<Scalars['String']>;
  referrerStat_?: Maybe<ReferrerStat_Filter>;
  referral?: Maybe<Scalars['String']>;
  referral_not?: Maybe<Scalars['String']>;
  referral_gt?: Maybe<Scalars['String']>;
  referral_lt?: Maybe<Scalars['String']>;
  referral_gte?: Maybe<Scalars['String']>;
  referral_lte?: Maybe<Scalars['String']>;
  referral_in?: Maybe<Array<Scalars['String']>>;
  referral_not_in?: Maybe<Array<Scalars['String']>>;
  referral_contains?: Maybe<Scalars['String']>;
  referral_contains_nocase?: Maybe<Scalars['String']>;
  referral_not_contains?: Maybe<Scalars['String']>;
  referral_not_contains_nocase?: Maybe<Scalars['String']>;
  referral_starts_with?: Maybe<Scalars['String']>;
  referral_starts_with_nocase?: Maybe<Scalars['String']>;
  referral_not_starts_with?: Maybe<Scalars['String']>;
  referral_not_starts_with_nocase?: Maybe<Scalars['String']>;
  referral_ends_with?: Maybe<Scalars['String']>;
  referral_ends_with_nocase?: Maybe<Scalars['String']>;
  referral_not_ends_with?: Maybe<Scalars['String']>;
  referral_not_ends_with_nocase?: Maybe<Scalars['String']>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<RegisteredReferral_Filter>>>;
  or?: Maybe<Array<Maybe<RegisteredReferral_Filter>>>;
};

export enum RegisteredReferral_OrderBy {
  Id = 'id',
  ReferrerStat = 'referrerStat',
  ReferrerStatId = 'referrerStat__id',
  ReferrerStatReferrer = 'referrerStat__referrer',
  ReferrerStatVolume = 'referrerStat__volume',
  ReferrerStatVolumeCumulative = 'referrerStat__volumeCumulative',
  ReferrerStatReferralCode = 'referrerStat__referralCode',
  ReferrerStatTrades = 'referrerStat__trades',
  ReferrerStatTradesCumulative = 'referrerStat__tradesCumulative',
  ReferrerStatTradedReferralsCount = 'referrerStat__tradedReferralsCount',
  ReferrerStatTradedReferralsCountCumulative = 'referrerStat__tradedReferralsCountCumulative',
  ReferrerStatRegisteredReferralsCount = 'referrerStat__registeredReferralsCount',
  ReferrerStatRegisteredReferralsCountCumulative = 'referrerStat__registeredReferralsCountCumulative',
  ReferrerStatTotalRebateUsd = 'referrerStat__totalRebateUsd',
  ReferrerStatTotalRebateUsdCumulative = 'referrerStat__totalRebateUsdCumulative',
  ReferrerStatDiscountUsd = 'referrerStat__discountUsd',
  ReferrerStatDiscountUsdCumulative = 'referrerStat__discountUsdCumulative',
  ReferrerStatTimestamp = 'referrerStat__timestamp',
  ReferrerStatPeriod = 'referrerStat__period',
  Referral = 'referral'
}

export type Subscription = {
  __typename?: 'Subscription';
  tier?: Maybe<Tier>;
  tiers: Array<Tier>;
  referrer?: Maybe<Referrer>;
  referrers: Array<Referrer>;
  distribution?: Maybe<Distribution>;
  distributions: Array<Distribution>;
  referralVolumeRecord?: Maybe<ReferralVolumeRecord>;
  referralVolumeRecords: Array<ReferralVolumeRecord>;
  referrerStat?: Maybe<ReferrerStat>;
  referrerStats: Array<ReferrerStat>;
  referralCode?: Maybe<ReferralCode>;
  referralCodes: Array<ReferralCode>;
  tradedReferral?: Maybe<TradedReferral>;
  tradedReferrals: Array<TradedReferral>;
  registeredReferral?: Maybe<RegisteredReferral>;
  registeredReferrals: Array<RegisteredReferral>;
  referralStat?: Maybe<ReferralStat>;
  referralStats: Array<ReferralStat>;
  globalStat?: Maybe<GlobalStat>;
  globalStats: Array<GlobalStat>;
  executeDecreaseOrder?: Maybe<ExecuteDecreaseOrder>;
  executeDecreaseOrders: Array<ExecuteDecreaseOrder>;
  positionReferralAction?: Maybe<PositionReferralAction>;
  positionReferralActions: Array<PositionReferralAction>;
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>;
};


export type SubscriptionTierArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionTiersArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Tier_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Tier_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionReferrerArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionReferrersArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Referrer_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Referrer_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionDistributionArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionDistributionsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Distribution_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Distribution_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionReferralVolumeRecordArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionReferralVolumeRecordsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReferralVolumeRecord_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<ReferralVolumeRecord_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionReferrerStatArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionReferrerStatsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReferrerStat_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<ReferrerStat_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionReferralCodeArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionReferralCodesArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReferralCode_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<ReferralCode_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionTradedReferralArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionTradedReferralsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<TradedReferral_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<TradedReferral_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionRegisteredReferralArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionRegisteredReferralsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<RegisteredReferral_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<RegisteredReferral_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionReferralStatArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionReferralStatsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReferralStat_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<ReferralStat_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionGlobalStatArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionGlobalStatsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<GlobalStat_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<GlobalStat_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionExecuteDecreaseOrderArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionExecuteDecreaseOrdersArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ExecuteDecreaseOrder_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<ExecuteDecreaseOrder_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionPositionReferralActionArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionPositionReferralActionsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<PositionReferralAction_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<PositionReferralAction_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type Subscription_MetaArgs = {
  block?: Maybe<Block_Height>;
};

export type Tier = {
  __typename?: 'Tier';
  id: Scalars['ID'];
  totalRebate: Scalars['BigInt'];
  discountShare: Scalars['BigInt'];
};

export type Tier_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  totalRebate?: Maybe<Scalars['BigInt']>;
  totalRebate_not?: Maybe<Scalars['BigInt']>;
  totalRebate_gt?: Maybe<Scalars['BigInt']>;
  totalRebate_lt?: Maybe<Scalars['BigInt']>;
  totalRebate_gte?: Maybe<Scalars['BigInt']>;
  totalRebate_lte?: Maybe<Scalars['BigInt']>;
  totalRebate_in?: Maybe<Array<Scalars['BigInt']>>;
  totalRebate_not_in?: Maybe<Array<Scalars['BigInt']>>;
  discountShare?: Maybe<Scalars['BigInt']>;
  discountShare_not?: Maybe<Scalars['BigInt']>;
  discountShare_gt?: Maybe<Scalars['BigInt']>;
  discountShare_lt?: Maybe<Scalars['BigInt']>;
  discountShare_gte?: Maybe<Scalars['BigInt']>;
  discountShare_lte?: Maybe<Scalars['BigInt']>;
  discountShare_in?: Maybe<Array<Scalars['BigInt']>>;
  discountShare_not_in?: Maybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<Tier_Filter>>>;
  or?: Maybe<Array<Maybe<Tier_Filter>>>;
};

export enum Tier_OrderBy {
  Id = 'id',
  TotalRebate = 'totalRebate',
  DiscountShare = 'discountShare'
}

export type TradedReferral = {
  __typename?: 'TradedReferral';
  id: Scalars['ID'];
  referrerStat: ReferrerStat;
  referral: Scalars['String'];
};

export type TradedReferral_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  referrerStat?: Maybe<Scalars['String']>;
  referrerStat_not?: Maybe<Scalars['String']>;
  referrerStat_gt?: Maybe<Scalars['String']>;
  referrerStat_lt?: Maybe<Scalars['String']>;
  referrerStat_gte?: Maybe<Scalars['String']>;
  referrerStat_lte?: Maybe<Scalars['String']>;
  referrerStat_in?: Maybe<Array<Scalars['String']>>;
  referrerStat_not_in?: Maybe<Array<Scalars['String']>>;
  referrerStat_contains?: Maybe<Scalars['String']>;
  referrerStat_contains_nocase?: Maybe<Scalars['String']>;
  referrerStat_not_contains?: Maybe<Scalars['String']>;
  referrerStat_not_contains_nocase?: Maybe<Scalars['String']>;
  referrerStat_starts_with?: Maybe<Scalars['String']>;
  referrerStat_starts_with_nocase?: Maybe<Scalars['String']>;
  referrerStat_not_starts_with?: Maybe<Scalars['String']>;
  referrerStat_not_starts_with_nocase?: Maybe<Scalars['String']>;
  referrerStat_ends_with?: Maybe<Scalars['String']>;
  referrerStat_ends_with_nocase?: Maybe<Scalars['String']>;
  referrerStat_not_ends_with?: Maybe<Scalars['String']>;
  referrerStat_not_ends_with_nocase?: Maybe<Scalars['String']>;
  referrerStat_?: Maybe<ReferrerStat_Filter>;
  referral?: Maybe<Scalars['String']>;
  referral_not?: Maybe<Scalars['String']>;
  referral_gt?: Maybe<Scalars['String']>;
  referral_lt?: Maybe<Scalars['String']>;
  referral_gte?: Maybe<Scalars['String']>;
  referral_lte?: Maybe<Scalars['String']>;
  referral_in?: Maybe<Array<Scalars['String']>>;
  referral_not_in?: Maybe<Array<Scalars['String']>>;
  referral_contains?: Maybe<Scalars['String']>;
  referral_contains_nocase?: Maybe<Scalars['String']>;
  referral_not_contains?: Maybe<Scalars['String']>;
  referral_not_contains_nocase?: Maybe<Scalars['String']>;
  referral_starts_with?: Maybe<Scalars['String']>;
  referral_starts_with_nocase?: Maybe<Scalars['String']>;
  referral_not_starts_with?: Maybe<Scalars['String']>;
  referral_not_starts_with_nocase?: Maybe<Scalars['String']>;
  referral_ends_with?: Maybe<Scalars['String']>;
  referral_ends_with_nocase?: Maybe<Scalars['String']>;
  referral_not_ends_with?: Maybe<Scalars['String']>;
  referral_not_ends_with_nocase?: Maybe<Scalars['String']>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<TradedReferral_Filter>>>;
  or?: Maybe<Array<Maybe<TradedReferral_Filter>>>;
};

export enum TradedReferral_OrderBy {
  Id = 'id',
  ReferrerStat = 'referrerStat',
  ReferrerStatId = 'referrerStat__id',
  ReferrerStatReferrer = 'referrerStat__referrer',
  ReferrerStatVolume = 'referrerStat__volume',
  ReferrerStatVolumeCumulative = 'referrerStat__volumeCumulative',
  ReferrerStatReferralCode = 'referrerStat__referralCode',
  ReferrerStatTrades = 'referrerStat__trades',
  ReferrerStatTradesCumulative = 'referrerStat__tradesCumulative',
  ReferrerStatTradedReferralsCount = 'referrerStat__tradedReferralsCount',
  ReferrerStatTradedReferralsCountCumulative = 'referrerStat__tradedReferralsCountCumulative',
  ReferrerStatRegisteredReferralsCount = 'referrerStat__registeredReferralsCount',
  ReferrerStatRegisteredReferralsCountCumulative = 'referrerStat__registeredReferralsCountCumulative',
  ReferrerStatTotalRebateUsd = 'referrerStat__totalRebateUsd',
  ReferrerStatTotalRebateUsdCumulative = 'referrerStat__totalRebateUsdCumulative',
  ReferrerStatDiscountUsd = 'referrerStat__discountUsd',
  ReferrerStatDiscountUsdCumulative = 'referrerStat__discountUsdCumulative',
  ReferrerStatTimestamp = 'referrerStat__timestamp',
  ReferrerStatPeriod = 'referrerStat__period',
  Referral = 'referral'
}

export type _Block_ = {
  __typename?: '_Block_';
  /** The hash of the block */
  hash?: Maybe<Scalars['Bytes']>;
  /** The block number */
  number: Scalars['Int'];
  /** Integer representation of the timestamp stored in blocks for the chain */
  timestamp?: Maybe<Scalars['Int']>;
};

/** The type for the top-level _meta field */
export type _Meta_ = {
  __typename?: '_Meta_';
  /**
   * Information about a specific subgraph block. The hash of the block
   * will be null if the _meta field has a block constraint that asks for
   * a block number. It will be filled if the _meta field has no block constraint
   * and therefore asks for the latest  block
   */
  block: _Block_;
  /** The deployment ID */
  deployment: Scalars['String'];
  /** If `true`, the subgraph encountered indexing errors at some past block */
  hasIndexingErrors: Scalars['Boolean'];
};

export enum _SubgraphErrorPolicy_ {
  /** Data will be returned even if the subgraph has indexing errors */
  Allow = 'allow',
  /** If the subgraph has indexing errors, data will be omitted. The default. */
  Deny = 'deny'
}

export type GetReferralCodesQueryVariables = Exact<{
  address?: Maybe<Scalars['String']>;
}>;


export type GetReferralCodesQuery = (
  { __typename?: 'Query' }
  & { referralCodes: Array<(
    { __typename?: 'ReferralCode' }
    & Pick<ReferralCode, 'code'>
  )> }
);

export type GetReferrerStatsQueryVariables = Exact<{
  referralCode?: Maybe<Scalars['String']>;
}>;


export type GetReferrerStatsQuery = (
  { __typename?: 'Query' }
  & { referrerStats: Array<(
    { __typename?: 'ReferrerStat' }
    & Pick<ReferrerStat, 'referrer' | 'id' | 'referralCode' | 'volume' | 'volumeCumulative' | 'trades' | 'tradesCumulative' | 'tradedReferralsCount' | 'tradedReferralsCountCumulative' | 'registeredReferralsCount' | 'registeredReferralsCountCumulative' | 'totalRebateUsd' | 'totalRebateUsdCumulative' | 'discountUsd' | 'discountUsdCumulative'>
  )> }
);

export type GetReferralQueryVariables = Exact<{
  address?: Maybe<Scalars['String']>;
}>;


export type GetReferralQuery = (
  { __typename?: 'Query' }
  & { registeredReferrals: Array<(
    { __typename?: 'RegisteredReferral' }
    & Pick<RegisteredReferral, 'referral'>
  )> }
);

export type GetReferrerLeaderboardQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReferrerStat_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
}>;


export type GetReferrerLeaderboardQuery = (
  { __typename?: 'Query' }
  & { referrerStats: Array<(
    { __typename?: 'ReferrerStat' }
    & Pick<ReferrerStat, 'id' | 'registeredReferralsCountCumulative' | 'volumeCumulative' | 'referralCode'>
  )> }
);


export const GetReferralCodesDocument = `
    query getReferralCodes($address: String) {
  referralCodes(
    first: 1
    orderBy: timestamp
    orderDirection: desc
    where: {owner: $address}
  ) {
    code
  }
}
    `;
export const GetReferrerStatsDocument = `
    query getReferrerStats($referralCode: String) {
  referrerStats(first: 1, where: {referralCode: $referralCode, period: total}) {
    referrer
    id
    referralCode
    volume
    volumeCumulative
    trades
    tradesCumulative
    tradedReferralsCount
    tradedReferralsCountCumulative
    registeredReferralsCount
    registeredReferralsCountCumulative
    totalRebateUsd
    totalRebateUsdCumulative
    discountUsd
    discountUsdCumulative
  }
}
    `;
export const GetReferralDocument = `
    query getReferral($address: String) {
  registeredReferrals(first: 1, where: {referral: $address}) {
    referral
  }
}
    `;
export const GetReferrerLeaderboardDocument = `
    query getReferrerLeaderboard($first: Int, $skip: Int, $orderBy: ReferrerStat_orderBy, $orderDirection: OrderDirection) {
  referrerStats(
    first: $first
    skip: $skip
    orderBy: $orderBy
    orderDirection: $orderDirection
    where: {registeredReferralsCountCumulative_gte: 1, period: total}
  ) {
    id
    registeredReferralsCountCumulative
    volumeCumulative
    referralCode
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getReferralCodes: build.query<GetReferralCodesQuery, GetReferralCodesQueryVariables | void>({
      query: (variables) => ({ document: GetReferralCodesDocument, variables })
    }),
    getReferrerStats: build.query<GetReferrerStatsQuery, GetReferrerStatsQueryVariables | void>({
      query: (variables) => ({ document: GetReferrerStatsDocument, variables })
    }),
    getReferral: build.query<GetReferralQuery, GetReferralQueryVariables | void>({
      query: (variables) => ({ document: GetReferralDocument, variables })
    }),
    getReferrerLeaderboard: build.query<GetReferrerLeaderboardQuery, GetReferrerLeaderboardQueryVariables | void>({
      query: (variables) => ({ document: GetReferrerLeaderboardDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetReferralCodesQuery, useLazyGetReferralCodesQuery, useGetReferrerStatsQuery, useLazyGetReferrerStatsQuery, useGetReferralQuery, useLazyGetReferralQuery, useGetReferrerLeaderboardQuery, useLazyGetReferrerLeaderboardQuery } = injectedRtkApi;


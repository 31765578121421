import { CaretDownGlyph } from '@carbon/icons-react'
import { ButtonError } from 'components/Button'
import { AutoColumn } from 'components/Column'
import CoverScreen from 'components/CoverScreen'
import Modal from 'components/Modal'
import { ReferralCtx } from 'components/ReferralProvider'
import { RowBetween } from 'components/Row'
import { Product } from 'constants/perps'
import download from 'downloadjs'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import useTheme from 'hooks/useTheme'
import { toPng } from 'html-to-image'
import { UTCTimestamp } from 'lightweight-charts'
import { darken, transparentize } from 'polished'
import { QRCodeCanvas } from 'qrcode.react'
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { Textfit } from 'react-textfit'
import { useModalOpen, useToggleModal } from 'state/application/hooks'
import { ApplicationModal } from 'state/application/reducer'
import styled from 'styled-components/macro'
import { CloseIcon, ThemedText } from 'theme'

import bgOne from '../../assets/images/card-bg-1.webp'
import bgTwo from '../../assets/images/card-bg-2.webp'
import bgThree from '../../assets/images/card-bg-3.webp'
import bgFour from '../../assets/images/card-bg-4.webp'
import bgFive from '../../assets/images/card-bg-5.webp'
import { ReactComponent as LogoSmall } from '../../assets/svg/logo-small.svg'
import { ReactComponent as Logo } from '../../assets/svg/logo-text.svg'

const BG: {
  [k: string]: { id: string; url: string; label: string }
} = {
  STATUE: { id: 'STATUE', url: bgOne, label: 'Statue' },
  COINS: { id: 'COINS', url: bgTwo, label: 'Coins' },
  CANDLEBARS: { id: 'CANDLEBARS', url: bgThree, label: 'Candlebars' },
  USDC: { id: 'USDC', url: bgFour, label: 'USDC' },
  HAND: { id: 'HAND', url: bgFive, label: 'Hand' },
}

const PNL: {
  [k: string]: { id: string; label: string }
} = {
  PERCENT: { id: 'PERCENT', label: 'Only Percentage' },
  USDC: { id: 'USDC', label: 'Only in USDC' },
  PERCENTUSDC: { id: 'PERCENTUSDC', label: 'Large Percentage & USDC' },
  USDCPERCENT: { id: 'USDCPERCENT', label: 'Large USDC & Percentage' },
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
`

const LargeModal = styled(Modal)`
  max-width: 720px;
  ${({ theme }) => theme.mediaWidth.upToLarge``}
`

const PrettyLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.primary1};
`
const ImgContainer = styled.div<{ customBg?: string }>`
  display: flex;
  background: url(${({ customBg }) => (customBg ? customBg : bgOne)});
  flex-direction: column;
  border-radius: 0;
  overflow: hidden;
  height: 512px;
  width: 1024px;
  padding: 2rem 3.5rem;
  background-color: blue;
`
const ProductButton = styled.button<{ active: boolean }>`
  background: ${({ theme, active }) => (active ? darken(0.05, theme.bg0) : 'transparent')};
  border: ${({ active, theme }) => (active ? `1px solid ` + theme.bg2 : 'none')}!important;
  font-weight: 600;
  gap: 0.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.white};
  padding: 1rem 0.5rem;
  text-align: start;
  border-radius: 0.25rem;
  border: 0 solid transparent;
  flex: 0 1;
  cursor: pointer;
  &:hover,
  &:focus {
    background: ${({ theme }) => darken(0.05, theme.bg0)};
  }
`

const BgButton = styled.button<{ disabled?: boolean }>`
  border: none;
  display: flex;
  align-items: center;
  text-align: center;
  background-color: transparent;
  color: ${({ theme, disabled }) => (disabled ? transparentize(0.3, theme.text1) : theme.text1)};
  padding: 0;
  cursor: pointer;
`

const FlyoutMenu = styled.div`
  min-width: 250px;
  align-items: flex-center;
  gap: 0.5rem;
  background-color: ${({ theme }) => theme.bg1};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  overflow: auto;
  padding: 16px;
  position: absolute;
  right: 0;
  z-index: 99;
  width: 100%;
  & > *:not(:last-child) {
    margin-bottom: 12px;
  }
`

const NodeContainer = styled.div`
  display: block;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: none;
  `}
`

const CronusIcon = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToLarge`
  display:none;
`};
`

const CronusIconSmall = styled.div`
  display: flex;
  margin-right: 1rem;
  position: relative;
  align-items: center;
`

const SelectBackgroundButton = styled.button`
  display: flex;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: none
  `};
  position: relative;
  justify-content: space-between;
  color: ${({ theme }) => theme.text1};
  align-items: center;
  border-radius: 0.25rem;
  padding: 0 1rem;
  border: 1px solid ${({ theme }) => theme.bg3};
  background: none;
  cursor: pointer;
`

const SelectBackgroundSelect = styled.select`
  display: none;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: flex;
  `};
  position: relative;
  justify-content: space-between;
  color: ${({ theme }) => theme.text1};
  align-items: center;
  border-radius: 0.25rem;
  padding: 0 1rem;
  border: 1px solid ${({ theme }) => theme.bg3};
  background: none;
  cursor: pointer;

  option {
    display: block;
    background: ${({ theme }) => theme.bg0};
    height: 30px;
    &::last-child {
      border-radius: 0 0 0.25rem 0.25rem;
    }
  }
`

const PnlText = styled(ThemedText.Black)`
  ${false &&
  `
  background: -webkit-linear-gradient(to right, #189c18, #57de62);
  background: linear-gradient(to right, #189c18, #57de62);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
 `};
`

const Title = styled.div`
  display: flex;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
`
type Candle = {
  time: UTCTimestamp
  value: number
}
export default function GetCardModal({
  card,
  onDismiss,
}: {
  card:
    | {
        product: Product
        isLong: boolean
        pnl: number
        pnlPercent: number
        leverage: number
        entryPrice: number
        markPrice: number
      }
    | undefined
  onDismiss: () => void
}) {
  const { white, text1, bg3, green1, red2 } = useTheme()
  const [dataUrl, setDataUrl] = useState<any>()
  const [elementRef, setElementRef] = useState()
  const [currentBackground, setCurrentBackground] = useState<{ id: string; url: string; label: string }>(BG.STATUE)
  const [currentPnl, setCurrentPnl] = useState<{ id: string; label: string }>(PNL.PERCENT)
  const { ownCode, ownCodeLoaded } = useContext(ReferralCtx)
  const memoisedUrl = useMemo(() => {
    if (ownCode) {
      return 'trading.cronusfinancexyz.com/#/?ref=' + ownCode
    }
    return 'trading.cronusfinancexyz.com'
  }, [ownCode])

  const bgNode = useRef<HTMLDivElement>()
  const pnlNode = useRef<HTMLDivElement>()
  const bgFlyoutOpen = useModalOpen(ApplicationModal.BG_SELECTOR)
  const pnlFlyoutOpen = useModalOpen(ApplicationModal.PNL_SELECTOR)
  const bgToggle = useToggleModal(ApplicationModal.BG_SELECTOR)
  const pnlToggle = useToggleModal(ApplicationModal.PNL_SELECTOR)
  useOnClickOutside(bgNode, bgFlyoutOpen ? bgToggle : undefined)
  useOnClickOutside(pnlNode, pnlFlyoutOpen ? pnlToggle : undefined)

  const handleRef = useCallback((node) => {
    setElementRef(node)
  }, [])

  const date = useMemo(() => {
    return Date.now()
  }, [card])

  useEffect(() => {
    if (!!card && !!elementRef) {
      toPng(elementRef)
        .then((data) => {
          setDataUrl(data)
        })
        .catch((e) => {
          console.log(e)
        })
    }
  }, [card, elementRef, currentBackground, currentPnl])

  const changeBg = (newBg: { id: string; url: string; label: string }) => {
    bgToggle()
    setCurrentBackground(newBg)
  }

  const changePnl = (newPnl: { id: string; label: string }) => {
    pnlToggle()
    setCurrentPnl(newPnl)
  }

  const downloadImage = () => {
    if (!dataUrl || !card) {
      return
    }
    download(dataUrl, `${card.product.label}-${date}.png`)
  }
  return (
    <LargeModal isOpen={!!card} onDismiss={onDismiss} minHeight={40}>
      {card && (
        <Wrapper>
          <RowBetween style={{ padding: '1rem', position: 'absolute' }}>
            <div />
            <CloseIcon onClick={onDismiss} />
          </RowBetween>
          <AutoColumn style={{ display: 'flex', flexDirection: 'column', gridRowGap: '0' }}>
            <div style={{ width: 0, height: 0, overflow: 'hidden' }}>
              <ImgContainer ref={handleRef} customBg={currentBackground.url}>
                <Title style={{ marginBottom: '3rem' }}>
                  <CronusIconSmall>
                    <LogoSmall fill={'#ffeee3'} height="40px" title="logo" />
                  </CronusIconSmall>
                  <CronusIcon>
                    <Logo fill={'#ffeee3'} height="40px" title="logo" />
                  </CronusIcon>
                </Title>
                <div style={{ width: '450px' }}>
                  <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'baseline' }}>
                    <ThemedText.Black fontSize="30px" color="#cacaca" fontWeight={'bold'}>
                      {card.product.label}
                    </ThemedText.Black>
                    <ThemedText.Black fontSize="30px" color={card.isLong ? green1 : red2} fontWeight={500}>
                      {card.isLong ? 'LONG' : 'SHORT'}
                    </ThemedText.Black>
                  </div>
                  <div style={{ height: '120px' }}>
                    <PnlText
                      color={card.pnl > 0 ? green1 : red2}
                      fontSize={'100px'}
                      fontWeight={800}
                      style={{ height: '100%' }}
                    >
                      {card.pnl >= 0 ? '+' : '-'}
                      {currentPnl.id === 'PERCENT' || currentPnl.id === 'PERCENTUSDC' ? '' : '$'}
                      {card.pnl >= 0
                        ? (currentPnl.id === 'PERCENT' || currentPnl.id === 'PERCENTUSDC'
                            ? card.pnlPercent
                            : card.pnl
                          ).toFixed(2)
                        : (currentPnl.id === 'PERCENT' || currentPnl.id === 'PERCENTUSDC' ? card.pnlPercent : card.pnl)
                            .toFixed(2)
                            .substring(1)}
                      {currentPnl.id === 'PERCENT' || currentPnl.id === 'PERCENTUSDC' ? '%' : ''}
                    </PnlText>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '1rem', width: '100%' }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100px',
                        height: '70px',
                        textAlign: 'center',
                        gap: '0.5rem',
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: '#ffefe4',
                          borderRadius: '0.25rem',
                          height: '25px',
                          justifyContent: 'center',
                          display: 'flex',
                          alignItems: 'baseline',
                          flex: '0 1 25px',
                          width: '100%',
                          padding: '0.25rem 0',
                        }}
                      >
                        <ThemedText.Black fontSize={'14px'} color={'black'} fontWeight={500}>
                          Leverage
                        </ThemedText.Black>
                      </div>
                      <div
                        style={{
                          height: '40px',
                          width: '100%',
                          display: 'flex',
                          alignItems: 'baseline',
                        }}
                      >
                        {/*bravo, shittiest fix ever for constant baseline - myself*/}
                        <span style={{ fontSize: '30px', display: 'hidden', width: 0, color: 'transparent' }}>0</span>
                        <ThemedText.Black color="#ffefe4" fontWeight={800} style={{ width: '100%' }}>
                          <Textfit max={30} mode="single">
                            {card.leverage.toFixed(2)}x
                          </Textfit>
                        </ThemedText.Black>
                      </div>
                    </div>
                    {currentPnl.id === 'PERCENTUSDC' && (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          width: '100px',
                          height: '70px',
                          textAlign: 'center',
                          gap: '0.5rem',
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: '#ffefe4',
                            borderRadius: '0.25rem',
                            height: '25px',
                            justifyContent: 'center',
                            display: 'flex',
                            alignItems: 'baseline',
                            flex: '0 1 25px',
                            width: '100%',
                            padding: '0.25rem 0',
                          }}
                        >
                          <span style={{ fontSize: '14px', display: 'hidden', width: 0, color: 'transparent' }}>0</span>
                          <ThemedText.Black fontSize={'12px'} color={'black'} fontWeight={500}>
                            {card.pnl >= 0 ? 'Profit' : 'Loss'} in USDC
                          </ThemedText.Black>
                        </div>
                        <div
                          style={{
                            height: '40px',
                            width: '100%',
                            display: 'flex',
                            alignItems: 'baseline',
                          }}
                        >
                          {/*bravo, shittiest fix ever for constant baseline - myself*/}
                          <span style={{ fontSize: '30px', display: 'hidden', width: 0, color: 'transparent' }}>0</span>
                          <ThemedText.Black color="#ffefe4" fontWeight={800} style={{ width: '100%' }}>
                            <Textfit max={30} mode="single">
                              {card.pnl >= 0 ? '+' : '-'}$
                              {card.pnl >= 0 ? card.pnl.toFixed(2) : card.pnl.toFixed(2).substring(1)}
                            </Textfit>
                          </ThemedText.Black>
                        </div>
                      </div>
                    )}
                    {currentPnl.id === 'USDCPERCENT' && (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          width: '100px',
                          height: '70px',
                          textAlign: 'center',
                          gap: '0.5rem',
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: '#ffefe4',
                            borderRadius: '0.25rem',
                            height: '25px',
                            justifyContent: 'center',
                            display: 'flex',
                            alignItems: 'baseline',
                            flex: '0 1 25px',
                            width: '100%',
                            padding: '0.25rem 0',
                          }}
                        >
                          <span style={{ fontSize: '14px', display: 'hidden', width: 0, color: 'transparent' }}>0</span>
                          <ThemedText.Black fontSize={'12px'} color={'black'} fontWeight={500}>
                            {card.pnl >= 0 ? 'Profit' : 'Loss'} in %
                          </ThemedText.Black>
                        </div>
                        <div
                          style={{
                            height: '40px',
                            width: '100%',
                            display: 'flex',
                            alignItems: 'baseline',
                          }}
                        >
                          {/*bravo, shittiest fix ever for constant baseline - myself*/}
                          <span style={{ fontSize: '30px', display: 'hidden', width: 0, color: 'transparent' }}>0</span>
                          <ThemedText.Black color="#ffefe4" fontWeight={800} style={{ width: '100%' }}>
                            <Textfit max={30} mode="single">
                              {card.pnl >= 0 ? '+' : '-'}
                              {card.pnl >= 0 ? card.pnlPercent.toFixed(2) : card.pnlPercent.toFixed(2).substring(1)}%
                            </Textfit>
                          </ThemedText.Black>
                        </div>
                      </div>
                    )}
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100px',
                        height: '70px',
                        textAlign: 'center',
                        gap: '0.5rem',
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: '#ffefe4',
                          borderRadius: '0.25rem',
                          height: '25px',
                          justifyContent: 'center',
                          display: 'flex',
                          alignItems: 'baseline',
                          flex: '0 1 25px',
                          width: '100%',
                          padding: '0.25rem 0',
                        }}
                      >
                        <ThemedText.Black fontSize={'14px'} color={'black'} fontWeight={500}>
                          Entry Price
                        </ThemedText.Black>
                      </div>
                      <div
                        style={{
                          height: '40px',
                          width: '100%',
                          display: 'flex',
                          alignItems: 'baseline',
                        }}
                      >
                        {/*bravo, shittiest fix ever for constant baseline - myself*/}
                        <span style={{ fontSize: '30px', display: 'hidden', width: 0, color: 'transparent' }}>0</span>
                        <ThemedText.Black color="#ffefe4" fontWeight={800} style={{ width: '100%' }}>
                          <Textfit max={30} mode="single">
                            ${card.entryPrice.toFixed(0)}
                          </Textfit>
                        </ThemedText.Black>
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100px',
                        height: '70px',
                        textAlign: 'center',
                        gap: '0.5rem',
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: '#ffefe4',
                          borderRadius: '0.25rem',
                          height: '25px',
                          justifyContent: 'center',
                          display: 'flex',
                          alignItems: 'baseline',
                          flex: '0 1 25px',
                          width: '100%',
                          padding: '0.25rem 0',
                        }}
                      >
                        <ThemedText.Black fontSize={'14px'} color={'black'} fontWeight={500}>
                          Mark Price
                        </ThemedText.Black>
                      </div>
                      <div
                        style={{
                          height: '40px',
                          width: '100%',
                          display: 'flex',
                          alignItems: 'baseline',
                        }}
                      >
                        {/*bravo, shittiest fix ever for constant baseline - myself*/}
                        <span style={{ fontSize: '30px', display: 'hidden', width: 0, color: 'transparent' }}>0</span>
                        <ThemedText.Black color="#ffefe4" fontWeight={800} style={{ width: '100%' }}>
                          <Textfit max={30} mode="single">
                            ${card.markPrice.toFixed(0)}
                          </Textfit>
                        </ThemedText.Black>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: 'auto', display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                  <div style={{ display: 'flex', gap: '1rem' }}>
                    <div
                      style={{
                        backgroundColor: 'transparent',
                        width: '70px',
                        height: '70px',
                        padding: '0.15rem',
                        border: '1px solid #ffefe4',
                      }}
                    >
                      <QRCodeCanvas
                        size={256}
                        style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                        value={'https://' + memoisedUrl}
                        fgColor={'#ffefe4'}
                        bgColor={'transparent'}
                        level="Q"
                      />
                    </div>
                  </div>
                  <ThemedText.Black color={'#ffefe4'} fontSize="18px" fontWeight={500}>
                    {memoisedUrl}
                  </ThemedText.Black>
                </div>
              </ImgContainer>
            </div>
            {dataUrl ? (
              <img src={dataUrl} alt="" height="210" style={{ width: '100%', objectFit: 'contain' }} />
            ) : (
              <div style={{ height: '210px', width: '100%', position: 'relative' }}>
                <CoverScreen loading={true} />
              </div>
            )}
          </AutoColumn>
          <AutoColumn gap="md" style={{ flex: '1 1', padding: '1rem', height: '100%' }}>
            <div
              style={{
                display: 'flex',
                height: '40px',
                alignItems: 'center',
                gap: '1.70rem',
                justifyContent: 'space-between',
              }}
            >
              <div>Background:</div>
              <SelectBackgroundSelect
                style={{ height: '100%', width: '150px', alignSelf: 'end' }}
                value={currentBackground.id}
                placeholder={currentBackground.label}
                onChange={(e) => e.target.value && setCurrentBackground(BG[e.target.value as string])}
              >
                {Object.values(BG).map((bg) => (
                  <option key={bg.id + '-selectItem'} value={bg.id}>
                    {bg.label}
                  </option>
                ))}
              </SelectBackgroundSelect>
              <NodeContainer ref={bgNode as any} style={{ height: '100%', position: 'relative' }}>
                <SelectBackgroundButton style={{ width: '200px', height: '100%' }} onClick={bgToggle}>
                  {currentBackground.label}
                  <CaretDownGlyph />
                </SelectBackgroundButton>
                {bgFlyoutOpen && (
                  <FlyoutMenu>
                    {Object.values(BG).map((bg) => (
                      <BgButton
                        key={bg.id + '-flyoutItem'}
                        disabled={bg.id === currentBackground.id}
                        onClick={() => changeBg(bg)}
                      >
                        {bg.label}
                      </BgButton>
                    ))}
                  </FlyoutMenu>
                )}
              </NodeContainer>
            </div>
            <div
              style={{
                display: 'flex',
                height: '40px',
                alignItems: 'center',
                gap: '1.70rem',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ whiteSpace: 'pre' }}>Show PnL as:</div>
              <SelectBackgroundSelect
                style={{
                  height: '100%',
                  width: '150px',
                  alignSelf: 'end',
                  whiteSpace: 'pre',
                  textOverflow: 'ellipsis',
                }}
                value={currentPnl.id}
                placeholder={currentPnl.label}
                onChange={(e) => e.target.value && setCurrentPnl(PNL[e.target.value as string])}
              >
                {Object.values(PNL).map((pnl) => (
                  <option key={pnl.id + '-selectItem'} value={pnl.id}>
                    {pnl.label}
                  </option>
                ))}
              </SelectBackgroundSelect>
              <NodeContainer ref={pnlNode as any} style={{ height: '100%', position: 'relative' }}>
                <SelectBackgroundButton style={{ height: '100%', width: '200px' }} onClick={pnlToggle}>
                  {currentPnl.label}
                  <CaretDownGlyph />
                </SelectBackgroundButton>
                {pnlFlyoutOpen && (
                  <FlyoutMenu>
                    {Object.values(PNL).map((pnl) => (
                      <BgButton
                        key={pnl.id + '-flyoutItem'}
                        disabled={pnl === currentPnl}
                        onClick={() => changePnl(pnl)}
                      >
                        {pnl.label}
                      </BgButton>
                    ))}
                  </FlyoutMenu>
                )}
              </NodeContainer>
            </div>
            {ownCodeLoaded && !ownCode && (
              <div style={{ display: 'block', height: '40px', alignItems: 'center', gap: '1.70rem' }}>
                Don{"'"}t have a referral code?{' '}
                <PrettyLink style={{ fontWeight: 600 }} to={'/referrals-and-leaderboards'}>
                  Generate one here
                </PrettyLink>{' '}
                and start earning rewards
              </div>
            )}
          </AutoColumn>
          <ButtonError onClick={() => downloadImage()} style={{ margin: '10px 0 0 0' }} id="download-image-button">
            <ThemedText.Black fontSize={20} fontWeight={500}>
              Download
            </ThemedText.Black>
          </ButtonError>
        </Wrapper>
      )}
    </LargeModal>
  )
}

import { Currency } from '@uniswap/sdk-core'
import { DEFAULT_FALLBACK_CHAIN, SupportedChainId } from 'constants/chains'
import useHttpLocations from 'hooks/useHttpLocations'
import { useEffect, useMemo, useState } from 'react'
import { WrappedTokenInfo } from 'state/lists/wrappedTokenInfo'

import EthereumLogo from '../../assets/images/ethereum-logo.png'
import EvmosLogo from '../../assets/images/evmos-logo.png'

type Network = 'ethereum' | 'evmos' | 'evmos_testnet' | 'rinkeby'

function chainIdToNetworkName(networkId: SupportedChainId): Network {
  switch (networkId) {
    case SupportedChainId.MAINNET:
      return 'evmos'
    case SupportedChainId.EVMOS_TESTNET:
      return 'evmos_testnet'
    case SupportedChainId.RINKEBY:
      return 'rinkeby'
    default:
      return 'ethereum'
  }
}

function getNativeLogoURI(chainId: SupportedChainId = DEFAULT_FALLBACK_CHAIN): string {
  switch (chainId) {
    case SupportedChainId.MAINNET:
    case SupportedChainId.EVMOS_TESTNET:
    case SupportedChainId.RINKEBY:
      return EvmosLogo
    default:
      return EthereumLogo
  }
}
const networksWithUrls = [SupportedChainId.MAINNET, SupportedChainId.EVMOS_TESTNET, SupportedChainId.RINKEBY]

function getTokenLogoURI(address: string, chainId: SupportedChainId = DEFAULT_FALLBACK_CHAIN): string | void {
  const networkName = chainIdToNetworkName(chainId)
  if (networksWithUrls.includes(chainId)) {
    return `https://raw.githubusercontent.com/cronus-finance/token-list/main/assets/${networkName}/${address}/logo.png`
  }
}

export default function useCurrencyLogoURIs(currency?: Currency | null): string[] {
  const [data, setData] = useState<undefined | Record<string, any>>(undefined)
  const locations = useHttpLocations(currency instanceof WrappedTokenInfo ? currency.logoURI : undefined)
  useEffect(() => {
    if (!data) {
      fetch('https://gateway.ipfs.io/ipns/tokens.uniswap.org')
        .then((res) => res.json())
        .then((json) => {
          setData(json.tokens)
        })
        .catch((e) => {
          setData(undefined)
        })
    }
  }, [data])

  return useMemo(() => {
    const logoURIs = [...locations]
    if (currency) {
      if (currency.isNative) {
        logoURIs.push(getNativeLogoURI(currency.chainId))
      } else if (currency.isToken) {
        let logoURI
        if (networksWithUrls.includes(currency.chainId)) {
          logoURI = getTokenLogoURI(currency.address, currency.chainId)
        } else {
          logoURI = data ? data.find((token: any) => token.address === currency.address)?.logoURI : undefined
        }
        if (logoURI) {
          logoURIs.push(logoURI)
        }
      }
    }
    return logoURIs
  }, [currency, locations, data])
}

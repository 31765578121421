import { Trans } from '@lingui/macro'
import { ButtonPrimary } from 'components/Button'
import { AutoColumn } from 'components/Column'
import Loader from 'components/Loader'
import Modal from 'components/Modal'
import ReferralProvider from 'components/ReferralProvider'
import { RowBetween } from 'components/Row'
import { Checkbox } from 'components/SearchModal/styleds'
import { VersionWarning } from 'components/VersionWarning'
import ApeModeQueryParamReader from 'hooks/useApeModeQueryParamReader'
import ms from 'ms.macro'
import ClaimTestnet from 'pages/ClaimTestnet'
import {
  // lazy,
  Suspense,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useQuery } from 'react-query'
import { Route, Switch } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { setLegalDisclaimer, setVersionTimestamp } from 'state/user/actions'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

import GoogleAnalyticsReporter from '../components/analytics/GoogleAnalyticsReporter'
import AddressClaimModal from '../components/claim/AddressClaimModal'
import ErrorBoundary from '../components/ErrorBoundary'
import Header from '../components/Header'
import Popups from '../components/Popups'
import Web3ReactManager from '../components/Web3ReactManager'
import { useModalOpen, useToggleModal } from '../state/application/hooks'
import { ApplicationModal } from '../state/application/reducer'
import DarkModeQueryParamReader from '../theme/DarkModeQueryParamReader'
import ManagePerp from './Earn/ManagePerp'
import ReferAndCompete from './ReferAndCompete'
import { RedirectPathToTradeOnly, RedirectToTrade } from './Swap/redirects'
import Trade from './Trade'

const AppWrapper = styled.div`
  display: flex;
  height: 100vh;
  flex-flow: column;
  align-items: flex-start;
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 100px 12px 0px 12px;
  align-items: center;
  flex: 1;
  z-index: 1;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 4.5rem 16px 16px 16px;
  `};
`

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 2;
`

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
`

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.bg2};
`

const Marginer = styled.div`
  margin-top: 5rem;
`

function TopLevelModals() {
  const open = useModalOpen(ApplicationModal.ADDRESS_CLAIM)
  const toggle = useToggleModal(ApplicationModal.ADDRESS_CLAIM)
  return <AddressClaimModal isOpen={open} onDismiss={toggle} />
}

export default function App() {
  const [modalOpen, setModalOpen] = useState(false)
  const [newVersion, setNewVersion] = useState('')
  const dispatch = useAppDispatch()

  const legalDisclaimer = useAppSelector((state) => state.user.legalDisclaimer)
  const versionTimestamp = useAppSelector((state) => state.user.versionTimestamp)
  const [legalCheckbox, setLegalCheckbox] = useState(false)

  useEffect(() => {
    if ((legalDisclaimer === undefined) !== modalOpen) {
      setModalOpen(true)
    }

    if (legalDisclaimer && legalDisclaimer + 2592000000 < Date.now() !== modalOpen) {
      dispatch(setLegalDisclaimer({ legalDisclaimer: undefined }))
      setModalOpen(true)
    }
  }, [dispatch, legalDisclaimer, modalOpen])

  function handleAcceptWarning() {
    dispatch(setLegalDisclaimer({ legalDisclaimer: Date.now() }))
    setModalOpen(false)
  }

  const getVersionTimestamp = async () => {
    return await fetch('https://raw.githubusercontent.com/cronus-finance/cronus-status/main/timestamp.json')
      .then((res: any) => {
        return res.json()
      })
      .then((data) => {
        return { timestamp: data.timestamp as number, tag: data.tag as string }
      })
      .catch((e) => {
        return e
      })
  }
  const V_TAG = 'v0.4.0'
  const timestamp = useQuery({
    queryKey: 'versionTimestamp',
    queryFn: getVersionTimestamp,
    refetchOnWindowFocus: 'always',
    refetchInterval: ms`15 minutes`,
  })

  useMemo(() => {
    if (!timestamp.data) return
    if (timestamp.isError) return
    const { timestamp: newTimestamp, tag } = timestamp.data
    if (newTimestamp === versionTimestamp) return
    if (tag === V_TAG) {
      dispatch(setVersionTimestamp({ timestamp: newTimestamp }))
      return
    }
    setNewVersion(tag)
  }, [timestamp.data])

  return (
    <ErrorBoundary>
      <Route component={GoogleAnalyticsReporter} />
      <Route component={DarkModeQueryParamReader} />
      <Route component={ApeModeQueryParamReader} />
      <Web3ReactManager>
        <AppWrapper>
          <HeaderWrapper>
            <Header />
          </HeaderWrapper>
          <BodyWrapper>
            <Popups />
            <TopLevelModals />
            <Suspense fallback={<Loader />}>
              <Modal isOpen={modalOpen} onDismiss={() => null} maxHeight={90}>
                <ContentWrapper gap="md">
                  <div>
                    <ThemedText.MediumHeader style={{ padding: '1rem', justifySelf: 'flex-start' }}>
                      <Trans>Legal Disclaimer</Trans>
                    </ThemedText.MediumHeader>
                    <Separator />
                  </div>
                  <div style={{ padding: '1rem', display: 'flex', flexDirection: 'column', gap: '0.75rem' }}>
                    <div>By using this interface, you acknowledge and agree that:</div>
                    <div>
                      The software (interface and smart contracts) is experimental and is provided &quot;as is&quot;,
                      without warranties of any kind, express or implied.
                    </div>
                    <div>
                      Cronus Finance does not guarantee the accuracy, completeness, reliability, or suitability of the
                      data relayed through the interface.
                    </div>
                    <div>
                      Cronus Finance will not be liable for any damages, losses, or expenses resulting from the use or
                      inability to use the software.
                    </div>
                    <div>
                      You use the interface at your own risk and are solely responsible for any consequences of its use.
                    </div>
                    <div>By clicking the checkbox and continue you declare your consent to these terms.</div>
                  </div>
                  <div style={{ display: 'flex', padding: '0 1rem 0.5rem 1rem', gap: '0.375rem' }}>
                    <Checkbox
                      type="checkbox"
                      checked={legalCheckbox}
                      onClick={() => setLegalCheckbox((prev: boolean) => !prev)}
                    />
                    I have read and agreed to the terms and conditions
                  </div>
                  <RowBetween>
                    <ButtonPrimary disabled={!legalCheckbox} onClick={handleAcceptWarning}>
                      Continue
                    </ButtonPrimary>
                  </RowBetween>
                </ContentWrapper>
              </Modal>
              <ReferralProvider>
                <Switch>
                  <Route exact strict path="/trade" component={Trade} />
                  <Route exact strict path="/trade/:contract" component={RedirectToTrade} />
                  <Route exact strict path="/perps/pool/:poolId" component={ManagePerp} />
                  <Route exact strict path="/referrals-and-leaderboards" component={ReferAndCompete} />
                  <Route exact strict path="/claim" component={ClaimTestnet} />

                  <Route component={RedirectPathToTradeOnly} />
                </Switch>
              </ReferralProvider>
              {newVersion && <VersionWarning version={newVersion} level={0} />}
            </Suspense>
            <Marginer />
          </BodyWrapper>
        </AppWrapper>
      </Web3ReactManager>
    </ErrorBoundary>
  )
}

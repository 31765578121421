import { api } from './slice';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigDecimal: any;
  BigInt: any;
  Bytes: any;
};






export type BlockChangedFilter = {
  number_gte: Scalars['Int'];
};

export type Block_Height = {
  hash?: Maybe<Scalars['Bytes']>;
  number?: Maybe<Scalars['Int']>;
  number_gte?: Maybe<Scalars['Int']>;
};


export type Data = {
  __typename?: 'Data';
  id: Scalars['ID'];
  cumulativeFees: Scalars['BigInt'];
  cumulativePnl: Scalars['BigInt'];
  cumulativeVolume: Scalars['BigInt'];
  cumulativeMargin: Scalars['BigInt'];
  openInterest: Scalars['BigInt'];
  openInterestLong: Scalars['BigInt'];
  openInterestShort: Scalars['BigInt'];
  positionCount: Scalars['BigInt'];
  tradeCount: Scalars['BigInt'];
};

export type Data_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  cumulativeFees?: Maybe<Scalars['BigInt']>;
  cumulativeFees_not?: Maybe<Scalars['BigInt']>;
  cumulativeFees_gt?: Maybe<Scalars['BigInt']>;
  cumulativeFees_lt?: Maybe<Scalars['BigInt']>;
  cumulativeFees_gte?: Maybe<Scalars['BigInt']>;
  cumulativeFees_lte?: Maybe<Scalars['BigInt']>;
  cumulativeFees_in?: Maybe<Array<Scalars['BigInt']>>;
  cumulativeFees_not_in?: Maybe<Array<Scalars['BigInt']>>;
  cumulativePnl?: Maybe<Scalars['BigInt']>;
  cumulativePnl_not?: Maybe<Scalars['BigInt']>;
  cumulativePnl_gt?: Maybe<Scalars['BigInt']>;
  cumulativePnl_lt?: Maybe<Scalars['BigInt']>;
  cumulativePnl_gte?: Maybe<Scalars['BigInt']>;
  cumulativePnl_lte?: Maybe<Scalars['BigInt']>;
  cumulativePnl_in?: Maybe<Array<Scalars['BigInt']>>;
  cumulativePnl_not_in?: Maybe<Array<Scalars['BigInt']>>;
  cumulativeVolume?: Maybe<Scalars['BigInt']>;
  cumulativeVolume_not?: Maybe<Scalars['BigInt']>;
  cumulativeVolume_gt?: Maybe<Scalars['BigInt']>;
  cumulativeVolume_lt?: Maybe<Scalars['BigInt']>;
  cumulativeVolume_gte?: Maybe<Scalars['BigInt']>;
  cumulativeVolume_lte?: Maybe<Scalars['BigInt']>;
  cumulativeVolume_in?: Maybe<Array<Scalars['BigInt']>>;
  cumulativeVolume_not_in?: Maybe<Array<Scalars['BigInt']>>;
  cumulativeMargin?: Maybe<Scalars['BigInt']>;
  cumulativeMargin_not?: Maybe<Scalars['BigInt']>;
  cumulativeMargin_gt?: Maybe<Scalars['BigInt']>;
  cumulativeMargin_lt?: Maybe<Scalars['BigInt']>;
  cumulativeMargin_gte?: Maybe<Scalars['BigInt']>;
  cumulativeMargin_lte?: Maybe<Scalars['BigInt']>;
  cumulativeMargin_in?: Maybe<Array<Scalars['BigInt']>>;
  cumulativeMargin_not_in?: Maybe<Array<Scalars['BigInt']>>;
  openInterest?: Maybe<Scalars['BigInt']>;
  openInterest_not?: Maybe<Scalars['BigInt']>;
  openInterest_gt?: Maybe<Scalars['BigInt']>;
  openInterest_lt?: Maybe<Scalars['BigInt']>;
  openInterest_gte?: Maybe<Scalars['BigInt']>;
  openInterest_lte?: Maybe<Scalars['BigInt']>;
  openInterest_in?: Maybe<Array<Scalars['BigInt']>>;
  openInterest_not_in?: Maybe<Array<Scalars['BigInt']>>;
  openInterestLong?: Maybe<Scalars['BigInt']>;
  openInterestLong_not?: Maybe<Scalars['BigInt']>;
  openInterestLong_gt?: Maybe<Scalars['BigInt']>;
  openInterestLong_lt?: Maybe<Scalars['BigInt']>;
  openInterestLong_gte?: Maybe<Scalars['BigInt']>;
  openInterestLong_lte?: Maybe<Scalars['BigInt']>;
  openInterestLong_in?: Maybe<Array<Scalars['BigInt']>>;
  openInterestLong_not_in?: Maybe<Array<Scalars['BigInt']>>;
  openInterestShort?: Maybe<Scalars['BigInt']>;
  openInterestShort_not?: Maybe<Scalars['BigInt']>;
  openInterestShort_gt?: Maybe<Scalars['BigInt']>;
  openInterestShort_lt?: Maybe<Scalars['BigInt']>;
  openInterestShort_gte?: Maybe<Scalars['BigInt']>;
  openInterestShort_lte?: Maybe<Scalars['BigInt']>;
  openInterestShort_in?: Maybe<Array<Scalars['BigInt']>>;
  openInterestShort_not_in?: Maybe<Array<Scalars['BigInt']>>;
  positionCount?: Maybe<Scalars['BigInt']>;
  positionCount_not?: Maybe<Scalars['BigInt']>;
  positionCount_gt?: Maybe<Scalars['BigInt']>;
  positionCount_lt?: Maybe<Scalars['BigInt']>;
  positionCount_gte?: Maybe<Scalars['BigInt']>;
  positionCount_lte?: Maybe<Scalars['BigInt']>;
  positionCount_in?: Maybe<Array<Scalars['BigInt']>>;
  positionCount_not_in?: Maybe<Array<Scalars['BigInt']>>;
  tradeCount?: Maybe<Scalars['BigInt']>;
  tradeCount_not?: Maybe<Scalars['BigInt']>;
  tradeCount_gt?: Maybe<Scalars['BigInt']>;
  tradeCount_lt?: Maybe<Scalars['BigInt']>;
  tradeCount_gte?: Maybe<Scalars['BigInt']>;
  tradeCount_lte?: Maybe<Scalars['BigInt']>;
  tradeCount_in?: Maybe<Array<Scalars['BigInt']>>;
  tradeCount_not_in?: Maybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<Data_Filter>>>;
  or?: Maybe<Array<Maybe<Data_Filter>>>;
};

export enum Data_OrderBy {
  Id = 'id',
  CumulativeFees = 'cumulativeFees',
  CumulativePnl = 'cumulativePnl',
  CumulativeVolume = 'cumulativeVolume',
  CumulativeMargin = 'cumulativeMargin',
  OpenInterest = 'openInterest',
  OpenInterestLong = 'openInterestLong',
  OpenInterestShort = 'openInterestShort',
  PositionCount = 'positionCount',
  TradeCount = 'tradeCount'
}

export type DayData = {
  __typename?: 'DayData';
  id: Scalars['ID'];
  date: Scalars['BigInt'];
  cumulativeFees: Scalars['BigInt'];
  cumulativePnl: Scalars['BigInt'];
  cumulativeVolume: Scalars['BigInt'];
  cumulativeMargin: Scalars['BigInt'];
  openInterest: Scalars['BigInt'];
  openInterestLong: Scalars['BigInt'];
  openInterestShort: Scalars['BigInt'];
  positionCount: Scalars['BigInt'];
  tradeCount: Scalars['BigInt'];
};

export type DayData_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  date?: Maybe<Scalars['BigInt']>;
  date_not?: Maybe<Scalars['BigInt']>;
  date_gt?: Maybe<Scalars['BigInt']>;
  date_lt?: Maybe<Scalars['BigInt']>;
  date_gte?: Maybe<Scalars['BigInt']>;
  date_lte?: Maybe<Scalars['BigInt']>;
  date_in?: Maybe<Array<Scalars['BigInt']>>;
  date_not_in?: Maybe<Array<Scalars['BigInt']>>;
  cumulativeFees?: Maybe<Scalars['BigInt']>;
  cumulativeFees_not?: Maybe<Scalars['BigInt']>;
  cumulativeFees_gt?: Maybe<Scalars['BigInt']>;
  cumulativeFees_lt?: Maybe<Scalars['BigInt']>;
  cumulativeFees_gte?: Maybe<Scalars['BigInt']>;
  cumulativeFees_lte?: Maybe<Scalars['BigInt']>;
  cumulativeFees_in?: Maybe<Array<Scalars['BigInt']>>;
  cumulativeFees_not_in?: Maybe<Array<Scalars['BigInt']>>;
  cumulativePnl?: Maybe<Scalars['BigInt']>;
  cumulativePnl_not?: Maybe<Scalars['BigInt']>;
  cumulativePnl_gt?: Maybe<Scalars['BigInt']>;
  cumulativePnl_lt?: Maybe<Scalars['BigInt']>;
  cumulativePnl_gte?: Maybe<Scalars['BigInt']>;
  cumulativePnl_lte?: Maybe<Scalars['BigInt']>;
  cumulativePnl_in?: Maybe<Array<Scalars['BigInt']>>;
  cumulativePnl_not_in?: Maybe<Array<Scalars['BigInt']>>;
  cumulativeVolume?: Maybe<Scalars['BigInt']>;
  cumulativeVolume_not?: Maybe<Scalars['BigInt']>;
  cumulativeVolume_gt?: Maybe<Scalars['BigInt']>;
  cumulativeVolume_lt?: Maybe<Scalars['BigInt']>;
  cumulativeVolume_gte?: Maybe<Scalars['BigInt']>;
  cumulativeVolume_lte?: Maybe<Scalars['BigInt']>;
  cumulativeVolume_in?: Maybe<Array<Scalars['BigInt']>>;
  cumulativeVolume_not_in?: Maybe<Array<Scalars['BigInt']>>;
  cumulativeMargin?: Maybe<Scalars['BigInt']>;
  cumulativeMargin_not?: Maybe<Scalars['BigInt']>;
  cumulativeMargin_gt?: Maybe<Scalars['BigInt']>;
  cumulativeMargin_lt?: Maybe<Scalars['BigInt']>;
  cumulativeMargin_gte?: Maybe<Scalars['BigInt']>;
  cumulativeMargin_lte?: Maybe<Scalars['BigInt']>;
  cumulativeMargin_in?: Maybe<Array<Scalars['BigInt']>>;
  cumulativeMargin_not_in?: Maybe<Array<Scalars['BigInt']>>;
  openInterest?: Maybe<Scalars['BigInt']>;
  openInterest_not?: Maybe<Scalars['BigInt']>;
  openInterest_gt?: Maybe<Scalars['BigInt']>;
  openInterest_lt?: Maybe<Scalars['BigInt']>;
  openInterest_gte?: Maybe<Scalars['BigInt']>;
  openInterest_lte?: Maybe<Scalars['BigInt']>;
  openInterest_in?: Maybe<Array<Scalars['BigInt']>>;
  openInterest_not_in?: Maybe<Array<Scalars['BigInt']>>;
  openInterestLong?: Maybe<Scalars['BigInt']>;
  openInterestLong_not?: Maybe<Scalars['BigInt']>;
  openInterestLong_gt?: Maybe<Scalars['BigInt']>;
  openInterestLong_lt?: Maybe<Scalars['BigInt']>;
  openInterestLong_gte?: Maybe<Scalars['BigInt']>;
  openInterestLong_lte?: Maybe<Scalars['BigInt']>;
  openInterestLong_in?: Maybe<Array<Scalars['BigInt']>>;
  openInterestLong_not_in?: Maybe<Array<Scalars['BigInt']>>;
  openInterestShort?: Maybe<Scalars['BigInt']>;
  openInterestShort_not?: Maybe<Scalars['BigInt']>;
  openInterestShort_gt?: Maybe<Scalars['BigInt']>;
  openInterestShort_lt?: Maybe<Scalars['BigInt']>;
  openInterestShort_gte?: Maybe<Scalars['BigInt']>;
  openInterestShort_lte?: Maybe<Scalars['BigInt']>;
  openInterestShort_in?: Maybe<Array<Scalars['BigInt']>>;
  openInterestShort_not_in?: Maybe<Array<Scalars['BigInt']>>;
  positionCount?: Maybe<Scalars['BigInt']>;
  positionCount_not?: Maybe<Scalars['BigInt']>;
  positionCount_gt?: Maybe<Scalars['BigInt']>;
  positionCount_lt?: Maybe<Scalars['BigInt']>;
  positionCount_gte?: Maybe<Scalars['BigInt']>;
  positionCount_lte?: Maybe<Scalars['BigInt']>;
  positionCount_in?: Maybe<Array<Scalars['BigInt']>>;
  positionCount_not_in?: Maybe<Array<Scalars['BigInt']>>;
  tradeCount?: Maybe<Scalars['BigInt']>;
  tradeCount_not?: Maybe<Scalars['BigInt']>;
  tradeCount_gt?: Maybe<Scalars['BigInt']>;
  tradeCount_lt?: Maybe<Scalars['BigInt']>;
  tradeCount_gte?: Maybe<Scalars['BigInt']>;
  tradeCount_lte?: Maybe<Scalars['BigInt']>;
  tradeCount_in?: Maybe<Array<Scalars['BigInt']>>;
  tradeCount_not_in?: Maybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<DayData_Filter>>>;
  or?: Maybe<Array<Maybe<DayData_Filter>>>;
};

export enum DayData_OrderBy {
  Id = 'id',
  Date = 'date',
  CumulativeFees = 'cumulativeFees',
  CumulativePnl = 'cumulativePnl',
  CumulativeVolume = 'cumulativeVolume',
  CumulativeMargin = 'cumulativeMargin',
  OpenInterest = 'openInterest',
  OpenInterestLong = 'openInterestLong',
  OpenInterestShort = 'openInterestShort',
  PositionCount = 'positionCount',
  TradeCount = 'tradeCount'
}

export type Order = {
  __typename?: 'Order';
  id: Scalars['ID'];
  productId: Scalars['Bytes'];
  size: Scalars['BigInt'];
  margin: Scalars['BigInt'];
  user: Scalars['Bytes'];
  currency: Scalars['Bytes'];
  isLong: Scalars['Boolean'];
  isClose: Scalars['Boolean'];
  createdAtTimestamp: Scalars['BigInt'];
  createdAtBlockNumber: Scalars['BigInt'];
};

/** Defines the order direction, either ascending or descending */
export enum OrderDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type Order_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  productId?: Maybe<Scalars['Bytes']>;
  productId_not?: Maybe<Scalars['Bytes']>;
  productId_gt?: Maybe<Scalars['Bytes']>;
  productId_lt?: Maybe<Scalars['Bytes']>;
  productId_gte?: Maybe<Scalars['Bytes']>;
  productId_lte?: Maybe<Scalars['Bytes']>;
  productId_in?: Maybe<Array<Scalars['Bytes']>>;
  productId_not_in?: Maybe<Array<Scalars['Bytes']>>;
  productId_contains?: Maybe<Scalars['Bytes']>;
  productId_not_contains?: Maybe<Scalars['Bytes']>;
  size?: Maybe<Scalars['BigInt']>;
  size_not?: Maybe<Scalars['BigInt']>;
  size_gt?: Maybe<Scalars['BigInt']>;
  size_lt?: Maybe<Scalars['BigInt']>;
  size_gte?: Maybe<Scalars['BigInt']>;
  size_lte?: Maybe<Scalars['BigInt']>;
  size_in?: Maybe<Array<Scalars['BigInt']>>;
  size_not_in?: Maybe<Array<Scalars['BigInt']>>;
  margin?: Maybe<Scalars['BigInt']>;
  margin_not?: Maybe<Scalars['BigInt']>;
  margin_gt?: Maybe<Scalars['BigInt']>;
  margin_lt?: Maybe<Scalars['BigInt']>;
  margin_gte?: Maybe<Scalars['BigInt']>;
  margin_lte?: Maybe<Scalars['BigInt']>;
  margin_in?: Maybe<Array<Scalars['BigInt']>>;
  margin_not_in?: Maybe<Array<Scalars['BigInt']>>;
  user?: Maybe<Scalars['Bytes']>;
  user_not?: Maybe<Scalars['Bytes']>;
  user_gt?: Maybe<Scalars['Bytes']>;
  user_lt?: Maybe<Scalars['Bytes']>;
  user_gte?: Maybe<Scalars['Bytes']>;
  user_lte?: Maybe<Scalars['Bytes']>;
  user_in?: Maybe<Array<Scalars['Bytes']>>;
  user_not_in?: Maybe<Array<Scalars['Bytes']>>;
  user_contains?: Maybe<Scalars['Bytes']>;
  user_not_contains?: Maybe<Scalars['Bytes']>;
  currency?: Maybe<Scalars['Bytes']>;
  currency_not?: Maybe<Scalars['Bytes']>;
  currency_gt?: Maybe<Scalars['Bytes']>;
  currency_lt?: Maybe<Scalars['Bytes']>;
  currency_gte?: Maybe<Scalars['Bytes']>;
  currency_lte?: Maybe<Scalars['Bytes']>;
  currency_in?: Maybe<Array<Scalars['Bytes']>>;
  currency_not_in?: Maybe<Array<Scalars['Bytes']>>;
  currency_contains?: Maybe<Scalars['Bytes']>;
  currency_not_contains?: Maybe<Scalars['Bytes']>;
  isLong?: Maybe<Scalars['Boolean']>;
  isLong_not?: Maybe<Scalars['Boolean']>;
  isLong_in?: Maybe<Array<Scalars['Boolean']>>;
  isLong_not_in?: Maybe<Array<Scalars['Boolean']>>;
  isClose?: Maybe<Scalars['Boolean']>;
  isClose_not?: Maybe<Scalars['Boolean']>;
  isClose_in?: Maybe<Array<Scalars['Boolean']>>;
  isClose_not_in?: Maybe<Array<Scalars['Boolean']>>;
  createdAtTimestamp?: Maybe<Scalars['BigInt']>;
  createdAtTimestamp_not?: Maybe<Scalars['BigInt']>;
  createdAtTimestamp_gt?: Maybe<Scalars['BigInt']>;
  createdAtTimestamp_lt?: Maybe<Scalars['BigInt']>;
  createdAtTimestamp_gte?: Maybe<Scalars['BigInt']>;
  createdAtTimestamp_lte?: Maybe<Scalars['BigInt']>;
  createdAtTimestamp_in?: Maybe<Array<Scalars['BigInt']>>;
  createdAtTimestamp_not_in?: Maybe<Array<Scalars['BigInt']>>;
  createdAtBlockNumber?: Maybe<Scalars['BigInt']>;
  createdAtBlockNumber_not?: Maybe<Scalars['BigInt']>;
  createdAtBlockNumber_gt?: Maybe<Scalars['BigInt']>;
  createdAtBlockNumber_lt?: Maybe<Scalars['BigInt']>;
  createdAtBlockNumber_gte?: Maybe<Scalars['BigInt']>;
  createdAtBlockNumber_lte?: Maybe<Scalars['BigInt']>;
  createdAtBlockNumber_in?: Maybe<Array<Scalars['BigInt']>>;
  createdAtBlockNumber_not_in?: Maybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<Order_Filter>>>;
  or?: Maybe<Array<Maybe<Order_Filter>>>;
};

export enum Order_OrderBy {
  Id = 'id',
  ProductId = 'productId',
  Size = 'size',
  Margin = 'margin',
  User = 'user',
  Currency = 'currency',
  IsLong = 'isLong',
  IsClose = 'isClose',
  CreatedAtTimestamp = 'createdAtTimestamp',
  CreatedAtBlockNumber = 'createdAtBlockNumber'
}

export type Pool = {
  __typename?: 'Pool';
  id: Scalars['ID'];
  cumulativeFees: Scalars['BigInt'];
};

export type Pool_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  cumulativeFees?: Maybe<Scalars['BigInt']>;
  cumulativeFees_not?: Maybe<Scalars['BigInt']>;
  cumulativeFees_gt?: Maybe<Scalars['BigInt']>;
  cumulativeFees_lt?: Maybe<Scalars['BigInt']>;
  cumulativeFees_gte?: Maybe<Scalars['BigInt']>;
  cumulativeFees_lte?: Maybe<Scalars['BigInt']>;
  cumulativeFees_in?: Maybe<Array<Scalars['BigInt']>>;
  cumulativeFees_not_in?: Maybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<Pool_Filter>>>;
  or?: Maybe<Array<Maybe<Pool_Filter>>>;
};

export enum Pool_OrderBy {
  Id = 'id',
  CumulativeFees = 'cumulativeFees'
}

export type Position = {
  __typename?: 'Position';
  id: Scalars['ID'];
  productId: Scalars['Bytes'];
  leverage: Scalars['BigInt'];
  price: Scalars['BigInt'];
  margin: Scalars['BigInt'];
  fee: Scalars['BigInt'];
  size: Scalars['BigInt'];
  liquidationPrice: Scalars['BigInt'];
  user: Scalars['Bytes'];
  currency: Scalars['Bytes'];
  isLong: Scalars['Boolean'];
  createdAtTimestamp: Scalars['BigInt'];
  createdAtBlockNumber: Scalars['BigInt'];
  updatedAtTimestamp: Scalars['BigInt'];
  updatedAtBlockNumber: Scalars['BigInt'];
};

export type Position_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  productId?: Maybe<Scalars['Bytes']>;
  productId_not?: Maybe<Scalars['Bytes']>;
  productId_gt?: Maybe<Scalars['Bytes']>;
  productId_lt?: Maybe<Scalars['Bytes']>;
  productId_gte?: Maybe<Scalars['Bytes']>;
  productId_lte?: Maybe<Scalars['Bytes']>;
  productId_in?: Maybe<Array<Scalars['Bytes']>>;
  productId_not_in?: Maybe<Array<Scalars['Bytes']>>;
  productId_contains?: Maybe<Scalars['Bytes']>;
  productId_not_contains?: Maybe<Scalars['Bytes']>;
  leverage?: Maybe<Scalars['BigInt']>;
  leverage_not?: Maybe<Scalars['BigInt']>;
  leverage_gt?: Maybe<Scalars['BigInt']>;
  leverage_lt?: Maybe<Scalars['BigInt']>;
  leverage_gte?: Maybe<Scalars['BigInt']>;
  leverage_lte?: Maybe<Scalars['BigInt']>;
  leverage_in?: Maybe<Array<Scalars['BigInt']>>;
  leverage_not_in?: Maybe<Array<Scalars['BigInt']>>;
  price?: Maybe<Scalars['BigInt']>;
  price_not?: Maybe<Scalars['BigInt']>;
  price_gt?: Maybe<Scalars['BigInt']>;
  price_lt?: Maybe<Scalars['BigInt']>;
  price_gte?: Maybe<Scalars['BigInt']>;
  price_lte?: Maybe<Scalars['BigInt']>;
  price_in?: Maybe<Array<Scalars['BigInt']>>;
  price_not_in?: Maybe<Array<Scalars['BigInt']>>;
  margin?: Maybe<Scalars['BigInt']>;
  margin_not?: Maybe<Scalars['BigInt']>;
  margin_gt?: Maybe<Scalars['BigInt']>;
  margin_lt?: Maybe<Scalars['BigInt']>;
  margin_gte?: Maybe<Scalars['BigInt']>;
  margin_lte?: Maybe<Scalars['BigInt']>;
  margin_in?: Maybe<Array<Scalars['BigInt']>>;
  margin_not_in?: Maybe<Array<Scalars['BigInt']>>;
  fee?: Maybe<Scalars['BigInt']>;
  fee_not?: Maybe<Scalars['BigInt']>;
  fee_gt?: Maybe<Scalars['BigInt']>;
  fee_lt?: Maybe<Scalars['BigInt']>;
  fee_gte?: Maybe<Scalars['BigInt']>;
  fee_lte?: Maybe<Scalars['BigInt']>;
  fee_in?: Maybe<Array<Scalars['BigInt']>>;
  fee_not_in?: Maybe<Array<Scalars['BigInt']>>;
  size?: Maybe<Scalars['BigInt']>;
  size_not?: Maybe<Scalars['BigInt']>;
  size_gt?: Maybe<Scalars['BigInt']>;
  size_lt?: Maybe<Scalars['BigInt']>;
  size_gte?: Maybe<Scalars['BigInt']>;
  size_lte?: Maybe<Scalars['BigInt']>;
  size_in?: Maybe<Array<Scalars['BigInt']>>;
  size_not_in?: Maybe<Array<Scalars['BigInt']>>;
  liquidationPrice?: Maybe<Scalars['BigInt']>;
  liquidationPrice_not?: Maybe<Scalars['BigInt']>;
  liquidationPrice_gt?: Maybe<Scalars['BigInt']>;
  liquidationPrice_lt?: Maybe<Scalars['BigInt']>;
  liquidationPrice_gte?: Maybe<Scalars['BigInt']>;
  liquidationPrice_lte?: Maybe<Scalars['BigInt']>;
  liquidationPrice_in?: Maybe<Array<Scalars['BigInt']>>;
  liquidationPrice_not_in?: Maybe<Array<Scalars['BigInt']>>;
  user?: Maybe<Scalars['Bytes']>;
  user_not?: Maybe<Scalars['Bytes']>;
  user_gt?: Maybe<Scalars['Bytes']>;
  user_lt?: Maybe<Scalars['Bytes']>;
  user_gte?: Maybe<Scalars['Bytes']>;
  user_lte?: Maybe<Scalars['Bytes']>;
  user_in?: Maybe<Array<Scalars['Bytes']>>;
  user_not_in?: Maybe<Array<Scalars['Bytes']>>;
  user_contains?: Maybe<Scalars['Bytes']>;
  user_not_contains?: Maybe<Scalars['Bytes']>;
  currency?: Maybe<Scalars['Bytes']>;
  currency_not?: Maybe<Scalars['Bytes']>;
  currency_gt?: Maybe<Scalars['Bytes']>;
  currency_lt?: Maybe<Scalars['Bytes']>;
  currency_gte?: Maybe<Scalars['Bytes']>;
  currency_lte?: Maybe<Scalars['Bytes']>;
  currency_in?: Maybe<Array<Scalars['Bytes']>>;
  currency_not_in?: Maybe<Array<Scalars['Bytes']>>;
  currency_contains?: Maybe<Scalars['Bytes']>;
  currency_not_contains?: Maybe<Scalars['Bytes']>;
  isLong?: Maybe<Scalars['Boolean']>;
  isLong_not?: Maybe<Scalars['Boolean']>;
  isLong_in?: Maybe<Array<Scalars['Boolean']>>;
  isLong_not_in?: Maybe<Array<Scalars['Boolean']>>;
  createdAtTimestamp?: Maybe<Scalars['BigInt']>;
  createdAtTimestamp_not?: Maybe<Scalars['BigInt']>;
  createdAtTimestamp_gt?: Maybe<Scalars['BigInt']>;
  createdAtTimestamp_lt?: Maybe<Scalars['BigInt']>;
  createdAtTimestamp_gte?: Maybe<Scalars['BigInt']>;
  createdAtTimestamp_lte?: Maybe<Scalars['BigInt']>;
  createdAtTimestamp_in?: Maybe<Array<Scalars['BigInt']>>;
  createdAtTimestamp_not_in?: Maybe<Array<Scalars['BigInt']>>;
  createdAtBlockNumber?: Maybe<Scalars['BigInt']>;
  createdAtBlockNumber_not?: Maybe<Scalars['BigInt']>;
  createdAtBlockNumber_gt?: Maybe<Scalars['BigInt']>;
  createdAtBlockNumber_lt?: Maybe<Scalars['BigInt']>;
  createdAtBlockNumber_gte?: Maybe<Scalars['BigInt']>;
  createdAtBlockNumber_lte?: Maybe<Scalars['BigInt']>;
  createdAtBlockNumber_in?: Maybe<Array<Scalars['BigInt']>>;
  createdAtBlockNumber_not_in?: Maybe<Array<Scalars['BigInt']>>;
  updatedAtTimestamp?: Maybe<Scalars['BigInt']>;
  updatedAtTimestamp_not?: Maybe<Scalars['BigInt']>;
  updatedAtTimestamp_gt?: Maybe<Scalars['BigInt']>;
  updatedAtTimestamp_lt?: Maybe<Scalars['BigInt']>;
  updatedAtTimestamp_gte?: Maybe<Scalars['BigInt']>;
  updatedAtTimestamp_lte?: Maybe<Scalars['BigInt']>;
  updatedAtTimestamp_in?: Maybe<Array<Scalars['BigInt']>>;
  updatedAtTimestamp_not_in?: Maybe<Array<Scalars['BigInt']>>;
  updatedAtBlockNumber?: Maybe<Scalars['BigInt']>;
  updatedAtBlockNumber_not?: Maybe<Scalars['BigInt']>;
  updatedAtBlockNumber_gt?: Maybe<Scalars['BigInt']>;
  updatedAtBlockNumber_lt?: Maybe<Scalars['BigInt']>;
  updatedAtBlockNumber_gte?: Maybe<Scalars['BigInt']>;
  updatedAtBlockNumber_lte?: Maybe<Scalars['BigInt']>;
  updatedAtBlockNumber_in?: Maybe<Array<Scalars['BigInt']>>;
  updatedAtBlockNumber_not_in?: Maybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<Position_Filter>>>;
  or?: Maybe<Array<Maybe<Position_Filter>>>;
};

export enum Position_OrderBy {
  Id = 'id',
  ProductId = 'productId',
  Leverage = 'leverage',
  Price = 'price',
  Margin = 'margin',
  Fee = 'fee',
  Size = 'size',
  LiquidationPrice = 'liquidationPrice',
  User = 'user',
  Currency = 'currency',
  IsLong = 'isLong',
  CreatedAtTimestamp = 'createdAtTimestamp',
  CreatedAtBlockNumber = 'createdAtBlockNumber',
  UpdatedAtTimestamp = 'updatedAtTimestamp',
  UpdatedAtBlockNumber = 'updatedAtBlockNumber'
}

export type Product = {
  __typename?: 'Product';
  id: Scalars['ID'];
  cumulativeFees: Scalars['BigInt'];
  cumulativePnl: Scalars['BigInt'];
  cumulativeVolume: Scalars['BigInt'];
  cumulativeMargin: Scalars['BigInt'];
  positionCount: Scalars['BigInt'];
  tradeCount: Scalars['BigInt'];
  openInterest: Scalars['BigInt'];
  openInterestLong: Scalars['BigInt'];
  openInterestShort: Scalars['BigInt'];
};

export type Product_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  cumulativeFees?: Maybe<Scalars['BigInt']>;
  cumulativeFees_not?: Maybe<Scalars['BigInt']>;
  cumulativeFees_gt?: Maybe<Scalars['BigInt']>;
  cumulativeFees_lt?: Maybe<Scalars['BigInt']>;
  cumulativeFees_gte?: Maybe<Scalars['BigInt']>;
  cumulativeFees_lte?: Maybe<Scalars['BigInt']>;
  cumulativeFees_in?: Maybe<Array<Scalars['BigInt']>>;
  cumulativeFees_not_in?: Maybe<Array<Scalars['BigInt']>>;
  cumulativePnl?: Maybe<Scalars['BigInt']>;
  cumulativePnl_not?: Maybe<Scalars['BigInt']>;
  cumulativePnl_gt?: Maybe<Scalars['BigInt']>;
  cumulativePnl_lt?: Maybe<Scalars['BigInt']>;
  cumulativePnl_gte?: Maybe<Scalars['BigInt']>;
  cumulativePnl_lte?: Maybe<Scalars['BigInt']>;
  cumulativePnl_in?: Maybe<Array<Scalars['BigInt']>>;
  cumulativePnl_not_in?: Maybe<Array<Scalars['BigInt']>>;
  cumulativeVolume?: Maybe<Scalars['BigInt']>;
  cumulativeVolume_not?: Maybe<Scalars['BigInt']>;
  cumulativeVolume_gt?: Maybe<Scalars['BigInt']>;
  cumulativeVolume_lt?: Maybe<Scalars['BigInt']>;
  cumulativeVolume_gte?: Maybe<Scalars['BigInt']>;
  cumulativeVolume_lte?: Maybe<Scalars['BigInt']>;
  cumulativeVolume_in?: Maybe<Array<Scalars['BigInt']>>;
  cumulativeVolume_not_in?: Maybe<Array<Scalars['BigInt']>>;
  cumulativeMargin?: Maybe<Scalars['BigInt']>;
  cumulativeMargin_not?: Maybe<Scalars['BigInt']>;
  cumulativeMargin_gt?: Maybe<Scalars['BigInt']>;
  cumulativeMargin_lt?: Maybe<Scalars['BigInt']>;
  cumulativeMargin_gte?: Maybe<Scalars['BigInt']>;
  cumulativeMargin_lte?: Maybe<Scalars['BigInt']>;
  cumulativeMargin_in?: Maybe<Array<Scalars['BigInt']>>;
  cumulativeMargin_not_in?: Maybe<Array<Scalars['BigInt']>>;
  positionCount?: Maybe<Scalars['BigInt']>;
  positionCount_not?: Maybe<Scalars['BigInt']>;
  positionCount_gt?: Maybe<Scalars['BigInt']>;
  positionCount_lt?: Maybe<Scalars['BigInt']>;
  positionCount_gte?: Maybe<Scalars['BigInt']>;
  positionCount_lte?: Maybe<Scalars['BigInt']>;
  positionCount_in?: Maybe<Array<Scalars['BigInt']>>;
  positionCount_not_in?: Maybe<Array<Scalars['BigInt']>>;
  tradeCount?: Maybe<Scalars['BigInt']>;
  tradeCount_not?: Maybe<Scalars['BigInt']>;
  tradeCount_gt?: Maybe<Scalars['BigInt']>;
  tradeCount_lt?: Maybe<Scalars['BigInt']>;
  tradeCount_gte?: Maybe<Scalars['BigInt']>;
  tradeCount_lte?: Maybe<Scalars['BigInt']>;
  tradeCount_in?: Maybe<Array<Scalars['BigInt']>>;
  tradeCount_not_in?: Maybe<Array<Scalars['BigInt']>>;
  openInterest?: Maybe<Scalars['BigInt']>;
  openInterest_not?: Maybe<Scalars['BigInt']>;
  openInterest_gt?: Maybe<Scalars['BigInt']>;
  openInterest_lt?: Maybe<Scalars['BigInt']>;
  openInterest_gte?: Maybe<Scalars['BigInt']>;
  openInterest_lte?: Maybe<Scalars['BigInt']>;
  openInterest_in?: Maybe<Array<Scalars['BigInt']>>;
  openInterest_not_in?: Maybe<Array<Scalars['BigInt']>>;
  openInterestLong?: Maybe<Scalars['BigInt']>;
  openInterestLong_not?: Maybe<Scalars['BigInt']>;
  openInterestLong_gt?: Maybe<Scalars['BigInt']>;
  openInterestLong_lt?: Maybe<Scalars['BigInt']>;
  openInterestLong_gte?: Maybe<Scalars['BigInt']>;
  openInterestLong_lte?: Maybe<Scalars['BigInt']>;
  openInterestLong_in?: Maybe<Array<Scalars['BigInt']>>;
  openInterestLong_not_in?: Maybe<Array<Scalars['BigInt']>>;
  openInterestShort?: Maybe<Scalars['BigInt']>;
  openInterestShort_not?: Maybe<Scalars['BigInt']>;
  openInterestShort_gt?: Maybe<Scalars['BigInt']>;
  openInterestShort_lt?: Maybe<Scalars['BigInt']>;
  openInterestShort_gte?: Maybe<Scalars['BigInt']>;
  openInterestShort_lte?: Maybe<Scalars['BigInt']>;
  openInterestShort_in?: Maybe<Array<Scalars['BigInt']>>;
  openInterestShort_not_in?: Maybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<Product_Filter>>>;
  or?: Maybe<Array<Maybe<Product_Filter>>>;
};

export enum Product_OrderBy {
  Id = 'id',
  CumulativeFees = 'cumulativeFees',
  CumulativePnl = 'cumulativePnl',
  CumulativeVolume = 'cumulativeVolume',
  CumulativeMargin = 'cumulativeMargin',
  PositionCount = 'positionCount',
  TradeCount = 'tradeCount',
  OpenInterest = 'openInterest',
  OpenInterestLong = 'openInterestLong',
  OpenInterestShort = 'openInterestShort'
}

export type Query = {
  __typename?: 'Query';
  data?: Maybe<Data>;
  datas: Array<Data>;
  dayData?: Maybe<DayData>;
  dayDatas: Array<DayData>;
  product?: Maybe<Product>;
  products: Array<Product>;
  position?: Maybe<Position>;
  positions: Array<Position>;
  trade?: Maybe<Trade>;
  trades: Array<Trade>;
  pool?: Maybe<Pool>;
  pools: Array<Pool>;
  order?: Maybe<Order>;
  orders: Array<Order>;
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>;
};


export type QueryDataArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryDatasArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Data_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Data_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryDayDataArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryDayDatasArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<DayData_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<DayData_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryProductArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryProductsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Product_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Product_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryPositionArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryPositionsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Position_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Position_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryTradeArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryTradesArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Trade_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Trade_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryPoolArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryPoolsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Pool_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Pool_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryOrderArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryOrdersArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Order_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Order_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type Query_MetaArgs = {
  block?: Maybe<Block_Height>;
};

export type Subscription = {
  __typename?: 'Subscription';
  data?: Maybe<Data>;
  datas: Array<Data>;
  dayData?: Maybe<DayData>;
  dayDatas: Array<DayData>;
  product?: Maybe<Product>;
  products: Array<Product>;
  position?: Maybe<Position>;
  positions: Array<Position>;
  trade?: Maybe<Trade>;
  trades: Array<Trade>;
  pool?: Maybe<Pool>;
  pools: Array<Pool>;
  order?: Maybe<Order>;
  orders: Array<Order>;
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>;
};


export type SubscriptionDataArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionDatasArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Data_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Data_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionDayDataArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionDayDatasArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<DayData_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<DayData_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionProductArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionProductsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Product_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Product_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionPositionArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionPositionsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Position_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Position_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionTradeArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionTradesArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Trade_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Trade_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionPoolArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionPoolsArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Pool_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Pool_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionOrderArgs = {
  id: Scalars['ID'];
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionOrdersArgs = {
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Order_OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  where?: Maybe<Order_Filter>;
  block?: Maybe<Block_Height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type Subscription_MetaArgs = {
  block?: Maybe<Block_Height>;
};

export type Trade = {
  __typename?: 'Trade';
  id: Scalars['ID'];
  txHash: Scalars['String'];
  positionKey: Scalars['String'];
  user: Scalars['Bytes'];
  currency: Scalars['Bytes'];
  productId: Scalars['Bytes'];
  margin: Scalars['BigInt'];
  leverage: Scalars['BigInt'];
  size: Scalars['BigInt'];
  entryPrice: Scalars['BigInt'];
  closePrice: Scalars['BigInt'];
  isLong: Scalars['Boolean'];
  fee: Scalars['BigInt'];
  pnl?: Maybe<Scalars['BigInt']>;
  wasLiquidated: Scalars['Boolean'];
  isFullClose: Scalars['Boolean'];
  duration: Scalars['BigInt'];
  timestamp: Scalars['BigInt'];
  blockNumber: Scalars['BigInt'];
};

export type Trade_Filter = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_lt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  txHash?: Maybe<Scalars['String']>;
  txHash_not?: Maybe<Scalars['String']>;
  txHash_gt?: Maybe<Scalars['String']>;
  txHash_lt?: Maybe<Scalars['String']>;
  txHash_gte?: Maybe<Scalars['String']>;
  txHash_lte?: Maybe<Scalars['String']>;
  txHash_in?: Maybe<Array<Scalars['String']>>;
  txHash_not_in?: Maybe<Array<Scalars['String']>>;
  txHash_contains?: Maybe<Scalars['String']>;
  txHash_contains_nocase?: Maybe<Scalars['String']>;
  txHash_not_contains?: Maybe<Scalars['String']>;
  txHash_not_contains_nocase?: Maybe<Scalars['String']>;
  txHash_starts_with?: Maybe<Scalars['String']>;
  txHash_starts_with_nocase?: Maybe<Scalars['String']>;
  txHash_not_starts_with?: Maybe<Scalars['String']>;
  txHash_not_starts_with_nocase?: Maybe<Scalars['String']>;
  txHash_ends_with?: Maybe<Scalars['String']>;
  txHash_ends_with_nocase?: Maybe<Scalars['String']>;
  txHash_not_ends_with?: Maybe<Scalars['String']>;
  txHash_not_ends_with_nocase?: Maybe<Scalars['String']>;
  positionKey?: Maybe<Scalars['String']>;
  positionKey_not?: Maybe<Scalars['String']>;
  positionKey_gt?: Maybe<Scalars['String']>;
  positionKey_lt?: Maybe<Scalars['String']>;
  positionKey_gte?: Maybe<Scalars['String']>;
  positionKey_lte?: Maybe<Scalars['String']>;
  positionKey_in?: Maybe<Array<Scalars['String']>>;
  positionKey_not_in?: Maybe<Array<Scalars['String']>>;
  positionKey_contains?: Maybe<Scalars['String']>;
  positionKey_contains_nocase?: Maybe<Scalars['String']>;
  positionKey_not_contains?: Maybe<Scalars['String']>;
  positionKey_not_contains_nocase?: Maybe<Scalars['String']>;
  positionKey_starts_with?: Maybe<Scalars['String']>;
  positionKey_starts_with_nocase?: Maybe<Scalars['String']>;
  positionKey_not_starts_with?: Maybe<Scalars['String']>;
  positionKey_not_starts_with_nocase?: Maybe<Scalars['String']>;
  positionKey_ends_with?: Maybe<Scalars['String']>;
  positionKey_ends_with_nocase?: Maybe<Scalars['String']>;
  positionKey_not_ends_with?: Maybe<Scalars['String']>;
  positionKey_not_ends_with_nocase?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['Bytes']>;
  user_not?: Maybe<Scalars['Bytes']>;
  user_gt?: Maybe<Scalars['Bytes']>;
  user_lt?: Maybe<Scalars['Bytes']>;
  user_gte?: Maybe<Scalars['Bytes']>;
  user_lte?: Maybe<Scalars['Bytes']>;
  user_in?: Maybe<Array<Scalars['Bytes']>>;
  user_not_in?: Maybe<Array<Scalars['Bytes']>>;
  user_contains?: Maybe<Scalars['Bytes']>;
  user_not_contains?: Maybe<Scalars['Bytes']>;
  currency?: Maybe<Scalars['Bytes']>;
  currency_not?: Maybe<Scalars['Bytes']>;
  currency_gt?: Maybe<Scalars['Bytes']>;
  currency_lt?: Maybe<Scalars['Bytes']>;
  currency_gte?: Maybe<Scalars['Bytes']>;
  currency_lte?: Maybe<Scalars['Bytes']>;
  currency_in?: Maybe<Array<Scalars['Bytes']>>;
  currency_not_in?: Maybe<Array<Scalars['Bytes']>>;
  currency_contains?: Maybe<Scalars['Bytes']>;
  currency_not_contains?: Maybe<Scalars['Bytes']>;
  productId?: Maybe<Scalars['Bytes']>;
  productId_not?: Maybe<Scalars['Bytes']>;
  productId_gt?: Maybe<Scalars['Bytes']>;
  productId_lt?: Maybe<Scalars['Bytes']>;
  productId_gte?: Maybe<Scalars['Bytes']>;
  productId_lte?: Maybe<Scalars['Bytes']>;
  productId_in?: Maybe<Array<Scalars['Bytes']>>;
  productId_not_in?: Maybe<Array<Scalars['Bytes']>>;
  productId_contains?: Maybe<Scalars['Bytes']>;
  productId_not_contains?: Maybe<Scalars['Bytes']>;
  margin?: Maybe<Scalars['BigInt']>;
  margin_not?: Maybe<Scalars['BigInt']>;
  margin_gt?: Maybe<Scalars['BigInt']>;
  margin_lt?: Maybe<Scalars['BigInt']>;
  margin_gte?: Maybe<Scalars['BigInt']>;
  margin_lte?: Maybe<Scalars['BigInt']>;
  margin_in?: Maybe<Array<Scalars['BigInt']>>;
  margin_not_in?: Maybe<Array<Scalars['BigInt']>>;
  leverage?: Maybe<Scalars['BigInt']>;
  leverage_not?: Maybe<Scalars['BigInt']>;
  leverage_gt?: Maybe<Scalars['BigInt']>;
  leverage_lt?: Maybe<Scalars['BigInt']>;
  leverage_gte?: Maybe<Scalars['BigInt']>;
  leverage_lte?: Maybe<Scalars['BigInt']>;
  leverage_in?: Maybe<Array<Scalars['BigInt']>>;
  leverage_not_in?: Maybe<Array<Scalars['BigInt']>>;
  size?: Maybe<Scalars['BigInt']>;
  size_not?: Maybe<Scalars['BigInt']>;
  size_gt?: Maybe<Scalars['BigInt']>;
  size_lt?: Maybe<Scalars['BigInt']>;
  size_gte?: Maybe<Scalars['BigInt']>;
  size_lte?: Maybe<Scalars['BigInt']>;
  size_in?: Maybe<Array<Scalars['BigInt']>>;
  size_not_in?: Maybe<Array<Scalars['BigInt']>>;
  entryPrice?: Maybe<Scalars['BigInt']>;
  entryPrice_not?: Maybe<Scalars['BigInt']>;
  entryPrice_gt?: Maybe<Scalars['BigInt']>;
  entryPrice_lt?: Maybe<Scalars['BigInt']>;
  entryPrice_gte?: Maybe<Scalars['BigInt']>;
  entryPrice_lte?: Maybe<Scalars['BigInt']>;
  entryPrice_in?: Maybe<Array<Scalars['BigInt']>>;
  entryPrice_not_in?: Maybe<Array<Scalars['BigInt']>>;
  closePrice?: Maybe<Scalars['BigInt']>;
  closePrice_not?: Maybe<Scalars['BigInt']>;
  closePrice_gt?: Maybe<Scalars['BigInt']>;
  closePrice_lt?: Maybe<Scalars['BigInt']>;
  closePrice_gte?: Maybe<Scalars['BigInt']>;
  closePrice_lte?: Maybe<Scalars['BigInt']>;
  closePrice_in?: Maybe<Array<Scalars['BigInt']>>;
  closePrice_not_in?: Maybe<Array<Scalars['BigInt']>>;
  isLong?: Maybe<Scalars['Boolean']>;
  isLong_not?: Maybe<Scalars['Boolean']>;
  isLong_in?: Maybe<Array<Scalars['Boolean']>>;
  isLong_not_in?: Maybe<Array<Scalars['Boolean']>>;
  fee?: Maybe<Scalars['BigInt']>;
  fee_not?: Maybe<Scalars['BigInt']>;
  fee_gt?: Maybe<Scalars['BigInt']>;
  fee_lt?: Maybe<Scalars['BigInt']>;
  fee_gte?: Maybe<Scalars['BigInt']>;
  fee_lte?: Maybe<Scalars['BigInt']>;
  fee_in?: Maybe<Array<Scalars['BigInt']>>;
  fee_not_in?: Maybe<Array<Scalars['BigInt']>>;
  pnl?: Maybe<Scalars['BigInt']>;
  pnl_not?: Maybe<Scalars['BigInt']>;
  pnl_gt?: Maybe<Scalars['BigInt']>;
  pnl_lt?: Maybe<Scalars['BigInt']>;
  pnl_gte?: Maybe<Scalars['BigInt']>;
  pnl_lte?: Maybe<Scalars['BigInt']>;
  pnl_in?: Maybe<Array<Scalars['BigInt']>>;
  pnl_not_in?: Maybe<Array<Scalars['BigInt']>>;
  wasLiquidated?: Maybe<Scalars['Boolean']>;
  wasLiquidated_not?: Maybe<Scalars['Boolean']>;
  wasLiquidated_in?: Maybe<Array<Scalars['Boolean']>>;
  wasLiquidated_not_in?: Maybe<Array<Scalars['Boolean']>>;
  isFullClose?: Maybe<Scalars['Boolean']>;
  isFullClose_not?: Maybe<Scalars['Boolean']>;
  isFullClose_in?: Maybe<Array<Scalars['Boolean']>>;
  isFullClose_not_in?: Maybe<Array<Scalars['Boolean']>>;
  duration?: Maybe<Scalars['BigInt']>;
  duration_not?: Maybe<Scalars['BigInt']>;
  duration_gt?: Maybe<Scalars['BigInt']>;
  duration_lt?: Maybe<Scalars['BigInt']>;
  duration_gte?: Maybe<Scalars['BigInt']>;
  duration_lte?: Maybe<Scalars['BigInt']>;
  duration_in?: Maybe<Array<Scalars['BigInt']>>;
  duration_not_in?: Maybe<Array<Scalars['BigInt']>>;
  timestamp?: Maybe<Scalars['BigInt']>;
  timestamp_not?: Maybe<Scalars['BigInt']>;
  timestamp_gt?: Maybe<Scalars['BigInt']>;
  timestamp_lt?: Maybe<Scalars['BigInt']>;
  timestamp_gte?: Maybe<Scalars['BigInt']>;
  timestamp_lte?: Maybe<Scalars['BigInt']>;
  timestamp_in?: Maybe<Array<Scalars['BigInt']>>;
  timestamp_not_in?: Maybe<Array<Scalars['BigInt']>>;
  blockNumber?: Maybe<Scalars['BigInt']>;
  blockNumber_not?: Maybe<Scalars['BigInt']>;
  blockNumber_gt?: Maybe<Scalars['BigInt']>;
  blockNumber_lt?: Maybe<Scalars['BigInt']>;
  blockNumber_gte?: Maybe<Scalars['BigInt']>;
  blockNumber_lte?: Maybe<Scalars['BigInt']>;
  blockNumber_in?: Maybe<Array<Scalars['BigInt']>>;
  blockNumber_not_in?: Maybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: Maybe<BlockChangedFilter>;
  and?: Maybe<Array<Maybe<Trade_Filter>>>;
  or?: Maybe<Array<Maybe<Trade_Filter>>>;
};

export enum Trade_OrderBy {
  Id = 'id',
  TxHash = 'txHash',
  PositionKey = 'positionKey',
  User = 'user',
  Currency = 'currency',
  ProductId = 'productId',
  Margin = 'margin',
  Leverage = 'leverage',
  Size = 'size',
  EntryPrice = 'entryPrice',
  ClosePrice = 'closePrice',
  IsLong = 'isLong',
  Fee = 'fee',
  Pnl = 'pnl',
  WasLiquidated = 'wasLiquidated',
  IsFullClose = 'isFullClose',
  Duration = 'duration',
  Timestamp = 'timestamp',
  BlockNumber = 'blockNumber'
}

export type _Block_ = {
  __typename?: '_Block_';
  /** The hash of the block */
  hash?: Maybe<Scalars['Bytes']>;
  /** The block number */
  number: Scalars['Int'];
  /** Integer representation of the timestamp stored in blocks for the chain */
  timestamp?: Maybe<Scalars['Int']>;
};

/** The type for the top-level _meta field */
export type _Meta_ = {
  __typename?: '_Meta_';
  /**
   * Information about a specific subgraph block. The hash of the block
   * will be null if the _meta field has a block constraint that asks for
   * a block number. It will be filled if the _meta field has no block constraint
   * and therefore asks for the latest  block
   */
  block: _Block_;
  /** The deployment ID */
  deployment: Scalars['String'];
  /** If `true`, the subgraph encountered indexing errors at some past block */
  hasIndexingErrors: Scalars['Boolean'];
};

export enum _SubgraphErrorPolicy_ {
  /** Data will be returned even if the subgraph has indexing errors */
  Allow = 'allow',
  /** If the subgraph has indexing errors, data will be omitted. The default. */
  Deny = 'deny'
}

export type GetPoolInfoQueryVariables = Exact<{
  tokenAddress?: Maybe<Scalars['ID']>;
  chainId?: Maybe<Scalars['Int']>;
}>;


export type GetPoolInfoQuery = (
  { __typename?: 'Query' }
  & { datas: Array<(
    { __typename?: 'Data' }
    & Pick<Data, 'id' | 'cumulativeFees' | 'cumulativePnl' | 'cumulativeVolume' | 'cumulativeMargin' | 'openInterest' | 'openInterestLong' | 'openInterestShort' | 'positionCount' | 'tradeCount'>
  )> }
);

export type GetUserHistoryQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  account?: Maybe<Scalars['Bytes']>;
  chainId?: Maybe<Scalars['Int']>;
}>;


export type GetUserHistoryQuery = (
  { __typename?: 'Query' }
  & { trades: Array<(
    { __typename?: 'Trade' }
    & Pick<Trade, 'id' | 'txHash' | 'positionKey' | 'productId' | 'currency' | 'margin' | 'leverage' | 'size' | 'entryPrice' | 'closePrice' | 'isLong' | 'pnl' | 'fee' | 'timestamp' | 'blockNumber' | 'wasLiquidated' | 'isFullClose'>
  )> }
);

export type GetPositionsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  account?: Maybe<Scalars['Bytes']>;
  chainId?: Maybe<Scalars['Int']>;
}>;


export type GetPositionsQuery = (
  { __typename?: 'Query' }
  & { positions: Array<(
    { __typename?: 'Position' }
    & Pick<Position, 'id' | 'productId' | 'currency' | 'margin' | 'fee' | 'size' | 'leverage' | 'price' | 'isLong' | 'createdAtTimestamp'>
  )> }
);

export type GetOrdersQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  account?: Maybe<Scalars['Bytes']>;
  chainId?: Maybe<Scalars['Int']>;
}>;


export type GetOrdersQuery = (
  { __typename?: 'Query' }
  & { orders: Array<(
    { __typename?: 'Order' }
    & Pick<Order, 'id' | 'productId' | 'currency' | 'margin' | 'size' | 'isClose' | 'isLong' | 'createdAtTimestamp'>
  )> }
);

export type GetVolumeQueryVariables = Exact<{
  chainId?: Maybe<Scalars['Int']>;
}>;


export type GetVolumeQuery = (
  { __typename?: 'Query' }
  & { datas: Array<(
    { __typename?: 'Data' }
    & Pick<Data, 'id' | 'cumulativeVolume'>
  )> }
);


export const GetPoolInfoDocument = `
    query getPoolInfo($tokenAddress: ID, $chainId: Int) {
  datas(where: {id: $tokenAddress}) {
    id
    cumulativeFees
    cumulativePnl
    cumulativeVolume
    cumulativeMargin
    openInterest
    openInterestLong
    openInterestShort
    positionCount
    tradeCount
  }
}
    `;
export const GetUserHistoryDocument = `
    query getUserHistory($first: Int, $skip: Int, $account: Bytes, $chainId: Int) {
  trades(
    orderBy: timestamp
    orderDirection: desc
    first: $first
    skip: $skip
    where: {user: $account}
  ) {
    id
    txHash
    positionKey
    productId
    currency
    margin
    leverage
    size
    entryPrice
    closePrice
    isLong
    pnl
    fee
    timestamp
    blockNumber
    wasLiquidated
    isFullClose
  }
}
    `;
export const GetPositionsDocument = `
    query getPositions($first: Int, $account: Bytes, $chainId: Int) {
  positions(
    orderBy: createdAtTimestamp
    orderDirection: desc
    first: $first
    where: {user: $account}
  ) {
    id
    productId
    currency
    margin
    fee
    size
    leverage
    price
    isLong
    createdAtTimestamp
  }
}
    `;
export const GetOrdersDocument = `
    query getOrders($first: Int, $account: Bytes, $chainId: Int) {
  orders(
    orderBy: createdAtTimestamp
    orderDirection: desc
    first: $first
    where: {user: $account}
  ) {
    id
    productId
    currency
    margin
    size
    isClose
    isLong
    createdAtTimestamp
  }
}
    `;
export const GetVolumeDocument = `
    query getVolume($chainId: Int) {
  datas(first: 2) {
    id
    cumulativeVolume
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPoolInfo: build.query<GetPoolInfoQuery, GetPoolInfoQueryVariables | void>({
      query: (variables) => ({ document: GetPoolInfoDocument, variables })
    }),
    getUserHistory: build.query<GetUserHistoryQuery, GetUserHistoryQueryVariables | void>({
      query: (variables) => ({ document: GetUserHistoryDocument, variables })
    }),
    getPositions: build.query<GetPositionsQuery, GetPositionsQueryVariables | void>({
      query: (variables) => ({ document: GetPositionsDocument, variables })
    }),
    getOrders: build.query<GetOrdersQuery, GetOrdersQueryVariables | void>({
      query: (variables) => ({ document: GetOrdersDocument, variables })
    }),
    getVolume: build.query<GetVolumeQuery, GetVolumeQueryVariables | void>({
      query: (variables) => ({ document: GetVolumeDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetPoolInfoQuery, useLazyGetPoolInfoQuery, useGetUserHistoryQuery, useLazyGetUserHistoryQuery, useGetPositionsQuery, useLazyGetPositionsQuery, useGetOrdersQuery, useLazyGetOrdersQuery, useGetVolumeQuery, useLazyGetVolumeQuery } = injectedRtkApi;


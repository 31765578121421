import { formatBytes32String } from '@ethersproject/strings'
import { Trans } from '@lingui/macro'
import { ButtonGray, ButtonPrimary } from 'components/Button'
import CandleChart from 'components/CandleChart'
import CandleProvider, { CANDLE_STATUS, RESOLUTIONS } from 'components/CandleProvider'
import ChainWarning from 'components/ChainWarning'
import ChooseProductModal from 'components/ChooseProductModal'
import Column from 'components/Column'
import ContractHeader from 'components/ContractHeader'
import DiscordWidget from 'components/DiscordWidget'
import FarmAdvert from 'components/FarmAdvert'
import FixedTradeFooter from 'components/FixedTradeFooter'
import GetCardModal from 'components/GetCardModal'
import InfoWidget from 'components/InfoWidget'
import KeeperProvider from 'components/LedgerProvider'
import MarketTrades from 'components/MarketTrades'
import OrderBook from 'components/OrderBook'
import PositionsWidget from 'components/PositionsWidget'
import PriceProvider, { PRICE_STATUS } from 'components/PriceProvider'
import ProductProvider from 'components/ProductProvider'
import { ReferralCtx } from 'components/ReferralProvider'
import Row, { RowBetween } from 'components/Row'
import dummyDataSeries from 'components/SimpleChart/dummyDataSeries'
import SimpleChartTwo from 'components/SimpleChart/SimpleChartTwo'
import TradeManager from 'components/TradeManager'
import TransactionConfirmationModal, { ConfirmationModalContent } from 'components/TransactionConfirmationModal'
import { allCards, GridCard } from 'constants/cards'
import { PERP_CHAIN_IDS, SupportedChainId } from 'constants/chains'
import { checkSupport, Features } from 'constants/dapp'
import { ZERO_ADDRESS } from 'constants/misc'
import { Collateral, PERP_PRODUCTS, Product } from 'constants/perps'
import { parseUnits } from 'ethers/lib/utils'
import { TransactionResponse } from 'ethers/node_modules/@ethersproject/providers'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { ApprovalState, useApproveCallback } from 'hooks/useApproveCallback'
import useWindowWidth from 'hooks/useDebouncedWindowSize'
import usePrevious from 'hooks/usePrevious'
import usePreviousConditional from 'hooks/usePreviousConditional'
import tryParseCurrencyAmount from 'lib/utils/tryParseCurrencyAmount'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import GridLayout, { Layout, Responsive, WidthProvider } from 'react-grid-layout'
import { useLocation } from 'react-router'
import { RouteComponentProps } from 'react-router-dom'
import { useAppSelector } from 'state/hooks'
import { Order, usePerpPosRouterContract, useProduct, useTradingContract } from 'state/perpetuals/hooks'
import { TransactionType } from 'state/transactions/actions'
import { useTransactionAdder } from 'state/transactions/hooks'
import styled, { useTheme } from 'styled-components/macro'
import { MEDIA_WIDTHS, ThemedText } from 'theme'
import { objMap } from 'utils'
import { getChainIdFromName } from 'utils/getParsedChainId'
import { replaceURLParam } from 'utils/routes'
import simpleBeautifyNumber from 'utils/simpleBeautifyNumber'

import { SwitchLocaleLink } from '../../components/SwitchLocaleLink'
import { useStoredLayoutManager } from '../../state/user/hooks'
import CardWrapper from './CardWrapper'

const Separator = styled.div`
  height: 1px !important;
  width: 100%;
  background-color: ${({ theme }) => theme.bg3};
`

const breakpoints = {
  lg: MEDIA_WIDTHS.upToLarge - 23,
  md: 960 - 23,
  sm: 0,
}

const cols = {
  sm: 1,
  md: 12,
  lg: 24,
}

function bottom(layout: Layout[]): number {
  let max = 0,
    bottomY
  for (let i = 0, len = layout.length; i < len; i++) {
    bottomY = layout[i].y + layout[i].h
    if (bottomY > max) max = bottomY
  }
  return max
}

function IntermediateLayout(props: any) {
  const [breakpoint, setBreakpoint] = useState('')
  const [storedLayouts] = useStoredLayoutManager()
  useEffect(
    () => {
      const newBreakpoint = (Responsive as any).utils.getBreakpointFromWidth(breakpoints, props.width)
      if (newBreakpoint !== breakpoint) {
        setBreakpoint(newBreakpoint)
        const newItems = storedLayouts[newBreakpoint].reduce((a, b, i) => {
          return { ...a, [b.i]: b.w && b.h ? { i } : undefined }
        })

        props.onBreakpointChange({
          breakpoint: newBreakpoint,
          items: newItems,
        })
      }
    },
    // eslint-disable-next-line
    [props.width]
  )
  return <Responsive {...props} />
}

const ResponsiveGridLayout = WidthProvider(IntermediateLayout)

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

export default function TradePage({ history }: RouteComponentProps) {
  const query = useQuery()
  const contractFromQuery = query.getAll('contract')[0]
  const chainFromQuery = getChainIdFromName(query.getAll('chain')[0])
  const { account, chainId } = useActiveWeb3React()
  const addTransaction = useTransactionAdder()
  const activeProducts = useAppSelector((state) => state.perpetuals.activeProducts)
  const [width, breakpoint] = useWindowWidth(200)
  const { referralCode } = useContext(ReferralCtx)
  // const loadedUrlParams = useDefaultsFromURLSearch()

  const safeChainId = useMemo(() => {
    return chainId && PERP_CHAIN_IDS.indexOf(chainId) >= 0
      ? chainId
      : chainFromQuery && PERP_CHAIN_IDS.indexOf(chainFromQuery as number) >= 0
      ? chainFromQuery
      : SupportedChainId.OPTIMISM
  }, [chainFromQuery, chainId])

  // Theme utils
  const { white, text1, bg3, green1, red2 } = useTheme()

  // Exchange state
  const [productId, setProductId] = useState('btc')
  useEffect(() => {
    if (!contractFromQuery) {
      history.replace({
        search: replaceURLParam(history.location.search, 'contract', productId),
      })
    } else {
      if (PERP_PRODUCTS[safeChainId][contractFromQuery]) setProductId(contractFromQuery)
    }
  }, [])

  useEffect(() => {
    history.replace({
      search: replaceURLParam(history.location.search, 'contract', productId),
    })
  }, [productId])
  const [products, productInfo] = useMemo(() => {
    return [
      Object.values(PERP_PRODUCTS[safeChainId]),
      PERP_PRODUCTS[safeChainId][productId] || PERP_PRODUCTS[SupportedChainId.OPTIMISM].eth,
    ]
  }, [productId, safeChainId])
  const contract = useTradingContract()
  const posContract = usePerpPosRouterContract()
  const [productDetailsLoaded, productDetailsRaw] = useProduct(productInfo.bytes32Id)
  const productDetails = usePreviousConditional(productDetailsRaw, productDetailsLoaded)

  const { fee, interest, liquidationThreshold, maxLeverage } = productDetails || productDetailsRaw

  const [selectedCollateral, setSelectedCollateral] = useState(productInfo.collaterals[0])
  const [chooseProductOpen, setChooseProductOpen] = useState(false)

  useEffect(() => {
    setSelectedCollateral(productInfo.collaterals[0])
  }, [safeChainId, productInfo])

  const [value, setValue] = useState('')

  const [approval, approveCallback] = useApproveCallback(
    tryParseCurrencyAmount(value, selectedCollateral.token) ?? undefined,
    contract?.address
  )

  const [quantity, setQuantity] = useState('')
  const [liqPrice, setLiqPrice] = useState(['-', '-'])
  const [orderDirection, setOrderDirection] = useState(true) // true: LONG, false: SHORT
  const [leverage, setLeverage] = useState(5)
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false) // clicked confirm
  const [txHash, setTxHash] = useState<string>('')
  const previousHash = usePrevious(txHash) || ''
  const [priceStatus, setPriceStatus] = useState(PRICE_STATUS.UNINITIALIZED)
  const [candleStatus, setCandleStatus] = useState(CANDLE_STATUS.UNINITIALIZED)
  const [stateOrders, setStateOrders] = useState<Order[]>([])

  const handleStateOrders = (orders: Order[]) => {
    setStateOrders(orders)
  }

  // Layout state
  const [storedLayouts, updateStoredLayouts] = useStoredLayoutManager()

  const memoisedLayout = useMemo(() => {
    return JSON.parse(JSON.stringify(storedLayouts))
  }, [storedLayouts])

  const [currentLayout, setCurrentLayout] = useState<{
    breakpoint: string
    items: { [k: string]: { i: number } | undefined }
  }>({ breakpoint: '', items: {} })

  const [settingsModal, setSettingsModal] = useState(false)
  const [orderModal, setOrderModal] = useState(false)

  const [drawer, setDrawer] = useState(false)

  // Exchange functions & effects
  const handleProductChange = (newProduct: string) => {
    if (productId !== newProduct) {
      setProductId(newProduct)
      setChooseProductOpen(false)
    }
  }

  const handlePriceStatus = (newStatus: PRICE_STATUS) => {
    setPriceStatus(newStatus)
  }

  const handleCandleStatus = (newStatus: CANDLE_STATUS) => {
    setCandleStatus(newStatus)
  }

  const handleUserValue = (newValue: string) => {
    if (!newValue.includes('.')) {
      setValue(newValue)
      return
    }
    const safeNewValue = newValue.split('.')
    if (!safeNewValue[1]) {
      setValue(newValue)
      return
    }
    const safeValue = [safeNewValue[0], safeNewValue[1].slice(0, selectedCollateral.token?.decimals ?? 18)].join('.')
    setValue(safeValue)
  }

  const handleUserQuantity = (newQuantity: string) => {
    if (!newQuantity.includes('.')) {
      setQuantity(newQuantity)
      return
    }
    const safeNewQuantity = newQuantity.split('.')
    if (!safeNewQuantity[1]) {
      setQuantity(newQuantity)
      return
    }
    const safeQuantity = [safeNewQuantity[0], safeNewQuantity[1].slice(0, productInfo.token?.decimals ?? 18)].join('.')
    setQuantity(safeQuantity)
  }

  const handleUserLeverage = (newLeverage: number) => {
    setLeverage(newLeverage)
  }

  const handleLiqPrice = (newPrices: string[]) => {
    setLiqPrice(newPrices)
  }

  const handleOpenOrder = (isLong: boolean) => {
    setOrderDirection(isLong)
    if (value && +value > 0) {
      setTxHash('')
      setOrderModal(true)
    }
  }

  const handleDismissOrder = useCallback(() => {
    setAttemptingTxn(false)
    setOrderModal(false)
  }, [])

  const handleConfirmOrder = async () => {
    setAttemptingTxn(true)
    const safeValue = value.charAt(value.length - 1) === '.' ? value.slice(0, value.length - 2) : value
    const size = parseUnits(`${+safeValue * leverage}`, 8)
    let margin = parseUnits(safeValue, 8)
    let marginEth = parseUnits('0', 18)

    if (selectedCollateral.token.isNative) {
      margin = parseUnits(safeValue, 8)
      marginEth = parseUnits((+safeValue + +safeValue * leverage * fee).toFixed(18), 18)
    }

    if (posContract && account && margin) {
      await posContract
        .createIncreasePosition(
          productInfo.bytes32Id,
          !selectedCollateral.token.isNative ? selectedCollateral.token.wrapped.address : ZERO_ADDRESS,
          orderDirection,
          margin,
          size,
          formatBytes32String(referralCode || ''), // '0x6173676131356e70000000000000000000000000000000000000000000000000', // referral
          { value: marginEth }
        )
        .then((response: TransactionResponse) => {
          setTxHash(response.hash)
          setStateOrders((prev) => {
            return [
              ...prev,
              {
                key: '',
                collateral: selectedCollateral,
                isClose: false,
                isLong: orderDirection,
                product: productInfo,
                margin: 0,
                value: 0,
                user: account,
                timestamp: 0,
              },
            ]
          })
          addTransaction(response, {
            type: TransactionType.OPEN_ORDER,
            amount: simpleBeautifyNumber(value),
            currencySymbol: selectedCollateral.token.symbol || 'TOKEN',
            contractLabel: productInfo.label,
            isLong: orderDirection,
            leverage,
          })
        })
        .catch((error: any) => {
          console.log(error)
        })
    }
  }

  const handleCancelOrder: OrderFunction = async (margin, size, value, product, collateral, isLong) => {
    setTxHash('')
    setAttemptingTxn(true)
    if (posContract) {
      const collateralAddress = !collateral.token.isNative ? collateral.token.wrapped.address : ZERO_ADDRESS
      await posContract
        .closeOrder(product.bytes32Id, collateralAddress, isLong)
        .then((response: TransactionResponse) => {
          setTxHash(response.hash)
          setAttemptingTxn(false)
          addTransaction(response, {
            type: TransactionType.CANCEL_ORDER,
            amount: simpleBeautifyNumber(margin),
            currencySymbol: collateral.symbol || 'TOKEN',
            contractLabel: product.label,
            isLong,
          })
        })
        .catch((error: any) => {
          setAttemptingTxn(false)
          console.log(error)
        })
    }
  }

  const handleUserSettleOrder = async (
    margin: number,
    product: Product,
    collateral: Collateral,
    isLong: boolean,
    price: number // remove in production
  ) => {
    setTxHash('')
    setAttemptingTxn(true)
    if (posContract) {
      const collateralAddress = !collateral.token.isNative ? collateral.token.wrapped.address : ZERO_ADDRESS
      await posContract
        .userSettleOrder(product.bytes32Id, collateralAddress, isLong, price)
        .then((response: TransactionResponse) => {
          setTxHash(response.hash)
          setAttemptingTxn(false)
          addTransaction(response, {
            type: TransactionType.CANCEL_ORDER,
            amount: simpleBeautifyNumber(margin),
            currencySymbol: collateral.symbol || 'TOKEN',
            contractLabel: product.label,
            isLong,
          })
        })
        .catch((error: any) => {
          setAttemptingTxn(false)
          console.log(error)
        })
    }
  }

  const handleClosePosition: OrderFunction = async (margin, size, value, product, collateral, isLong) => {
    setTxHash('')
    setAttemptingTxn(true)
    const parsedSize = parseUnits(size.toFixed(18), 18)
    const { isNative } = collateral.token
    const feeEth = isNative ? value * product.fee : 0
    const currencyAddress = isNative ? ZERO_ADDRESS : collateral.token.address
    if (posContract && parsedSize) {
      await posContract
        .createDecreasePosition(product.bytes32Id, currencyAddress, isLong, parsedSize, {
          value: parseUnits(feeEth.toFixed(18), 18),
        })
        .then((response: TransactionResponse) => {
          setTxHash(response.hash)
          setAttemptingTxn(false)
          addTransaction(response, {
            type: TransactionType.CLOSE_TRADE,
            amount: simpleBeautifyNumber(size),
            contractLabel: product.label,
            isLong,
            currencySymbol: collateral.token.symbol || collateral.symbol || 'TOKEN',
          })
        })
    }
  }

  const handleChangeCollateral = (collateral: Collateral) => {
    setSelectedCollateral(collateral)
  }

  const [cardModal, setCardModal] = useState<
    | {
        product: Product
        isLong: boolean
        pnl: number
        pnlPercent: number
        leverage: number
        entryPrice: number
        markPrice: number
      }
    | undefined
  >(undefined)

  const handleOpenCardModal = (props: {
    product: Product
    isLong: boolean
    pnl: number
    pnlPercent: number
    leverage: number
    entryPrice: number
    markPrice: number
  }) => {
    setCardModal(props)
  }

  // Layout functions & effects

  const handleLayoutChange = (newLayout: Layout[]) => {
    const newLayouts = { ...storedLayouts }
    newLayouts[breakpoint as 'sm' | 'md' | 'lg'] = newLayout
    updateStoredLayouts(newLayouts)
  }

  const handleDeleteCard = (name: string) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const newLayouts = { ...storedLayouts }

    Object.keys(breakpoints).forEach((breakpoint) => {
      const index = newLayouts[breakpoint].findIndex((card: Layout) => card.i === name)
      const newBreakpointLayout = [...newLayouts[breakpoint]]
      newBreakpointLayout.splice(index, 1)
      newLayouts[breakpoint] = newBreakpointLayout
    })

    updateStoredLayouts(newLayouts)
  }

  const handleAddCard = (name: string) => {
    /* Add logic to add the element from a set storage of cards */
    const { identifier, isStatic, isResizable, defaultSettings } = allCards[name]
    const newLayout = { ...storedLayouts }

    Object.keys(breakpoints).forEach((breakpoint) => {
      const breakpointDefaultSettings = defaultSettings[breakpoint as 'sm' | 'md' | 'lg']
      const newCard = {
        i: identifier,
        isResizable,
        static: isStatic,
        ...breakpointDefaultSettings,
        x: (newLayout[breakpoint as 'sm' | 'md' | 'lg'].length * 2) % cols[breakpoint as 'sm' | 'md' | 'lg'],
        y: bottom(newLayout[breakpoint as 'sm' | 'md' | 'lg']),
      }
      newLayout[breakpoint as 'sm' | 'md' | 'lg'] = [...storedLayouts[breakpoint], newCard]
    })
    updateStoredLayouts(newLayout)
  }

  const handleStaticCard = (name: string) => {
    /* Add logic to add the element from a set storage of cards */
    const newLayout = { ...storedLayouts }

    Object.keys(breakpoints).forEach((breakpoint) => {
      const oldBreakpoint = [...newLayout[breakpoint as 'sm' | 'md' | 'lg']]

      const oldCardIndex = oldBreakpoint.findIndex((card) => card.i === name)
      const oldCard = oldBreakpoint[oldCardIndex]

      const card = {
        ...oldCard,
        static: !oldCard.static,
        isResizable: allCards[name].isResizable && !!oldCard.static,
      }
      // console.log(card)
      oldBreakpoint[oldCardIndex] = card

      newLayout[breakpoint as 'sm' | 'md' | 'lg'] = oldBreakpoint
    })
    updateStoredLayouts(newLayout)
  }

  useEffect(() => {
    setCurrentLayout({
      breakpoint,
      items: storedLayouts[breakpoint].reduce((a, b, i) => {
        return { ...a, [b.i]: b.w && b.h ? { i } : undefined }
      }, {}),
    })
  }, [breakpoint, storedLayouts])

  const availableCards = useMemo<{ [card: string]: GridCard }>(() => {
    const availableCards = objMap(allCards, (cardKey: string, card: GridCard): undefined | GridCard => {
      if (!currentLayout.items[cardKey]) return card
      return
    }) as unknown as { [card: string]: GridCard }

    Object.keys(availableCards).forEach((key) => availableCards[key] === undefined && delete availableCards[key])

    return availableCards
  }, [currentLayout.items])

  const pendingText = useMemo(() => {
    return (
      <div style={{ padding: '0 1rem' }}>
        <Trans>
          Submitting a {orderDirection ? 'long' : 'short'} order of {quantity}{' '}
          {productInfo.symbol || productInfo.token?.symbol} for {productInfo.label}
        </Trans>
      </div>
    )
  }, [productInfo.label, orderDirection, quantity, productInfo])

  const modalHeader = useCallback(() => {
    const size = parseFloat((+value * leverage).toFixed(5))
    return (
      <Column style={{ padding: '2rem 1rem', gap: '1rem' }}>
        <RowBetween>
          <ThemedText.Main>Order Price</ThemedText.Main>
          <div>Last Market Price</div>
        </RowBetween>
        <RowBetween>
          <ThemedText.Main>Order Cost</ThemedText.Main>
          <div>
            {value} {selectedCollateral.symbol || selectedCollateral.token.symbol}
          </div>
        </RowBetween>
        <RowBetween>
          <ThemedText.Main>Order Value</ThemedText.Main>
          <div>
            {size} {selectedCollateral.symbol || selectedCollateral.token.symbol}
          </div>
        </RowBetween>
        <RowBetween>
          <ThemedText.Main>Fee</ThemedText.Main>
          <div>
            {parseFloat((size * (productDetails?.fee || 0)).toFixed(5))}{' '}
            {selectedCollateral.symbol || selectedCollateral.token.symbol}
          </div>
        </RowBetween>
        <RowBetween>
          <Separator style={{ margin: '0.5rem 0' }} />
        </RowBetween>
        <RowBetween>
          <ThemedText.Main>Est. Quantity</ThemedText.Main>
          <div>
            {quantity} {productInfo.symbol || productInfo.token?.symbol}
          </div>
        </RowBetween>
        <RowBetween>
          <ThemedText.Main>Est. Liquidation Price</ThemedText.Main>
          <div>${liqPrice[orderDirection ? 0 : 1]}</div>
        </RowBetween>
        <RowBetween>
          <ThemedText.Main>Leverage</ThemedText.Main>
          <div>{leverage.toFixed(2)}x</div>
        </RowBetween>
      </Column>
    )
  }, [quantity, value])

  const modalBottom = () => {
    return (
      <Row>
        {approval === ApprovalState.APPROVED ? (
          <ButtonPrimary onClick={handleConfirmOrder}>
            <ThemedText.Main fontWeight={500} fontSize={20} color={white}>
              <Trans>Confirm</Trans>
            </ThemedText.Main>
          </ButtonPrimary>
        ) : (
          <ButtonPrimary onClick={approveCallback}>
            <ThemedText.Main fontWeight={500} fontSize={20} color={white}>
              <Trans>Approve {selectedCollateral.symbol || selectedCollateral.token.symbol}</Trans>
            </ThemedText.Main>
          </ButtonPrimary>
        )}
        <ButtonGray onClick={handleDismissOrder}>
          <ThemedText.Main fontWeight={500} fontSize={20}>
            <Trans>Cancel</Trans>
          </ThemedText.Main>
        </ButtonGray>
      </Row>
    )
  }

  const memoisedTitle = useMemo(() => {
    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
        {productInfo.label}
        <span
          style={{
            display: 'flex',
            alignItems: 'center',
            marginRight: '0.25rem',
            padding: '0.125rem 0.25rem',
            backgroundColor: orderDirection ? green1 : red2,
            borderRadius: '0.125rem',
          }}
        >
          <ThemedText.Small color={white} fontSize={9}>
            {orderDirection ? 'LONG' : 'SHORT'}
          </ThemedText.Small>
        </span>
      </div>
    )
  }, [green1, orderDirection, productInfo.label, red2, white])

  return (
    <ProductProvider>
      <PriceProvider
        productIds={[productId, ...activeProducts.map((prod) => prod.id)]}
        collaterals={productInfo.collaterals.map((c) => c.apiId)}
        onStatusChange={handlePriceStatus}
      >
        <CandleProvider
          productId={productId}
          collateral={selectedCollateral}
          resolution={RESOLUTIONS['1M']}
          start={0}
          end={9}
          visibleLogicalRange={10}
          onStatusChange={handleCandleStatus}
        >
          <KeeperProvider product={productInfo} hash={txHash || previousHash}>
            <ChooseProductModal
              currentProduct={productInfo}
              products={products}
              isOpen={chooseProductOpen}
              onDismiss={() => setChooseProductOpen(false)}
              onProductChange={handleProductChange}
            />
            <GetCardModal card={cardModal} onDismiss={() => setCardModal(undefined)} />
            <TransactionConfirmationModal
              isOpen={orderModal}
              onDismiss={handleDismissOrder}
              attemptingTxn={attemptingTxn}
              hash={txHash}
              content={() => (
                <ConfirmationModalContent
                  title={memoisedTitle}
                  onDismiss={handleDismissOrder}
                  topContent={modalHeader}
                  bottomContent={modalBottom}
                />
              )}
              pendingText={pendingText}
            />
            {!checkSupport(Features.PERPETUALS, chainId) ? (
              <ChainWarning chainId={chainId} feature={Features.PERPETUALS} />
            ) : (
              <>
                <div style={{ position: 'relative', width: '100%', paddingTop: '0.75rem' }}>
                  {Object.values(storedLayouts).every((layout) => !!layout.length) && (
                    <GridLayout
                      style={{ width: '100%', marginBottom: drawer ? '10rem' : '0' }}
                      className="layout"
                      layout={memoisedLayout[currentLayout.breakpoint as 'sm' | 'md' | 'lg']}
                      cols={cols[currentLayout.breakpoint as 'sm' | 'md' | 'lg']}
                      isBounded={true}
                      rowHeight={30}
                      onLayoutChange={handleLayoutChange}
                      draggableHandle=".drag-handle"
                      resizeHandles={['se']}
                      isDraggable={currentLayout.breakpoint !== 'sm' && !drawer}
                      margin={currentLayout.breakpoint === 'sm' ? [0, 5] : [10, 10]}
                      width={currentLayout.breakpoint === 'sm' ? width - 20 : width - 40}
                    >
                      {currentLayout.breakpoint &&
                        storedLayouts[currentLayout.breakpoint].map((item, index) => {
                          const card = allCards[item.i]
                          let child
                          switch (item.i) {
                            case 'tachart':
                              child = (
                                <CandleChart
                                  productId={productId}
                                  breakpoint={currentLayout.breakpoint}
                                  width={item.w}
                                  height={item.h}
                                />
                              )
                              break
                            case 'simpleChart':
                              child = (
                                <SimpleChartTwo
                                  type="candlestick"
                                  data={dummyDataSeries}
                                  width={item.w}
                                  height={item.h}
                                  colors={{
                                    backgroundColor: 'transparent',
                                    lineColor: '#2962FF',
                                    textColor: text1,
                                    gridColor: bg3,
                                  }}
                                />
                              )
                              break
                            case 'contractHeader':
                              child = (
                                <ContractHeader
                                  product={productInfo}
                                  width={item.w}
                                  onChooseModal={() => setChooseProductOpen(true)}
                                />
                              )
                              break
                            case 'orderbook':
                              child = <OrderBook height={item.h} />
                              break
                            case 'manager':
                              child = (
                                <TradeManager
                                  product={productInfo}
                                  breakpoint={currentLayout.breakpoint}
                                  selectedCollateral={selectedCollateral}
                                  value={value}
                                  quantity={quantity}
                                  leverage={leverage}
                                  maxLeverage={maxLeverage}
                                  liqPrice={liqPrice}
                                  onChangeCollateral={handleChangeCollateral}
                                  onOpenOrder={handleOpenOrder}
                                  onUserLeverage={handleUserLeverage}
                                  onUserValue={handleUserValue}
                                  onUserQuantity={handleUserQuantity}
                                  onLiqPrice={handleLiqPrice}
                                  height={item.h}
                                />
                              )
                              break
                            case 'positions':
                              child = (
                                <PositionsWidget
                                  breakpoint={currentLayout.breakpoint}
                                  width={item.w}
                                  onCancelOrder={handleCancelOrder}
                                  onCancelClose={handleCancelOrder}
                                  onClosePosition={handleClosePosition}
                                  onProductChange={handleProductChange}
                                  onOpenCardModal={handleOpenCardModal}
                                  onUserSettleOrder={handleUserSettleOrder}
                                />
                              )
                              break
                            case 'marketTrades':
                              child = <MarketTrades height={item.h} collateral={selectedCollateral} />
                              break
                            case 'info':
                              child = <InfoWidget productInfo={productInfo} productDetails={productDetails} />
                              break
                            case 'discord':
                              child = <DiscordWidget breakpoint={currentLayout.breakpoint} />
                              break
                            case 'adv':
                              child = <FarmAdvert collateral={selectedCollateral} />
                              break
                            default:
                              child = undefined
                          }

                          return (
                            <div key={item.i} style={{ position: 'relative' }}>
                              <CardWrapper
                                name={card.name}
                                description={card.description}
                                isEdit={drawer}
                                deletable={currentLayout.breakpoint !== 'sm' && card.isDeletable}
                                isStatic={currentLayout.breakpoint === 'sm' || item.static}
                                staticAble={true}
                                onStaticCard={() => handleStaticCard(item.i)}
                                onDeleteCard={() => handleDeleteCard(item.i)}
                              >
                                {child}
                              </CardWrapper>
                            </div>
                          )
                        })}
                    </GridLayout>
                  )}
                </div>
                <SwitchLocaleLink />

                {currentLayout.breakpoint !== 'sm' && (
                  <FixedTradeFooter
                    drawer={drawer}
                    onSetDrawer={setDrawer}
                    availableCards={availableCards}
                    onAddCard={handleAddCard}
                    currentLayouts={storedLayouts}
                    currentBreakpoint={currentLayout.breakpoint}
                  />
                )}
              </>
            )}
          </KeeperProvider>
        </CandleProvider>
      </PriceProvider>
    </ProductProvider>
  )
}

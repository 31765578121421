import { parseBytes32String } from '@ethersproject/strings'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import useParsedQueryString from 'hooks/useParsedQueryString'
import useTimeout from 'hooks/useTimeout'
import ms from 'ms.macro'
import { createContext, ReactNode, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useGetReferralQuery } from 'state/referral/enhanced'
import { useReferralCodes } from 'state/referral/hooks'

export const ReferralCtx = createContext({
  referralCode: '',
  wipeCode: () => {
    return
  },
  ownCode: '',
  ownCodeLoaded: false,
})

const EXPIRE_CODE = 60000 * 60
const TIMEOUT_DELAY = 60000 * 5

export default function ReferralProvider({ children }: { children: ReactNode }) {
  const { account } = useActiveWeb3React()
  const [referralCode, setReferralCode] = useState('')
  const [ownCode, setOwnCode] = useState('')
  const [ownCodeLoaded, setOwnCodeLoaded] = useState(false)

  const [referralCodeTime, setReferralCodeTime] = useState<null | number>(null)
  const [referred, setReferred] = useState(false)

  const query = useParsedQueryString()
  const history = useHistory()
  const userReferralCode: any = useReferralCodes()
  const referral = useGetReferralQuery(
    { address: account ?? '' },
    {
      pollingInterval: ms`5 minutes`,
    }
  )

  useEffect(() => {
    if (!account || !userReferralCode) return
    if (userReferralCode.status !== 'fulfilled') {
      return
    }
    setOwnCodeLoaded(true)
    const raw_code = userReferralCode.data.referralCodes[0]
    if (!raw_code) return

    setOwnCode(raw_code.code ? parseBytes32String(raw_code.code) : '')
  }, [account, userReferralCode])

  useEffect(() => {
    if (!account || !referral) return
    if (referral.status !== 'fulfilled') return
    // console.log(referral.data)
    const raw_response = referral.data.registeredReferrals[0]
    if (!raw_response) return
    setReferred(true)
    setReferralCode('')
    setReferralCodeTime(null)
  }, [account, referral])

  useEffect(() => {
    if (query.ref && typeof query.ref === 'string') {
      setReferralCode(query.ref.toLowerCase())
      setReferralCodeTime(Date.now())
      const newQuery = new URLSearchParams(query as Record<string, string>)
      newQuery.delete('ref')
      history.replace({
        search: newQuery.toString(),
      })
    }
  }, [history, query])

  useTimeout(() => {
    if (!referralCodeTime || !referralCode) return
    const expired = Date.now() > referralCodeTime + EXPIRE_CODE
    if (!expired) return
    setReferralCode('')
    setReferralCodeTime(null)
  }, TIMEOUT_DELAY)

  const handleWipeCode = () => {
    setReferralCode('')
    setReferralCodeTime(null)
  }

  return (
    <ReferralCtx.Provider value={{ referralCode, wipeCode: () => handleWipeCode(), ownCode, ownCodeLoaded }}>
      {children}
    </ReferralCtx.Provider>
  )
}

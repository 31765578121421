import { DotMark16 } from '@carbon/icons-react'
import { ButtonGray, ButtonPrimary } from 'components/Button'
import Row from 'components/Row'
import { transparentize } from 'polished'
import { ReactNode } from 'react'
import styled, { useTheme } from 'styled-components/macro'
import { ThemedText } from 'theme'

const TransparentWrapper = styled.div<{ isEdit?: boolean }>`
  position: relative;
  height: 100%;

  &:hover {
    .drag-handle {
      opacity: ${({ isEdit }) => (isEdit ? '0' : '1')};
    }
  }
`

const EditOverlay = styled.div`
  background-color: ${({ theme }) => transparentize(0.35, theme.bg0)};
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 2500;
  display: flex;
  justify-content: center;
`

const StyledDotMark = styled.div<{ isEdit?: boolean }>`
  z-index: 2000;
  opacity: 0;
  color: #bebebe;
  cursor: ${({ isEdit }) => (isEdit ? 'initial' : 'grab')};
  &:active {
    opacity: ${({ isEdit }) => (isEdit ? '0' : '1')};
    cursor: ${({ isEdit }) => (isEdit ? 'initial' : 'grabbing')};
  }
`

const EditContainer = styled.div`
  display: flex;
  text-align: center;
  gap: 1rem;
  height: 100%;
  max-width: 200px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`
function EditScreen({
  name,
  description,
  icon,
  deletable = true,
  staticAble,
  isStatic,
  onDelete,
  onStatic,
}: {
  name?: string
  description?: string
  icon?: ReactNode
  deletable?: boolean
  staticAble: boolean
  isStatic?: boolean
  onDelete: () => void
  onStatic: () => void
}) {
  const { text1 } = useTheme()

  return (
    <EditOverlay>
      <EditContainer>
        {name && <ThemedText.Main color={text1}>{name}</ThemedText.Main>}
        {description && <ThemedText.Small color={text1}>{description}</ThemedText.Small>}
        <Row style={{ gap: '1rem', justifyContent: 'center' }}>
          {deletable && (
            <ButtonPrimary width={'auto'} style={{ fontSize: '14px' }} maxHeight={'2.5rem'} onClick={onDelete}>
              Remove
            </ButtonPrimary>
          )}
          {staticAble && (
            <ButtonGray width={'auto'} style={{ fontSize: '14px' }} maxHeight={'2.5rem'} onClick={onStatic}>
              {isStatic ? 'Unlock' : 'Lock'}
            </ButtonGray>
          )}
        </Row>
      </EditContainer>
    </EditOverlay>
  )
}

export default function CardWrapper({
  name,
  description,
  icon,
  isEdit,
  deletable,
  isStatic,
  staticAble,
  onDeleteCard,
  onStaticCard,
  children,
}: {
  name: string
  description: string
  icon?: ReactNode
  isEdit: boolean
  deletable: boolean
  staticAble: boolean
  isStatic?: boolean
  onDeleteCard: () => void
  onStaticCard: () => void
  children?: JSX.Element
}) {
  return (
    <TransparentWrapper isEdit={isEdit || isStatic}>
      <StyledDotMark
        isEdit={isEdit || isStatic}
        className="drag-handle"
        style={{ position: 'absolute', right: '0', top: '0' }}
      >
        <DotMark16 />
      </StyledDotMark>
      {isEdit && (
        <EditScreen
          name={name}
          description={description}
          icon={icon}
          deletable={deletable}
          onDelete={onDeleteCard}
          isStatic={isStatic}
          staticAble={staticAble}
          onStatic={onStaticCard}
        />
      )}
      {children}
    </TransparentWrapper>
  )
}

import { Web3Provider } from '@ethersproject/providers'
import ms from 'ms.macro'

import { SupportedChainId } from '../constants/chains'

const NETWORK_POLLING_INTERVALS: { [chainId: number]: number } = {
  [SupportedChainId.MAINNET]: ms`1s`,
  [SupportedChainId.EVMOS_TESTNET]: ms`1s`,
  [SupportedChainId.RINKEBY]: ms`1s`,
  [SupportedChainId.OPTIMISM_GOERLI]: ms`1s`,
}

export default function getLibrary(provider: any): Web3Provider {
  const library = new Web3Provider(provider, 'any')
  library.pollingInterval = 15_000
  library.detectNetwork().then((network) => {
    let networkPollingInterval = ms`2s`
    if (library.connection.url === 'metamask') {
      networkPollingInterval = NETWORK_POLLING_INTERVALS[network.chainId]
    }

    if (networkPollingInterval) {
      console.debug('Setting polling interval', networkPollingInterval)
      library.pollingInterval = networkPollingInterval
    }
  })
  return library
}

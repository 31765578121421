// import { keccak256 as solidityKeccak256 } from '@ethersproject/solidity'
import { createContext, ReactNode } from 'react'
import { useAppSelector } from 'state/hooks'
import { useProducts } from 'state/perpetuals/hooks'
// import simpleBeautifyNumber from 'utils/simpleBeautifyNumber'

export const ProductContext = createContext({})

export default function ProductProvider({ children }: { children: ReactNode }) {
  const activeProductsIds = useAppSelector((state) => state.perpetuals.activeProducts).map((product) => {
    return product.bytes32Id
  })

  const products = useProducts(activeProductsIds)

  return <ProductContext.Provider value={products}>{children}</ProductContext.Provider>
}

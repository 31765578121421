import backgroundGeometric from 'assets/svg/pattern.svg'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useEffect } from 'react'
import { useDarkModeManager } from 'state/user/hooks'

import { SupportedChainId } from '../constants/chains'

const initialStyles = {
  width: '200vw',
  height: '200vh',
  transform: 'translate(-50vw, -100vh)',
  backgroundBlendMode: '',
  backgroundRepeat: 'repeat-y',
}
const backgroundResetStyles = {
  width: '100vw',
  height: '100vh',
  transform: 'unset',
  backgroundBlendMode: '',
  margin: '0 auto',
  backgroundRepeat: 'repeat-y',
  backgroundPositionX: '15%',
}

type TargetBackgroundStyles = typeof initialStyles | typeof backgroundResetStyles

const backgroundElement = document.getElementById('background-geometric')
const setBackground = (newValues: TargetBackgroundStyles) =>
  Object.entries(newValues).forEach(([key, value]) => {
    if (backgroundElement) {
      backgroundElement.style[key as keyof typeof backgroundResetStyles] = value
    }
  })
export default function RadialGradientByChainUpdater(): null {
  const { chainId } = useActiveWeb3React()
  const [darkMode] = useDarkModeManager()
  // manage background color
  useEffect(() => {
    if (!backgroundElement) {
      return
    }
    //TODO edit styling just used Polygon here
    switch (chainId) {
      case SupportedChainId.MAINNET:
      case SupportedChainId.EVMOS_TESTNET:
        setBackground(backgroundResetStyles)
        backgroundElement.style.backgroundImage = `url(${darkMode ? backgroundGeometric : ''})`
        break
      default:
        setBackground(initialStyles)
        backgroundElement.style.background = ''
    }
  }, [darkMode, chainId])
  return null
}

import { formatBytes32String } from '@ethersproject/strings'
import { Token } from '@uniswap/sdk-core'

import { PERP_CHAIN_IDS, SupportedChainId } from './chains'
import { CRN, CRN_P, ExtendedEther, nativeOnChain, USDC, WBTC, WETH } from './tokens'

type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>
  }[Keys]

type SupportedPerpChainId = typeof PERP_CHAIN_IDS[number]

export type Collateral = {
  id: string
  apiId: string
  poolId: string
  chainId: SupportedChainId
  symbol?: string
  token: Token | ExtendedEther
  isStable?: boolean
}

type ProductRaw = {
  id: string
  chainId: number
  bytes32Id: string
  label: string
  logo?: string
  symbol?: string
  token?: Token | ExtendedEther
  riskLimit: number // denominated in usd
  liqThreshold: number // percentage
  minOrder: number // denominated in usd
  collaterals: Collateral[]
}

export type Product = RequireAtLeastOne<ProductRaw, 'logo' | 'token'>

type Products = {
  [key in SupportedPerpChainId as number]: {
    [key: string]: Product
  }
}

type PerpPoolRaw = {
  id: string
  chainId: SupportedChainId
  symbol?: string
  poolAddress: string
  poolRewardsAddress?: string
  poolRewardsInception: number
  poolRewardsAddresses?: { [pool: string]: { address: string; inception: number } }
  token: Token | ExtendedEther
  isExchange: boolean
}

export type PerpPool = RequireAtLeastOne<PerpPoolRaw, 'poolRewardsAddress' | 'poolRewardsAddresses'>

type PerpPools = {
  [key in SupportedPerpChainId as number]: {
    [key: string]: PerpPool
  }
}

const optimism_usdc: Collateral = {
  id: 'optimism_usdc',
  apiId: 'usdc',
  chainId: SupportedChainId.OPTIMISM,
  token: USDC[SupportedChainId.OPTIMISM],
  isStable: true,
  poolId: 'op-usdc',
}

const optimism_eth: Collateral = {
  id: 'optimism_eth',
  apiId: 'eth',
  chainId: SupportedChainId.OPTIMISM,
  symbol: 'ETH',
  token: nativeOnChain(SupportedChainId.OPTIMISM),
  poolId: 'op-eth',
}

const optimismgoerli_usdc: Collateral = {
  id: 'optimismgoerli_usdc',
  apiId: 'usdc',
  chainId: SupportedChainId.OPTIMISM_GOERLI,
  token: USDC[SupportedChainId.OPTIMISM_GOERLI],
  isStable: true,
  poolId: 'og-usdc',
}

const optimismgoerli_eth: Collateral = {
  id: 'optimismgoerli_eth',
  apiId: 'eth',
  chainId: SupportedChainId.OPTIMISM_GOERLI,
  symbol: 'ETH',
  token: nativeOnChain(SupportedChainId.OPTIMISM_GOERLI),
  poolId: 'og-eth',
}

const arbitrum_usdc: Collateral = {
  id: 'arbitrum_usdc',
  apiId: 'usdc',
  chainId: SupportedChainId.ARBITRUM,
  token: USDC[SupportedChainId.ARBITRUM],
  isStable: true,
  poolId: 'arb-usdc',
}

const arbitrum_eth: Collateral = {
  id: 'arbitrum_eth',
  apiId: 'eth',
  chainId: SupportedChainId.ARBITRUM,
  symbol: 'ETH',
  token: WETH[SupportedChainId.ARBITRUM],
  poolId: 'arb-eth',
}

export const collaterals: Collateral[] = [
  optimism_usdc,
  optimism_eth,
  optimismgoerli_usdc,
  optimismgoerli_eth,
  arbitrum_usdc,
  arbitrum_eth,
]

export const STABLE_COLLATERALS: string[] = [
  'optimism_usdc',
  'optimismgoerli_usdc',
  'arbitrum_usdc',
  'optimism_usdt',
  'optimismgoerli_usdt',
  'arbitrum_usdt',
  'optimism_dai',
  'optimismgoerli_dai',
  'arbitrum_dai',
  'optimism_frax',
  'optimismgoerli_frax',
  'arbitrum_frax',
]

export const PERP_PRODUCTS: Products = {
  [SupportedChainId.OPTIMISM]: {
    eth: {
      id: 'eth',
      chainId: SupportedChainId.OPTIMISM,
      bytes32Id: formatBytes32String('ETH-USD'),
      label: 'ETHUSD',
      symbol: 'ETH',
      riskLimit: 10000,
      liqThreshold: 0.05,
      minOrder: 50,
      token: nativeOnChain(SupportedChainId.OPTIMISM),
      collaterals: [optimism_usdc, optimism_eth],
    },
    btc: {
      id: 'btc',
      chainId: SupportedChainId.OPTIMISM,
      bytes32Id: formatBytes32String('BTC-USD'),
      label: 'BTCUSD',
      symbol: 'BTC',
      riskLimit: 10000,
      liqThreshold: 0.05,
      minOrder: 50,
      token: WBTC[SupportedChainId.OPTIMISM],
      collaterals: [optimism_usdc, optimism_eth],
    },
  },
  [SupportedChainId.OPTIMISM_GOERLI]: {
    eth: {
      id: 'eth',
      chainId: SupportedChainId.OPTIMISM_GOERLI,
      bytes32Id: formatBytes32String('ETH-USD'),
      label: 'ETHUSD',
      symbol: 'ETH',
      riskLimit: 10000,
      liqThreshold: 0.05,
      minOrder: 50,
      token: nativeOnChain(SupportedChainId.OPTIMISM_GOERLI),
      collaterals: [optimismgoerli_usdc, optimismgoerli_eth],
    },
    btc: {
      id: 'btc',
      chainId: SupportedChainId.OPTIMISM_GOERLI,
      bytes32Id: formatBytes32String('BTC-USD'),
      label: 'BTCUSD',
      symbol: 'BTC',
      riskLimit: 10000,
      liqThreshold: 0.05,
      minOrder: 50,
      token: WBTC[SupportedChainId.OPTIMISM_GOERLI],
      collaterals: [optimismgoerli_usdc, optimismgoerli_eth],
    },
  },
  [SupportedChainId.ARBITRUM]: {
    eth: {
      id: 'eth',
      chainId: SupportedChainId.ARBITRUM,
      bytes32Id: formatBytes32String('eth'),
      label: 'ETHUSD',
      symbol: 'ETH',
      riskLimit: 10000,
      liqThreshold: 0.05,
      minOrder: 50,
      token: WETH[SupportedChainId.ARBITRUM],
      collaterals: [arbitrum_usdc],
    },
  },
}

export const PERP_POOLS: PerpPools = {
  [SupportedChainId.OPTIMISM]: {
    eth: {
      id: 'op-eth',
      chainId: SupportedChainId.OPTIMISM,
      symbol: 'ETH',
      poolAddress: '0x0337dB4284A852A228c457C6E48a4a84927F1425',
      poolRewardsAddress: '0xc8FDb15EbA8ffbAB729aCb1C40d37c54333185E9',
      poolRewardsInception: 0,
      token: nativeOnChain(SupportedChainId.OPTIMISM),
      isExchange: false,
    },
    usdc: {
      id: 'op-usdc',
      chainId: SupportedChainId.OPTIMISM,
      symbol: 'USDC',
      poolAddress: '0x3DdB9CDD43828b77B7BB8D90dE8A23b99a2327A7',
      poolRewardsAddress: '0x4f3c26aFA8CA5c86E07cb4127FA5deEe73e68F61',
      poolRewardsInception: 0,
      token: USDC[SupportedChainId.OPTIMISM],
      isExchange: false,
    },
    crn: {
      id: 'op-crn',
      chainId: SupportedChainId.OPTIMISM,
      symbol: 'CRN',
      poolAddress: '0x98Cce976201e06fF4Fc44c14Ae880b53aa1F0669',
      poolRewardsInception: 0,
      poolRewardsAddresses: {
        'o-eth': { address: '0xB6Ad1c66f372F9599A847eBcDd923ebC4BE392c8', inception: 0 },
        'o-usdc': { address: '0x472627cA7d8f27C8b6F3BD59841130159cD6597e', inception: 0 },
      },
      token: CRN[SupportedChainId.OPTIMISM],
      isExchange: true,
    },
  },
  [SupportedChainId.OPTIMISM_GOERLI]: {
    eth: {
      id: 'og-eth',
      chainId: SupportedChainId.OPTIMISM_GOERLI,
      symbol: 'ETH',
      poolAddress: '0x1b82dC96B748eecA9E5534D63D3a8aDF7c98503c',
      poolRewardsAddress: '0x83617e0E3d56690a9d8bc99C161046fD1d04d1Ab',
      poolRewardsInception: 0,
      token: nativeOnChain(SupportedChainId.OPTIMISM_GOERLI),
      isExchange: false,
    },
    usdc: {
      id: 'og-usdc',
      chainId: SupportedChainId.OPTIMISM_GOERLI,
      symbol: 'USDC',
      poolAddress: '0xFF7De6267581B20025f94400b3f8ceA8A042bDDA',
      poolRewardsAddress: '0xfF18A33515802ebA7D7a30dbEEe7981745AE9D2f',
      poolRewardsInception: 0,
      token: USDC[SupportedChainId.OPTIMISM_GOERLI],
      isExchange: false,
    },
    crn: {
      id: 'og-crn',
      chainId: SupportedChainId.OPTIMISM_GOERLI,
      symbol: 'CRN',
      poolAddress: '0xD7d8A558455511897CA71Fb1512719bec02635d0',
      poolRewardsInception: 0,
      poolRewardsAddresses: {
        'og-eth': { address: '0x1B5e3b9b0Eb966B46D071336950C091e475E4960', inception: 0 },
        'og-usdc': { address: '0x5cb0Ba0D72Af0229F12DfD30f663C4cDe56eCdb2', inception: 0 },
      },
      token: CRN_P[SupportedChainId.OPTIMISM_GOERLI],
      isExchange: true,
    },
  },
}

export const PERP_SUBGRAPHS: { [key in SupportedPerpChainId as number]: string } = {
  [SupportedChainId.OPTIMISM]: '',
  [SupportedChainId.OPTIMISM_GOERLI]: 'https://api.thegraph.com/subgraphs/name/barenfels/perp-stuff',
}

import ChainWarning from 'components/ChainWarning'
import { checkSupport, Features } from 'constants/dapp'
import { PERP_POOLS } from 'constants/perps'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useMemo } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import ManagePerpPool from './ManagePerpPool'

export default function Manage({
  match: {
    params: { poolId },
  },
  history,
}: RouteComponentProps<{ poolId: string }>) {
  const { chainId } = useActiveWeb3React()
  const pool = useMemo(() => {
    return Object.values(PERP_POOLS)
      .flatMap((chain) => {
        return Object.values(chain)
      })
      .find((pool) => pool.id === poolId)
  }, [poolId])

  if (!pool) {
    history.replace('/trade')
    return <></>
  }

  if (!checkSupport(Features.PERPETUALS, chainId)) {
    return <ChainWarning chainId={chainId} feature={Features.PERPETUALS} />
  }
  return <ManagePerpPool pool={pool} />
}

import { objMap } from 'utils'

type DefaultSettings = {
  w: number
  h: number
  minW: number | undefined
  minH: number | undefined
  maxW: number | undefined
  maxH: number | undefined
}

export type GridCard = {
  identifier: string
  name: string
  description: string
  isResizable: boolean
  isStatic: boolean
  isDeletable: boolean
  hidden?: boolean
  defaultSettings: { sm: DefaultSettings; md: DefaultSettings; lg: DefaultSettings }
}

export const allCards: { [identifier: string]: GridCard } = {
  tachart: {
    identifier: 'tachart',
    name: 'Technical Analysis Chart',
    description: '',
    isResizable: true,
    isStatic: false,
    isDeletable: true,
    defaultSettings: {
      sm: {
        w: 1,
        h: 7,
        minW: 1,
        minH: 7,
        maxW: 1,
        maxH: 7,
      },
      md: {
        w: 8,
        h: 12,
        minW: 4,
        minH: 8,
        maxW: 12,
        maxH: 15,
      },
      lg: { w: 14, h: 14, minW: 8, minH: 7, maxW: 19, maxH: 20 },
    },
  },
  contractHeader: {
    identifier: 'contractHeader',
    name: 'Contract Info',
    description: '',
    isResizable: true,
    isStatic: false,
    isDeletable: false,
    defaultSettings: {
      sm: {
        w: 1,
        h: 3,
        minW: 1,
        minH: 3,
        maxW: 1,
        maxH: 3,
      },
      md: {
        w: 8,
        h: 2,
        minW: 8,
        minH: 2,
        maxW: 12,
        maxH: 2,
      },
      lg: { w: 10, h: 2, minW: 9, minH: 2, maxW: 24, maxH: 2 },
    },
  },
  marketTrades: {
    identifier: 'marketTrades',
    name: 'Latest Trades',
    description: '',
    isResizable: true,
    isStatic: false,
    isDeletable: true,
    hidden: true,
    defaultSettings: {
      sm: {
        w: 1,
        h: 2,
        minW: 1,
        minH: 0, // TBD
        maxW: Infinity, // TBD
        maxH: Infinity, // TBD
      },
      md: {
        w: 6,
        h: 6,
        minW: 3,
        minH: 5,
        maxW: 12,
        maxH: 15,
      },
      lg: { w: 5, h: 10, minW: 4, minH: 5, maxW: 19, maxH: 20 },
    },
  },
  orderbook: {
    identifier: 'orderbook',
    name: 'Orderbook',
    description: '',
    isResizable: true,
    isStatic: false,
    isDeletable: true,
    hidden: true,
    defaultSettings: {
      sm: {
        w: 1,
        h: 2,
        minW: 1,
        minH: 0, // TBD
        maxW: Infinity, // TBD
        maxH: Infinity, // TBD
      },
      md: {
        w: 2,
        h: 2,
        minW: 2, // TBD
        minH: 2, // TBD
        maxW: 2, // TBD
        maxH: 2, // TBD
      },
      lg: { w: 5, h: 10, minW: 4, minH: 5, maxW: 19, maxH: 21 },
    },
  },
  manager: {
    identifier: 'manager',
    name: 'Trade Manager',
    description: '',
    isResizable: true,
    isStatic: false,
    isDeletable: false,
    defaultSettings: {
      sm: {
        w: 1,
        h: 11,
        minW: 1,
        minH: 11,
        maxW: 1,
        maxH: 11,
      },
      md: {
        w: 4,
        h: 11,
        minW: 4,
        minH: 10,
        maxW: 6,
        maxH: 16,
      },
      lg: { w: 5, h: 12, minW: 5, minH: 10, maxW: 10, maxH: 14 },
    },
  },
  positions: {
    identifier: 'positions',
    name: 'Positions',
    description: '',
    isResizable: true,
    isStatic: false,
    isDeletable: false,
    defaultSettings: {
      sm: {
        w: 1,
        h: 10,
        minW: 1,
        minH: 12,
        maxW: 1,
        maxH: 12,
      },
      md: {
        w: 8,
        h: 11,
        minW: 8,
        minH: 6,
        maxW: 12,
        maxH: 16,
      },
      lg: {
        w: 19,
        h: 7,
        minW: 9, // TBD
        minH: 7, // TBD
        maxW: 24, // TBD
        maxH: 16, // TBD
      },
    },
  },
  info: {
    identifier: 'info',
    name: 'Trade Info',
    description: '',
    isResizable: false,
    isStatic: false,
    isDeletable: true,
    defaultSettings: {
      sm: {
        w: 1,
        h: 5,
        minW: 1,
        minH: 5,
        maxW: 1,
        maxH: 5,
      },
      md: {
        w: 6,
        h: 6,
        minW: 4, // TBD
        minH: 6, // TBD
        maxW: 6, // TBD
        maxH: 6, // TBD
      },
      lg: { w: 5, h: 4, minW: 5, minH: 4, maxW: 5, maxH: 4 },
    },
  },
  adv: {
    identifier: 'adv',
    name: '',
    description: '',
    isResizable: false,
    isStatic: false,
    isDeletable: false,
    defaultSettings: {
      sm: {
        w: 1,
        h: 2,
        minW: 1,
        minH: 0, // TBD
        maxW: Infinity, // TBD
        maxH: Infinity, // TBD
      },
      md: {
        w: 2,
        h: 2,
        minW: 2, // TBD
        minH: 2, // TBD
        maxW: 2, // TBD
        maxH: 2, // TBD
      },
      lg: { w: 5, h: 4, minW: 5, minH: 4, maxW: 5, maxH: 4 },
    },
  },
  simpleChart: {
    identifier: 'simpleChart',
    name: 'Simple Chart',
    description: '',
    isResizable: true,
    isStatic: false,
    isDeletable: true,
    hidden: true,
    defaultSettings: {
      sm: {
        w: 1,
        h: 2,
        minW: 1,
        minH: 0, // TBD
        maxW: Infinity, // TBD
        maxH: Infinity, // TBD
      },
      md: {
        w: 8,
        h: 12,
        minW: 4,
        minH: 8,
        maxW: 12,
        maxH: 15,
      },
      lg: { w: 14, h: 14, minW: 5, minH: 7, maxW: 19, maxH: 20 },
    },
  },
  discord: {
    identifier: 'discord',
    name: 'Discord Chat',
    description: '',
    isResizable: true,
    isStatic: false,
    isDeletable: true,
    defaultSettings: {
      sm: {
        w: 1,
        h: 3,
        minW: 1,
        minH: 3,
        maxW: 1,
        maxH: 3,
      },
      md: {
        w: 4,
        h: 14,
        minW: 4,
        minH: 8,
        maxW: 12,
        maxH: 30,
      },
      lg: { w: 5, h: 16, minW: 4, minH: 5, maxW: 19, maxH: 21 },
    },
  },
}

type TemplateObject = { x: number | null; y: number | null; w?: number; h?: number }

type Template = {
  sm: { [card: string]: TemplateObject }
  md: { [card: string]: TemplateObject }
  lg: { [card: string]: TemplateObject }
}

export const templates: { [name: string]: Template } = {
  standard: {
    sm: {
      contractHeader: { x: 0, y: 0 },
      tachart: { x: 0, y: 3 },
      manager: { x: 0, y: 10 },
      positions: { x: 0, y: 20 },
      discord: { x: 0, y: 30 },
      info: { x: 0, y: 33 },
    },
    md: {
      tachart: { x: 0, y: 2 },
      contractHeader: { x: 0, y: 0 },
      discord: { x: 8, y: 0 },
      manager: { x: 8, y: 10 },
      positions: { x: 0, y: 14 },
      info: { x: 6, y: 22 },
    },
    lg: {
      tachart: { x: 0, y: 2, w: 14, h: 13 },
      contractHeader: { x: 0, y: 0, w: 14, h: 2 },
      manager: { x: 14, y: 0, w: 5, h: 11 },
      positions: { x: 0, y: 15 },
      info: { x: 14, y: 11 },
      adv: { x: 19, y: 19 },
      discord: { x: 19, y: 0, w: 5, h: 18 },
    },
  },
  /*
  advanced: {
    sm: {
      contractHeader: { x: 0, y: 0 },
      tachart: { x: 0, y: 3 },
      manager: { x: 0, y: 10 },
      positions: { x: 0, y: 21 },
      info: { x: 0, y: 22 },
      adv: { x: 0, y: 24 },
      discord: { x: 0, y: 26 },
    },
    md: {
      tachart: { x: null, y: null },
      contractHeader: { x: null, y: null },
      manager: { x: null, y: null },
      positions: { x: null, y: null },
      info: { x: null, y: null },
      adv: { x: null, y: null },
    },
    lg: {
      tachart: { x: 0, y: 0 },
      contractHeader: { x: 14, y: 0 },
      manager: { x: 19, y: 0 },
      positions: { x: 0, y: 14 },
      info: { x: 19, y: 14 },
      adv: { x: 19, y: 18 },
    },
  },
  */
}

export const generatedTemplates: any = objMap(templates, (templateKey, template: Template) => {
  return objMap(template, (breakpointKey, breakpoint) => {
    const entries = Object.entries(breakpoint)

    return entries.reduce((accumulator, entry): any => {
      const entryId = entry[0]

      const { identifier, isStatic, isResizable, defaultSettings } = allCards[entryId]
      const cardSettings = defaultSettings[breakpointKey as 'sm' | 'md' | 'lg']

      const entryValue = entry[1] as unknown as TemplateObject
      const { x, y } = entry[1] as unknown as TemplateObject

      return [
        ...accumulator,
        {
          i: identifier,
          static: isStatic,
          isResizable,
          ...cardSettings,
          ...entryValue,
          x: x ? x : 0,
          y: y ? y : 0,
        },
      ]
    }, [])
  })
})

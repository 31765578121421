import arbitrumLogoUrl from 'assets/svg/arbitrum_logo.svg'
import evmosLogoUrlLightMode from 'assets/svg/evmos-icon-dark.svg' //TODO add
import evmosLogoUrlDarkMode from 'assets/svg/evmos-icon-light.svg' //TODO add
import optimismLogoUrlLightMode from 'assets/svg/optimism-icon-black.svg' //TODO add
import optimismgoerliLogoUrl from 'assets/svg/optimism-icon-gray.svg' //TODO add
import optimismLogoUrlDarkMode from 'assets/svg/optimism-icon-white.svg' //TODO add
import ms from 'ms.macro'

import { SupportedChainId, SupportedL1ChainId, SupportedL2ChainId } from './chains'
import { ARBITRUM_LIST } from './lists'
export enum NetworkType {
  L1,
  L2,
}

interface BaseChainInfo {
  readonly networkType: NetworkType
  readonly blockWaitMsBeforeWarning?: number
  readonly docs: string
  readonly bridge?: string
  readonly explorer: string
  readonly infoLink: string
  readonly logoUrl: string
  readonly logoUrlDarkMode?: string
  readonly label: string
  readonly helpCenterUrl?: string
  readonly nativeCurrency: {
    name: string // e.g. 'Goerli ETH',
    symbol: string // e.g. 'gorETH',
    decimals: number // e.g. 18,
  }
}

export interface L1ChainInfo extends BaseChainInfo {
  readonly networkType: NetworkType.L1
  readonly statusPage?: string
}

export interface L2ChainInfo extends BaseChainInfo {
  readonly networkType: NetworkType.L2
  readonly bridge: string
  readonly statusPage?: string
  readonly defaultListUrl: string
}

export type ChainInfoMap = { readonly [chainId: number]: L1ChainInfo | L2ChainInfo } & {
  readonly [chainId in SupportedL2ChainId]: L2ChainInfo
} &
  { readonly [chainId in SupportedL1ChainId]: L1ChainInfo }

export const CHAIN_INFO: ChainInfoMap = {
  [SupportedChainId.MAINNET]: {
    networkType: NetworkType.L1,
    blockWaitMsBeforeWarning: ms`10m`,
    bridge: '/#/bridge',
    docs: 'https://evmos.io/',
    explorer: 'https://evmos.com/',
    infoLink: 'https://analytics.cronusfinance.xyz/#/evmos/',
    label: 'Evmos',
    logoUrl: evmosLogoUrlLightMode,
    logoUrlDarkMode: evmosLogoUrlDarkMode,
    nativeCurrency: { name: 'Evmos', symbol: 'EVMOS', decimals: 18 },
  },
  [SupportedChainId.OPTIMISM]: {
    networkType: NetworkType.L2,
    defaultListUrl: '',
    blockWaitMsBeforeWarning: ms`10m`,
    bridge: '/#/bridge',
    docs: 'https://www.optimism.io/',
    explorer: 'https://optimistic.etherscan.io/',
    infoLink: 'https://analytics.cronusfinance.xyz/#/optimism/',
    label: 'Optimism',
    logoUrl: optimismLogoUrlLightMode,
    logoUrlDarkMode: optimismLogoUrlDarkMode,
    nativeCurrency: { name: 'Ethereum', symbol: 'ETH', decimals: 18 },
  },
  [SupportedChainId.ETHEREUM]: {
    networkType: NetworkType.L1,
    blockWaitMsBeforeWarning: ms`10m`,
    bridge: '/#/bridge',
    docs: '',
    explorer: 'https://etherscan.io/',
    infoLink: '',
    label: 'Ethereum',
    logoUrl: optimismLogoUrlLightMode,
    logoUrlDarkMode: optimismLogoUrlDarkMode,
    nativeCurrency: { name: 'Ethereum', symbol: 'ETH', decimals: 18 },
  },
  [SupportedChainId.ARBITRUM]: {
    networkType: NetworkType.L2,
    defaultListUrl: '',
    blockWaitMsBeforeWarning: ms`10m`,
    bridge: '/#/bridge',
    docs: 'https://arbitrum.io/',
    explorer: 'https://arbiscan.io/',
    infoLink: 'https://analytics.cronusfinance.xyz/#/arbitrum_one/',
    label: 'Arbitrum',
    logoUrl: evmosLogoUrlLightMode,
    logoUrlDarkMode: evmosLogoUrlDarkMode,
    nativeCurrency: { name: 'Ethereum', symbol: 'ETH', decimals: 18 },
  },
  [SupportedChainId.OPTIMISM_GOERLI]: {
    networkType: NetworkType.L2,
    defaultListUrl: '',
    blockWaitMsBeforeWarning: ms`10m`,
    bridge: '/#/bridge',
    docs: 'https://www.optimism.io/',
    explorer: 'https://goerli-optimism.etherscan.io/',
    infoLink: 'https://analytics.cronusfinance.xyz/#/optimism/',
    label: 'Paper Trading',
    logoUrl: optimismgoerliLogoUrl,
    logoUrlDarkMode: optimismgoerliLogoUrl,
    nativeCurrency: { name: 'Ethereum', symbol: 'gETH', decimals: 18 },
  },
  [SupportedChainId.EVMOS_TESTNET]: {
    networkType: NetworkType.L1,
    blockWaitMsBeforeWarning: ms`10m`,
    bridge: '/bridge',
    docs: 'https://evmos.io/',
    explorer: 'https://evmos.com/',
    infoLink: 'https://analytics.cronusfinance.xyz/#/evmos/',
    label: 'Evmos Testnet',
    logoUrl: evmosLogoUrlLightMode,
    logoUrlDarkMode: evmosLogoUrlDarkMode,
    nativeCurrency: { name: 'Evmos', symbol: 'tEVMOS', decimals: 18 },
  },
  [SupportedChainId.RINKEBY]: {
    networkType: NetworkType.L1,
    blockWaitMsBeforeWarning: ms`10m`,
    bridge: '/bridge',
    docs: 'https://evmos.io/',
    explorer: 'https://rinkeby.etherscan.io/',
    infoLink: 'https://analytics.cronusfinance.xyz/#/evmos/',
    label: 'Evmos',
    logoUrl: evmosLogoUrlDarkMode,
    nativeCurrency: { name: 'Evmos', symbol: 'rEVMOS', decimals: 18 },
  },
  [SupportedChainId.NULL_CHAIN]: {
    networkType: NetworkType.L2,
    blockWaitMsBeforeWarning: ms`10m`,
    bridge: 'https://bridge.arbitrum.io/',
    docs: 'https://offchainlabs.com/',
    explorer: 'https://rinkeby-explorer.arbitrum.io/',
    infoLink: 'https://info.uniswap.org/#/arbitrum/',
    label: 'Arbitrum Rinkeby',
    logoUrl: arbitrumLogoUrl,
    defaultListUrl: ARBITRUM_LIST,
    helpCenterUrl: 'https://help.uniswap.org/en/collections/3137787-uniswap-on-arbitrum',
    nativeCurrency: { name: 'Rinkeby Arbitrum Ether', symbol: 'rinkArbETH', decimals: 18 },
  },
}

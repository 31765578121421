import React from 'react'
import styled from 'styled-components/macro'
import { Z_INDEX } from 'theme'

export const BodyWrapper = styled.main<{ margin?: string; maxWidth?: string; background?: string; boxShadow?: string }>`
  position: relative;
  margin-top: ${({ margin }) => margin ?? '2.5rem'};
  max-width: ${({ maxWidth }) => maxWidth ?? '480px'};
  width: 100%;
  background: ${({ background, theme }) => (background ? background : theme.bg0)};
  box-shadow: ${({ boxShadow }) =>
    boxShadow
      ? boxShadow
      : '0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04), 0px 24px 32px rgba(0, 0, 0, 0.01)'};
  margin-left: auto;
  margin-right: auto;
  z-index: ${Z_INDEX.deprecated_content};
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({
  children,
  ...rest
}: {
  children: React.ReactNode
  maxWidth?: string
  margin?: string
  background?: string
  boxShadow?: string
}) {
  return <BodyWrapper {...rest}>{children}</BodyWrapper>
}

import { FACTORY_ADDRESS as V3_FACTORY_ADDRESS } from '@uniswap/v3-sdk'

import { constructSameAddressMap } from '../utils/constructSameAddressMap'
import { SupportedChainId } from './chains'
import { ZERO_ADDRESS } from './misc'

type AddressMap = { [chainId: number]: string }

export const CRN_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x1488346419FFc85C6D54E71be80a222971fb2240',
  [SupportedChainId.EVMOS_TESTNET]: '0x8D092da14C492e769436EA538B94637fA9d5A7e7',
  [SupportedChainId.RINKEBY]: '0x7182f79225019356FB718cF464AD1A3eB6129429',
}

export const MULTICALL_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0xa9140d23a2A2aC45Ab2003B5B3e013d1ccDFEec6',
  [SupportedChainId.ARBITRUM]: '0x1F98415757620B543A52E61c46B32eB19261F984', // Uniswap multicall
  [SupportedChainId.OPTIMISM]: '0x10971c4C11807007DDe6830488f6790BeE82178E',
  [SupportedChainId.OPTIMISM_GOERLI]: '0x1649C74c19E87cB059C6B4c1d87E67F78793b5B3',
  [SupportedChainId.EVMOS_TESTNET]: '0x788c623a1C7F203F89e272d4fDd4ba6fcEb7229c',
  [SupportedChainId.RINKEBY]: '0x80291E295747fCB0d1E4c828EDbfE471Fa79caD1',
}

export const MASTERCHEF_ADDRESS_V2: AddressMap = {
  [SupportedChainId.MAINNET]: '0x3Dc30d756A1382BF454dD6bDb48b7225CA214bB3',
  // [SupportedChainId.ARBITRUM]: '',
  // [SupportedChainId.OPTIMISM]: '',
  // [SupportedChainId.OPTIMISM_GOERLI]: '',
  [SupportedChainId.EVMOS_TESTNET]: '0x616aC866aB53e7d3a6CD3A293536fcaD7eA85E10',
  [SupportedChainId.RINKEBY]: '0x40DD7E50f673Fe76697EdE8bfda85EEd256679E5',
}

export const STABLE_CRONUS_STAKING_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: ZERO_ADDRESS,
  // [SupportedChainId.ARBITRUM]: '',
  // [SupportedChainId.OPTIMISM]: '',
  // [SupportedChainId.OPTIMISM_GOERLI]: '',
  [SupportedChainId.EVMOS_TESTNET]: '0xc324DEcAaaBF07368D32d0CaD011C34847e3BA8C',
  [SupportedChainId.RINKEBY]: '0x7eAEe9e18bf90e2C48BF5DaD0AA303d1eed6f2bc',
}

export const V2_FACTORY_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0x20570b7bFf86B2f92068622D0805160f318554Be',
  // [SupportedChainId.ARBITRUM]: '',
  // [SupportedChainId.OPTIMISM]: '',
  // [SupportedChainId.OPTIMISM_GOERLI]: '',
  [SupportedChainId.EVMOS_TESTNET]: '0x3Dc30d756A1382BF454dD6bDb48b7225CA214bB3',
  [SupportedChainId.RINKEBY]: '0x96a7c17FE97AB9F2f96399Ab15544C187B23b110',
}

export const V2_ROUTER_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x3Bc1F48740a5D6dffAc36FAF4c9905a941509348',
  // [SupportedChainId.ARBITRUM]: '',
  // [SupportedChainId.OPTIMISM]: '',
  // [SupportedChainId.OPTIMISM_GOERLI]: '',
  [SupportedChainId.EVMOS_TESTNET]: '0xcAD65DfF50050b175EDe9e9A6A7A1BDd4D08E920',
  [SupportedChainId.RINKEBY]: '0xB5cE05cc93f22054C65834def7C7D1BB94591942',
}

export const PERP_ROUTER_ADDRESS: AddressMap = {
  // [SupportedChainId.ARBITRUM]: '',
  [SupportedChainId.OPTIMISM]: '0xB29acCa99c03347286FcC3677b805a91d5CEedB1',
  [SupportedChainId.OPTIMISM_GOERLI]: '0x6642174719aaA3169a7b8A5Db97dE0FDA40CE69A',
}

export const PERP_POS_ROUTER_ADDRESS: AddressMap = {
  // [SupportedChainId.ARBITRUM]: '',
  [SupportedChainId.OPTIMISM]: '0x10a1898d974cDA0Ac2C06e7be9F7EB2b1Ab10B67',
  [SupportedChainId.OPTIMISM_GOERLI]: '0xBBB5b831399b854bde331919eb328b329Bcbf2B3',
}

export const PERP_VAULT_ADDRESS: AddressMap = {
  [SupportedChainId.OPTIMISM]: '0xe496f22eB27Ec439fc86DD432CA65bA11585a6C0',
  [SupportedChainId.OPTIMISM_GOERLI]: '0x22b540822d1a975645F75b1d24848787aC2Bd7fc',
}

export const REFSTORAGE_ADDRESS: AddressMap = {
  [SupportedChainId.OPTIMISM]: '0x7542E2c22016740332c35C3405B20277ED7175d8',
  [SupportedChainId.OPTIMISM_GOERLI]: '0x3b480ab17c7838E940E20376A95eE3E450950aF2',
}

export const REFREADER_ADDRESS: AddressMap = {
  [SupportedChainId.OPTIMISM]: '0xF15C56414e934e5595f89A810C50850113475178',
  [SupportedChainId.OPTIMISM_GOERLI]: '0x7df050BDF368a54D15AA2C0562c59729289382FD',
}

export const TOKEN_FAUCET: AddressMap = {
  [SupportedChainId.OPTIMISM_GOERLI]: '0xdb3FA21488C74E46e438AD6CA5B49722c4ab9319',
}

export const STABLE_SWAP_ADRESS: AddressMap = {
  [SupportedChainId.MAINNET]: ZERO_ADDRESS,
  // [SupportedChainId.ARBITRUM]: '',
  // [SupportedChainId.OPTIMISM]: '',
  // [SupportedChainId.OPTIMISM_GOERLI]: '',
  [SupportedChainId.EVMOS_TESTNET]: '0xf1D9953103050c25fC8E4A7c4cC27022753aabb3',
  [SupportedChainId.RINKEBY]: ZERO_ADDRESS,
}

export const PUBLIC_SALE_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x0b430490a3FF8C2dcD47e48F6F1CE119cB2BD8A2',
  [SupportedChainId.EVMOS_TESTNET]: '0x00e5c7a3C1a728383B3982e0b93C83fb1243C271',
  [SupportedChainId.RINKEBY]: ZERO_ADDRESS,
}

export const ENS_REGISTRAR_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0xae9Da235A2276CAa3f6484ad8F0EFbF4e0d45246',
  // [SupportedChainId.ARBITRUM]: '',
  // [SupportedChainId.OPTIMISM]: '',
  // [SupportedChainId.OPTIMISM_GOERLI]: '',
  [SupportedChainId.EVMOS_TESTNET]: '0xa6AC1Ab061DAb2edB8e1D597FD78D1b88B2d989e',
  [SupportedChainId.RINKEBY]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
}

// OLD UNISWAP ADDRESSES

export const V3_ROUTER_ADDRESS: AddressMap = constructSameAddressMap(ZERO_ADDRESS, [])
export const SWAP_ROUTER_ADDRESSES: AddressMap = constructSameAddressMap(ZERO_ADDRESS, [
  //TODO edit address
  SupportedChainId.MAINNET,
  SupportedChainId.EVMOS_TESTNET,
  SupportedChainId.RINKEBY,
])

/**
 * The oldest V0 governance address
 */
export const GOVERNANCE_ALPHA_V0_ADDRESSES: AddressMap = constructSameAddressMap(
  '0x5e4be8Bc9637f0EAA1A755019e06A68ce081D58F'
)
/**
 * The older V1 governance address
 */
export const GOVERNANCE_ALPHA_V1_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0xC4e172459f1E7939D522503B81AFAaC1014CE6F6',
}
/**
 * The latest governor bravo that is currently admin of timelock
 */
export const GOVERNANCE_BRAVO_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0x408ED6354d4973f66138C91495F2f2FCbd8724C3',
}

export const TIMELOCK_ADDRESS: AddressMap = constructSameAddressMap('0x1a9C8182C09F50C8318d769245beA52c32BE35BC')

export const MERKLE_DISTRIBUTOR_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x090D4613473dEE047c3f2706764f49E0821D256e',
}
export const ARGENT_WALLET_DETECTOR_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0xeca4B0bDBf7c55E9b7925919d03CbF8Dc82537E8',
}
export const V3_CORE_FACTORY_ADDRESSES: AddressMap = constructSameAddressMap(V3_FACTORY_ADDRESS, [
  SupportedChainId.MAINNET,
  SupportedChainId.EVMOS_TESTNET,
  SupportedChainId.RINKEBY,
])
export const QUOTER_ADDRESSES: AddressMap = constructSameAddressMap('0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6', [
  SupportedChainId.MAINNET,
  SupportedChainId.EVMOS_TESTNET,
  SupportedChainId.RINKEBY,
])
export const NONFUNGIBLE_POSITION_MANAGER_ADDRESSES: AddressMap = constructSameAddressMap(
  '0xC36442b4a4522E871399CD717aBDD847Ab11FE88',
  [SupportedChainId.MAINNET, SupportedChainId.EVMOS_TESTNET, SupportedChainId.RINKEBY]
)
export const SOCKS_CONTROLLER_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0x65770b5283117639760beA3F867b69b3697a91dd',
}

export const V3_MIGRATOR_ADDRESSES: AddressMap = constructSameAddressMap('0xA5644E29708357803b5A882D272c41cC0dF92B34', [
  SupportedChainId.MAINNET,
  SupportedChainId.EVMOS_TESTNET,
  SupportedChainId.RINKEBY,
])

export const TICK_LENS_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0xbfd8137f7d1516D3ea5cA83523914859ec47F573',
  [SupportedChainId.EVMOS_TESTNET]: '0xbfd8137f7d1516D3ea5cA83523914859ec47F573',
  [SupportedChainId.RINKEBY]: '0xbfd8137f7d1516D3ea5cA83523914859ec47F573',
}

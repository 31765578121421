import { AutoColumn } from 'components/Column'
import CoverScreen from 'components/CoverScreen'
import CurrencyLogo from 'components/CurrencyLogo'
import Modal from 'components/Modal'
import SimpleChartTwo from 'components/SimpleChart/SimpleChartTwo'
import { Product } from 'constants/perps'
import useDebounce from 'hooks/useDebounce'
import useTheme from 'hooks/useTheme'
import { UTCTimestamp } from 'lightweight-charts'
import { darken, transparentize } from 'polished'
import { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import simpleBeautifyNumber from 'utils/simpleBeautifyNumber'

const Wrapper = styled.div`
  width: 100%;
  max-width: 720px;
  display: flex;
  position: relative;
`

const ButtonColumn = styled(AutoColumn)`
  display: flex;
  padding: 1rem 0.5rem;
  flex-direction: column;
  flex: 0 1 30%;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex: 0 1 100%;
`};
`

const ChartColumn = styled(AutoColumn)`
  flex: 0 1 70%;
  padding: 1rem 1rem 1rem 0.5rem;
  height: 100%;
  ${({ theme }) => theme.mediaWidth.upToMedium`
  display: none;
`};
`
const LargeModal = styled(Modal)`
  max-width: 720px;
  ${({ theme }) => theme.mediaWidth.upToLarge``}
`
const ChartContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.bg2};
  border-radius: 0.25rem;
  overflow: hidden;
  height: 325px;
`
const ProductButton = styled.button<{ active: boolean }>`
  background: ${({ theme, active }) => (active ? darken(0.05, theme.bg0) : 'transparent')};
  border: ${({ active, theme }) => (active ? `1px solid ` + theme.bg2 : 'none')}!important;
  font-weight: 600;
  gap: 0.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.white};
  padding: 1rem 0.5rem;
  text-align: start;
  border-radius: 0.25rem;
  border: 0 solid transparent;
  flex: 0 1;
  cursor: pointer;
  &:hover,
  &:focus {
    background: ${({ theme }) => darken(0.05, theme.bg0)};
  }
`

type Candle = {
  time: UTCTimestamp
  value: number
}
export default function ChooseProductModal({
  currentProduct,
  products,
  isOpen,
  onDismiss,
  onProductChange,
}: {
  currentProduct: Product
  products: Product[]
  isOpen: boolean
  onDismiss: () => void
  onProductChange: (newProduct: string) => void
}) {
  const [candles, setCandles] = useState<{
    [k: string]: { candles: Candle[]; diffSide: boolean; diff_24h: number; open: number; price: number }
  }>({})
  const [loading, setLoading] = useState(true)
  const [currentHover, setCurrentHover] = useState('')
  const debouncedHover = useDebounce(currentHover, 400)

  const currentChart = useMemo(() => {
    return debouncedHover || currentProduct.id
  }, [debouncedHover, currentProduct])

  const { text1, bg1, green1, red2 } = useTheme()

  useEffect(() => {
    let ready = true
    const fetchCandles = async () => {
      if (ready && isOpen) {
        const date = +(Date.now() / 1000).toFixed(0)
        const result: {
          [k: string]: { candles: Candle[]; diffSide: boolean; diff_24h: number; open: number; price: number }
        } = {}
        await Promise.all(
          products.map(async (product) => {
            result[product.id] = { candles: [], price: 0, diff_24h: 0, diffSide: true, open: 0 }
            const candleUrl = `https://api.exchange.coinbase.com/products/${
              product.symbol
            }-USD/candles?granularity=900&&start=${date - 86400}&end=${date}`

            const statsUrl = `https://api.exchange.coinbase.com/products/${product.symbol}-USD/stats`
            await fetch(candleUrl)
              .then((res: any) => {
                return res.json()
              })
              .then((data) => {
                result[product.id].candles = data
                  .map((candle: number[]) => {
                    const [timestamp, low, high, open, close, volume] = candle
                    return { time: timestamp as UTCTimestamp, value: +close.toFixed(2) }
                  })
                  .reverse()
              })

            await fetch(statsUrl)
              .then((res: any) => {
                return res.json()
              })
              .then((data) => {
                const { last, open } = data
                result[product.id].price = last
                result[product.id].open = open
                if (last > open) {
                  result[product.id].diffSide = true
                  result[product.id].diff_24h = +simpleBeautifyNumber(((last - open) / open) * 100)
                } else {
                  result[product.id].diffSide = false
                  result[product.id].diff_24h = +simpleBeautifyNumber(((open - last) / open) * 100)
                }
              })
          })
        )
        setCandles(result)
        setLoading(false)
      }
    }

    fetchCandles()
    return () => {
      ready = false
    }
  }, [isOpen])

  const memoisedButtons = useMemo(() => {
    return products.map((product) => {
      if (candles[product.id]) {
        const { diffSide, diff_24h } = candles[product.id]

        return (
          <ProductButton
            key={product.bytes32Id}
            onClick={() => product.id !== currentProduct.id && onProductChange(product.id)}
            onMouseEnter={() => setCurrentHover(product.id)}
            onMouseLeave={() => setCurrentHover('')}
            active={product.id === currentProduct.id}
          >
            <div style={{ display: 'flex', gap: '0.6rem' }}>
              <CurrencyLogo currency={product.token} size={'16px'} />
              <div>{product.label}</div>
            </div>
            <div style={{ fontWeight: 400, color: diffSide ? green1 : red2 }}>
              {diffSide ? '+' : '-'}
              {diff_24h}%
            </div>
          </ProductButton>
        )
      }
      return null
    })
  }, [candles, green1, products, red2])

  return (
    <LargeModal isOpen={isOpen} onDismiss={onDismiss} minHeight={40} maxWidth={720}>
      <Wrapper>
        <CoverScreen bg={bg1} timeout={2000} />
        <ButtonColumn gap="md">{memoisedButtons}</ButtonColumn>
        <ChartColumn gap="md">
          {candles[currentChart] && (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {products.find((product) => product.id === currentChart)?.label}
              <div>${candles[currentChart].price}</div>
            </div>
          )}
          <ChartContainer>
            {candles[currentChart] && candles[currentChart].candles.length && (
              <SimpleChartTwo
                type="area"
                width={5}
                height={5}
                data={candles[currentChart].candles}
                colors={{
                  backgroundColor: 'transparent',
                  lineColor: candles[currentChart].diffSide ? green1 : red2,
                  topColor: candles[currentChart].diffSide
                    ? transparentize('0.2', green1)
                    : transparentize('0.2', red2),
                  bottomColor: candles[currentChart].diffSide
                    ? transparentize('0.9', green1)
                    : transparentize('0.9', red2),
                  textColor: text1,
                  gridColor: 'transparent',
                }}
              />
            )}
          </ChartContainer>
        </ChartColumn>
      </Wrapper>
    </LargeModal>
  )
}

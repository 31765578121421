import { createChart } from 'lightweight-charts'
import {
  createContext,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components/macro'

const InvisibleHeader = styled.div`
  top: 0;
  position: absolute;
  height: 2rem;
  width: 100%;
  z-index: 2000;
`
const Context = createContext<undefined | { api(): any; free(): void; _api?: any }>(undefined)

const initialData = [
  { time: '2018-10-11', value: 52.89 },
  { time: '2018-10-12', value: 51.65 },
  { time: '2018-10-13', value: 51.56 },
  { time: '2018-10-14', value: 50.19 },
  { time: '2018-10-15', value: 51.86 },
  { time: '2018-10-16', value: 51.25 },
]

const currentDate = new Date(initialData[initialData.length - 1].time)

const ChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.bg0};
  .tv-lightweight-charts {
    left: -4px;
    position: relative;
  }
`

export default function SimpleChartTwo({
  type = 'line',
  width,
  height,
  data = initialData,
  colors,
  customTypeOptions,
}: {
  type?: string
  width: number
  height: number
  data?: any
  colors: {
    backgroundColor: string
    lineColor: string
    textColor: string
    topColor?: string
    bottomColor?: string
    gridColor: string
  }
  customTypeOptions?: any
}) {
  const { backgroundColor, lineColor, textColor, gridColor, topColor, bottomColor } = colors
  const [chartLayoutOptions, setChartLayoutOptions] = useState({})
  const [typeOptions, setTypeOptions] = useState({})
  const [chartGridOptions, setChartGridOptions] = useState({})

  // The following variables illustrate how a series could be updated.
  const series1 = useRef<any>(null)
  const [started, setStarted] = useState(false)

  // The purpose of this effect is purely to show how a series could
  // be updated using the `reference` passed to the `Series` component.
  useEffect(() => {
    if (!series1.current) {
      return
    }

    if (started) {
      const interval = setInterval(() => {
        currentDate.setDate(currentDate.getDate() + 1)
        const next = {
          time: currentDate.toISOString().slice(0, 10),
          value: 53 - 2 * Math.random(),
        }
        series1.current.update(next)
      }, 1000)
      return () => clearInterval(interval)
    }
    return
  }, [started])

  useEffect(() => {
    setChartLayoutOptions({
      background: {
        color: backgroundColor,
      },
      textColor,
      crossHairMarkerVisible: false,
    })
  }, [backgroundColor, textColor])

  useEffect(() => {
    setChartGridOptions({
      vertLines: {
        color: gridColor,
      },
      horzLines: {
        color: gridColor,
      },
    })
  }, [gridColor])

  useEffect(() => {
    setTypeOptions(customTypeOptions)
  }, [customTypeOptions])

  return (
    <ChartWrapper>
      <Chart layout={chartLayoutOptions} grid={chartGridOptions} type={typeOptions}>
        <Series
          ref={series1}
          type={type}
          data={data}
          topColor={topColor}
          bottomColor={bottomColor}
          lineColor={lineColor}
          crosshairMarkerVisible={false}
          priceLineVisible={false}
        />
      </Chart>
    </ChartWrapper>
  )
}

export function Chart(props: any) {
  const [container, setContainer] = useState(false)
  const handleRef = useCallback((ref) => setContainer(ref), [])

  return (
    <div ref={handleRef} style={{ flex: '1 0', height: '100%' }}>
      {container && <ChartContainer {...props} container={container} />}
    </div>
  )
}

export const ChartContainer = forwardRef((props: any, ref) => {
  const { children, container, layout, grid, ...rest } = props

  const chartApiRef = useRef<{ api(): any; free(): void; _api?: any }>({
    api() {
      if (!this._api) {
        this._api = createChart(container, {
          ...rest,
          layout,
          grid,
          width: container.clientWidth,
          height: 300,
        })
        this._api.timeScale().fitContent()
      }
      return this._api
    },
    free() {
      if (this._api) {
        this._api.remove()
      }
    },
  })

  useEffect(() => {
    const currentRef = chartApiRef.current
    const chart = currentRef.api()
    //console.log(container.offsetHeight)
    chart.applyOptions({
      ...rest,
      width: container.offsetWidth + 8,
      height: container.offsetHeight,
      handleScale: false, // TODO@barenfels: parametrize
      handleScroll: false, // TODO@barenfels: parametrize
      crosshair: {
        vertLine: { color: 'transparent' },
        horzLine: { color: 'transparent' },
      },
      timeScale: {
        borderVisible: false,
        visible: false,
      },
      rightPriceScale: {
        visible: false,
        borderVisible: false,
      },
    })
  }, [container.offsetHeight, container.offsetWidth, container.offsetBottom, grid])

  useLayoutEffect(() => {
    const currentRef = chartApiRef.current
    currentRef.api()
  }, [])

  useLayoutEffect(() => {
    const currentRef = chartApiRef.current
    currentRef.api().applyOptions(rest)
  }, [])

  useImperativeHandle(ref, () => chartApiRef.current.api(), [])

  useEffect(() => {
    const currentRef = chartApiRef.current
    currentRef.api().applyOptions({ layout })
  }, [layout])

  return <Context.Provider value={chartApiRef.current}>{props.children}</Context.Provider>
})
ChartContainer.displayName = 'ChartContainer'

export const Series = forwardRef((props: any, ref) => {
  const parent: undefined | { api(): any; free(): void; _api?: any } = useContext(Context)
  const context = useRef<{ api(): any; free(): void; _api?: any }>({
    api(): any {
      if (!this._api && parent) {
        const { children, data, type, ...rest } = props
        switch (type) {
          case 'bar':
            this._api = parent.api().addBarSeries(rest)
            break
          case 'baseline':
            this._api = parent.api().addBaselineSeries(rest)
            break
          case 'candlestick':
            this._api = parent.api().addCandlestickSeries(rest)
            break
          case 'histogram':
            this._api = parent.api().addHistogramSeries(rest)
            break
          case 'line':
            this._api = parent.api().addLineSeries(rest)
            break
          case 'area':
          default:
            this._api = parent.api().addAreaSeries(rest)
        }
        this._api.setData(data)
      }
      return this._api
    },
    free() {
      if (this._api && parent) {
        parent.free()
      }
    },
  })

  useLayoutEffect(() => {
    const currentRef = context.current
    currentRef.api()

    return () => currentRef.free()
  }, [])

  useLayoutEffect(() => {
    const currentRef = context.current
    const { children, data, ...rest } = props
    currentRef.api().applyOptions(rest)
  })

  useLayoutEffect(() => {
    const currentRef = context.current
    currentRef.api().setData(props.data)
  }, [props.data])

  useImperativeHandle(ref, () => context.current.api(), [])

  return <Context.Provider value={context.current}>{props.children}</Context.Provider>
})
Series.displayName = 'Series'

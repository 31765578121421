import { TransactionResponse } from '@ethersproject/providers'
import { parseUnits } from '@ethersproject/units'
import { Trans } from '@lingui/macro'
import { CurrencyAmount, NativeCurrency, Token } from '@uniswap/sdk-core'
import { NULL_TOKEN } from 'constants/tokens'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import JSBI from 'jsbi'
import tryParseCurrencyAmount from 'lib/utils/tryParseCurrencyAmount'
import { ClickableText } from 'pages/Pool/styleds'
import { ReactNode, useCallback, useEffect, useState } from 'react'
import { DetailedPerpPool, usePoolContract } from 'state/perpetuals/hooks'
import styled from 'styled-components/macro'
import simpleBeautifyNumber from 'utils/simpleBeautifyNumber'

import { ApprovalState, useApproveCallback } from '../../hooks/useApproveCallback'
import useTransactionDeadline from '../../hooks/useTransactionDeadline'
import { TransactionType } from '../../state/transactions/actions'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { CloseIcon, ThemedText } from '../../theme'
import { formatCurrencyAmount } from '../../utils/formatCurrencyAmount'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { ButtonConfirmed, ButtonError } from '../Button'
import { AutoColumn } from '../Column'
import CurrencyInputPanel from '../CurrencyInputPanel'
import Modal from '../Modal'
import { LoadingView, SubmittedView } from '../ModalViews'
import Row, { RowBetween } from '../Row'

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 1rem;
`
const StakeClickableText = styled(ClickableText)<{ selected: boolean }>`
  color: ${({ selected, theme }) => (selected ? theme.primary1 : theme.bg5)};
`

interface ManagePerpModalProps {
  isOpen: boolean
  onDismiss: () => void
  poolInfo: DetailedPerpPool
  userLiquidityUnstaked: CurrencyAmount<Token | NativeCurrency> | undefined
  unstake?: boolean
}

export default function ManagePerpModal({
  isOpen,
  onDismiss,
  poolInfo,
  userLiquidityUnstaked,
  unstake = false,
}: ManagePerpModalProps) {
  const { account } = useActiveWeb3React()
  const poolContract = usePoolContract(poolInfo.poolAddress)

  // track and parse user input
  const [typedStakeValue, setTypedStakeValue] = useState('')
  const [typedUnstakeValue, setTypedUnstakeValue] = useState('')
  const [isUnstaking, setIsUnstaking] = useState(unstake)
  const parsedAmount = tryParseCurrencyAmount(typedStakeValue || typedUnstakeValue, poolInfo.token)

  useEffect(() => {
    if (unstake !== isUnstaking) {
      setIsUnstaking(unstake)
    }
  }, [unstake])

  let unstakeError: ReactNode | undefined
  if (!account) {
    unstakeError = <Trans>Connect a wallet</Trans>
  }
  if (!poolInfo.userBalance) {
    unstakeError = unstakeError ?? <Trans>Enter an amount</Trans>
  }

  // state for pending and submitted txn views
  const addTransaction = useTransactionAdder()
  const [attempting, setAttempting] = useState<boolean>(false)
  const [hash, setHash] = useState<string | undefined>()
  const wrappedOnDismiss = useCallback(() => {
    setHash(undefined)
    setAttempting(false)
    onDismiss()
  }, [onDismiss])

  // approval data for stake
  const deadline = useTransactionDeadline()
  const [signatureData, setSignatureData] = useState<{ v: number; r: string; s: string; deadline: number } | null>(null)
  const [approval, approveCallback] = useApproveCallback(parsedAmount, poolInfo.poolAddress)
  const maxUnstakeAmountInput = tryParseCurrencyAmount(String(poolInfo.userBalance), NULL_TOKEN)

  async function onStake() {
    setAttempting(true)

    if (poolContract && parsedAmount && deadline) {
      const { isNative } = poolInfo.token
      let depositEth = parseUnits('0', 18)

      if (isNative) {
        depositEth = parseUnits(typedStakeValue, 18)
      }
      await poolContract
        .deposit(parseUnits(typedStakeValue, 18), { value: depositEth })
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            type: TransactionType.DEPOSIT_SINGLE_STAKING,
            amount: simpleBeautifyNumber(typedStakeValue),
            currencySymbol: poolInfo.token.symbol ?? 'TOKEN',
          })
          setTypedStakeValue('')
          setHash(response.hash)
        })
        .catch((error: any) => {
          setAttempting(false)
          console.log(error)
        })
    } else {
      setAttempting(false)
      throw new Error('Attempting to stake without approval or a signature. Please contact support.')
    }
  }

  async function onWithdraw() {
    setAttempting(true)
    if (poolContract && poolInfo.userBalance) {
      const parsedAmount = parseUnits(typedUnstakeValue, 18)
      await poolContract
        .withdraw(parsedAmount)
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            type: TransactionType.WITHDRAW_SINGLE_STAKING,
            amount: simpleBeautifyNumber(typedUnstakeValue),
            currencySymbol: poolInfo.token.symbol ?? 'TOKEN',
          })
          setTypedUnstakeValue('')
          setHash(response.hash)
        })
        .catch((error: any) => {
          setAttempting(false)
          console.log(error)
        })
    }
  }

  function handleStaking() {
    setIsUnstaking(false)
    setTypedStakeValue('')
    setTypedUnstakeValue('')
  }

  function handleUnstaking() {
    setIsUnstaking(true)
    setTypedStakeValue('')
    setTypedUnstakeValue('')
  }

  // wrapped onUserInput to clear signatures
  const onUserInput = useCallback(
    (typedValue: string) => {
      setSignatureData(null)
      if (!isUnstaking) {
        setTypedStakeValue(typedValue)
      } else {
        setTypedUnstakeValue(typedValue)
      }
    },
    [isUnstaking]
  )

  // used for max input button
  const maxStakeAmountInput = maxAmountSpend(userLiquidityUnstaked)
  const atMaxAmount = Boolean(maxStakeAmountInput && parsedAmount?.equalTo(maxStakeAmountInput))
  const atMaxUnstakeAmount = Boolean(
    maxUnstakeAmountInput && tryParseCurrencyAmount(typedUnstakeValue, NULL_TOKEN) === maxUnstakeAmountInput
  )
  const handleMax = useCallback(() => {
    if (!isUnstaking && maxStakeAmountInput) {
      onUserInput(maxStakeAmountInput.toExact())
    }
    if (isUnstaking && maxUnstakeAmountInput) {
      onUserInput(maxUnstakeAmountInput.toExact())
    }
  }, [isUnstaking, maxStakeAmountInput, maxUnstakeAmountInput, onUserInput])

  return (
    <Modal isOpen={isOpen} onDismiss={wrappedOnDismiss} maxHeight={100}>
      {!attempting && !hash && (
        <div style={{ width: '100%' }}>
          <ContentWrapper gap="lg">
            <RowBetween>
              <ThemedText.MediumHeader>
                {!isUnstaking ? <Trans>Deposit</Trans> : <Trans>Withdraw</Trans>}
              </ThemedText.MediumHeader>
              <CloseIcon onClick={wrappedOnDismiss} />
            </RowBetween>
            {!(poolInfo.userBalance === 0 || userLiquidityUnstaked?.equalTo(JSBI.BigInt(0))) && (
              <RowBetween style={{ justifyContent: 'end' }}>
                <Row>
                  <StakeClickableText selected={!isUnstaking} style={{ paddingRight: '10px' }} onClick={handleStaking}>
                    Stake
                  </StakeClickableText>
                  <StakeClickableText selected={isUnstaking} onClick={handleUnstaking}>
                    Unstake
                  </StakeClickableText>
                </Row>
              </RowBetween>
            )}
            <CurrencyInputPanel
              value={!isUnstaking ? typedStakeValue : typedUnstakeValue}
              onUserInput={onUserInput}
              onMax={handleMax}
              showMaxButton={!isUnstaking ? !atMaxAmount : !atMaxUnstakeAmount}
              currency={poolInfo.token}
              label={''}
              renderBalance={(amount) =>
                !isUnstaking ? (
                  <Trans>Available to deposit: {formatCurrencyAmount(amount, 4)} </Trans>
                ) : (
                  <Trans>Available to withdraw: {formatCurrencyAmount(maxUnstakeAmountInput, 4)}</Trans>
                )
              }
              id="stake-liquidity-token"
            />
            <Trans>Note: Once you deposit there{"'"}s a 1 hour withdrawal cooldown</Trans>
          </ContentWrapper>
          {!isUnstaking ? (
            <RowBetween style={{ height: '3.25rem' }}>
              {!(approval === ApprovalState.APPROVED || signatureData !== null || poolInfo.token.isNative) && (
                <ButtonConfirmed
                  mr="0"
                  style={{ height: '100%' }}
                  onClick={approveCallback}
                  disabled={approval !== ApprovalState.NOT_APPROVED || signatureData !== null}
                >
                  <Trans>Approve</Trans>
                </ButtonConfirmed>
              )}
              <ButtonError
                style={{ height: '100%' }}
                disabled={signatureData === null && approval !== ApprovalState.APPROVED}
                error={!!parsedAmount}
                onClick={onStake}
              >
                <Trans>Deposit</Trans>
              </ButtonError>
            </RowBetween>
          ) : (
            <ButtonError
              disabled={!!unstakeError || !typedUnstakeValue}
              error={!!unstakeError && !!poolInfo.userBalance}
              onClick={onWithdraw}
            >
              {unstakeError ?? <Trans>Withdraw</Trans>}
            </ButtonError>
          )}
        </div>
      )}
      {attempting && !hash && (
        <LoadingView onDismiss={wrappedOnDismiss}>
          {!isUnstaking ? (
            <AutoColumn gap="12px" justify={'center'}>
              <ThemedText.LargeHeader>
                <Trans>Depositing Liquidity</Trans>
              </ThemedText.LargeHeader>
              <ThemedText.Body fontSize={20}>
                <Trans>
                  {parsedAmount?.toSignificant(4)} {poolInfo.token.symbol || poolInfo.symbol}
                </Trans>
              </ThemedText.Body>
            </AutoColumn>
          ) : (
            <AutoColumn gap="12px" justify={'center'}>
              <ThemedText.Body fontSize={20}>
                <Trans>
                  Withdrawing {typedUnstakeValue} {poolInfo.token.symbol || poolInfo.symbol}
                </Trans>
              </ThemedText.Body>
            </AutoColumn>
          )}
        </LoadingView>
      )}
      {!!hash && (
        <SubmittedView onDismiss={wrappedOnDismiss} hash={hash}>
          <AutoColumn gap="12px" justify={'center'}>
            <ThemedText.LargeHeader>
              <Trans>Transaction Submitted</Trans>
            </ThemedText.LargeHeader>
            {!isUnstaking ? (
              <ThemedText.Body fontSize={20}>
                <Trans>
                  Deposited {parsedAmount?.toSignificant(4)} {poolInfo.token.symbol || poolInfo.symbol}
                </Trans>
              </ThemedText.Body>
            ) : (
              <>
                <ThemedText.Body fontSize={20}>
                  <Trans>Withdrew {poolInfo.token.symbol || poolInfo.symbol}!</Trans>
                </ThemedText.Body>
              </>
            )}
          </AutoColumn>
        </SubmittedView>
      )}
    </Modal>
  )
}

import { BaseQueryFn } from '@reduxjs/toolkit/query'
import { createApi } from '@reduxjs/toolkit/query/react'
import { SupportedChainId } from 'constants/chains'
import { DocumentNode } from 'graphql'
import { ClientError, gql, GraphQLClient } from 'graphql-request'
import { AppState } from 'state'

// List of supported subgraphs. Note that the app currently only support one active subgraph at a time
const CHAIN_SUBGRAPH_URL: Record<number, string> = {
  [SupportedChainId.OPTIMISM]: 'https://api.thegraph.com/subgraphs/name/barenfels/crn-referrals',
  [SupportedChainId.OPTIMISM_GOERLI]: 'https://api.thegraph.com/subgraphs/name/barenfels/refer-stuff',
}

export const api = createApi({
  reducerPath: 'refApi',
  baseQuery: graphqlRequestBaseQuery(),
  endpoints: (builder) => ({
    getReferralCodes: builder.query({
      query: ({ address }) => ({
        document: gql`
          query getReferralCodes($address: String) {
            referralCodes(first: 1, orderBy: timestamp, orderDirection: desc, where: { owner: $address }) {
              code
            }
          }
        `,
        variables: {
          address,
        },
      }),
    }),
    getReferrerStats: builder.query({
      query: ({ referralCode }) => ({
        document: gql`
          query getReferrerStats($referralCode: String) {
            referrerStats(first: 1, where: { referralCode: $referralCode, period: total }) {
              referrer
              id
              referralCode
              volume
              volumeCumulative
              trades
              tradesCumulative
              tradedReferralsCount
              tradedReferralsCountCumulative
              registeredReferralsCount
              registeredReferralsCountCumulative
              totalRebateUsd
              totalRebateUsdCumulative
              discountUsd
              discountUsdCumulative
            }
          }
        `,
        variables: {
          referralCode,
        },
      }),
    }),
    getReferral: builder.query({
      query: ({ address }) => ({
        document: gql`
          query getReferral($address: String) {
            registeredReferrals(first: 1, where: { referral: $address }) {
              referral
            }
          }
        `,
        variables: {
          address,
        },
      }),
    }),
    getReferrerLeaderboard: builder.query({
      query: ({ first, skip, orderBy, orderDirection }) => ({
        document: gql`
          query getReferrerLeaderboard(
            $first: Int
            $skip: Int
            $orderBy: ReferrerStat_orderBy
            $orderDirection: OrderDirection
          ) {
            referrerStats(
              first: $first
              skip: $skip
              orderBy: $orderBy
              orderDirection: $orderDirection
              where: { registeredReferralsCountCumulative_gte: 1, period: total }
            ) {
              id
              registeredReferralsCountCumulative
              volumeCumulative
              referralCode
            }
          }
        `,
        variables: {
          first,
          skip,
          orderBy,
          orderDirection,
        },
      }),
    }),
  }),
})

// Graphql query client wrapper that builds a dynamic url based on chain id
function graphqlRequestBaseQuery(): BaseQueryFn<
  { document: string | DocumentNode; variables?: any },
  unknown,
  Pick<ClientError, 'name' | 'message' | 'stack'>,
  Partial<Pick<ClientError, 'request' | 'response'>>
> {
  return async ({ document, variables }, { getState }) => {
    try {
      const queryVariables = variables
      const queryChainId = queryVariables.chainId ?? (getState() as AppState).application.chainId
      delete queryVariables.chainId

      const subgraphUrl = queryChainId ? CHAIN_SUBGRAPH_URL[queryChainId] : undefined

      if (!subgraphUrl) {
        return {
          error: {
            name: 'UnsupportedChainId',
            message: `Subgraph queries against ChainId ${queryChainId} are not supported.`,
            stack: '',
          },
        }
      }

      return { data: await new GraphQLClient(subgraphUrl).request(document, queryVariables), meta: {} }
    } catch (error) {
      if (error instanceof ClientError) {
        const { name, message, stack, request, response } = error
        return { error: { name, message, stack }, meta: { request, response } }
      }
      throw error
    }
  }
}

import 'rc-slider/assets/index.css'
import './overrides.css'

import { CaretDownGlyph } from '@carbon/icons-react'
import { keccak256 as solidityKeccak256 } from '@ethersproject/solidity'
import Column from 'components/Column'
import { LedgerContext } from 'components/LedgerProvider'
import { disableOpacityMixin, loadingOpacityMixin } from 'components/Loader/styled'
import { PriceContext } from 'components/PriceProvider'
import Row, { RowBetween } from 'components/Row'
import { Dots } from 'components/swap/styleds'
import { ZERO_ADDRESS } from 'constants/misc'
import { Collateral, Product, STABLE_COLLATERALS } from 'constants/perps'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import useDebounce from 'hooks/useDebounce'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import usePreviousConditional from 'hooks/usePreviousConditional'
import useCurrencyBalance from 'lib/hooks/useCurrencyBalance'
import { darken, transparentize } from 'polished'
import Slider from 'rc-slider'
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useCollateralModalToggle, useModalOpen, useWalletModalToggle } from 'state/application/hooks'
import { ApplicationModal } from 'state/application/reducer'
import { Order } from 'state/perpetuals/hooks'
import styled, { useTheme } from 'styled-components/macro'
import { ThemedText } from 'theme'

import { Input as NumericalInput } from '../NumericalInput'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0;
  background: ${({ theme }) => theme.bg0};
`

const StyledNumericalInput = styled(NumericalInput)<{ $loading: boolean; $disable: boolean }>`
  ${loadingOpacityMixin};
  ${disableOpacityMixin}
  font-size: 1rem;
  text-align: left;
`

const InputRow = styled.div<{ selected: boolean }>`
  ${({ theme }) => theme.flexRowNoWrap}
  border: 1px solid ${({ theme }) => theme.bg2};
  border-radius: 0.25rem;
  padding: 0 0.5rem;
  align-items: center;
  justify-content: space-between;
  height: 2.5rem;
  // padding: ${({ selected }) => (selected ? ' 1rem 1rem 0.75rem 1rem' : '1rem 1rem 1rem 1rem')};
`
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: row;
    gap: 1rem;
    > div {
      flex: 0 1 50%;
    }
  `}
`

const ReversableColumn = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column-reverse;
    margin-top: 0.5rem;
  `};
`
const TradeButton = styled.button<{ selected?: boolean }>`
  background: ${({ theme, selected }) => (selected ? theme.bg1 : theme.bg0)};
  font-weight: ${({ selected }) => (selected ? 600 : 400)};
  color: ${({ theme }) => theme.text1};
  padding: 0.25rem 0.5rem;
  border-radius: 0;
  border: 1px solid ${({ theme }) => theme.bg3};
  flex: 1 1 33.33%;
  cursor: pointer;
  &:hover,
  &:focus {
    background: ${({ theme }) => theme.bg1};
  }
  &:first-child {
    border-radius: 0.25rem 0 0 0.25rem;
    border-right: 0;
  }
  &:last-child {
    border-radius: 0 0.25rem 0.25rem 0;
    border-left: 0;
  }
`

const SizeButton = styled.button<{ selected?: boolean }>`
  background: ${({ theme, selected }) => (selected ? theme.bg1 : theme.bg0)};
  font-size: 10px;
  font-weight: ${({ selected }) => (selected ? 400 : 300)};
  color: ${({ theme }) => theme.text1};
  padding: 0.25rem 0.5rem;
  border: none;
  flex: 1 1 33.33%;
  border-radius: 0.25rem;

  cursor: pointer;
  &:focus {
    background: ${({ theme, selected }) => (selected ? theme.bg1 : theme.bg0)};
  }

  &:hover {
    background: ${({ theme }) => theme.bg1};
  }

  &:first-child {
    border-right: 0;
  }
  &:last-child {
    border-left: 0;
  }
`

const ActionButton = styled.button<{ long?: boolean; disabled?: boolean }>`
  background: ${({ theme, long, disabled }) =>
    disabled
      ? long
        ? transparentize('0.50', theme.green1)
        : transparentize('0.50', theme.red2)
      : long
      ? theme.green1
      : theme.red2};
  font-weight: 600;
  color: ${({ theme, disabled }) => (disabled ? transparentize('0.50', theme.white) : theme.white)};
  padding: 1rem 1.5rem;
  border-radius: 0.25rem;
  border: 0 solid transparent;
  flex: 1 1 33.33%;
  height: 100%;
  cursor: pointer;
  &:hover,
  &:focus {
    background: ${({ theme, long, disabled }) =>
      disabled
        ? long
          ? transparentize('0.50', theme.green1)
          : transparentize('0.50', theme.red2)
        : darken(0.05, long ? theme.green1 : theme.red2)};
  }
`

const ConnectButton = styled.button`
  background: ${({ theme }) => theme.primary1};
  font-weight: 600;
  color: ${({ theme }) => theme.white};
  padding: 1rem 1.5rem;
  border-radius: 0.25rem;
  border: 0 solid transparent;
  flex: 1 1 33.33%;
  height: 100%;
  cursor: pointer;
  &:hover,
  &:focus {
    background: ${({ theme }) => darken(0.05, theme.primary1)};
  }
`

const TransparentButton = styled.button<{ multipleChoice?: boolean }>`
  border: none;
  display: flex;
  align-items: center;
  background-color: transparent;
  color: ${({ theme }) => theme.text1};
  padding: 0;
  > *:last-child {
    display: none;
  }
  ${({ multipleChoice }) =>
    multipleChoice &&
    `
    cursor: pointer;
    gap: 0.35rem;
    > *:last-child {
      display: block;
    }
  `}
`

const CollateralButton = styled.button<{ disabled?: boolean }>`
  border: none;
  display: flex;
  align-items: center;
  text-align: center;
  background-color: transparent;
  color: ${({ theme, disabled }) => (disabled ? transparentize(0.3, theme.text1) : theme.text1)};
  padding: 0;
  cursor: pointer;
`

const StyledSlider = styled(Slider)`
  flex: 0 1 80%;
  .rc-slider-track {
    border-radius: 0;
    background-color: ${({ theme }) => theme.primary1};
  }
  .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    border-color: #57c5f7;
    box-shadow: 0 0 0 5px #96dbfa;
  }
  .rc-slider-handle:focus {
    outline: none;
    box-shadow: none;
  }
  .rc-slider-handle:focus-visible {
    border-color: #2db7f5;
    box-shadow: 0 0 0 3px #96dbfa;
  }
  .rc-slider-handle-click-focused:focus {
    border-color: #96dbfa;
    box-shadow: unset;
  }
  .rc-slider-handle:hover {
    border-color: #57c5f7;
  }
  .rc-slider-handle:active {
    border-color: #57c5f7;
    box-shadow: 0 0 5px #57c5f7;
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }

  .rc-slider-rail {
    border-radius: 0;
    background-color: ${({ theme }) => theme.bg3};
  }

  .rc-slider-handle {
    border: solid 2px transparent;
    background-color: ${({ theme }) => theme.primary1};
    opacity: 1;
  }

  .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    border-color: transparent;
    box-shadow: 0 0 0 5px transparent;
  }
  .rc-slider-handle:focus {
    outline: none;
    box-shadow: none;
  }
  .rc-slider-handle:focus-visible {
    border-color: transparent;
    box-shadow: 0 0 0 3px transparent;
  }
  .rc-slider-handle-click-focused:focus {
    border-color: transparent;
    box-shadow: unset;
  }
  .rc-slider-handle:hover {
    border-color: transparent;
  }
  .rc-slider-handle:active {
    border-color: transparent;
    box-shadow: 0 0 5px transparent;
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }

  .rc-slider-mark-text {
    display: none;
  }

  .rc-slider-dot {
    border-radius: 0;
    background-color: transparent;
    border: 2px solid ${({ theme }) => theme.bg3};
  }

  .rc-slider-dot-active {
    border-color: ${({ theme }) => theme.primary1};
    background-color: ${({ theme }) => theme.primary1};
  }
`

const FlyoutMenu = styled.div`
  align-items: flex-center;
  gap: 0.5rem;
  background-color: ${({ theme }) => theme.bg1};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  overflow: auto;
  padding: 16px;
  position: absolute;
  right: -0.5rem;
  top: 1.25rem;
  z-index: 99;
  & > *:not(:last-child) {
    margin-bottom: 12px;
  }
`

const CollateralWrapper = styled.div`
  position: relative;
`

export default function TradeManager({
  product,
  selectedCollateral,
  quantity,
  value,
  leverage,
  maxLeverage,
  liqPrice,
  breakpoint,
  height,
  onChangeCollateral,
  onOpenOrder,
  onLiqPrice,
  onUserLeverage,
  onUserQuantity,
  onUserValue,
}: {
  product: Product
  selectedCollateral: Collateral
  quantity: string
  value: string
  leverage: number
  maxLeverage: number
  breakpoint?: string
  height: number
  liqPrice: string[]
  onChangeCollateral: (collateral: Collateral) => void
  onOpenOrder: (long: boolean) => void
  onUserLeverage: (leverage: number) => void
  onUserQuantity: (quantity: string) => void
  onUserValue: (value: string) => void
  onLiqPrice: (prices: string[]) => void
}) {
  const { account } = useActiveWeb3React()
  const { text3, green1, red2 } = useTheme()
  const toggleWalletModal = useWalletModalToggle()

  const { collaterals, symbol, token, liqThreshold } = product
  const productSymbol = symbol ?? token?.symbol

  const [tradeType, setTradeType] = useState('limit') // useless on v1.0.0

  const [inputIsValue, setInputIsValue] = useState(true)
  const [count, setCount] = useState(0)

  const balanceRaw = useCurrencyBalance(account ?? undefined, selectedCollateral.token)
  const balance = usePreviousConditional(balanceRaw, balanceRaw !== undefined)
  const debouncedLeverage = useDebounce(leverage, 200)

  const DataContext = useContext(LedgerContext) as Record<string, unknown>
  const orders = useMemo(() => {
    if ('orders' in DataContext) return DataContext.orders
  }, [DataContext]) as Order[]

  const [longKeccak, shortKeccak] = useMemo(() => {
    if (account) {
      const tokenAddress = selectedCollateral.token.isNative ? ZERO_ADDRESS : selectedCollateral.token.address
      const long = solidityKeccak256(
        ['address', 'bytes32', 'address', 'bool'],
        [account, product.bytes32Id, tokenAddress, true]
      )
      const short = solidityKeccak256(
        ['address', 'bytes32', 'address', 'bool'],
        [account, product.bytes32Id, tokenAddress, false]
      )
      return [long, short]
    }
    return ['', '']
  }, [account, product.bytes32Id, selectedCollateral.token])
  const [canLong, canShort] = useMemo(() => {
    const canLong = !!!orders.find((order) => order.key === longKeccak)
    const canShort = !!!orders.find((order) => order.key === shortKeccak)
    return [canLong, canShort]
  }, [longKeccak, orders, shortKeccak])

  const PriceCtx = useContext(PriceContext) as any
  const productPrice = PriceCtx[product.id]
  const collateralPrice = useMemo(() => {
    if (STABLE_COLLATERALS.indexOf(selectedCollateral.id) >= 0) {
      return { price: '1' }
    } else {
      return PriceCtx[selectedCollateral.apiId]
    }
  }, [PriceCtx, selectedCollateral])

  useEffect(() => {
    const price = productPrice?.price ? +productPrice.price.split(',').join('') : 0
    const multiplier = 1 - (1 - liqThreshold) / debouncedLeverage

    const liquidationPriceLong = price * multiplier
    const liquidationPriceShort = price / multiplier

    onLiqPrice([liquidationPriceLong.toFixed(2), liquidationPriceShort.toFixed(2)])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedLeverage, product, count, inputIsValue, quantity, value])

  const node = useRef<HTMLDivElement>()
  const collateralFlyoutOpen = useModalOpen(ApplicationModal.COLLATERAL_SELECTOR)
  const toggle = useCollateralModalToggle()
  useOnClickOutside(node, collateralFlyoutOpen ? toggle : undefined)

  const loading = false
  const disable = false

  const setValueByPercent = (value: number) => {
    if (!account) {
      return
    }
    setInputIsValue(true)
    setCount((prev) => prev + 1)
    onUserValue(String((balance ? +balance.toFixed(2) / 100 : 0) * value))
  }

  const handleUserQuantity = (quantity: string) => {
    setInputIsValue(false)
    onUserQuantity(quantity)
  }

  const handleUserValue = useCallback(
    (value: string) => {
      setInputIsValue(true)
      onUserValue(value)
    },
    [onUserValue]
  )

  const handleOpenOrder = (long: boolean) => {
    setInputIsValue(true)
    onOpenOrder(long)
  }

  const changeCollateral = (collateral: Collateral) => {
    toggle()
    onChangeCollateral(collateral)
  }

  const changeLeverage = (newLeverage: number) => {
    onUserLeverage(newLeverage)
  }

  const marks = useMemo(() => {
    if (maxLeverage === 1) {
      return { 0.5: '0.50x' }
    } else if (maxLeverage === 2) {
      return { 0.5: '0.50x', 1: '1.00x', 1.5: '1.50x' }
    } else if (maxLeverage === 3) {
      return { 2: '2.00x' }
    } else if (maxLeverage === 4) {
      return { 2: '2.00x', 3: '3.00x' }
    } else if (maxLeverage === 5) {
      return { 2: '2.00x', 3: '3.00x', 4: '4.00x' }
    } else if (maxLeverage % 5) {
      return {}
    } else if (maxLeverage >= 200) {
      const m = maxLeverage / 20 - 1
      const array = Array.from({ length: m }, (_, i) => i + 1)
      return array.reduce((accumulator, element) => {
        const perTen = element * 20
        return { ...accumulator, [perTen]: `${perTen}.00x` }
      }, {})
    } else if (maxLeverage >= 100) {
      const m = maxLeverage / 10 - 1
      const array = Array.from({ length: m }, (_, i) => i + 1)
      return array.reduce((accumulator, element) => {
        const perTen = element * 10
        return { ...accumulator, [perTen]: `${perTen}.00x` }
      }, {})
    } else {
      const m = maxLeverage / 5 - 1
      const array = Array.from({ length: m }, (_, i) => i + 1)
      return array.reduce((accumulator, element) => {
        const perFive = element * 5
        return { ...accumulator, [perFive]: `${perFive}.00x` }
      }, {})
    }
  }, [maxLeverage])

  useEffect(() => {
    const price = productPrice?.price ? +productPrice.price.split(',').join('') : 0
    const priceCollateral = collateralPrice?.price ? +collateralPrice.price.split(',').join('') : 0
    const exchangeRate = price / priceCollateral
    if (inputIsValue) {
      let newQuantity = 0
      if (selectedCollateral.token === product.token) {
        newQuantity = +value * debouncedLeverage || 0
      } else {
        newQuantity = (+value * debouncedLeverage) / +exchangeRate || 0
      }
      onUserQuantity(String(parseFloat(newQuantity.toFixed(5))))
    } else if (!inputIsValue) {
      let newValue = 0
      if (selectedCollateral.token === product.token) {
        newValue = +quantity / debouncedLeverage || 0
      } else {
        newValue = (+quantity / debouncedLeverage) * +exchangeRate || 0
      }
      onUserValue(String(parseFloat(newValue.toFixed(5))))
    }
  }, [value, quantity, debouncedLeverage, count, productPrice])

  return (
    <Wrapper>
      <RowBetween className="drag-header" style={{ padding: '0.5rem 0.75rem' }}>
        <Row style={{ display: 'flex', alignItems: 'center' }}>
          <ThemedText.Small fontWeight={600}>Trade</ThemedText.Small>
        </Row>
      </RowBetween>
      <Column style={{ padding: '0.25rem 0.75rem 0.75rem 0.75rem', flex: '1 1 50%', overflow: 'hidden' }}>
        {false && (
          <Row justify="space-between" style={{ marginTop: '0.25rem', marginBottom: '1.25rem' }}>
            <TradeButton selected={tradeType === 'limit'} onClick={() => setTradeType('limit')}>
              Limit
            </TradeButton>
            <TradeButton selected={tradeType === 'market'} onClick={() => setTradeType('market')}>
              Market
            </TradeButton>
            <TradeButton selected={tradeType === 'stop'} onClick={() => setTradeType('stop')}>
              Stop
            </TradeButton>
          </Row>
        )}
        <InputContainer>
          <Column>
            <ThemedText.Small color={text3} style={{ marginBottom: '0.25rem' }}>
              Quantity
            </ThemedText.Small>
            <InputRow style={{ marginBottom: '1rem' }} selected={false}>
              <StyledNumericalInput
                className="token-amount-input"
                value={quantity}
                onUserInput={handleUserQuantity}
                $loading={loading}
                $disable={disable}
                disable={disable}
              />
              <ThemedText.Small fontSize={12}>{productSymbol}</ThemedText.Small>
            </InputRow>
          </Column>
          <Column>
            <RowBetween style={{ marginBottom: '0.25rem' }}>
              <ThemedText.Small color={text3}>Margin</ThemedText.Small>
              <ThemedText.Small color={text3}>{account ? balance?.toFixed(2) || '0.00' : ''}</ThemedText.Small>
            </RowBetween>
            <InputRow style={{ marginBottom: '1rem' }} selected={false}>
              <StyledNumericalInput
                className="token-amount-input"
                value={value}
                onUserInput={handleUserValue}
                $loading={loading}
                $disable={disable}
                disable={disable}
              />
              <CollateralWrapper ref={node as any}>
                <TransparentButton
                  multipleChoice={collaterals.length > 1}
                  disabled={collaterals.length <= 1}
                  onClick={toggle}
                >
                  <ThemedText.Small fontSize={12}>
                    {selectedCollateral.symbol || selectedCollateral.token?.symbol}
                  </ThemedText.Small>
                  <CaretDownGlyph />
                </TransparentButton>
                {collateralFlyoutOpen && (
                  <FlyoutMenu>
                    {collaterals.map((collateral) => (
                      <CollateralButton
                        key={collateral.id + '-flyoutItem'}
                        disabled={collateral === selectedCollateral}
                        onClick={() => changeCollateral(collateral)}
                      >
                        {collateral.symbol || collateral.token?.symbol}
                      </CollateralButton>
                    ))}
                  </FlyoutMenu>
                )}
              </CollateralWrapper>
            </InputRow>
          </Column>
        </InputContainer>
        <Row justify="space-between" style={{ marginBottom: '1.5rem' }}>
          <SizeButton selected={false} onClick={() => setValueByPercent(10)}>
            10%
          </SizeButton>
          <SizeButton selected={false} onClick={() => setValueByPercent(25)}>
            25%
          </SizeButton>
          <SizeButton selected={false} onClick={() => setValueByPercent(50)}>
            50%
          </SizeButton>
          <SizeButton selected={false} onClick={() => setValueByPercent(75)}>
            75%
          </SizeButton>
          <SizeButton selected={false} onClick={() => setValueByPercent(100)}>
            100%
          </SizeButton>
        </Row>
        <ThemedText.Small color={text3} style={{ marginBottom: '1rem' }}>
          Leverage
        </ThemedText.Small>
        <Row
          align="center"
          style={{ justifyContent: 'space-between', gap: '0.75rem', width: '100%', marginBottom: '1rem' }}
        >
          <StyledSlider
            min={1}
            max={maxLeverage}
            step={0.5}
            defaultValue={5}
            value={leverage}
            onChange={(newValue) => changeLeverage(newValue as number)}
            marks={marks}
          />
          <ThemedText.Label fontSize={14} fontWeight={600} style={{ textAlign: 'right', flex: '1 0 auto' }}>
            {leverage.toFixed(2)}x
          </ThemedText.Label>
        </Row>
        <ReversableColumn>
          <Row style={{ gap: '1rem', marginTop: 'auto', marginBottom: '1.5rem' }}>
            <Column style={{ flex: '1 1 50%' }}>
              <ThemedText.Small color={text3} style={{ marginBottom: '0.25rem' }}>
                Order Value
              </ThemedText.Small>
              <ThemedText.Small fontSize={12}>
                {(+value * leverage).toFixed(2)} {selectedCollateral.symbol || selectedCollateral.token.symbol}
              </ThemedText.Small>
            </Column>
            <Column style={{ flex: '1 1 50%' }}>
              <ThemedText.Small color={text3} style={{ marginBottom: '0.25rem' }}>
                Liquidation Price
              </ThemedText.Small>
              <ThemedText.Small fontSize={12}>
                <span style={{ color: green1 }}>{liqPrice[0]}</span> /{' '}
                <span style={{ color: red2 }}>{liqPrice[1]}</span>
              </ThemedText.Small>
            </Column>
          </Row>
          {account ? (
            <Row style={{ gap: '1rem' }}>
              <ActionButton disabled={!canLong} long onClick={() => handleOpenOrder(true)}>
                {canLong ? 'Open Long' : <Dots>Settling order</Dots>}
              </ActionButton>
              <ActionButton disabled={!canShort} onClick={() => handleOpenOrder(false)}>
                {canShort ? 'Open Short' : <Dots>Settling order</Dots>}
              </ActionButton>
            </Row>
          ) : (
            <Row style={{ gap: '1rem' }}>
              <ConnectButton onClick={toggleWalletModal}>Connect Wallet</ConnectButton>
            </Row>
          )}
        </ReversableColumn>
      </Column>
    </Wrapper>
  )
}

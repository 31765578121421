import { SupportedChainId } from './chains'

const INFURA_KEY = process.env.REACT_APP_INFURA_KEY
if (typeof INFURA_KEY === 'undefined') {
  throw new Error(`REACT_APP_INFURA_KEY must be a defined environment variable`)
}

/**
 * These are the network URLs used by the interface when there is not another available source of chain data
 */
export const INFURA_NETWORK_URLS: { [key in SupportedChainId]: string } = {
  [SupportedChainId.MAINNET]: `https://eth.bd.evmos.org:8545`,
  [SupportedChainId.OPTIMISM]: `https://optimism-mainnet.public.blastapi.io`,
  [SupportedChainId.ARBITRUM]: `https://rpc.ankr.com/arbitrum`,
  [SupportedChainId.OPTIMISM_GOERLI]: `https://goerli.optimism.io/`,
  [SupportedChainId.EVMOS_TESTNET]: `https://eth.bd.evmos.dev:8545/`,
  [SupportedChainId.ETHEREUM]: `https://rpc.flashbots.net`,
  [SupportedChainId.RINKEBY]: `https://rinkeby.infura.io/v3/${INFURA_KEY}`,
  [SupportedChainId.NULL_CHAIN]: `https://arbitrum-rinkeby.infura.io/v3/${INFURA_KEY}`,
}

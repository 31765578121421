import { STABLE_COLLATERALS } from 'constants/perps'
import { createContext, ReactNode, useEffect, useState } from 'react'
import useWebSocket from 'react-use-websocket'
import simpleBeautifyNumber from 'utils/simpleBeautifyNumber'

export enum PRICE_STATUS {
  UNINITIALIZED = 'uninitialized',
  LOADING = 'loading',
  READY = 'ready',
  ERROR = 'error',
  STALE = 'stale',
}

type TimeElement = {
  time: string
  price: string
  open_24h: string
  low_24h: string
  volume_24h: string
  diff_24h: string
  diffSide: boolean
  sideFromLast: boolean
}

export const PriceContext = createContext({})

function areEqual(array1: any[], array2: any[]): boolean {
  if (array1.length === array2.length) {
    return array1.every((element) => {
      if (array2.includes(element)) {
        return true
      }

      return false
    })
  }

  return false
}

function onlyUnique(value: string, index: number, self: string[]) {
  return self.indexOf(value) === index
}

export default function PriceProvider({
  productIds,
  collaterals,
  onStatusChange,
  children,
}: {
  productIds: string[]
  collaterals: string[]
  onStatusChange: (status: PRICE_STATUS) => void
  children: ReactNode
}) {
  const [ctx, setCtx] = useState<{ [k: string]: TimeElement }>({})

  const [activeProducts, setActiveProducts] = useState<string[] | never[]>([])

  useEffect(() => {
    const newActiveProducts = [...new Set([...productIds.filter(onlyUnique), ...collaterals.filter(onlyUnique)])]
    const coinbaseIds = newActiveProducts
      .map((id) => {
        if (STABLE_COLLATERALS.indexOf(id) + 1) {
          return undefined
        }
        return id.toUpperCase() + '-USD'
      })
      .filter((id) => id !== undefined) as string[]

    if (!areEqual(coinbaseIds, activeProducts)) {
      setActiveProducts(coinbaseIds)
    }
  }, [productIds, collaterals, activeProducts])

  const [socketUrl, setSocketUrl] = useState('wss://ws-feed.exchange.coinbase.com')

  const [messageHistory, setMessageHistory] = useState<{ [k: string]: TimeElement[] } | Record<string, unknown>>({})

  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl)

  useEffect(() => {
    sendMessage(
      JSON.stringify({
        type: 'unsubscribe',
        channels: ['ticker_batch'],
      })
    )
    if (activeProducts.length) {
      sendMessage(
        JSON.stringify({
          type: 'subscribe',
          product_ids: activeProducts,
          channels: ['ticker_batch'],
        })
      )
    }
    return () => {
      sendMessage(
        JSON.stringify({
          type: 'unsubscribe',
          channels: ['ticker_batch'],
        })
      )
    }
  }, [activeProducts, sendMessage])

  useEffect(() => {
    if (!lastMessage) return
    const message = JSON.parse(lastMessage?.data)

    const { type, product_id, open_24h, price, volume_24h, low_24h, high_24h, time } = message
    if (type !== 'ticker') return

    const product = product_id.split('-')[0].toLowerCase()

    const diffSide = price > open_24h

    const diff_24h = diffSide ? ((price - open_24h) / open_24h) * 100 : ((open_24h - price) / open_24h) * 100

    const newElement = {
      time,
      price: simpleBeautifyNumber(price),
      diffSide,
      diff_24h: simpleBeautifyNumber(diff_24h),
      open_24h: simpleBeautifyNumber(open_24h),
      volume_24h: simpleBeautifyNumber(volume_24h),
      low_24h: simpleBeautifyNumber(low_24h),
      high_24h: simpleBeautifyNumber(high_24h),
    }
    setMessageHistory((prev) => {
      const prevProduct = prev[product] ? prev[product] : []
      const prevMessage = (prevProduct as TimeElement[]).slice(-1).pop()

      const sideFromLast =
        prevMessage && +price !== +prevMessage.price.split(',').join()
          ? +price > +prevMessage.price.split(',').join()
            ? true
            : false
          : undefined

      return {
        ...prev,
        [product]: [...(prevProduct as TimeElement[]), { ...newElement, sideFromLast }],
      }
    })
    setCtx((prev) => {
      const prevProduct = prev[product] ? prev[product] : undefined

      const sideFromLast =
        prevProduct && +price !== +prevProduct.price.split(',').join()
          ? +price > +prevProduct.price.split(',').join()
            ? true
            : false
          : undefined

      return { ...prev, [product]: { ...newElement, sideFromLast } }
    })
  }, [lastMessage, setMessageHistory])

  return <PriceContext.Provider value={ctx}>{children}</PriceContext.Provider>
}

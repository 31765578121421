import { Share16 } from '@carbon/icons-react'
import { parseUnits } from '@ethersproject/units'
import Column from 'components/Column'
import { EnhancedPosition } from 'components/LedgerProvider'
import Row from 'components/Row'
import { Collateral, Product } from 'constants/perps'
import { Dots } from 'pages/Pool/styleds'
import { useMemo, useState } from 'react'
import { useTheme } from 'styled-components/macro'
import { ThemedText } from 'theme'
import beautify from 'utils/simpleBeautifyNumber'

import { CardProps, TABS } from './common'
import {
  ButtonContent,
  CellContent,
  CloseButton,
  Divider,
  LiqPriceRow,
  PnlContainer,
  PnlContainerSmall,
  PositionRow,
  TitleSmall,
} from './styled'

export function PositionItem({
  position,
  last,
  onClosePosition,
  onCancelClose,
  onProductChange,
  onOpenCardModal,
  onSetTab,
  onUserSettleOrder,
}: {
  position: EnhancedPosition
  last?: boolean
  onProductChange: (productId: string) => void
  onOpenCardModal: (props: CardProps) => void
  onSetTab: (newTab: string) => void
  onCancelClose: OrderFunction
  onClosePosition: OrderFunction
  onUserSettleOrder: (margin: number, product: Product, collateral: Collateral, isLong: boolean, price: number) => void
}) {
  const {
    product,
    collateral,
    leverage,
    isLong,
    price: entryPrice,
    value,
    margin,
    // timestamp,
    isClosing,
    markPrice,
    pnl,
    pnlPercent,
    liquidationPrice,
  } = position

  // const date = new Date(timestamp) // @TODO: once graphql integration is live

  const collateralSymbol = collateral.isStable ? '$' : collateral.token.isNative ? 'Ξ' : `${collateral.token.symbol} `

  return (
    <>
      <PositionRow templateColumns={{ md: '100px 1fr 1fr 1fr 1fr 1fr' }}>
        <LabelCell product={product} onProductChange={onProductChange} isLong={isLong} leverage={leverage} />
        <ValueCell symbol={collateralSymbol} value={value} />
        <EntryCell price={entryPrice} />
        <CurrentCell price={markPrice} />
        <LiquidationCell price={liquidationPrice} />
        <MarginCell margin={margin} symbol={collateralSymbol} />
        <PnlCell pnl={pnl} percent={pnlPercent} />
        <ButtonCell
          position={position}
          onCancelClose={onCancelClose}
          onClosePosition={onClosePosition}
          onReferralModal={onOpenCardModal}
          onSetTab={onSetTab}
          onUserSettleOrder={onUserSettleOrder}
        />
      </PositionRow>
      {!last && <Divider />}
    </>
  )
}

function LabelCell({
  product,
  isLong,
  leverage,
  onProductChange,
}: {
  product: Product
  isLong: boolean
  leverage: number
  onProductChange: (id: string) => void
}) {
  const { green1, red2, white } = useTheme()

  return (
    <Column style={{ paddingRight: '0.5rem', alignItems: 'start', gap: '0.2rem' }}>
      <ThemedText.Small
        color="text"
        fontWeight="600"
        style={{ cursor: 'pointer' }}
        onClick={() => onProductChange(product.id)}
      >
        {product.label}
      </ThemedText.Small>
      <Row>
        <span
          style={{
            marginRight: '0.25rem',
            padding: '0.125rem 0.25rem',
            backgroundColor: isLong ? green1 : red2,
            borderRadius: '0.125rem',
          }}
        >
          <ThemedText.Small color={white} fontSize={9}>
            {isLong ? 'Long' : 'Short'}
          </ThemedText.Small>
        </span>
        <ThemedText.Small fontSize={10}>{leverage.toFixed(2)}x</ThemedText.Small>
      </Row>
    </Column>
  )
}

function ValueCell({ symbol, value }: { symbol: string; value: number }) {
  const valueFormatted = beautify(value, 4)
  return (
    <CellContent className="hideonmedium">
      <TitleSmall>
        <ThemedText.Small fontWeight={600}>Value</ThemedText.Small>
      </TitleSmall>
      <ThemedText.Small>
        {symbol}
        {valueFormatted}
      </ThemedText.Small>
    </CellContent>
  )
}

function EntryCell({ price }: { price: number }) {
  const priceFormatted = beautify(price.toFixed(2))

  return (
    <CellContent className="hideonmedium">
      <TitleSmall>
        <ThemedText.Small fontWeight={600}>Entry</ThemedText.Small>
      </TitleSmall>
      <ThemedText.Small>{priceFormatted}</ThemedText.Small>
    </CellContent>
  )
}

function CurrentCell({ price }: { price: number }) {
  const priceFormatted = beautify(price)

  return (
    <CellContent className="hideonmedium">
      <TitleSmall>
        <ThemedText.Small fontWeight={600}>Current</ThemedText.Small>
      </TitleSmall>
      <ThemedText.Small>{priceFormatted}</ThemedText.Small>
    </CellContent>
  )
}

function LiquidationCell({ price }: { price: number }) {
  const { primaryText3 } = useTheme()

  const priceFormatted = beautify(price)

  return (
    <LiqPriceRow alignItems="start" style={{ paddingRight: '0.5rem' }}>
      <ThemedText.Small color={primaryText3}>{priceFormatted}</ThemedText.Small>
    </LiqPriceRow>
  )
}

function MarginCell({ margin, symbol }: { margin: number; symbol: string }) {
  const marginFormatted = beautify(margin, 6)

  return (
    <CellContent className="hideonmedium">
      <TitleSmall>
        <ThemedText.Small fontWeight={600}>Margin</ThemedText.Small>
      </TitleSmall>
      <ThemedText.Small>
        {symbol}
        {marginFormatted}
      </ThemedText.Small>
    </CellContent>
  )
}

function PnlCell({ pnl, percent }: { pnl: number; percent: number }) {
  const { green1, red2 } = useTheme()

  const [isNumber, setIsNumber] = useState(true)

  const isZero = pnl === 0
  const isPositive = pnl > 0
  const small = Math.abs(pnl) < 0.01
  const sign = isPositive ? '+' : '-'

  const color = useMemo(() => {
    if (isZero) return ''
    if (isPositive) return green1
    return red2
  }, [green1, isPositive, isZero, red2])

  const pnlFormatted = useMemo(() => {
    if (pnl === 0) return '-'
    if (isPositive) {
      return beautify(pnl, small ? 6 : 2)
    } else {
      return beautify(pnl, small ? 6 : 2).substring(1)
    }
  }, [isPositive, pnl, small])

  const percentFormatted = useMemo(() => {
    if (isPositive) {
      return percent.toFixed(2)
    } else {
      return percent.toFixed(2).substring(1)
    }
  }, [isPositive, percent])

  return (
    <Column style={{ paddingRight: '0.5rem' }} className={'pnl-cell'}>
      <PnlContainerSmall onClick={() => setIsNumber((prev) => !prev)}>
        <TitleSmall>
          <ThemedText.Small fontWeight={600}>PnL</ThemedText.Small>
        </TitleSmall>
        {isNumber ? (
          <ThemedText.Small style={{ color }}>
            {sign}
            {pnlFormatted}
          </ThemedText.Small>
        ) : (
          <ThemedText.Small style={{ color }}>
            {isZero ? (
              ' '
            ) : (
              <>
                {sign}
                {percentFormatted}%
              </>
            )}
          </ThemedText.Small>
        )}
      </PnlContainerSmall>
      <PnlContainer>
        <ThemedText.Small style={{ color }}>
          {sign}
          {pnlFormatted}
        </ThemedText.Small>
        <ThemedText.Small style={{ color }}>
          {isZero ? (
            ' '
          ) : (
            <>
              ({sign}
              {percentFormatted}%)
            </>
          )}
        </ThemedText.Small>
      </PnlContainer>
    </Column>
  )
}

function ButtonCell({
  position,
  onCancelClose,
  onClosePosition,
  onReferralModal,
  onSetTab,
  onUserSettleOrder,
}: {
  position: EnhancedPosition
  onCancelClose: OrderFunction
  onClosePosition: OrderFunction
  onReferralModal: (props: CardProps) => void
  onSetTab: (newTab: string) => void
  onUserSettleOrder: (margin: number, product: Product, collateral: Collateral, isLong: boolean, price: number) => void
}) {
  const {
    collateral,
    isClosing,
    isLong,
    isUpdating,
    leverage,
    margin,
    markPrice,
    pnl,
    pnlPercent,
    price: entryPrice,
    product,
    size,
    value,
  } = position

  return (
    <ButtonContent className={'button-cell'}>
      {isUpdating ? (
        isClosing ? (
          <>
            {false && (
              <CloseButton
                onClick={() =>
                  onUserSettleOrder(
                    margin,
                    product,
                    collateral,
                    isLong,
                    +parseUnits(product.id === 'eth' ? '1200' : '16000', 8)
                  )
                }
              >
                <ThemedText.Small>Force Execute</ThemedText.Small>
              </CloseButton>
            )}
            <CloseButton onClick={() => onCancelClose(margin, size, 0, product, collateral, isLong)}>
              <ThemedText.Small>Cancel Close</ThemedText.Small>
            </CloseButton>
          </>
        ) : (
          <CloseButton onClick={() => onSetTab(TABS.OPEN_ORDERS)}>
            <ThemedText.Small>
              <Dots>Updating</Dots>
            </ThemedText.Small>
          </CloseButton>
        )
      ) : (
        <div style={{ display: 'flex', gap: '0.5rem' }}>
          <CloseButton
            onClick={() => onReferralModal({ product, isLong, pnl, pnlPercent, leverage, entryPrice, markPrice })}
            style={{ padding: '0.25rem 0.25rem', display: 'flex', alignItems: 'center' }}
          >
            <Share16 width={14} />
          </CloseButton>

          <CloseButton onClick={() => onClosePosition(margin, size, value, product, collateral, isLong)}>
            <ThemedText.Small>Close</ThemedText.Small>
          </CloseButton>
        </div>
      )}
    </ButtonContent>
  )
}

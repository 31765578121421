import Row from 'components/Row'

import { SORT_FIELD } from './common'
import { ClickableText, Divider, LiqPriceRow, PNLRow, PositionHeaderGrid, SymbolRow } from './styled'

export default function PositionHeader({
  breakpoint,
  sortDirection,
  onSetSortDirection,
  sortedColumn,
  onSetSortedColumn,
  active,
}: {
  breakpoint: string
  sortDirection: boolean
  onSetSortDirection: React.Dispatch<React.SetStateAction<boolean>>
  sortedColumn: string
  onSetSortedColumn: React.Dispatch<React.SetStateAction<string>>
  active: boolean
}) {
  return (
    <>
      <PositionHeaderGrid templateColumns={{ md: '100px 1fr 1fr 1fr 1fr 1fr' }} templateAreas={{ md: 'contract size' }}>
        <Row alignItems="center" justifyContent="flexStart">
          <ClickableText
            color="text"
            area="contract"
            fontWeight="500"
            onClick={(e) => {
              if (active) {
                onSetSortedColumn(SORT_FIELD.CONTRACT)
                onSetSortDirection(sortedColumn !== SORT_FIELD.CONTRACT ? true : !sortDirection)
              }
            }}
          >
            Contract
            <div style={{ minWidth: '0.5rem' }}>
              {sortedColumn === SORT_FIELD.CONTRACT ? (!sortDirection ? '↑' : '↓') : ''}
            </div>
          </ClickableText>
        </Row>
        {false && (
          <SymbolRow alignItems="center">
            <ClickableText
              area="size"
              onClick={() => {
                if (active) {
                  onSetSortedColumn(SORT_FIELD.SIZE)
                  onSetSortDirection(sortedColumn !== SORT_FIELD.SIZE ? true : !sortDirection)
                }
              }}
            >
              Size
              <div style={{ minWidth: '0.5rem' }}>
                {sortedColumn === SORT_FIELD.SIZE ? (!sortDirection ? '↑' : '↓') : ''}
              </div>
            </ClickableText>
          </SymbolRow>
        )}
        <Row alignItems="center" className="hideonmedium">
          <ClickableText
            area="value"
            onClick={() => {
              if (active) {
                onSetSortedColumn(SORT_FIELD.VALUE)
                onSetSortDirection(sortedColumn !== SORT_FIELD.VALUE ? true : !sortDirection)
              }
            }}
          >
            Value
            <div style={{ minWidth: '0.5rem' }}>
              {sortedColumn === SORT_FIELD.VALUE ? (!sortDirection ? '↑' : '↓') : ''}
            </div>
          </ClickableText>
        </Row>
        <Row alignItems="center" className="hideonmedium">
          <ClickableText
            area="entry"
            style={{ textOverflow: 'clip', overflow: 'hidden', whiteSpace: 'nowrap' }}
            onClick={() => {
              if (active) {
                onSetSortedColumn(SORT_FIELD.ENTRY_PRICE)
                onSetSortDirection(sortedColumn !== SORT_FIELD.ENTRY_PRICE ? true : !sortDirection)
              }
            }}
          >
            Entry Price
            <div style={{ minWidth: '0.5rem' }}>
              {sortedColumn === SORT_FIELD.ENTRY_PRICE ? (!sortDirection ? '↑' : '↓') : ''}
            </div>
          </ClickableText>
        </Row>
        <Row alignItems="center">
          <ClickableText
            area="mark"
            style={{ textOverflow: 'clip', overflow: 'hidden', whiteSpace: 'nowrap' }}
            onClick={() => {
              if (active) {
                onSetSortedColumn(SORT_FIELD.MARK_PRICE)
                onSetSortDirection(sortedColumn !== SORT_FIELD.MARK_PRICE ? true : !sortDirection)
              }
            }}
          >
            Current Price
            <div style={{ minWidth: '0.5rem' }}>
              {sortedColumn === SORT_FIELD.MARK_PRICE ? (!sortDirection ? '↑' : '↓') : ''}
            </div>
          </ClickableText>
        </Row>
        <LiqPriceRow alignItems="center">
          <ClickableText
            area="liq"
            onClick={() => {
              if (active) {
                onSetSortedColumn(SORT_FIELD.LIQ_PRICE)
                onSetSortDirection(sortedColumn !== SORT_FIELD.LIQ_PRICE ? true : !sortDirection)
              }
            }}
          >
            Liq. Price
            <div style={{ minWidth: '0.5rem' }}>
              {sortedColumn === SORT_FIELD.LIQ_PRICE ? (!sortDirection ? '↑' : '↓') : ''}
            </div>
          </ClickableText>
        </LiqPriceRow>
        <Row alignItems="center">
          <ClickableText
            area="margin"
            onClick={() => {
              if (active) {
                onSetSortedColumn(SORT_FIELD.MARGIN)
                onSetSortDirection(sortedColumn !== SORT_FIELD.MARGIN ? true : !sortDirection)
              }
            }}
          >
            Margin
            <div style={{ minWidth: '0.5rem' }}>
              {sortedColumn === SORT_FIELD.MARGIN ? (!sortDirection ? '↑' : '↓') : ''}
            </div>
          </ClickableText>
        </Row>
        <PNLRow alignItems="center">
          <ClickableText
            area="pnl"
            onClick={() => {
              if (active) {
                onSetSortedColumn(SORT_FIELD.PNL)
                onSetSortDirection(sortedColumn !== SORT_FIELD.PNL ? true : !sortDirection)
              }
            }}
          >
            PnL
            <div style={{ minWidth: '0.5rem' }}>
              {sortedColumn === SORT_FIELD.PNL ? (!sortDirection ? '↑' : '↓') : ''}
            </div>
          </ClickableText>
        </PNLRow>
      </PositionHeaderGrid>
      <Divider />
    </>
  )
}

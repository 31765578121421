import { format as formatDateFn } from 'date-fns'

function formatDateTime(time) {
  return formatDateFn(time * 1000, 'dd MMM yyyy, h:mm a')
}

const dummyDataSeries = [
  {
    open: 3076.03,
    high: 3180,
    low: 2942.31,
    close: 2983.58,
    time: formatDateTime(1650499200),
  },
  {
    open: 2983.57,
    high: 3034.32,
    low: 2930.35,
    close: 2962.39,
    time: formatDateTime(1650585600),
  },
  {
    open: 2962.39,
    high: 2978.03,
    low: 2913.89,
    close: 2933.03,
    time: formatDateTime(1650672000),
  },
  {
    open: 2933.04,
    high: 2967.06,
    low: 2912.09,
    close: 2921,
    time: formatDateTime(1650758400),
  },
  {
    open: 2920.99,
    high: 3025.65,
    low: 2797.01,
    close: 3006.62,
    time: formatDateTime(1650844800),
  },
  {
    open: 3006.63,
    high: 3039.54,
    low: 2767,
    close: 2809.67,
    time: formatDateTime(1650931200),
  },
  {
    open: 2809.68,
    high: 2919.87,
    low: 2794.46,
    close: 2888.96,
    time: formatDateTime(1651017600),
  },
  {
    open: 2888.95,
    high: 2980,
    low: 2853.83,
    close: 2936.93,
    time: formatDateTime(1651104000),
  },
  {
    open: 2936.92,
    high: 2946.46,
    low: 2776.63,
    close: 2817.13,
    time: formatDateTime(1651190400),
  },
  {
    open: 2817.13,
    high: 2841.63,
    low: 2709.26,
    close: 2726.66,
    time: formatDateTime(1651276800),
  },
  {
    open: 2726.67,
    high: 2849.9,
    low: 2716.13,
    close: 2824.81,
    time: formatDateTime(1651363200),
  },
  {
    open: 2824.81,
    high: 2894.22,
    low: 2778.78,
    close: 2856.54,
    time: formatDateTime(1651449600),
  },
  {
    open: 2856.54,
    high: 2861.47,
    low: 2755.19,
    close: 2781.7,
    time: formatDateTime(1651536000),
  },
  {
    open: 2781.7,
    high: 2965.85,
    low: 2771.74,
    close: 2940.64,
    time: formatDateTime(1651622400),
  },
  {
    open: 2940.65,
    high: 2954.65,
    low: 2683,
    close: 2747.97,
    time: formatDateTime(1651708800),
  },
  {
    open: 2747.96,
    high: 2758.18,
    low: 2632.95,
    close: 2692.85,
    time: formatDateTime(1651795200),
  },
  {
    open: 2692.86,
    high: 2704.36,
    low: 2584.75,
    close: 2635.34,
    time: formatDateTime(1651881600),
  },
  {
    open: 2635.35,
    high: 2642,
    low: 2484,
    close: 2519.71,
    time: formatDateTime(1651968000),
  },
  {
    open: 2519.71,
    high: 2530.78,
    low: 2225,
    close: 2228.55,
    time: formatDateTime(1652054400),
  },
  {
    open: 2229,
    high: 2457.34,
    low: 2200,
    close: 2342.05,
    time: formatDateTime(1652140800),
  },
  {
    open: 2342.04,
    high: 2451.37,
    low: 2005,
    close: 2084.99,
    time: formatDateTime(1652227200),
  },
  {
    open: 2084.99,
    high: 2195,
    low: 1800,
    close: 1960.22,
    time: formatDateTime(1652313600),
  },
  {
    open: 1960.22,
    high: 2147.54,
    low: 1940.23,
    close: 2009.69,
    time: formatDateTime(1652400000),
  },
  {
    open: 2009.69,
    high: 2070.06,
    low: 1951.21,
    close: 2056.48,
    time: formatDateTime(1652486400),
  },
  {
    open: 2056.47,
    high: 2167,
    low: 2000.68,
    close: 2145.29,
    time: formatDateTime(1652572800),
  },
  {
    open: 2145.29,
    high: 2145.29,
    low: 1979.12,
    close: 2023.66,
    time: formatDateTime(1652659200),
  },
  {
    open: 2023.66,
    high: 2122.42,
    low: 2008,
    close: 2090.72,
    time: formatDateTime(1652745600),
  },
  {
    open: 2090.72,
    high: 2110.72,
    low: 1908,
    close: 1915.11,
    time: formatDateTime(1652832000),
  },
  {
    open: 1915.11,
    high: 2040.39,
    low: 1903.18,
    close: 2019.55,
    time: formatDateTime(1652918400),
  },
  {
    open: 2019.55,
    high: 2064,
    low: 1923,
    close: 1959.08,
    time: formatDateTime(1653004800),
  },
  {
    open: 1959.08,
    high: 1990.86,
    low: 1937.36,
    close: 1975.07,
    time: formatDateTime(1653091200),
  },
  {
    open: 1975.06,
    high: 2056.41,
    low: 1966.33,
    close: 2043.19,
    time: formatDateTime(1653177600),
  },
  {
    open: 2043.19,
    high: 2088.5,
    low: 1957.12,
    close: 1973.32,
    time: formatDateTime(1653264000),
  },
  {
    open: 1973.33,
    high: 1993.83,
    low: 1913.02,
    close: 1979.41,
    time: formatDateTime(1653350400),
  },
  {
    open: 1979.4,
    high: 2022.34,
    low: 1934.61,
    close: 1942.63,
    time: formatDateTime(1653436800),
  },
  {
    open: 1942.64,
    high: 1966.01,
    low: 1735,
    close: 1792.23,
    time: formatDateTime(1653523200),
  },
  {
    open: 1792.24,
    high: 1822.26,
    low: 1703,
    close: 1727.27,
    time: formatDateTime(1653609600),
  },
  {
    open: 1727.26,
    high: 1808.98,
    low: 1722.26,
    close: 1792.22,
    time: formatDateTime(1653696000),
  },
  {
    open: 1792.21,
    high: 1827.4,
    low: 1759.96,
    close: 1813.64,
    time: formatDateTime(1653782400),
  },
  {
    open: 1813.64,
    high: 2013,
    low: 1803.18,
    close: 1998.78,
    time: formatDateTime(1653868800),
  },
  {
    open: 1998.78,
    high: 2016.45,
    low: 1925,
    close: 1941.9,
    time: formatDateTime(1653955200),
  },
  {
    open: 1941.9,
    high: 1972.58,
    low: 1763.75,
    close: 1817.95,
    time: formatDateTime(1654041600),
  },
  {
    open: 1817.96,
    high: 1853.05,
    low: 1782.42,
    close: 1834.08,
    time: formatDateTime(1654128000),
  },
  {
    open: 1834.09,
    high: 1843.91,
    low: 1737,
    close: 1775.12,
    time: formatDateTime(1654214400),
  },
  {
    open: 1775.13,
    high: 1816,
    low: 1748.43,
    close: 1804.58,
    time: formatDateTime(1654300800),
  },
  {
    open: 1804.58,
    high: 1830.17,
    low: 1773.05,
    close: 1806.23,
    time: formatDateTime(1654387200),
  },
  {
    open: 1806.22,
    high: 1920,
    low: 1804.35,
    close: 1859.84,
    time: formatDateTime(1654473600),
  },
  {
    open: 1859.84,
    high: 1874.98,
    low: 1725.13,
    close: 1813.33,
    time: formatDateTime(1654560000),
  },
  {
    open: 1813.33,
    high: 1838.77,
    low: 1765.28,
    close: 1791.88,
    time: formatDateTime(1654646400),
  },
  {
    open: 1791.88,
    high: 1833,
    low: 1777.33,
    close: 1788.81,
    time: formatDateTime(1654732800),
  },
  {
    open: 1788.8,
    high: 1804.32,
    low: 1655.93,
    close: 1662.91,
    time: formatDateTime(1654819200),
  },
  {
    open: 1662.91,
    high: 1682.21,
    low: 1496.74,
    close: 1532.89,
    time: formatDateTime(1654905600),
  },
  {
    open: 1532.89,
    high: 1547,
    low: 1423,
    close: 1434.84,
    time: formatDateTime(1654992000),
  },
  {
    open: 1434.84,
    high: 1455,
    low: 1165.45,
    close: 1209.82,
    time: formatDateTime(1655078400),
  },
  {
    open: 1209.35,
    high: 1268.45,
    low: 1075.8,
    close: 1208.9,
    time: formatDateTime(1655164800),
  },
  {
    open: 1208.9,
    high: 1250,
    low: 1014.4,
    close: 1237.52,
    time: formatDateTime(1655251200),
  },
  {
    open: 1237.53,
    high: 1257.85,
    low: 1051.55,
    close: 1068.5,
    time: formatDateTime(1655337600),
  },
  {
    open: 1068.5,
    high: 1118,
    low: 1051.11,
    close: 1086.94,
    time: formatDateTime(1655424000),
  },
  {
    open: 1086.93,
    high: 1097.58,
    low: 881.56,
    close: 995.13,
    time: formatDateTime(1655510400),
  },
  {
    open: 995.12,
    high: 1158.62,
    low: 936.15,
    close: 1128.53,
    time: formatDateTime(1655596800),
  },
  {
    open: 1128.53,
    high: 1170,
    low: 1053,
    close: 1128.24,
    time: formatDateTime(1655683200),
  },
  {
    open: 1128.24,
    high: 1194,
    low: 1109.23,
    close: 1125.85,
    time: formatDateTime(1655769600),
  },
  {
    open: 1125.86,
    high: 1127.33,
    low: 1043.65,
    close: 1050.19,
    time: formatDateTime(1655856000),
  },
  {
    open: 1050.19,
    high: 1155,
    low: 1045.3,
    close: 1144.72,
    time: formatDateTime(1655942400),
  },
  {
    open: 1144.71,
    high: 1245.43,
    low: 1132,
    close: 1225.02,
    time: formatDateTime(1656028800),
  },
  {
    open: 1225.02,
    high: 1256.77,
    low: 1180,
    close: 1242.36,
    time: formatDateTime(1656115200),
  },
  {
    open: 1242.31,
    high: 1280,
    low: 1194.6,
    close: 1197.79,
    time: formatDateTime(1656201600),
  },
  {
    open: 1197.79,
    high: 1238.93,
    low: 1172.49,
    close: 1192.51,
    time: formatDateTime(1656288000),
  },
  {
    open: 1192.51,
    high: 1237.99,
    low: 1136.32,
    close: 1144.05,
    time: formatDateTime(1656374400),
  },
  {
    open: 1144.05,
    high: 1156.8,
    low: 1087.42,
    close: 1100.21,
    time: formatDateTime(1656460800),
  },
  {
    open: 1100.21,
    high: 1106.62,
    low: 998,
    close: 1071.01,
    time: formatDateTime(1656547200),
  },
  {
    open: 1071.02,
    high: 1117,
    low: 1033.44,
    close: 1059.73,
    time: formatDateTime(1656633600),
  },
  {
    open: 1059.73,
    high: 1078.88,
    low: 1028.29,
    close: 1067.01,
    time: formatDateTime(1656720000),
  },
  {
    open: 1067.01,
    high: 1088.34,
    low: 1040.78,
    close: 1074.26,
    time: formatDateTime(1656806400),
  },
  {
    open: 1074.26,
    high: 1160.9,
    low: 1045.37,
    close: 1151,
    time: formatDateTime(1656892800),
  },
  {
    open: 1151.01,
    high: 1174.9,
    low: 1076.34,
    close: 1132.5,
    time: formatDateTime(1656979200),
  },
  {
    open: 1132.51,
    high: 1205,
    low: 1111.44,
    close: 1186.57,
    time: formatDateTime(1657065600),
  },
  {
    open: 1186.57,
    high: 1254.29,
    low: 1163.18,
    close: 1237.49,
    time: formatDateTime(1657152000),
  },
  {
    open: 1237.49,
    high: 1276.46,
    low: 1193.15,
    close: 1214.04,
    time: formatDateTime(1657238400),
  },
  {
    open: 1214.03,
    high: 1235.4,
    low: 1204.67,
    close: 1217.02,
    time: formatDateTime(1657324800),
  },
  {
    open: 1217.02,
    high: 1219.95,
    low: 1153,
    close: 1168.36,
    time: formatDateTime(1657411200),
  },
  {
    open: 1168.37,
    high: 1171.29,
    low: 1091.71,
    close: 1096.4,
    time: formatDateTime(1657497600),
  },
  {
    open: 1096.37,
    high: 1098.26,
    low: 1033.46,
    close: 1038.58,
    time: formatDateTime(1657584000),
  },
  {
    open: 1038.58,
    high: 1119.18,
    low: 1006.32,
    close: 1115,
    time: formatDateTime(1657670400),
  },
  {
    open: 1115.01,
    high: 1214.65,
    low: 1072.11,
    close: 1193.42,
    time: formatDateTime(1657756800),
  },
  {
    open: 1193.42,
    high: 1287.82,
    low: 1181.05,
    close: 1231.25,
    time: formatDateTime(1657843200),
  },
  {
    open: 1231.25,
    high: 1422.91,
    low: 1191.23,
    close: 1355.85,
    time: formatDateTime(1657929600),
  },
  {
    open: 1355.84,
    high: 1387,
    low: 1319.04,
    close: 1338.65,
    time: formatDateTime(1658016000),
  },
  {
    open: 1338.65,
    high: 1597.47,
    low: 1336.07,
    close: 1581.04,
    time: formatDateTime(1658102400),
  },
  {
    open: 1581.03,
    high: 1631,
    low: 1493.11,
    close: 1542.32,
    time: formatDateTime(1658188800),
  },
  {
    open: 1542.33,
    high: 1620.4,
    low: 1484.23,
    close: 1521.58,
    time: formatDateTime(1658275200),
  },
  {
    open: 1521.63,
    high: 1605.98,
    low: 1463.56,
    close: 1575.45,
    time: formatDateTime(1658361600),
  },
  {
    open: 1575.45,
    high: 1650.05,
    low: 1516.11,
    close: 1535.27,
    time: formatDateTime(1658448000),
  },
  {
    open: 1535.27,
    high: 1596.51,
    low: 1488,
    close: 1548.67,
    time: formatDateTime(1658534400),
  },
  {
    open: 1548.66,
    high: 1664.34,
    low: 1545.67,
    close: 1597.7,
    time: formatDateTime(1658620800),
  },
  {
    open: 1597.7,
    high: 1609.55,
    low: 1435.58,
    close: 1440.79,
    time: formatDateTime(1658707200),
  },
  {
    open: 1440.79,
    high: 1454.11,
    low: 1356.17,
    close: 1449.12,
    time: formatDateTime(1658793600),
  },
  {
    open: 1449.12,
    high: 1644.44,
    low: 1420.83,
    close: 1635.74,
    time: formatDateTime(1658880000),
  },
  {
    open: 1635.74,
    high: 1784.79,
    low: 1593.27,
    close: 1724.52,
    time: formatDateTime(1658966400),
  },
  {
    open: 1724.52,
    high: 1765.99,
    low: 1655.02,
    close: 1721.68,
    time: formatDateTime(1659052800),
  },
  {
    open: 1721.68,
    high: 1744.85,
    low: 1673.01,
    close: 1697,
    time: formatDateTime(1659139200),
  },
  {
    open: 1697,
    high: 1754.69,
    low: 1666,
    close: 1678.12,
    time: formatDateTime(1659225600),
  },
  {
    open: 1678.12,
    high: 1704.68,
    low: 1606.12,
    close: 1630.28,
    time: formatDateTime(1659312000),
  },
  {
    open: 1630.28,
    high: 1678,
    low: 1559.28,
    close: 1630.91,
    time: formatDateTime(1659398400),
  },
  {
    open: 1630.91,
    high: 1685.03,
    low: 1589.71,
    close: 1618.16,
    time: formatDateTime(1659484800),
  },
  {
    open: 1618.16,
    high: 1662.79,
    low: 1580.39,
    close: 1607.96,
    time: formatDateTime(1659571200),
  },
  {
    open: 1607.97,
    high: 1742.07,
    low: 1605.64,
    close: 1736.81,
    time: formatDateTime(1659657600),
  },
  {
    open: 1736.81,
    high: 1749.93,
    low: 1685.26,
    close: 1690.6,
    time: formatDateTime(1659744000),
  },
  {
    open: 1690.6,
    high: 1729.79,
    low: 1668,
    close: 1700.19,
    time: formatDateTime(1659830400),
  },
  {
    open: 1700.2,
    high: 1818,
    low: 1693.87,
    close: 1777.05,
    time: formatDateTime(1659916800),
  },
  {
    open: 1777.05,
    high: 1790.83,
    low: 1667.93,
    close: 1702.76,
    time: formatDateTime(1660003200),
  },
  {
    open: 1702.76,
    high: 1885,
    low: 1656.78,
    close: 1853.57,
    time: formatDateTime(1660089600),
  },
  {
    open: 1853.58,
    high: 1942,
    low: 1850.32,
    close: 1880.19,
    time: formatDateTime(1660176000),
  },
  {
    open: 1880.19,
    high: 1964.71,
    low: 1853.06,
    close: 1958.28,
    time: formatDateTime(1660262400),
  },
  {
    open: 1958.28,
    high: 2020,
    low: 1946.5,
    close: 1983.55,
    time: formatDateTime(1660348800),
  },
  {
    open: 1983.55,
    high: 2030,
    low: 1906,
    close: 1935.31,
    time: formatDateTime(1660435200),
  },
  {
    open: 1935.32,
    high: 2012.47,
    low: 1872.31,
    close: 1899.06,
    time: formatDateTime(1660521600),
  },
  {
    open: 1899.05,
    high: 1915,
    low: 1853,
    close: 1876.67,
    time: formatDateTime(1660608000),
  },
  {
    open: 1876.67,
    high: 1959.43,
    low: 1818.74,
    close: 1834.25,
    time: formatDateTime(1660694400),
  },
  {
    open: 1834.24,
    high: 1882.37,
    low: 1821.25,
    close: 1846.39,
    time: formatDateTime(1660780800),
  },
  {
    open: 1846.39,
    high: 1848,
    low: 1602.37,
    close: 1609.01,
    time: formatDateTime(1660867200),
  },
  {
    open: 1609,
    high: 1657.08,
    low: 1523.67,
    close: 1576.04,
    time: formatDateTime(1660953600),
  },
  {
    open: 1576.03,
    high: 1646.52,
    low: 1562.34,
    close: 1618.13,
    time: formatDateTime(1661040000),
  },
  {
    open: 1618.13,
    high: 1619.47,
    low: 1599.21,
    close: 1604.52,
    time: formatDateTime(1661126400),
  },
]

export default dummyDataSeries
